const wellbeingProgramme = {
    pagetitle: `
        The Wellbeing Programme
    `, 
    introTitle: `
    Programme Introduction
    `, 
    introDesc: `
    The World Health Organisation (WHO) declares that health is "a state of complete physical, mental and social wellbeing and not merely the absence of disease or infirmity". 
    The inclusion of “mental and social wellbeing” in WHO’s definition, highlights the importance of incorporating Mental Health and Wellbeing training into our educational programme. 
    In response to this, Off the Curriculum has partnered with Nuture with Education to create our ‘Wellbeing Programme’. This programme will ensure that Mental Health and Wellbeing is considered throughout our Seminar Series.
    `, 
    programmeleadsTitle: `
    Programme Leads
    `,
    programmeLeadsDesc: `
    The Wellbeing Programme is led by Nicole Curtis, MSc and Sinead Reed Forester, MSc. Nicole Curtis is the Chief Communications Officer at Off the Curriculum and works within Mental Health and Education. 
    She is passionate about providing young-people with a platform to speak openly about their mental health, believing it is an integral part of the learning process and the key to self-actualisation. Sinead Reed-Forester is the founder of Nurture with Education and a Holistic Tutor for neurodivergent students. 
    She has ten years experience, including six years working as a specialist mentor at university, and believes changing our attitudes towards mental health will lead to the decolonisation of education.
    `, 
    programmeaimsTitle:`
    Programme Aims
    `, 
    programmeaimsDesc:`
    The aim of OTC's Wellbeing Programme is to equip our participants' with the knowledge on how to better navigate life stresses, leading to the creation of a flourishing educational environment for both participants and volunteers at Off the Curriculum.
    `,  
    programmeplanTitle:
    `
    Programme Plan
    `, 
    programmeplanDesc:`
    The Wellbeing Programme will consist of bi-termly seminars (one seminar every second term) on a range of wellbeing, mental health, and resilience topics.
    The topics of this Wellbeing programme will be entirely decided upon by our programme participants, where the students nominate the topics they wish to explore, and our project leads will go about organising seminars aligned to such.
    `, 
    programmeevaluationTitle: `
    Programme Evaluation
    `, 
    programmeevaluationDesc: `
    Throughout enrollment in the OTC programme, our participants will be invited to answer our ‘Wellbeing Programme Questionnaire’ (Rosenberg, 1965). 
    This is a 10-item scale that measures global self-worth by measuring the positive and negative feelings an individual may feel toward themselves. All statements are answered using a four-point Likert scale, ranging from strongly agree to strongly disagree.
    `, 
    programmerationaleTitle: `
    Rationale
    `, 
    programmerationaleDesc: `
    There exists some contention with regards to whether student self-esteem improves academic performance (ref), with a link to later employment satisfaction, though a causal relationship has yet not been established. Nonetheless, self-esteem has a strong relation to happiness, and increasing levels of esteem can lead to further happiness and prosperity. 
    Through this Wellbeing Programme, Off the Curriculum aims to build our students self-esteem and motivation, resulting in students reporting enhanced initiative, knowledge and positive self-image.
    `, 


}

export {
    wellbeingProgramme

}