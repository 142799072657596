import React from 'react';
import eductionTeam from '../../../images/functionality/main/education/education/eductionTeam.webp';
import './MainStyling/EducationStyling.css';
import { extendedProject } from './Media/EPQ';

function EPQ() {
    return ( 
    <React.Fragment>
        <div className='centre-frame'>
        <div className="second-second-Education">
            <h1 className='educationHeader' id='EPQ-Introduction'>
                {extendedProject.pageTitle}
            </h1>
                <p className="educationP">
                    {extendedProject.pageIntroduction}
                </p>

                <h2 className="educationHeader" id="EPQ-programme-itinerary">
                    {extendedProject.headerTwo}
                </h2>
                    <h3 id="Milestones">
                        {extendedProject.headerThree}
                    </h3>
                <p className="educationP">
                    {extendedProject.milestonesDesc}
                </p>
                    <h3 id="OTC-led-literature-review">
                        {extendedProject.headerFour}
                    </h3>
                <p className="educationP">
                    {extendedProject.litreviewDesc}
                </p>
                    <h3 id="Mentor-programme">
                        {extendedProject.mentorproTitle}
                    </h3>
                <p className="educationP">
                    {extendedProject.mentorproDesc}
                </p>
                <h2 id="Recruitment-and-training-for-mentors">
                    {extendedProject.recruitmentTitle}
                </h2>
                <h3 id="Recruitment">
                    {extendedProject.recruitmentsubHeading}
                </h3>
                <p className="educationP">
                    {extendedProject.recruitmentP}
                </p>
                <h3 id = "Training">
                    {extendedProject.recruitmentsubHeading2}
                </h3>
                <p className="educationP">
                    {extendedProject.trainingDesc}
                </p>
                <h2 id = "Review-of-programme">
                    {extendedProject.evaluationTitle}
                </h2>
                <p className="educationP">
                    {extendedProject.evaulationDesc}
                </p>
            </div>
            <div className="one-second-Education">
                <div className="columnEducationOne">
                    <img src={eductionTeam} className="authorEducation" alt="Education team photo at Off the Curriculum"></img>
                        <ul className="news_article_summary">
                        <br />
                    <div>
                    </div>
                </ul>
                <div className="news-icon">
                    <div className="footer_center">
                    </div>
                </div>
                    <h2 className="About_the_author">About the team:</h2>
                        <p className="prose_about_the_author">
                            The Education team is in charge of developing Off the Curriculum's pedagogic philosophy. The Education team is responsible for our Seminar Series, reviewing EDUCO content, and facilitating the EPQ programme. 
                        </p>
                    </div>
                    <div className='columnEducationTwo'>
                        <h4 className="educationquickLinks">
                            Article quick links:
                        </h4>
                        <li className="news_article"><a className="news_article" href="#EPQ-Introduction">
                            Introduction
                        </a></li>
                        <li className="news_article"><a className="news_article" href="#EPQ-programme-itinerary">
                            EPQ Programme Itinerary
                        </a></li>
                        <li className="news_article"><a className="news_article" href="#Milestones">
                            Milestones
                        </a></li>
                        <li className="news_article"><a className="news_article" href="#OTC-led-literature-review">
                            OTC led literature review
                        </a></li>
                        <li className="news_article"><a className="news_article" href="#Mentor-programme">
                            Mentor programme
                        </a></li>
                        <li className="news_article"><a className="news_article" href="#Recruitment-and-training-for-mentors">
                            Recruitment-and-training-for-mentors
                        </a></li>
                        <li className="news_article"><a className="news_article" href="#Recruitment">
                            Recruitment
                        </a></li>
                        <li className="news_article"><a className="news_article" href="#Training">
                            Training
                        </a></li>
                        <li className="news_article"><a className="news_article" href="#Review-of-programme">
                            Review of programme
                        </a></li>
                    </div>
        </div>
        </div>
    </React.Fragment>
    );
    
}
export default EPQ