import React from 'react';

const EllipsisText = ({ text, tag = 'p', maxLength = 100 }) => {
  const trimmedText = text.slice(0, maxLength);
  const isOverflowing = text.length > maxLength;

  return React.createElement(tag, {
    title: isOverflowing ? text : null,
    style: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  }, isOverflowing ? trimmedText + '...' : text);
};

export default EllipsisText;
