import { createContext, useState, useEffect } from "react";
import { ref, onValue, query } from "firebase/database";
import { db, auth } from "./firebase";

function emails(s) {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

export const User = ({ children }) => {
  const [userDetails, setUserDetails] = useState(null)
  console.log('running')
  useEffect(() => {
    const fetchData = async () => {
      const currentUser = auth.currentUser;
      console.log('currentUser:', currentUser); // Check currentUser directly

      if (!currentUser) return;

      const emailHash = emails(currentUser.email);
      console.log('emailHash:', emailHash);

      const dbRef = query(ref(db, `person/${emailHash}`));

      onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.val() == null) {
            console.log('No data found');
          } else {
            setUserDetails(snapshot.val());
          }
        },
        {
          onlyOnce: true,
        }
      );
    };

    fetchData();
  }, []);

  return (
    <UserDetails.Provider value={userDetails}>
      {children}
    </UserDetails.Provider>
  );
};
export const UserDetails = createContext(User); 