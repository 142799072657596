import "./stylingSheet/createProfileSkinTone.css";

export function Pics() {
  let Head = [
    "afro",
    " bangs",
    "bangs2",
    "bantuKnots",
    "bear",
    "bun",
    "bun2",
    "buns",
    "cornrows",
    "cornrows2",
    "dreads1",
    "dreads2",
    "flatTop",
    "flatTopLong",
    "grayBun",
    "grayMedium",
    "grayShort",
    "hatBeanie",
    "hatHip",
    "hijab",
    "long",
    "longAfro",
    "longBangs",
    "longCurly",
    "medium1",
    "medium2",
    "medium3",
    "mediumBangs",
    "mediumBangs2",
    "mediumBangs3",
    " mediumStraight",
    "mohawk",
    "mohawk2",
    "noHair1",
    "noHair2",
    "noHair3",
    " pomp",
    "shaved1",
    "shaved2",
    "shaved3",
    "short1",
    "short2",
    "short3",
    "short4",
    "short5",
    "turban",
    "twists",
    "twists2",
  ];

  let skin = ["variant01", "variant02", "variant03", "variant04", "variant05"];

  let clothing = [
    "red01",
    "orange01",
    "yellow01",
    "green01",
    "tail01",
    "blue01",
    "pink01",
  ];
  let src = "";

  function backgroundChange(e) {
    let ids = e.target.selectedOptions[0].id;

    switch (ids) {
      case "variant01":
        e.target.style.backgroundColor = "#f2d3b1";
        break;
      case "variant02":
        e.target.style.backgroundColor = "#da9666";
        break;
        case "variant03":
          e.target.style.backgroundColor = "#9e5622";
          break;
          case "variant04":
            e.target.style.backgroundColor = "#7a3d04";
            break;
            case "variant05":
              e.target.style.backgroundColor = "#3d1e03";
              break;
      default:
        e.target.style.backgroundColor = "white";
    }
  }

  function ShowImage() {
    const url = "https://avatars.dicebear.com/api/open-peeps/";
    let clothing = document.getElementById("clothing").value;
    let head = document.getElementById("head").value;
    let skinsChoice = document.getElementById("skin");
    let skins = skinsChoice[skinsChoice.selectedIndex].id;
 

    src =
      url +
      "profile.svg?head=" +
      head +
      "&skinColor=" +
      skins +
      "&clothingColor=" +
      clothing;
    //console.log(src);
    let holder = document.getElementById("image");
    ////console.log(holder);
    let pic = document.createElement("img");
    pic.src = src;
    pic.alt = " profile pic";
    pic.id = "picProfile";
    if (holder.childNodes.length > 0) {
      if (holder.innerHTML !== "") {
        holder.innerHTML = "";
      }
      holder.insertAdjacentElement("afterbegin", pic);
    }
    holder.insertAdjacentElement("afterbegin", pic);
  }
  return (
    <div className="setProfile">

      <div className="options">
        <h2>Design your avatar picture</h2>
        <label htmlFor="clothing">Choose your clothing colour</label>
        <select onChange={this} id="clothing" required>
          <option>-- Choose your Clothing colour --</option>{" "}
          {clothing.map((color, i) => {
            return <option key={i}>{color}</option>;
          })}{" "}
        </select>
        <label htmlFor="chair">Choose your hair style</label>
        <select placeholder="Hair" onChange={this} id="head" required>
          <option value="">-- choose your hair style --</option>{" "}
          {Head.map((items, i) => {
            return <option key={i}>{items}</option>;
          })}{" "}
        </select>
        <label htmlFor="chair">Choose your skin tone</label>
        <select
          placeholder="skin"
          onChange={this}
          onInput={(e) => {
            backgroundChange(e);
          }}
          id="skin"
          required
        >
          <option value="">-- choose your skin tone --</option>{" "}
          {skin.map((item, i) => {
            return (
              <option key={i} id={item}>
             {item}
              </option>
            );
          })}{" "}
        </select>
      </div>
      <div className="avatar">
      <div className="image" id="image">
        <img src='https://avatars.dicebear.com/api/open-peeps/profile.svg?head=bear&skinColor=variant02&clothingColor=tail01' alt='default avatar' />
        </div>
        <button onClick={ShowImage} id="showImage">
          Click to see your new Avatar!
        </button>
        <h4>
          Use the options on the right to design your Avatar when you are happy
          with your design, enter your name and email to create your profile
        </h4>
      </div>
    </div>
  );
}