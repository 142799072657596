import { useState, useEffect, useRef, createContext } from "react";
import "./EducoHome.css";
import EllipsisText from "../../../pages/educo/Components/ellipsisText/EllipsisText";

//firebase inf
import { db } from "../../../firebase/firebase";
import { ref, onValue, query } from "firebase/database";

export const vCounter = createContext(9);

export default function EducoFinal() {
  const [videoCounter, setVideoCounter] = useState(9);
  const [vName, setVName] = useState([]);
  const records = useRef([]);

  function card(
    aHref,
    aName,
    imgSrc,
    imgId,
    imgName,
    imgAlt,
    Title,
    description,
    Author,
    Likes,
    Views, 
    videoSnippet
  ) {
    return {
      aHref,
      aName,
      imgSrc,
      imgId,
      imgName,
      imgAlt,
      Title,
      description,
      Author,
      Likes,
      Views,
      videoSnippet
    };
  }

  const dbRef = query(ref(db, "video/name/"));

  useEffect(() => {
    const listener = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const tempRecords = [];
        snapshot.forEach((childSnapshot) => {
          const data = childSnapshot.val();
          if (data) {
            const cardData = card(
              data.videohref,
              "seminarLink",
              data.videoImage,
              data.key,
              "videoCard",
              "seminar card",
              data.key,
              data.videoDesc,
              data.author,
              data.likes,
              data.count, 
              data.videoSnippet
            );

            if (cardData.Author !== undefined) {
              tempRecords.push(cardData);
            }
          }
        });
        records.current = tempRecords;
            // Load the first nine cards on initial page load
    if (records.current.length > 0) {
      const firstNine = records.current.slice(0, videoCounter);
      setVName(firstNine);
    }
      }
    });

    return () => {
      // Clean up the listener on unmount
      listener();
    };
  }, []);

  const increaseCounter = () => {
    setVideoCounter((prevCount) => prevCount + 9);
  };

  const renderCards = () => {
    const firstNine = records.current.slice(0, videoCounter);
    const ImageComponent = ({ imageSrc, imgId, imgName, imgAlt, videoSrc }) => {
      const [isHovered, setIsHovered] = useState(false);

      const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };
    
      return (
        <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
   <img
        src={imageSrc}
        alt={imgAlt}
        id={imgId}
        className={imgName}
        style={{
          opacity: isHovered ? 0 : 1,
          transition: 'opacity 500ms',
        }}
      />

      {isHovered && (
        <video
          autoPlay
          muted
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: isHovered ? 1 : 0,
            transition: 'opacity 10ms ease-in-out',
          }}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
      )}
        </div>
      );
    };

    return firstNine.map((item, i) => (
      <div className="soloSeminar" key={i}>
        <a href={item.aHref} className={item.aName}>
        <ImageComponent
            imageSrc={item.imgSrc}
            imgId={item.imgId}
            imgName={item.imgName}
            imgAlt={item.imgAlt}
            videoSrc={item.videoSnippet}
          />
          <span id="views">Views: {item.Views} | </span>
          <span>Likes: {item.Likes}</span>
          <h5>{item.Title}</h5>
          <EllipsisText text={item.description} tag="p" maxLength={25} />
          <button className="educoLongFormContentButton">Watch this Seminar...</button>
        </a>
      </div>
    ));
  };
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <vCounter.Provider value={videoCounter}>
      <div id="educoNew">
        <div id="educoViewer">{renderCards()}</div>
        <div className="bottomEduco" id="bottomEduco">
          <button onClick={increaseCounter}  className="educoSeeMoreButton">
            See more...
          </button>   
          <button onClick={scrollToTop} className="educoTopBtn" title="Go to top">
            ^
          </button>
        </div>
      </div>
    </vCounter.Provider>
  );
}