import {
    collection,
    addDoc,
    query,
    orderBy,
    limit,
    onSnapshot,
    setDoc,
    doc,
    serverTimestamp,
  } from "firebase/firestore";
  
  import { getMessaging, getToken, onMessage } from "firebase/messaging";
  import {firestore, auth} from '../../../../../firebase/firebase';
  import profilePic from "../../../../../images/functionality/google.webp";
  
  import { useEffect } from "react";
  import { useLocation } from "react-router-dom";
  import { GetProfilePicUrl } from "../../../../../firebase/firebaseComments";
  
  let dummyElement = false;
  function LocationTracker() {
    const location = useLocation();
    //output is null
    useEffect(() => {
      let downloader = true;
      setTimeout(messageBuilder, 5000);
      messageBuilder(downloader);
    }, [location, dummyElement]);
  }
  
  export function getProfilePicUrl() {
    let profile = document.getElementsByClassName("pic");
    let profilePics = profile[0].src;
  
    return profilePics || profilePic;
  }
  // Loads chat messages history and listens htmlFor upcoming ones.
  
  function messageBuilder(props) {
    let student = props;
    let studentId = student.toString()
    /* if (downloader === true) {
       getUserName = auth.currentUser.displayName;
      }*/
    let mentor = document.getElementsByClassName("mentorDataSet");
  console.log(mentor)
    let mentorId = mentor[0].id;
  
    let getUserName = auth.currentUser.displayName;
  
    function loadMessages() {
      // Create the query to load the last 12 messages and listen htmlFor new ones.
  console.log('Tosay here', typeof mentorId,typeof studentId,getUserName)
      const recentMessagesQuery = query(
        collection(firestore, "mentors", mentorId, studentId),
        orderBy("timestamp")
      );  
  console.log('here', recentMessagesQuery)
      // Start listening to the query.
      onSnapshot(recentMessagesQuery, function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "removed") {
            deleteMessage(change.doc.id);
          } else {
            let message = change.doc.data();
            console.log(message);
            displayMessage(
              change.doc.id,
              message.timestamp,
              message.name,
              message.text,
              message.profilePicUrl
            );
          }
        });
      });
    }
    async function saveMessage(messageText) {
      // Add a new message entry to the Firebase database.
      console.log('saving messages') 
      console.log('check', 
        typeof mentorId,
        typeof studentId,
        typeof getUserName,
        messageText,
        typeof getProfilePicUrl(),
        serverTimestamp()
      );
      console.log('here', typeof mentorId,typeof studentId,getUserName)
      try {
        await addDoc(collection(firestore, "mentors", mentorId, studentId), {
          studentId: studentId,
          name: getUserName,
          text: messageText,
          profilePicUrl: getProfilePicUrl(),
          timestamp: serverTimestamp(),
        });
      } catch (error) {
        console.error("Error writing new message to Firebase Database", error);
      }
    }
  
    // Saves the messaging device token to Cloud Firestore.
    async function saveMessagingDeviceToken() {
      try {
        const currentToken = await getToken(getMessaging());
        if (currentToken) {
          // Saving the Device Token to Cloud Firestore.
          const tokenRef = doc(firestore, "fcmTokens", currentToken);
          await setDoc(tokenRef, { uid: auth.currentUser.uid });
  
          // This will fire when a message is received while the app is in the foreground.
          // When the app is in the background, firebase-messaging-sw.js will receive the message instead.
          onMessage(getMessaging(), (message) => {});
        } else {
          // Need to request permissions to show notifications.
          requestNotificationsPermissions();
        }
      } catch (error) {
        console.error("Unable to get messaging token.", error);
      }
    }
  
    // Requests permissions to show notifications.
    async function requestNotificationsPermissions() {
      const permission = await Notification.requestPermission();
  
      if (permission === "granted") {
        // Notification permission granted.
        await saveMessagingDeviceToken();
      } else {
      }
    }
  
    // Triggered when a file is selected via the media picker.
  
    // Triggered when the send new message form is submitted.
    function onMessageFormSubmit(e) {
      e.preventDefault();
      // Check that the user entered a message and is signed in.
      if (messageInputElement.value && checkSignedInWithMessage()) {
        saveMessage(messageInputElement.value).then(function () {
          // Clear message text field and re-enable the SEND button.
          resetMaterialTextfield(messageInputElement);
          toggleButton();
        });
      }
    }
  
    // Triggers when the auth state change htmlFor instance when the user signs-in or signs-out.
    function authStateObserver(user) {
      if (user) {
        // User is signed in!
        // Get the signed-in user's profile pic and name.
        let profilePicUrl = getProfilePicUrl();
        let userName = getUserName();
  
        // Set the user's profile pic and name.
        userPicElement.style.backgroundImage =
          "url(" + addSizeToGoogleProfilePic(profilePicUrl) + ")";
        userNameElement.textContent = userName;
  
        // Show user's profile and sign-out button.
        userNameElement.removeAttribute("hidden");
        userPicElement.removeAttribute("hidden");
  
        // We save the Firebase Messaging Device token and enable notifications.
        saveMessagingDeviceToken();
      } else {
        // User is signed out!
        // Hide user's profile and sign-out button.
        userNameElement.setAttribute("hidden", "true");
        userPicElement.setAttribute("hidden", "true");
      }
    }
  
    // Returns true if user is signed-in. Otherwise false and displays a message.
    function checkSignedInWithMessage() {
      // Return true if the user is signed in Firebase
      if (auth.currentUser) {
        return true;
      } else {
        alert("Please sign in");
      }
    }
  
    // Resets the given MaterialTextField.
    function resetMaterialTextfield(element) {
      element.value = "";
      element.parentNode.MaterialTextfield.boundUpdateClassesHandler();
    }
  
    // Template htmlFor messages.
    let MESSAGE_TEMPLATE =
      '<div className="messageContainer">' +
      '<div id="pic"></div>' +
      '<div id="name">:</div>' +
      '<div id="time">:</div>' +
      '<div id="message">:</div>' +
      "</div>";
  
    // Adds a size to Google Profile pics URLs.
    function addSizeToGoogleProfilePic(url) {
      if (
        url.indexOf("googleusercontent.com") !== -1 &&
        url.indexOf("?") === -1
      ) {
        return url + "?sz=150";
      }
      return url;
    }
  
    // A loading image URL.
    let LOADING_IMAGE_URL = "https://www.google.com/images/spin-32.gif?a";
  
    // Delete a Message from the UI.
    function deleteMessage(id) {
      let div = document.getElementById(id);
      // If an element htmlFor that message exists we delete it.
      if (div) {
        div.parentNode.removeChild(div);
      }
    }
  
    function createAndInsertMessage(id, timestamp) {
      const container = document.createElement("div");
      container.innerHTML = MESSAGE_TEMPLATE;
      const div = container.firstChild;
      div.setAttribute("id", id);
      timestamp = timestamp ? timestamp.toString() : Date.now();
  
      div.setAttribute("timestamp", timestamp);
  
      // figure out where to insert new message
      const existingMessages = messageListElement.children;
  
      if (existingMessages.length === 0) {
        messageListElement.appendChild(div);
      } else {
        let messageListNode = existingMessages[0];
  
        while (messageListNode) {
          const messageListNodeTime = messageListNode.getAttribute("timestamp");
  
          if (!messageListNodeTime) {
            throw new Error(
              `Child ${messageListNode.id} has no 'timestamp' attribute`
            );
          }
  
          if (messageListNodeTime > timestamp) {
            break;
          }
  
          messageListNode = messageListNode.nextSibling;
        }
  
        messageListElement.before(div, messageListNode);
      }
  
      return div;
    }
    function convert(timer) {
      console.log(timer);
      return timer.toDate();
    }
  
    // Displays a Message in the UI.
    function displayMessage(id, timestamp, name, text, picUrl) {
      console.log(id, timestamp, name, text, picUrl);
      let time = convert(timestamp);
      let timer = time.toString().split("GMT");
  
      if (id !== "null") {
        let div =
          document.getElementById(id) || createAndInsertMessage(id, timestamp);
  
        // profile picture
        if (picUrl) {
          let hold = (div.querySelector("#pic").style.backgroundImage =
            "url(" + addSizeToGoogleProfilePic(picUrl) + ")");
        }
  
        div.querySelector("#name").textContent = name;
        div.querySelector("#time").textContent = timer[0];
        let messageElement = div.querySelector("#message");
  
        if (text) {
          // If the message is text.
          messageElement.textContent = text;
          // Replace all line breaks by <br>.
          messageElement.innerHTML = messageElement.innerHTML.replace(
            /\n/g,
            "<br>"
          );
          let card = document.getElementById("messagesCard").childNodes;
  
          for (let i = 0; i < card.length; i++) {
            if (card[i].nodeName === "#text") {
              card[i].data = "";
            }
          }
        }
  
        setTimeout(function () {
          div.classList.add("visible", "messageContainer");
        }, 1);
        messageListElement.scrollTop = messageListElement.scrollHeight;
        messageInputElement.focus();
      }
    }
    // Enables or disables the submit button depending on the values of the input
    // fields.
    function toggleButton() {
      if (messageInputElement.value) {
        submitButtonElement.removeAttribute("disabled");
      } else {
        submitButtonElement.setAttribute("disabled", "true");
      }
    }
  
    // Shortcuts to DOM Elements.
  
    let messageListElement = document.getElementById("messages");
    let messageInputElement = document.getElementById("messageInput");
    let messageFormElement = document.getElementById("message-form");
    let submitButtonElement = document.getElementById("submit");
  
    let userPicElement = document.getElementById("user-pic");
    let userNameElement = document.getElementById("user-name");
  
    // Saves message on form submit.
    messageFormElement.addEventListener("submit", onMessageFormSubmit);
  
    // Toggle htmlFor the button.
    // messageInputElement.addEventListener("keyup", toggleButton);
    messageInputElement.addEventListener("input", toggleButton);
  
    loadMessages();
  }
  
  function CommentSection(props) {
    let studentId = props.props;
  
    function ShowChat() {
      let coll = document.getElementsByClassName("collapsible");
      getProfilePicUrl();
      if (getProfilePicUrl) {
        console.log(getProfilePicUrl.prototype)
        messageBuilder(studentId);
      }
  
      let content = coll[0].nextElementSibling;
  
      if (content.style.display === "block") {
        coll[0].classList.toggle("active");
        content.style.display = "none";
      } else {
        coll[0].classList.toggle("active");
        content.style.display = "block";
      }
    }
  
    return (
      <>
        <button className="showChats collapsible" onClick={ShowChat}>
          {" "}
          Comment section <span>+</span>
        </button>
        <main id="mains" className="contents">
          <div id="messagesCard-container">
            <div id="messagesCard">
              <div id="messages">
                <form id="message-form" action="#" timestamp=" ">
                  <input
                    className="messageField"
                    type="text"
                    id="messageInput"
                    autoComplete="off"
                    spellCheck
                    size="10"
                    placeholder="Add a comment..."
                  />
  
                  <button
                    id="submit"
                    disabled
                    type="submit"
                    className="messageSubmit"
                  >
                    Post
                  </button>
                 
                </form>
              </div>
            </div>
            <button className="collapser" onClick={ShowChat}>
              Close comments <span>-</span>
            </button>
          </div>
          {/**DO NOT REMOVE THE STYLE THIS IS HERE TO CALL THE FUNCTION SO IT WILL WORK */}
          <div style={{ display: "none" }}>
            <GetProfilePicUrl />
          </div>
        </main>
      </>
    );
  }
  
  export default function MenteeChat(props) {
    let studentId = props.props;
  
    return (
      <>
        <section>
          <CommentSection props={studentId} />
        </section>
      </>
    );
  }