import React from "react";
import Socialetiquetteinonlinegatheringspolicy from "../Policy-folder-pdf/Social_Etiquette_in_online_gatherings.pdf";

function Socialetiquetteinonlinegatherings() {
  return (
    /*include text inside the React.Fragment*/
    <React.Fragment>
      <div>
        <h1>
          Social etiquette in online gatherings policy at Off the Curriculum
        </h1>
        <iframe
        title ='Our Social etiquette in online gatherings policy'
          src={Socialetiquetteinonlinegatheringspolicy}
          className="policy"
        ></iframe>
      </div>
    </React.Fragment>
  );
}
export default Socialetiquetteinonlinegatherings;
