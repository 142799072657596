import React from "react";
import { useState, useEffect } from "react";
import calendar from "../../../../images/functionality/main/social-media/calendar.webp";
import clock from "../../../../images/functionality/main/social-media/clock.webp";
import person from "../../../../images/functionality/main/social-media/person.webp";
import nicoleCurtis from "../../../../images/functionality/main/meet-the-team/josephLevermore.webp";
// //{ MetaTags } from "react-meta-tags";

// CSS
import "./articleText/stylingSheet/Articletemplate.css";
// Markdown
import Markdown from "markdown-to-jsx";
import mdAdviceforYoungPeopleStudingforATwoHourPeriod from "./articleText/AdviceforYoungPeopleStudingforATwoHourPeriod.md";

function AdviceforYoungPeopleStudingforATwoHourPeriod() {
  let [content, setContent] = useState({ md: "" });

  useEffect(() => {
    fetch(mdAdviceforYoungPeopleStudingforATwoHourPeriod)
      .then((response) => response.text())
      .then((md) => {
        setContent({ md });
      });
  }, []);
  return (
    /*include text inside the React.Fragement*/

    <React.Fragment>
      {/*<MetaTags>
        <title>Teach, Watch and Learn at Off the Curriculum</title>
        <meta
          name="title"
          content="Teach, Watch and Learn at Off the Curriculum"
        />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Teach, Watch, Learn. Be a part of the change and start transforming lives today through educational empowerment."
        />
        <meta
          name="keywords"
          content="Education, Charity, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Educovolunteers"
        />
        <meta
          property="og:title"
          content="Meet the Team at Off the Curriculum"
        />
        <meta
          property="og:description"
          content="Teach, Watch, Learn. Be a part of the change and start transforming lives today through educational empowerment."
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/news/educo_recruiting/educo_recruiting.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/news/educo_recruiting/educo_recruiting.webp"
        />
        <meta property="og:image:alt" content="Educo volunteering figure." />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Educovolunteers"
        />
        <meta
          property="twitter:title"
          content="Meet the Team at Off the Curriculum"
        />
        <meta
          property="twitter:description"
          content="Teach, Watch, Learn. Be a part of the change and start transforming lives today through educational empowerment."
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/news/educo_recruiting/educo_recruiting.webp"
        />
        <meta
          property="twitter:image:alt"
          content="Educo volunteering figure."
        />
      </MetaTags>

      <head>
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/about/news/educo_volunteer_recruitment.html"
        />
        <meta
          property="og:title"
          content="Meet the Team at Off the Curriculum"
        />
        <meta
          property="og:description"
          content="Teach, Watch, Learn. Be a part of the change and start transforming lives today through educational empowerment."
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/news/educo_recruiting/educo_recruiting.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/news/educo_recruiting/educo_recruiting.webp"
        />
        <meta property="og:image:alt" content="Educo volunteering figure." />
  </head>*/}
      <div className="centre-frame">
        <div className="first-third">
          <img className="new-article-image" src={nicoleCurtis} alt= 'Nicole Curtis'></img>
          <ul className="news_article_summary">
            <div>
              <img className="reelIcons" src={calendar} alt='Calendar'></img>
              <span>9th May 2023</span>
            </div>
            <br />
            <div>
              <img className="reelIcons" src={clock}  alt='Clock'></img>
              <span>5 minutes read</span>
            </div>
            <br />
            <div>
              <img className="reelIcons" src={person}  alt='Person Icon'></img>
              <span>Dr. Joseph Michael Levermore</span>
            </div>
          </ul>
          <div className="news-icon">
            <div className="footer_center">
              <a
                href="https://twitter.com/otclambeth/"
                target="_blank"
                rel="noreferrer"
              ></a>
              <a
                href="https://www.linkedin.com/in/nicole-l-aa60301b5"
                target="_blank"
                rel="noreferrer"
              ></a>
              <a
                href="https://www.instagram.com/offthecurriculumlondon/"
                target="_blank"
                rel="noreferrer"
              ></a>
            </div>
          </div>
          <br />

          <h2 className="About_the_author">About the author:</h2>
          <p className="prose_about_the_author">
            I am the founder of Off the Curriculum and a researcher at Imperial College London, with a keen interest in educational empowerment as a vehicle for social mobility. Upon realising the limited opportunities for educational activities provided to young people in Lambeth and Southwark London, we decided to take action. I am passionate about sports, science (physics and chemistry), and philosophy.
          </p>
        </div>
        <div className="second-third">
          {/* Markdown Start */}
          <p className="articlePtemplate">
            <Markdown>{content && content.md}</Markdown>
          </p>
          {/* Markdown End */}
        </div>
        <div className="third-third">
          <div className="containerOne">
            <h1 className="rec_title">Recommended Articles</h1>
            <div>
              <a className="Article_title" href="/Mindfulness">
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Fmindfulness.webp?alt=media&token=f8585110-94e6-42ea-8a3b-ad1979e436d4'
                  className="iconDetails"
                  alt="Mindfulness article header"
                />
              </a>
            </div>
            <div className="containerTwo">
              <a className="Article_title" href="/Mindfulness">
                <h4 className="recommended_articles">Mindfulness</h4>
              </a>
              <div className="section_text">Category: Education</div>
              <br />
              <div className="section_text">Nicole T Levermore</div>
            </div>
          </div>
          <div className="containerOne">
            <div>
              <a className="Article_title" href="/Learningtolearn">
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6'
                  className="iconDetails"
                  alt="Learning to learn article photo"
                />
              </a>
            </div>
            <div className="containerTwo">
              <a className="Article_title" href="/Learningtolearn">
                <h4 className="recommended_articles">
                  Learning to Learn from home
                </h4>
              </a>
              <div className="section_text">Category: Education</div>
              <br />
              <div className="section_text">Nicole T Levermore</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default AdviceforYoungPeopleStudingforATwoHourPeriod;
