import React from "react";
import VideoContainer from "./videoContainer";

const videos = [
    { 
        id: 1, 
        name: 'Artemis',
        src: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FArtemis%2FArtemis.mov?alt=media&token=3d08184e-1c3b-4666-a26b-fca8f8243065",
        snippet: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FArtemis%2FArtemis.gif?alt=media&token=6d5b7f2e-1125-4b8f-838b-3ae7fa3658f1",
     },
  { 
    id: 2, 
    name: 'Black Holes',
    src: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FBlackHoles%2FBlackHoles.mov?alt=media&token=ffc49b51-f59a-45cb-ae90-83e3047dff20",
    snippet: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FBlackHoles%2FBlackHoles.gif?alt=media&token=93eb83e3-9b5b-4eb7-b9c5-ed39ccc5318c",
 },
 { 
    id: 3, 
    name: 'Curiosity Rover Mission',
    src: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FCuriosityRoverMission%2FCuriosityRoverMission.mov?alt=media&token=bb53de03-def2-4e7c-a751-6b3126818868", 
    snippet: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FCuriosityRoverMission%2FCuriosityRoverMission.gif?alt=media&token=f5d54298-edab-440b-ab17-2d6a12bbf190",
 },
  { 
    id: 4, 
    name: 'Dark Matter',
    src: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FDarkMatter%2FDarkMatter.mov?alt=media&token=66fb7067-b89a-440d-ad3b-f0f9c8fdd2da", 
    snippet: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FDarkMatter%2FDarkMatter.gif?alt=media&token=720ebb1f-2d5b-42ec-bac4-f2de4b29d147",
 },
 { 
    id: 5, 
    name: 'Dark Matter Candidates',
    src: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FDarkMatterCandidates%2FDarkMatterCandidates.mov?alt=media&token=ffe5a171-c3e1-419b-a50e-044c2b330899", 
    snippet: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FDarkMatterCandidates%2FDarkMatterCandidates.gif?alt=media&token=c2e66860-8f05-4758-b298-0be17b8d48c0",
 },
  { 
    id: 6, 
    name: 'Juno Jupiter',
    src: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FJunoJupiter%2FJunoJupiter.mp4?alt=media&token=77d7be36-0fa3-42a5-9aa1-8357464b5622", 
    snippet: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FJunoJupiter%2FJunoJupiter.gif?alt=media&token=26d590d7-d051-4735-80ac-c89a52e98b44",
 },
  { 
    id: 7, 
    name: 'Math behind black holes',
    src: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FMathOfBlackHoles%2FMathOfBlackHoles.mov?alt=media&token=e034c960-9279-4c1e-8630-4fe3ef227fc2", 
    snippet: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FMathOfBlackHoles%2FMathOfBlackHoles.gif?alt=media&token=1aa92ae4-3170-46fb-9125-55a772042087",
 },
  { 
    id: 8, 
    name: 'Photons and the Event Horizons',
    src: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FPhotonsAndtheEventHorizon%2FPhotonsAndtheEventHorizon.mov?alt=media&token=5f193ead-fe18-4784-b975-1036188165ba", 
    snippet: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FPhotonsAndtheEventHorizon%2FPhotonsAndtheEventHorizon.gif?alt=media&token=a46d6e36-e0d5-4759-a62d-3fc3b05bca05",
},
{ 
    id: 9, 
    name: 'The Solar System',
    src: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FTheSolarSystemReel%2FTheSolarSystemReel.mov?alt=media&token=6e2d8022-a759-4753-bced-a49873f7e167",
    snippet: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FTheSolarSystemReel%2FTheSolarSystemReel.gif?alt=media&token=4029c97b-3a80-4b33-ae67-f6818583b28c",
 },
  { 
    id: 10, 
    name: 'Trapped in a Black Hole',
    src: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FTrappedInABlackHole%2FTrappedInABlackHole.mov?alt=media&token=fb27115b-b092-4f0b-91ce-d88809752664",
    snippet: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FTrappedInABlackHole%2FTrappedInBlackHoles.gif?alt=media&token=6f2843a6-0d1a-4ea0-8228-4bea4f4a39af",
 },
  { 
    id: 11, 
    name: 'Vera Rubin Dark Matter',
    src: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FVeraRubinDarkMatter%2FVeraRubinDarkMatter.mov?alt=media&token=262a21fc-a0c9-4360-aafb-c3a3c3024d02", 
    snippet:"https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FVeraRubinDarkMatter%2FVeraRubinDarkMatter.gif?alt=media&token=fe5612a7-fbe3-4398-a342-e0621c700abc",
},
  { 
    id: 12, 
    name: 'Voyager Two',
    src: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FVoyagerTwo%2FVoyagerTwo.mov?alt=media&token=6e1d13e7-4898-4fa0-8d46-11870a252270", 
    snippet:"https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/shorts%2Fastronomy%2FVoyagerTwo%2FVoyagerTwo.gif?alt=media&token=3fd717c2-7a1d-47f3-8bc9-fea0e71ff2c4",
},
];

const ShortsDiv = () => {
  return <VideoContainer videos={videos} />;
};

export default ShortsDiv;
