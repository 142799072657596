import style from "./career-styles.module.css";
import careers from "./career-index";
//{ MetaTags } from "react-meta-tags";

const CareerTemplate = (props) => {
  const name = props.name;
  let career = {};
  let careerTime = {};
  let careerWhere = {};
  if (careers[name]) career = careers[name];
  if (career.where) careerWhere = career.where;
  if (career.time) careerTime = career.time;

  return (
    <>
    {/*
          <MetaTags>
        <title>{career.title} - Volunteer with Off the Curriculum</title>
      </MetaTags>
  */}
      {/* ********************  Career Header  ********************* */}
      <div className={style["career-header"]}>
        <div className={style["career-header-container"]}>
          <h2 className={style["heading"]}>{career.title}</h2>
          <div className={style["subheading-container"]}>
            <h4 className={style["sub-heading"]}>{career.subtitle}</h4>
            <a
              className={style["btn-apply--secondary"]}
              href="/become-an-officer"
            >
              Apply
            </a>
          </div>
        </div>
      </div>
      <div className={style["wrapper"]}>
        <div className={style["container"]}>
          {/* ********************  Career Info  ********************* */}
          <section className={style["info-section"]}>
            <article className={style["info-section-article"]}>
              <h3 className={style["info-section-header"]}>
              SKills
              </h3>
              <p className={style["info-section-paragraph"]}>
                <ul>
                  {career.skills &&
                    career.skills.map((item, key) => <li key={key}>{item}</li>)}
                </ul>
              </p>
            </article>
            <article className={style["info-section-article"]}>
              <h3 className={style["info-section-header"]}>
           Where
              </h3>
              <p className={style["info-section-paragraph"]}>
                {careerWhere.location}
                <br />
                {careerWhere.desc}
              </p>
            </article>
            <article className={style["info-section-article"]}>
              <h3 className={style["info-section-header"]}>
               Time
              </h3>
              <p className={style["info-section-paragraph"]}>
                {careerTime.workTime}
              </p>
              <p className={style["info-section-paragraph"]}>
                Estimate of time needed: <br />
                {careerTime.timeNeeded}
              </p>
            </article>
          </section>

          {/* ********************  Career Profile  ********************* */}
          <section className={style["profile-section"]}>
            <a
              className={style["btn-apply--primary"]}
              href="/become-an-officer"
            >
              Apply
            </a>
            <p className={style["profile-section-desc"]}>
              {career.desc && (
                <>
                  We are looking for a Volunteer <strong>{career.title}</strong>{" "}
                  , to {career.desc}
                </>
              )}
              {career.descFull && career.descFull}
            </p>
            {(career.whatWillYouBeDoing.p !== "" ||
              career.whatWillYouBeDoing.ul.length > 0) && (
              <article className={style["profile-articles"]}>
                <h3 className={style["profile-articles-header"]}>
                  What will you be doing?
                </h3>
                <p className={style["profile-articles-paragraph"]}>
                  {career.whatWillYouBeDoing.p && career.whatWillYouBeDoing.p}
                </p>
                {career.whatWillYouBeDoing.ul && (
                  <ul>
                    {career.whatWillYouBeDoing.ul.map((item, key) => (
                      <li className={style["profile-list-item"]} key={key}>
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
              </article>
            )}
            {(career.whatAreWeLookingFor.p !== "" ||
              career.whatAreWeLookingFor.ul.length > 0) && (
              <article className={style["profile-articles"]}>
                <h3 className={style["profile-articles-header"]}>
                  What are we looking for?
                </h3>
                <p className={style["profile-articles-paragraph"]}>
                  {career.whatAreWeLookingFor.p && career.whatAreWeLookingFor.p}
                </p>
                {career.whatAreWeLookingFor.ul && (
                  <ul>
                    {career.whatAreWeLookingFor.ul.map((item, key) => (
                      <li className={style["profile-list-item"]} key={key}>
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
              </article>
            )}
            {(career.whatDifferenceWillYouMake.p !== "" ||
              career.whatDifferenceWillYouMake.ul.length > 0) && (
              <article className={style["profile-articles"]}>
                <h3 className={style["profile-articles-header"]}>
                  What difference will you make?
                </h3>
                <p className={style["profile-articles-paragraph"]}>
                  {career.whatDifferenceWillYouMake.p &&
                    career.whatDifferenceWillYouMake.p}
                </p>
                {career.whatDifferenceWillYouMake.ul && (
                  <ul>
                    {career.whatDifferenceWillYouMake.ul.map((item, key) => (
                      <li className={style["profile-list-item"]} key={key}>
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
              </article>
            )}
            {(career.whatsInItForTheVolunteer.p !== "" ||
              career.whatsInItForTheVolunteer.ul.length > 0) && (
              <article className={style["profile-articles"]}>
                <h3 className={style["profile-articles-header"]}>
                  What's in it for the volunteer?
                </h3>
                <p className={style["profile-articles-paragraph"]}>
                  {career.whatsInItForTheVolunteer.p &&
                    career.whatsInItForTheVolunteer.p}
                </p>
                {career.whatsInItForTheVolunteer.ul && (
                  <ul>
                    {career.whatsInItForTheVolunteer.ul.map((item, key) => (
                      <li className={style["profile-list-item"]} key={key}>
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
              </article>
            )}
            <a
              className={style["btn-apply--primary"]}
              href="/become-an-officer"
            >
              Apply
            </a>
          </section>
        </div>
        {/* End of container */}
      </div>
      {/* End of wrapper */}
    </>
  );
};

export default CareerTemplate;
