import Stars from '../../../../../images/functionality/educo/icons/term-1/astronomy/stars.webp'
import Planets from '../../../../../images//functionality/educo/icons/term-1/astronomy/planets.webp'
import Protoplanets from '../../../../../images/functionality/educo/icons/term-1/astronomy/protoplanets.webp'
import Solarsystem from '../../../../../images/functionality/educo/icons/term-1/astronomy/solarsystem.webp'
import Telescope from '../../../../../images/functionality/educo/icons/term-1/astronomy/telescope.webp'
import Moon from '../../../../../images/functionality/educo/icons/term-1/astronomy/moon.webp'

const objPolitics = {
    webpagedesc: ``, 
    webpagekeywords: ``, 
    webpagepageurl: ``, 
    webpagethumbnail: ``, 
    webpagethumbnailAlt:``, 
    organisationtwitter: ``, 
    title: 'Politics', 
    courseDesc: 
        `
        In this seminar series students will explore the complex world of politics, covering: Comparative Government, Political Theory, Capitalism and Marxism: A Power Struggle, Labour Economics and Inequality, and Political Thought: Plato to Rousseau. We are seeking your help to create a high-quality educational experience, and would like you to create videos exploring one area you find of interest from the mentioned seminar topics. 
        `
    ,
    quickLinkOneDesc:
    `
    Comparative Government
    `
    ,
    quickLinkOne: `
    /PoliticsComparativeGovernment
    `, 
    quickLinkTwoDesc: 
    `
    Political Critical Theory
    `, 
    quickLinkTwo:
    `
    /PoliticalCriticalTheory
    `, 
    quickLinkThreeDesc:
    `
    Capitalism and Marxism: A Power Struggle
    `, 
    quickLinkThree: 
    `
    #
    `, 
    quickLinkFourDesc:`
    Labour Economics and Inequality
    `, 
    quickLinkFour: 
    `
    #
    `, 
    quickLinkFiveDesc: 
    `
    Political thought: Plato to Rousseau
    `, 
    quickLinkFive: 
    `
    #
    `, 
    courses:[
    {
        key: 1, 
        sectionimage: Telescope, 
        sectionimageAlt: `
        Comparative Government Image Icon
        `, 
        sectiontitle: `
        Comparative Government
        `, 
        sectiondesc: `
        In this seminar series, the student-volunteer will explore the field of comparative government. A brief history of the field should be reviewed and students should identify main contributors to the study of comparative government. Governmental comparisons should be drawn between countries as examples. Critiques of the field should be discussed and the power relations embedded within the study should also be considered. Finally the future of and implications of the study of comparative government should be examined. 
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
                [
                    {
                        key: 1, 
                        videoTitle: 'Comparative Government',
                        videoDesc: `
                        Yingshu will give you a brief introduction of comparative government, also known as comparative politics. Comparative government is defined as a principle subfield of political science, that focuses on the comparative study of constitutions, systems of government, and political developments.
                        `,
                        videoInfo: `100 views | 10 days ago | Yingshu Chen`, 
                        videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fpolitics%2FPoliticsComparativeGovernment%2FpoliticsComparativeGovernmentThumbnail.webp?alt=media&token=de2279c2-de30-435e-aff6-faa2a29b66b1',
                        videoImageAlt: `A Journey Through The Solar System Thumbnail`, 
                        videohref: `/PoliticsComparativeGovernment`, 
                    },
                ]
    }, 
    {
        key: 2, 
        sectionimage: Solarsystem, 
        sectionimageAlt: `
        Political Theory icon
        `, 
        sectiontitle: `
        Political Theory
        `, 
        sectiondesc: `
        This seminar series will cover the study of political theory. The definition and role of the study should be examined. The history of the study of political theory should be discussed. Core political theories should be explained and compared with each other, such as liberalism, socialism, and egalitarianism. The implications of these differing theories on society and government should also be considered. Finally, contemporary political theory should be examined. Student-volunteers are encouraged to explore non-western political theories as well.  
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'Politics And Critical Theory',
                videoDesc: `Why not uncover extraterrestrial missions to Mars, Jupiter and beyond!`,
                videoInfo: `100 views | 10 days ago | Teresa Su`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fpolitics%2FPoliticsCriticalTheory%2FpoliticsCriticalTheoryThumbnail.webp?alt=media&token=3e2941f1-e48e-4695-a1f9-c8572b96bef3',
                videoImageAlt: `Politics And Critical Theory Thumbnail`, 
                videohref: `/PoliticsCriticalTheory`, 
            },
            {
                key: 2, 
                videoTitle: 'Egalitarianism',
                videoDesc: `
                Ana explores 'Egalitarianism' from the French word “égal”, which means equality. It was first used in England in the late 19th century by John Locke in reference to a belief in human inequality. It is a school of thought in political philosophy with the purpose of ensuring that every individual has an equal opportunity to be able to make the most of their lives and talents, despite their age, gender, race or beliefs.
                `,
                videoInfo: `100 views | 10 days ago | Ana Isabel Prieto Angulo`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fpolitics%2FPoliticsEgalitarianism%2FpoliticsEgalitarianismThumbnail.webp?alt=media&token=227608e9-16e2-41f6-897d-ee797e9254fb',
                videoImageAlt: `Egalitarianism Thumbnail`, 
                videohref: `/PoliticsEgalitarianism`, 
            },
        ]
    }, 
    {
        key: 3, 
        sectionimage: Stars, 
        sectionimageAlt: `
        Capitalism and Marxism: A Power Struggle Icon Image
        `, 
        sectiontitle: `
        Capitalism and Marxism: A Power Struggle
        `, 
        sectiondesc: `
        This seminar series will consider the politics surrounding Capitalism and Marxism and discuss the complex tension between the two structural systems. Student-Volunteers should explain and define Capitalism and Marxism and critiques of the systems discussed. Histories of these political systems should be considered as well as their current functions today. The power struggles between these political systems should be discussed within both national (for example: the UK) and international contexts (for example: The Cold War). Furthermore, comparisons between Capitalism and Marxism in theory and practice should be drawn. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'Marxism',
                videoDesc: `
                Marxism is a theory in which there are no different classes in a society. It is a social, political
                and economic philosophy named after Karl Marx.
                `,
                videoInfo: `100 views | 10 days ago | Ana Isabel Prieto Angulo`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fpolitics%2FPoliticsMarxism%2FpoliticsMarxismThumbnail.webp?alt=media&token=7e5e3386-3d56-421e-852d-c4835c836a76',
                videoImageAlt: `Marxism Thumbnail`, 
                videohref: `/PoliticsMarxism`, 
            },
        ]
    }, 
    {
        key: 4, 
        sectionimage: Stars, 
        sectionimageAlt: `
        Labour Economics and Inequality Icon Image
        `, 
        sectiontitle: `
        Labour Economics and Inequality
        `, 
        sectiondesc: `
        The Labour Economics and Inequality seminar series will discuss the relationship between the labour market, and social and economic inequality. Competition, globalisation, and unionisation should all be discussed and their implications for equality considered. The micro and macro economics of labour markets should also be explored. Today’s labour economics should be explained in reference to rising inequality in many capitalist societies. Specific case studies can be provided to support discussion of the relationship between labour economics and inequality. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'Common vs Private Property', 
                videoDesc:`We will explore how Stars evolve from a pre-main sequence star to a Supernova (depending on the Stars size). The rise and death of a Star is an incredibly dramatic episode, one we are sure you are going to enjoy exploring!`, 
                videoInfo: `100 views | 10 days ago | Yoojin Lee`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fpolitics%2FPoliticsPrivateProperty%2FPoliticsPrivatePropertyThumbnail.webp?alt=media&token=3bcbb00e-e2ff-4fe7-ac28-23e144e13bba',
                videoImageAlt:`
                videoTitle: 'The Stars Thumbnail`, 
                videohref:`/PoliticsPrivateProperty`, 
            },
        ]
    }, 
    {
        key: 5, 
        sectionimage: Stars, 
        sectionimageAlt: `
        Political thought: Plato to Rousseau  Icon Image
        `, 
        sectiontitle: `
        Political thought: Plato to Rousseau 
        `, 
        sectiondesc: `
        In this seminar series student-volunteers should provide an overview of political philosophers and thinkers beginning from Plato and the Ancient Greeks to Rousseau. This could include but is not limited to Aristotle, Machiavelli, Hobbes, and Locke. Again, student-volunteers are encouraged to consider non-Western thinkers such as Mencius and Chanakya. The historical context of these philosophers should be discussed and comparisons should be drawn across philosophers. The main ideas, theories, and contributions of the philosopher should be highlighted. The contribution of these political thinkers to today's political systems should also be considered. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'The Stars', 
                videoDesc:`We will explore how Stars evolve from a pre-main sequence star to a Supernova (depending on the Stars size). The rise and death of a Star is an incredibly dramatic episode, one we are sure you are going to enjoy exploring!`, 
                videoInfo: `100 views | 10 days ago | Teresa Su`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EDUCO%2Fastronomy%2Fteresa-su%2Fthe-stars%2FtheStarsThumbnail.png?alt=media&token=ef70cb2d-53b0-4321-a7aa-4eadcdc0a2ee',
                videoImageAlt:`
                videoTitle: 'The Stars Thumbnail`, 
                videohref:`/AstronomyTheStars`, 
            },
        ]
    }, 
    ],
}

export default objPolitics; 
