const partneredSchoolspage = {
    pageTitle: `
    Partnered Sixth Forms
    `, 
    affiliatesProgramme: `
    Off the Curriculum has created an affiliates programme, whereby any Sixth Form student from our partnered schools, would be able to participate in the seminar series on topics which interest them with no overall commitment to the programme. 
    This affiliates programme would allow each interested student to join every session via the Live stream of the talk, which will be hosted on our website. The breakout room activities will slightly differ, as they will not have access to the main event, but there will be breakout room activities which will be just as fun and engaing. 
    An important thing to note is that our programme affiliates would not be in a position to vote during our termly topic deciders.
    `


}

export {
    partneredSchoolspage,
}