const programmeTestimonial ={
    pageHeader: `
    Programme Testimonials
    `, 
    testimonialOne: `
    "During my time attending OTC seminars, I found myself developing a new interest in neuroscience. It has been really rewarding not only because of the knowledge I’ve acquired from particular areas of study, but also because it enabled me to share and exchange ideas with other like-minded peers. OTC has pushed me to get out of my comfort zone through delivering a presentation for an assessment piece, which has increased my confidence and allowed me to develop my oral skills as well as understanding about the brain and addiction. My OTC experience has been intriguing and exciting and I look forward to attending more sessions!" 
    `, 
    testimonialOneAuthor:`
    Jeriel
    `, 
    testimonialOneDesc: `
    Programme Participant
    `, 
    testimonialTwo: `
    "OTC is definitely a unique experience which few extracurricular academic programmes have achieved in my opinion. It has provided its students with a tailored agenda according to a vote on the next specific subject for the new term. This not only makes the participants look forward to the sessions, but it also gives them a sense of involvement instead of being directed by the volunteers by an already-given plan. The experts brought in to talk have a wide-ranging background from all around the world, giving the students an insight into the different fields and institutions they themselves may desire to be involved in later. The content is also astoundingly high-level which distinguishes it from what a student would study from day to day at school; I have as a result benefited from an expansion in vocabulary, ideas, and cognizance of topical and important affairs such as climate change and neuroscience. The self-determined assessment at the end of each chosen course acts as a consolidation of knowledge and is also a method of getting the students to focus throughout the sessions for their assessment to be reflective of their dedication to the programme. This feature certainly is special to OTC only!" 
    `, 
    testimonialTwoAuthor:`
    Raghad
    `, 
    testimonialTwoDesc: `
    Programme Participant
    `, 
    teacherTestimonial:`
    Teacher Testimonials
    `, 
    testimonialThee:`
    "The sessions delivered by OTC have been incredible! They have exposed our students to a level of expertise that we cannot provide in school. Our students have found each session fascinating and having the chance to engage with academic experts has improved their ability to speak and write academically, which has had a demonstrable impact on their confidence levels and ambition. I would recommend working with OTC to any school that wants to push their students to achieve their full potential."
    `, 
    testimonialThreeAuthor: `
    Dr. Jamie McDougall
    `, 
    testimonialThreeDesc: `
    History Teacher and EPQ Coordinator
    `, 
    testimonialTheeInstitute:`
    Lambeth Academy
    `,
}

export {
    programmeTestimonial
}