import React from "react";
import { Link } from "react-router-dom";
import "./mainStyling/News.css";
import calendar from "../../../images/functionality/main/social-media/calendar.webp";
import clock from "../../../images/functionality/main/social-media/clock.webp";
import person from "../../../images/functionality/main/social-media/person.webp";
import { Articles   } from "./News/Content/Index";



function News() {
  return (
    <React.Fragment>
      {/*} <MetaTags>
        <title>News at Off the Curriculum</title>
        <meta name="title" content="News at Off the Curriculum" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta
          name="description"
          content="Find the latest news about Off the Curriculum, our team, our journey, fundraising, governance, management, communications and social initiatives."
        />
        <meta
          name="keywords"
          content="Education, Charity, News, Educational News, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/News"
        />
        <meta property="og:title" content="News at Off the Curriculum" />
        <meta
          property="og:description"
          content="Find the latest news about Off the Curriculum, our team, our journey, fundraising, governance, management, communications and social initiatives."
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/logos/OTC_logo_small_colour_with_background.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/logos/OTC_logo_small_colour_with_background.webp"
        />
        <meta property="og:image:alt" content="The Off the Curriculum logo." />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/News"
        />
        <meta property="twitter:title" content="News at Off the Curriculum" />
        <meta
          property="twitter:description"
          content="Find the latest news about Off the Curriculum, our team, our journey, fundraising, governance, management, communications and social initiatives."
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/logos/OTC_logo_small_colour_with_background.webp"
        />
        <meta
          property="twitter:image:alt"
          content="The Off the Curriculum logo."
        />
  </MetaTags>*/}
      <div className="centre-frame">
        <h1 className="news-landing">Recent news at Off the Curriculum</h1>
        <div className="row-02">
        {Articles.map((items) => (
          <div className="column-02-02">
          <div className="card-02-02">
              <h3 className="news_header">
              {items.title}
              </h3>
              <div className="img_news">
                <img
                  src={items.imageSrc}
                  className="news_images"
                  alt={items.imageSrcAlt}
                />
              </div>
              <div className="news-container">
                <div>
                  <img
                    src={calendar}
                    className="img-news"
                    alt="Google Logo"
                  ></img>
                  <span>{items.date}</span>
                </div>
                <br />
                <div>
                  <img
                    src={person}
                    className="img-news"
                    alt="Google Logo"
                  ></img>
                  <span>{items.author}</span>
                </div>
                <br />
                <div>
                  <img src={clock} className="img-news" alt="Google Logo"></img>
                  <span>{items.time}</span>
                </div>
              </div>
              <p className="news">
                {items.article}
              </p>
              <Link to={items.articlepagehref} className="newsBtn">
                <btn className="newsbtn">Read more here</btn>
              </Link>
            </div>
            </div>
                    ))}
            
          </div>
        </div>
    </React.Fragment>
  );
}
export default News;
