import Stars from '../../../../../images/functionality/educo/icons/term-1/astronomy/stars.webp'
import Planets from '../../../../../images//functionality/educo/icons/term-1/astronomy/planets.webp'
import Protoplanets from '../../../../../images/functionality/educo/icons/term-1/astronomy/protoplanets.webp'
import Solarsystem from '../../../../../images/functionality/educo/icons/term-1/astronomy/solarsystem.webp'
import Telescope from '../../../../../images/functionality/educo/icons/term-1/astronomy/telescope.webp'
import Moon from '../../../../../images/functionality/educo/icons/term-1/astronomy/moon.webp'

const objMusic = {
    webpagedesc: ``, 
    webpagekeywords: ``, 
    webpagepageurl: ``, 
    webpagethumbnail: ``, 
    webpagethumbnailAlt:``, 
    organisationtwitter: ``, 
    title: 'Music', 
    courseDesc: 
        `
        Music is an innate human trait, and has been in existence ever since humans have been. So lets explore together how music is a coordination of sounds to form beauty, rhythm, melody, and harmony.
        `
    ,
    quickLinkOneDesc:
    `
    Queen's Master of Music and Anatomy of hearing
    `
    ,
    quickLinkOne: `
    /MusicInductionSeminar
    `, 
    quickLinkTwoDesc: 
    `
    Uncertainty Quantification in Climate Systems
    `, 
    quickLinkTwo:
    `
    /AstronomyTheStars
    `, 
    quickLinkThreeDesc:
    `
    Climate Policy
    `, 
    quickLinkThree: 
    `
    /AstronomyDwarfPlanets
    `, 
    quickLinkFourDesc:`
    TheMoons
    `, 
    quickLinkFour: 
    `
    /AstronomyTheMoons
    `, 
    quickLinkFiveDesc: 
    `
    Space Policy
    `, 
    quickLinkFive: 
    `
    /AstronomySpacePolicy
    `, 
    courses:[
    {
        key: 1, 
        sectionimage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fmusic%2FMusic%2Fclassical-music-and-the-art-of-composition-2.webp?alt=media&token=6cc2b7b4-0395-4c54-9cbf-044d9a8bb5da', 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        Classical music and the art of composition
        `, 
        sectiondesc: `
        Written music allows us to hear music as the composer wanted us to hear it- providing instructions for tone, volume, rhythm and key. When did people begin writing music in the form of sheet music, a form that musicians are familiar with nowadays? How has such an art evolved throughout time? What does it look like to be a composer in recent times and what did it look like then? Classical music and its composers from all over the world will be explored in this seminar, along with some fascinating insights into modern composition. 
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
                [
                    {
                        key: 1, 
                        videoTitle: `
                        Interview with the Queen's Master of Music and the Anatomy and Physiology of Hearing
                        `, 
                        videoDesc:`Why not uncover extraterrestrial missions to Mars, Jupiter and beyond!`, 
                        videoInfo: `100 views | 10 days ago | Judith Weir, and Nicole Levermore`, 
                        videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fmusic%2FMusicInductionSeminar%2FmusicInductionSeminarThumbnail.webp?alt=media&token=c2b1b737-6eab-439c-b8c6-b7d00247f777',
                        videoImageAlt:` Thumbnail`, 
                        videohref:`/MusicInductionSeminar `, 
                    },
                ]
    }, 
    {
        key: 2, 
        sectionimage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fmusic%2FMusic%2Fclassical-music-and-the-art-of-composition.webp?alt=media&token=93e3f64d-6a19-4ff9-8624-a1992d958571', 
        sectionimageAlt: `
        History of music and its earliest forms
        `, 
        sectiontitle: `
        History of music and its earliest forms
        `, 
        sectiondesc: `
        Music is an innate human trait, and has been in existence ever since humans have been. There is also evidence that our closest ancient human relatives, neanderthals (Homo neanderthalensis) also had a culture of music. Music is a coordination of sounds to form beauty, rhythm, melody, and harmony. What is it within the human species that draws us to music? What music did our oldest ancestors enjoy? And how has musical expression evolved through time into what we have today? These are some of the questions that this fundamental seminar will cover. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: '', 
                videoDesc:``, 
                videoInfo: ``, 
                videoImage: '',
                videoImageAlt:``, 
                videohref:``, 
            },
        ]
    }, 
    {
        key: 3, 
        sectionimage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fmusic%2FMusic%2Fmusic-induction-seminar-series.webp?alt=media&token=f2b8b867-828c-4e77-80e0-559ec36d289b', 
        sectionimageAlt: `
        The psychology of music
        `, 
        sectiontitle: `
        The psychology of music
        `, 
        sectiondesc: `
        Music can evoke an array of emotions upon listening: sadness, melancholy, excitement. It can also evoke memories, and has been shown to help those suffering from dementia, soothing their unease and often stimulating the brain to recall long forgotten memories. Why is it that music can have such a varied and multifaceted effect on the human brain and psyche? Music, and how it’s various forms can impact human psychology will be covered in this seminar. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: '', 
                videoDesc:``, 
                videoInfo: ``, 
                videoImage: '',
                videoImageAlt:``, 
                videohref:``, 
            }
        ]
    }, 
     
    {
        key: 4, 
        sectionimage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fmusic%2FMusic%2Fmusic-to-send-a-message.webp?alt=media&token=ebd8f0d5-b78f-461c-b397-58c361807a26', 
        sectionimageAlt: `
        Music to send a message: political significance of music and subcultures
        `, 
        sectiontitle: `
        Music to send a message: political significance of music and subcultures
        `, 
        sectiondesc: `
        From Punk to Emo to Hippy, how has music led to the formation of subcultures, and what brings people to shape their identity around music in this way? Often, subcultures arise due to the political climate of a country at any given time, for example the anti-racist and inclusive messages within 2-Tone music, originating in a time shrouded by division. The origins of subculture and some interesting subcultures from across the world that have their roots in musical expression and politics will be explored in this enlightening seminar. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: '', 
                videoDesc:``, 
                videoInfo: ``, 
                videoImage: '',
                videoImageAlt:``, 
                videohref:``, 
            },
        ]
    }, 
    {
        key: 5, 
        sectionimage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fmusic%2FMusic%2Fmodern-music-of-the-world.webp?alt=media&token=cf841270-4808-435d-b7e6-942548a8b6cc', 
        sectionimageAlt: `
        Sampling the city
        `, 
        sectiontitle: `
        Sampling the city
        `, 
        sectiondesc: `
        How have everyday city sounds been incorporated into music? A recent example being Billie Eilish’s new album ‘Happier Than Ever’. How have certain cities and their unique atmospheres influenced music through time? This seminar will be a deep dive into the impact location can have on music. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: '', 
                videoDesc:``, 
                videoInfo: ``, 
                videoImage: '',
                videoImageAlt:``, 
                videohref:``, 
            }
        ]
    }, 
    ],
}

export default objMusic;