const objVideographyOfficer = {
  title: 'Videography Officer',
  subtitle: 'London or Remote',
  desc: `work with Education, Outreach, and Social Media teams to deliver targets set by Charity’s education board.`,
  descFull: ``,
  skills: [
    'Network / System Management',
    'SEO and Analytics',
    'Software and Web Development',
    'Web Design (including UX/UI)',
  ],
  where: {
    location: 'London, SE12DU',
    desc: 'Mainly at home, or remote opportunity.',
  },
  time: {
    workTime: 'Either in or out of office hours',
    timeNeeded: '3-7 hours / week',
  },
  whatWillYouBeDoing: {
    p: `The main duties of the role:`,
    ul: [
      `To create graphics and content to support the video footage generated`,
      `To help edit and finalise our educational videos for Educo`,
      `To streamline all future development of footage, making the processes more efficient with time`,
      `To aid in the creation of a variety of videos for Educo`,
      `To support the film crew and the production of high-quality content`,
      `To ensure all content produced and made public on our social media is visually engaging and of top calibre`,
      `To collaborate with our Social Media team, to generate posts for Instagram, LinkedIn, Twitter, YouTube on a regular basis and engage with our audience`,
    ],
  },
  whatAreWeLookingFor: {
    p: ``,
    ul: [
      `Proficiency using Davinci Resolve or other animation software`,
      `Excellent multi-channel communication, knowledge of principles of animation including staging, timing, ease in/out, anticipation`,
      `Independent and team skills`,
      `Good time management, with specific attention to detail`,
    ],
  },
  whatDifferenceWillYouMake: {
    p: `The Educo Videography Officer will help develop and produce the video 
        content published on Educo, generated from the footage captured of our 
        Student Volunteers explaining a topic from their area of training. This 
        will enhance the quality of OTC's educational content and therefore 
        improve the participants' educational experience.`,
    ul: [],
  },
  whatsInItForTheVolunteer: {
    p: `Joining OTC, the Videographer will have the opportunity to build 
        prosperous relationships with the local community and all those involved 
        with the charity, be part of an organisation that aims to build a 
        supportive and actualised community and have a positive impact on young 
        students' educational experience. Since this role is online, the 
        candidate will have full support to carry out their responsibilities in 
        a flexible way and a manner of their choosing.`,
    ul: [],
  },
};

export default objVideographyOfficer;
