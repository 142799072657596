import React from "react";
import covid19impacts from "../../../images/functionality/main/education/education/covid19impacts.webp";
import Seminar_series_certificate from "../../../images/functionality/main/education/education/seminar-series-certificate.webp";
import eductionTeam from "../../../images/functionality/main/education/education/eductionTeam.webp";
import "./MainStyling/EducationStyling.css";
//import MetaTags from "react-meta-tags";
import { educationPage } from "./Media/Education";

function Education() {
  function enlarge() {
    var modal = document.getElementById("myModal");
    var img = document.getElementById("myImg1");
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");
    img.onclick = function () {
      modal.style.display = "block";
      modalImg.src = this.src;
      captionText.innerHTML = this.alt;
    };
    var span = document.getElementsByClassName("close")[0];
    span.onclick = function () {
      modal.style.display = "none";
    };
  }
  function enlargeTwo() {
    var modal = document.getElementById("myModal2");
    var img = document.getElementById("myImg2");
    var modalImg = document.getElementById("img02");
    var captionText = document.getElementById("caption2");
    img.onclick = function () {
      modal.style.display = "block";
      modalImg.src = this.src;
      captionText.innerHTML = this.alt;
    };
    var span = document.getElementsByClassName("close2")[0];
    span.onclick = function () {
      modal.style.display = "none";
    };
  }
  return (
    <React.Fragment>
      {/*<MetaTags>
        <title>Education at Off the Curriculum</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta
          name="description"
          content="Designed by experts, refined by researchers, and led by Off the Curriculum, if you are a Sixth Form student enrolled at a Lambeth or Southwark school, OTC wants to help you reach your full potential here…"
        />
        <meta
          name="keywords"
          content="Education, Charity, News, Educational News, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta name="title" content="Education at Off the Curriculum" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Education"
        />
        <meta property="og:title" content="Education at Off the Curriculum" />
        <meta
          property="og:description"
          content="Designed by experts, refined by researchers, and led by Off the Curriculum, if you are a Sixth Form student enrolled at a Lambeth or Southwark school, OTC wants to help you reach your full potential here…"
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/education/headers/about_education.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/education/headers/about_education.webp"
        />
        <meta
          property="og:image:alt"
          content="Education at Off the Curriculum."
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Education"
        />
        <meta
          property="twitter:title"
          content="Education at Off the Curriculum"
        />
        <meta
          property="twitter:description"
          content="Designed by experts, refined by researchers, and led by Off the Curriculum, if you are a Sixth Form student enrolled at a Lambeth or Southwark school, OTC wants to help you reach your full potential here…"
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/education/headers/about_education.webp"
        />
        <meta
          property="twitter:image:alt"
          content="Education at Off the Curriculum."
        />
      </MetaTags>*/}
      <div className="centre-frame">
        <div className="second-second-Education">
          <h1 className="educationHeader" id="Education-at-OTC">
            {educationPage.title}
          </h1>
          <p className="educationP">
            Inspiring the next generation is what Off the Curriculum is all
            about! We believe we can achieve this mission through our
            well-rounded, vibrant, and interdisciplinary educational programme,
            which has been shown to open minds to new possibilities, that may
            not have otherwise been considered. Off the Curriculum's educational
            services consists of four main areas: an repeating
            five-week-project-seminar-series, the Mighty Minds Wellbeing
            programme, our online educational platform,{" "}
            <a className="nav_email" href="/Educo">
              <b>EDUCO</b>
            </a>
            , and an academic mentoring programme.
          </p>

          <h3 className="educationHeader" id="Seminar-Series-at-OTC">
            {educationPage.seminarSeriesTitle}
          </h3>
          <p className="educationP">{educationPage.seminarSeriesDesc}</p>
          <img
            src={covid19impacts}
            alt="Figure caption: An example seminar block on the ‘African Diaspora (History)’."
            className="centerscheme2"
            onClick={() => {
              enlarge();
            }}
            id="myImg1"
          />
          <div
            id="myModal"
            className="modal"
            onClick={() => {
              enlarge();
            }}
          >
            <span
              className="close"
              onClick={() => {
                enlarge();
              }}
            >
              &times;
            </span>
            <img
              className="modal-content"
              id="img01"
              onClick={() => {
                enlarge();
              }}
              alt="An example of five-week project seminar series on Covid 19 and it's impact on human civilisation"
            />
            <div
              id="caption"
              onClick={() => {
                enlarge();
              }}
            ></div>
          </div>
          <p className="news_article-caption">
            <b>Figure 1.</b> An example five-week seminar series on History of
            African Diaspora at Off the Curriculum.
          </p>
          <h3 className="educationHeader" id="Seminar-Structure-at-OTC">
            Structure of subject specific seminars
          </h3>
          <p className="educationP">
            Problem-solving and creative thinking will be an the forefront of
            our educational seminars. Where ‘problem(s)’ are proposed by the
            guest speaker and seminar volunteers, and it will up to our
            programme participants to collaboratively create solutions by
            drawing from their pre-existing and gained. Promotion of
            problem-solving and critical thinking in this way can culminate in
            the emergence of new ideas and continued personal development. A
            visually description of our Problem Solving Framework, inspired by
            researchers in the field of Education, is shown in Figure Two.
          </p>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FEducation%2FEducation_griffin_problemsolving_framework.svg?alt=media&token=31f3bed9-3fb9-458c-8955-7fd25bb09ab9"
            alt="Figure caption: Off the Curriculum's collaborative problem solving framework."
            className="centerscheme2"
            onClick={() => {
              enlargeTwo();
            }}
            id="myImg2"
          />
          <div
            id="myModal2"
            className="modal"
            onClick={() => {
              enlarge();
            }}
          >
            <span
              className="close2"
              onClick={() => {
                enlarge();
              }}
            >
              &times;
            </span>
            <img
              className="modal-content"
              id="img02"
              onClick={() => {
                enlarge();
              }}
              alt="Off the Curriculum's collaborative problem solving framework."
            />
            <div
              id="caption2"
              onClick={() => {
                enlarge();
              }}
            ></div>
          </div>
          <p className="news_article-caption">
            <b>Figure 2.</b> Off the Curriculum's collaborative problem solving
            framework.
          </p>
          <p className="educationP">
            To find out more about our 2022/23 programme plan, head to our{" "}
            <a className="nav_email" href="./Programme">
              <b>Programme plan page</b>
            </a>
            . Seminars will be knowledge-building environments, providing a
            space for participants to think and express themselves. The
            emergence of new ideas in communal spaces allows others to critique,
            link, and reframe such ideas; leading to the formation of an
            adaptive, social, and innovative learning experience.
          </p>
          <h4 className="educationHeader" id="Topic-selection">
            Topic selection
          </h4>
          <p className="educationP">
            Each term, participants will have a hand in deciding which Project
            they will explore. To help the participant decide, each student will
            receive a pamphlet containing an detailed description of each
            Project's seminar plan. Participants will vote via a questionnaire
            for their particular topic, which will be circulated after the first
            seminar of the current Seminar Series (as shown in Figure 1). The
            project which has received the highest number of votes will be the
            topic for the next Seminar Series. If your topic is not selected,
            please do not let this deter you from attending our Seminar Series,
            as we promise the seminar will be a fun and engaging environment.
            Plus, we will ensure that content published to{" "}
            <a className="nav_email" href="/Educo">
              <b>EDUCO</b>
            </a>{" "}
            will cover all of the topics you get to choose from.
          </p>

          <h4 className="educationHeader" id="Pomdoro-Technique-at-OTC">
            Pomodoro Technique
          </h4>
          <p className="educationP">
            To ensure the seminars remain exciting, engaging and dynamic Off the
            Curriculum has employed the Pomodoro technique. The Pomodoro
            technique is a timeboxing technique, breaking down each seminar into
            discrete intervals (Gobbo and Vaccari, 2008).
          </p>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FEducation%2FEducation_horizontal_seminar_structure.svg?alt=media&token=a4776b75-cb6e-4308-9f29-73f11b9dfc88"
            alt="Figure caption: An example seminar design."
            className="centerscheme3"
            onclick="fn8()"
            id="myImg2"
          />
          <p className="news_article-caption">
            <b>Figure 3.</b> An example seminar design.
          </p>
          <p className="educationP">
            To limit repetition fatigue, the example seminar structure (as shown
            above) will not be followed for every seminar. However, generally,
            the seminar structure will be as follows: (1) Seminars will begin
            with a 20-minute talk by the guest speaker; (2) Participants will be
            separated into smaller focus groups, where they will be encouraged
            to engage and reflect on the topic of the seminar - with discussions
            mediated by OTC student volunteers; (3) The participants will return
            to the main seminar and engage with others and the guest speaker;
            (4) Participants will then enter the second stage of group
            discussion, this time with it being problem focussed; (5) Solutions
            proposed will be presented in the final seminar stage, where the
            seminar's content will be reiterated and reflected.
          </p>

          <h4 className="educationHeader" id="Wild-Card-Seminar">
            Wild-Card Seminars
          </h4>
          <p className="educationP">
            To give our programme participants more ownership over their
            education, Off the Curriculum have created the Wild Card programme,
            where each seminar series programme participants will nominate
            topics they would like to study, instead of electing from a
            predefined list. This can be on what ever topic they all decide
            upon, and Off the Curriculum will go about organising the seminar.
          </p>

          <h4
            className="educationHeader"
            id="Self-determined-assessment-session"
          >
            Self-determined assessment session
          </h4>
          <p className="educationP">
            Participant determined assessment is an integral part of Off the
            Curriculum's educational experience. We want assessments to serve as
            an enjoyable experience, granting participants the opportunity to
            work creatively. This could include a presentation, a social media
            challenge, or a video tutorial - the possibilities are endless.
            During such self-determined assessment session, programme
            participants are able to win one of the Seminar's Digital badges.
            More about our Digital Badging scheme can be found on our{" "}
            <a className="nav_email" href="./Accreditation">
              <b>Accreditation page</b>
            </a>
            .
          </p>
          <h3 className="educationHeader" id="Accrediation-at-OTC">
            Accrediation
          </h3>
          <h4 className="educationHeader" id="Seminar-Series-Award">
            Seminar Series Award
          </h4>

          <p className="educationP">
            Participant's will receive a Seminar Series qualification upon the
            completion of every series you participate in. To earn such a
            qualification participants are required to maintain an 80%
            attendance rate for the Seminar Series, and participate in the
            assessment session. An example of a Seminar Series certificate is
            shown in Figure 4.
          </p>
          <img
            src={Seminar_series_certificate}
            alt="Figure caption: An example Seminar Series Certificate."
            className="centerscheme2"
            onclick="fn7()"
            id="myImg1"
          />
          <p className="news_article-caption">
            <b>Figure 4.</b> An example Seminar Series Certificate.
          </p>
          <h4 className="educationHeader" id="Off-the-Curriculum-Award">
            Off the Curriculum Award
          </h4>

          <p className="educationP">
            Off the Curriculum is a two-year educational programme and we ask
            our participants to maintain an 80% attendance rate to receive an
            Off the Curriculum Award and graduate from the programme. The
            participant's graduation will be a University-esque graduation
            ceremony, flush with cap and gowns, and celebrations to follow.
          </p>

          <h3 className="educationHeader" id="Academic-mentoring-at-OTC">
            Academic Mentoring
          </h3>
          <p className="educationP">
            Off the Curriculum is proud to be facilitating the Extended Project
            Qualification (EPQ) for students at Lambeth Academy. To assist in
            the development of a successful EPQ Programme, OTC has created a
            milestones structure as part of the Programme. The EPQ is an
            independent research project worth half an A-level. Many schools
            offer this for students to complete alongside their A-levels, and is
            a programme moderated by school representatives and evaluated by
            exam boards like Edexcel. To receive an EPQ students have the choice
            of three routes: <b>Dissertation</b> - an extended essay of 5000
            words, <b>Investigation</b> - collection of primary data, creation
            of a 4000-5000 word report and delivery of a 10-minute presentation,
            or <b>Artefact</b> - a shorter essay of 1000 words to accompany an
            artefact they create - these can range from an app to a creative
            writing piece. Off the Curriculum leads the recruitment and
            assignment of expert mentors to a student's EPQ programme, which is
            otherwise run internally via the schools faculty. The assignmnent of
            specialist mentors to the students EPQ programmes is hoped to vastly
            improve performance, and both Off the Curriculum and Lambeth Academy
            will be evaluating the programme throughout. More about the EPQ and
            our pilot programme can be found{" "}
            <a className="nav_email" href="/EPQ">
              <b>here.</b>
            </a>
          </p>

          <h3 className="educationHeader" id="EDUCO">
            EDUCO
          </h3>
          <p className="educationP">
            Our online learning platform{" "}
            <a className="nav_email" href="/EDUCO">
              <b>EDUCO</b>
            </a>
            , is a space for engagement with seminars, access to assignment
            information, hand-in dropboxes and access to additional learning
            resources.
            <a className="nav_email" href="/Educo">
              <b> EDUCO</b>
            </a>{" "}
            has gone further than previously available learning platforms by
            providing Off the Curriculum participants with video tutorials
            (released weekly) on subjects aligned with the current seminar block
            they are attending, as well as other educative information. EDUCO is
            a student volunteer led platform, and participation in content
            creation is vital to receiving the Off the Curriculum Volunteer
            Award. This task is all about inspiration and exploration, the
            volunteers will decide the content focus, from myth-busting to
            concept explanation - the choice is theirs. With moderation and
            input from Off the Curriculum officers, these video tutorials form
            the basis of the broader learning experience offered to
            participants. In tandem, these tutorials will provide a perfect
            opportunity for volunteers to hone their presentation skills.
          </p>
        </div>
        <div className="one-second-Education">
          <div className="columnEducationOne">
            <img
              src={eductionTeam}
              className="authorEducation"
              alt="Education Team header for Off the Curriculum"
            ></img>
            <ul className="news_article_summary"></ul>
            <div className="news-icon">
              <div className="footer_center"></div>
            </div>
            <h2 className="About_the_author">About the team:</h2>
            <p className="prose_about_the_author">
              The Education team is in charge of developing Off the Curriculum's
              pedagogic philosophy. The Education team is responsible for our
              Seminar Series, reviewing EDUCO content, and facilitating the EPQ
              programme.
            </p>
          </div>
          <div className="columnEducationTwo">
            <h4 className="educationquickLinks">Article quick links:</h4>
            <li className="news_article">
              <a className="news_article" href="#Seminar-Series-at-OTC">
                Seminar Series at OTC
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Seminar-Structure-at-OTC">
                Seminar Structure at OTC
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Topic-selection">
                Topic selection
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Pomdoro-Technique-at-OTC">
                Pomodoro Technique
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Wild-Card-Seminar">
                Wild-Card Seminar
              </a>
            </li>
            <li className="news_article">
              <a
                className="news_article"
                href="#Self-determined-assessment-session"
              >
                Self-determined assessment session
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Accrediation-at-OTC">
                Accreditation at OTC
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Seminar-Series-Award">
                Seminar Series Award
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Off-the-Curriculum-Award">
                Off the Curriculum Award
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Academic-mentoring-at-OTC">
                Academic Mentoring
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#EDUCO">
                EDUCO
              </a>
            </li>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Education;
