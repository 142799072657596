import { FindVideo } from "../../../firebase/firebaseGetVideo";
import { UpdateViews } from "../../../firebase/firebaseStoreVideo";

export function VideoCheckPlays() {
  document.onreadystatechange = () => {
    if (document.readyState === "complete") {
      videoListener();
    }
  };
}

function videoListener() {
  let videos = document.querySelectorAll("video");
 
  let name = videos[0].nextElementSibling.attributes[1].nodeValue;
  FindVideo(name);

  videos[0].addEventListener("play", (e) => {
    UpdateViews(e);
  });
  videos[0].onplay = (e) => {

  };
}

export function VideoUpdater(name, likes, views) {
  let video = document.querySelector(".counts ");

  if (video.attributes.value.nodeValue === name) {
    let like = video.children[0];
    let view = video.firstChild;
    like.innerHTML = "likes:" + likes;
    view.innerHTML = "views:" + views;
  }
}
