import { useState, useEffect } from "react";

/**forms to use */
import SeminarContentForm from "./seminarContentForm";
import EventsForm from "./eventForm";
import GuestSpeakerForm from "./guestSpeakerForm";

/**css  */
import "./admin.css";

/**trials */
import GetEvents from "../../../firebase/firebaseGetEvents";
import { auth, db } from "../../../firebase/firebase";
import { query } from "firebase/firestore";
import { onValue, ref } from "firebase/database";

function emails(s) {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

export default function AdminPage() {
  const [formToUse, SetFormToUse] = useState(null);
  const [areaToUpdate, setAreaToUpdate] = useState("");
  const [adminTrue, setAdminTrue] = useState(null)
  const [currentUser, setCurrentUser] = useState(null);

  function updater(e) {
    e.preventDefault();
    setAreaToUpdate(e.target[0].value);
  }
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () =>{
      const emailHash = emails(currentUser.email)
      console.log(emailHash)
      const dbRef = query(ref(db, `person/${emailHash}`)); 
      onValue(dbRef, (snapshot) => {
          const userData = ('snapshot', snapshot.val())
          const adminData = userData.Admin;
          if(adminData === true) {
            console.log('what ')
            setAdminTrue(true)
          } else {
            setAdminTrue(false)
            alert('do better')
          }
          }
      )
    }
    fetchData();
  }, []);



  useEffect(() => {
    console.log(areaToUpdate);
    if (areaToUpdate === "video/name") {
      SetFormToUse(SeminarContentForm);
    } else if (areaToUpdate === "event") {
      SetFormToUse(EventsForm);
    } else if (areaToUpdate === "speaker") {
      SetFormToUse(GuestSpeakerForm);
    }
  }, [areaToUpdate]);
  // to use for newsArticle https://uiwjs.github.io/react-md-editor/
  return (
    <>
      <div className="admin">
        <form method="dialog" onSubmit={(e) => updater(e)}>
          <label htmlFor="areaToUpdate">What are you updating ?</label>
          <br />

          <select name="area" id="areaToUpdate">
            <option>Please choose a option</option>
            <optgroup label="database">
              <option value="event">Events</option>
              <option value="video/name">Seminar content</option>
              <option value="content">Content</option>
              <option value="newsArticle">News Article</option>
            </optgroup>
            <optgroup label="Storage">
              <option value="educo">Add a seminar video</option>
              <option value="shorts">Add a seminar short video</option>
              <option value="speaker">Add a guest speaker</option>
            </optgroup>
          </select>
          <br />
          <input type="submit" value="update" id="button" />
        </form>
      </div>

      <div className="formArea">
        {formToUse ? (
          <div className="formToUse">{formToUse}</div>
        ) : (
          <h1>Choose a option to update above</h1>
        )}
      </div>

      <GetEvents/>
    </>
  );
}