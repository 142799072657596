import React from "react";
import Trusteecontractpolicy from "../Policy-folder-pdf/Trustee_contract.pdf";

function Trusteecontract() {
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <div>
        <h1>Supervision policy at Off the Curriculum</h1>
        <iframe title='Our Trustee contract policy' src={Trusteecontractpolicy} className="policy"></iframe>
      </div>
    </React.Fragment>
  );
}
export default Trusteecontract;
