import React from "react";
import "./NewsLandingPage.css";
import EllipsisText from "../../../pages/educo/Components/ellipsisText/EllipsisText";
const cards = [
  { 
    id: 1, 
    title: "Advice for young people: Unleashing the Power of Self-Gratitude", 
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FunleashingSelfGratitude.webp?alt=media&token=aa5ecbf9-4280-4a7a-ba92-69506094df52',
    date: '17/05/2023',
    content: "It's time to turn up the self-appreciation dial and celebrate the hard work you've put into achieving your goals.", 
    href:'/AdviceforYoungPeoplePowerOfSelfGratitude',
},
  { 
    id: 2, 
    title: "Young. Black. And Neurodiverse.", 
    image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FyoungBlackNeurodiverse.webp?alt=media&token=e3c4353f-b53b-4d3e-a7bf-93f0666c15e3",
    date: '06/02/2023',
    content: "During our Psychology and Neuroscience seminar series last year, one of our enrolled students, Joshua-Tobiloba Olowe",
    href:'/YoungBlackAndNeurodiverse',
},
  { 
    id: 3, 
    title: "Advice for young people: Best revision techniques", 
    image:'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FbestRevisionTechniques.webp?alt=media&token=b8fa03ea-85e7-4a5d-a2f5-2edf10f5fdf0',
    date: '17/05/2023',
    content: "Revising and memorising difficult content can be a challenging task for students, particularly when faced with a large amount of information to learn.", 
    href:`/AdviceforYoungPeopleBestRevisionTechniques`
},
];

function NewsLandingPage(){
  return (
    <div>
        <h1 className="eventsHeader">
            News
        </h1>
        <h4 className="eventsHeader">
            Keep up-to-date with OTC!
        </h4>
    <div className="newsContainer">
      {cards.map((card) => (
        <div key={card.id} className="newsCard">
         <img src={card.image} alt="" />
          <h6>{card.title}</h6>
          <p>Date: {card.date}</p>
          <EllipsisText text={card.content} tag="p" maxLength={25} />
          <a href={card.href}>
          <button className='newsButton'>
            Read more here. 
          </button>
          </a>
        </div>
      ))}
    </div>
    </div>
  );
};

export default NewsLandingPage
