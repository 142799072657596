/** dependencies */
import { ref, set } from "firebase/database";
import { useState, useEffect } from "react";

/** components */
import { db } from "./firebase";
import { auth } from "./firebase";

export function CreateProfile() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  }, []);

  function emails(s) {
    return s.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  function writeUserData(name, email, profileImage, age,topicInterests, careerIntentions, colour) {

    const emailHash = emails(email);
    const userRef = ref(db, `person/${emailHash}`);
    const uid = currentUser.uid;

    const data = {
      token: true,
      user_id: uid,
      Name: name,
      Email: email,
      picture: profileImage,
      colour: colour,
      age: age,
      Liked: '', 
      Viewed:'',
      lastViewed: '', 
      lastViewTime: '', 
      seminarTagOfLastViewed: '', 
      topicInterests: topicInterests, 
      careerIntentions: careerIntentions
    };

    set(userRef, data)
      .then(() => {
        window.location.href = '/Profile';
      })
      .catch((error) => {
        //console.error(error);
      });
}

  function handleSubmit(e) {
    e.preventDefault();
    //console.log(e.target);
    const name = e.target[0].value;
    const age = e.target[1].value;
    const topicInterests = e.target[2].value;
    const careerIntentions = e.target[3].value;
    const colour = e.target[4].value;
    const email = currentUser.email;

    const pics = document.getElementById('image');
    const profileImage = pics.children.picProfile.currentSrc;

    writeUserData(name, email, profileImage, age, topicInterests, careerIntentions, colour);
  }

  return (
    <>
      <h1>Tell us about you..</h1>
      <form onSubmit={handleSubmit} className="userDetails">
      <label htmlFor="age">What is your name?</label>
        <input
          id="name1"
          type="text"
          name="firstname"
          placeholder="E.g. Michael"
        />

        <label htmlFor="age">What's your age:</label>
        <input
          id="age"
          type="number"
          name="age"
          placeholder="E.g. 16"
          min={0}
          max={100}
          required
        />
        <label htmlFor="topicInterest">What topics most interest you?</label>
        <input
          id="topicInterest"
          type="text"
          placeholder="E.g. Toxicology"
        />
        <label htmlFor="yourFuture">What would like to be in the future?</label>
        <input
          id="yourFuture"
          type="text"
          placeholder="Medicine"

        />
        <label htmlFor="colour">What's your favorite colour:</label>
        <select name="colour" id="colour" required>
          <option value="">--Please choose an option--</option>
          <option value="blue">Blue</option>
          <option value="green">Green</option>
          <option value="orange">Orange</option>
          <option value="pink">Pink</option>
          <option value="purple">Purple</option>
          <option value="red">Red</option>
          <option value="yellow">Yellow</option>
        </select>

        <input type="submit" 
                 style={{
                  backgroundColor: '#ffbaab'
              }}/>
      </form>
    </>
  );
}