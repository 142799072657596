/**dependencies */
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { ref, onValue, query as dbQuery } from "firebase/database";
import { useRef, useState, useEffect, useContext } from "react";
import { firestore, auth, db } from "../../../firebase/firebase";
import { LoginContext } from "../../../App";
import { formatDistanceToNow } from 'date-fns';
import './mediaStyling/Comment.css'
function emails(s) {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

function DisplayMessage({ name, timestamp, text, picUrl }) {
  if (timestamp) {
    const formattedTimestamp = formatDistanceToNow(timestamp.toDate(), { addSuffix: true });
    
    return (
      <div className="messagesCard-container">
        <div className="messagesCard">
          <div className="messageContainer">
            <img className="pic" src={picUrl} alt={name} />
            <div className="name">{name}</div>
            |
            <div className="time">{formattedTimestamp}</div>
            <div className="message">{text}</div>
          </div>
        </div>
      </div>
    );
  } else {
    // Return a comment without the timestamp when it's null
    return (
      <div className="messagesCard-container">
        <div className="messagesCard">
          <div className="messageContainer">
            <img className="pic" src={picUrl} alt={name} />
            <div className="name">{name}</div>
            |
            <div className="time">About a minute ago</div>
            <div className="message">{text}</div>
          </div>
        </div>
      </div>
    );
  }
}
export default function CommentSection() {
  const [message, setMessage] = useState(""); // State to store the message input value
  const [messages, setMessages] = useState([]);
  const messageInputRef = useRef(null);
  const [currentUser, setCurrentUser] = useState();
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state
  const [isChatOpen, setIsChatOpen] = useState(false); // State to track chat open/close
  const isUserSignedIn = useContext(LoginContext);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      const emailHash = emails(currentUser.email);
      const dbRef = query(ref(db, `person/${emailHash}`));
      onValue(
        dbRef,
        (snapshot) => {
          const profileData = snapshot.val();
          setStudent(profileData);
          setLoading(false); // Set loading to false when data is fetched
        },
        {
          onlyOnce: true,
        }
      );
    }
  }, [currentUser]);

  useEffect(() => {
    if (student && student.token === true) {
      messageBuilder();
    }
  }, [student]);

  function messageBuilder() {
    // Create a reference to the messages collection
    const dataComments = collection(firestore, "comments");

    let comment = document.title;
    let commentStore = comment.replaceAll(" ", "");

    const recentMessagesQuery = query(
      collection(firestore, "comments", "videos", commentStore),
      orderBy("timestamp")
    );

    // Start listening to the query.
    const unsubscribe = onSnapshot(recentMessagesQuery, function (snapshot) {
      const newMessages = [];

      snapshot.docChanges().forEach(function (change) {
        if (change.type === "added") {
          // A new message has been added
          let message = change.doc.data();
          newMessages.push({
            name: message.name,
            timestamp: message.timestamp,
            text: message.text,
            picUrl: message.profilePicUrl,
          });
          //console.log('new message:', message);
          // Update your UI with the new message here
        }
      });

      setMessages((prevMessages) => [...prevMessages, ...newMessages]);
    });

    // Remember to unsubscribe from the listener when the component unmounts
    return unsubscribe;
  }

  function handleInputChange(event) {
    setMessage(event.target.value);
  }

  async function handleFormSubmit(event) {
    event.preventDefault(); // Prevent the default form submission behavior
    if (message.trim() === "") {
      return; // Do not save empty messages
    }

    // Call the saveMessage function with the message text
    await saveMessage(message);

    // Clear the message input field
    setMessage("");

    // Focus on the message input for the next message
    messageInputRef.current.focus();
  }

  async function saveMessage(messageText) {
    // Add a new message entry to the Firebase database.
  
    let comment = document.title;
    let getUserName;
    let dataComments = collection(firestore, "comments");
    let commentStore = comment.replaceAll(" ", "");
    //console.log(commentStore)
    if (window.onload) {
      getUserName = auth.currentUser.displayName;
    }
    try {
      await addDoc(collection(dataComments, "videos", commentStore), {
        name: getUserName,
        text: messageText,
        profilePicUrl: student.picture,
        timestamp: serverTimestamp(),
      });
    } catch (error) {
      //console.error("Error writing new message to Firebase Database", error);
    }
  }

  function toggleChat() {
    setIsChatOpen((prevIsChatOpen) => !prevIsChatOpen);
  }

  const messageElements = messages.map((message, index) => (
    <DisplayMessage
      key={index}
      name={message.name}
      timestamp={message.timestamp}
      text={message.text}
      picUrl={message.profilePicUrl}
    />
  ));

  return (
    <>
      <button className={`showChats collapsible ${isChatOpen ? 'active' : ''}`} onClick={toggleChat}>
        Comment section {isChatOpen ? '-' : '+'}
      </button>
      {isChatOpen && (
        <main id="mains" className="contents">
          <div className="commentContainer">
            <div id="messagesCard-container">
              <div id="messageList">
                {messages.length > 0 ? (
                  <div>
                    {messages.map((message, index) => (
                      <DisplayMessage
                        key={index}
                        name={message.name}
                        timestamp={message.timestamp}
                        text={message.text}
                        picUrl={message.picUrl}
                      />
                    ))}
                  </div>
                ) : (
                  <div>No comments yet.</div>
                )}
              </div>
            </div>
            <div id="messagesCard">
              <div id="messages">
                <form id="message-form" onSubmit={handleFormSubmit}>
                  <input
                    className="messageField"
                    type="text"
                    id="messageInput"
                    autoComplete="off"
                    spellCheck
                    size="10"
                    placeholder="Add a comment..."
                    value={message}
                    onChange={handleInputChange}
                    ref={messageInputRef}
                  />
                  <button
                    id="submit"
                    disabled={message.trim() === ""}
                    type="submit"
                    className="messageSubmit"
                  >
                    Post
                  </button>
                </form>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}