import styles from './NewsletterBanner.module.css';
function NewsletterBanner() {
  return (
    <section className={styles.banner_container}>
      <div className={styles.content_container}>
        <div className={styles.text_container}>
          <h3>Sign Up To Our Newsletter!</h3>
          <p>
            Interested in staying up-to-date with our charity's activities, we have a <b>monthly newsletter</b> - consider signing up by here!
          </p>
        </div>
        <div className={styles.link_container}>
          <a
            href="https://offthecurriculum.us14.list-manage.com/subscribe/post?u=eda1471915b675cfc40023b97&id=85a626e92b"
            target="_blank"
            rel="noreferrer"
          >
            → Sign up!
          </a>
        </div>
      </div>
    </section>
  );
}

export default NewsletterBanner;