import React from "react";
//{ MetaTags } from "react-meta-tags";
import wellbeingTeam from "../../../images/functionality/main/education/education/wellbeingTeam.webp";
import "./MainStyling/EducationStyling.css";
import { wellbeingProgramme } from "./Media/Wellbeingprogramme";

function Wellbeingprogramme() {
  return (
    <React.Fragment>
      {/*
      <MetaTags>
        <title>Well-being programme at Off the Curriculum</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta
          name="description"
          content="The World Health Organisation (WHO) declares that health is A state of complete physical, mental and social well-being and not merely the absence of disease or infirmity…"
        />
        <meta
          name="keywords"
          content="Education, Charity, News, Educational News, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta
          name="title"
          content="Well-being programme at Off the Curriculum"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Wellbeingprogramme"
        />
        <meta
          property="og:title"
          content="Well-being programme at Off the Curriculum"
        />
        <meta
          property="og:description"
          content="The World Health Organisation (WHO) declares that health is A state of complete physical, mental and social well-being and not merely the absence of disease or infirmity…"
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/education/headers/about_education.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/education/headers/about_education.webp"
        />
        <meta
          property="og:image:alt"
          content="Well-being programme at Off the Curriculum"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Wellbeingprogramme"
        />
        <meta
          property="twitter:title"
          content="Education at Off the Curriculum"
        />
        <meta
          property="twitter:description"
          content="The World Health Organisation (WHO) declares that health is A state of complete physical, mental and social well-being and not merely the absence of disease or infirmity…"
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/education/headers/about_education.webp"
        />
        <meta
          property="twitter:image:alt"
          content="Well-being programme at Off the Curriculum"
        />
      </MetaTags>
  */}

      <div className="centre-frame">
        <div className="second-second-Education">
          <h1 className="educationHeader">{wellbeingProgramme.pagetitle}</h1>
          <h3 className="educationHeader" id="Wellbeing-Introduction">
            {wellbeingProgramme.pagetitle}
          </h3>
          <p className="educationP">{wellbeingProgramme.introDesc}</p>
          <h3 className="educationHeader" id="Wellbeing-programmeLeads">
            {wellbeingProgramme.programmeleadsTitle}
          </h3>
          <p className="educationP">{wellbeingProgramme.programmeLeadsDesc}</p>
          <h3 className="educationHeader" id="Wellbeing-programmeAims">
            {wellbeingProgramme.programmeaimsTitle}
          </h3>
          <p className="educationP">{wellbeingProgramme.programmeaimsDesc}</p>
          <h3 className="educationHeader" id="Wellbeing-Plan">
            {wellbeingProgramme.programmeplanTitle}
          </h3>
          <p className="educationP">{wellbeingProgramme.programmeplanDesc}</p>
          <h3 className="educationHeader" id="Wellbeing-Evaluation">
            {wellbeingProgramme.programmeevaluationTitle}
          </h3>
          <p className="educationP">{wellbeingProgramme.programmeLeadsDesc}</p>
          <h3 className="educationHeader" id="Wellbeing-Rationale">
            {wellbeingProgramme.programmerationaleTitle}
          </h3>
          <p className="educationP">
            {wellbeingProgramme.programmerationaleDesc}
          </p>
        </div>
        <div className="one-second-Education">
          <div className="columnEducationOne">
            <img
              src={wellbeingTeam}
              className="authorEducation"
              alt="Wellbeing Team at Off the Curriculum"
            ></img>
            <ul className="news_article_summary">
              <br />
            </ul>
            <div className="news-icon">
              <div className="footer_center"></div>
            </div>

            <h2 className="About_the_author">About the team:</h2>
            <p className="prose_about_the_author">
              The Education team is in charge of developing Off the Curriculum's
              pedagogic philosophy. The Education team is responsible for our
              Seminar Series, reviewing EDUCO content, and facilitating the EPQ
              programme.
            </p>
          </div>
          <div className="columnEducationTwo">
            <h4 className="educationquickLinks">Article quick links:</h4>
            <li className="news_article">
              <a className="news_article" href="#Wellbeing-Introduction">
                {wellbeingProgramme.pagetitle}
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Wellbeing-programmeLeads">
                {wellbeingProgramme.programmeleadsTitle}
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Wellbeing-programmeAims">
                {wellbeingProgramme.programmeaimsTitle}
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Wellbeing-Plan">
                {wellbeingProgramme.programmeplanTitle}
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Wellbeing-Evaluation">
                {wellbeingProgramme.programmeevaluationTitle}
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Wellbeing-Rationale">
                {wellbeingProgramme.programmerationaleTitle}
              </a>
            </li>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Wellbeingprogramme;
