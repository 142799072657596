import AstroAnswer from "../quizAnswerSheets/Astronomy/AstrophysicsAnswerSheet";
import QuizChecker from "../quizAnswerSheets/multiUseTrial/multi";

export default function AstrophysicsQuestion() {
  function videoRestart(e) {
    e.preventDefault();
    //AstroAnswer(e);
    let video = document.getElementById("videoPlayer");
    let quiz = document.getElementById("quiz");
    quiz.style.visibility = "hidden";
    QuizChecker("astro", e);
    window.location.href = '/'
    // We need to set this manually for each quiz page
  }
  return (
    <>
      <form>
        {/*Centre this - but do we need these labels?*/}
        <label htmlFor="name">Name</label>
        <input type="text" required />
        <label htmlFor="email">Email</label>
        <input type="email" required />
        <br />
        {/* Then needs to start on a new page - Press enter starts a new page scroll*/}
        <label htmlFor="Question one">Question 1:</label>
        <p>
          Fill in the blanks to complete this passage about the electromagnetic
          spectrum: Spectra or (a)_________ is a measure of how light interacts
          with (b)________. It is a measure of how much light passes through, is
          (c)_________ by, or is (d)________ by that (b)__________ .
        </p>
        <div className="questionBox">
          <br />
          <label htmlFor="answer a"> Question 2 answers:</label>
          <select
            name="answerA"
            id="answerA"
            required
            className="answer"
            value={this}
          >
            <option value="">--Please choose an answer for (a)--</option>
            <option value="absorbed">Absorbed</option>
            <option value="matter">Matter</option>
            <option value="reflected">Reflected</option>
            <option value="spectroscopy">spectroscopy</option>
          </select>
          <select
            name="answerB"
            id="answerB"
            required
            className="answer"
            value={this}
          >
            <option value="">--Please choose an answer for (b)--</option>
            <option value="absorbed">Absorbed</option>
            <option value="matter">Matter</option>
            <option value="reflected">Reflected</option>
            <option value="spectroscopy">spectroscopy</option>
          </select>
          <select
            name="answerC"
            id="answerC"
            required
            className="answer"
            value={this}
          >
            <option value="">--Please choose an answer for (c)--</option>
            <option value="absorbed">Absorbed</option>
            <option value="matter">Matter</option>
            <option value="reflected">Reflected</option>
            <option value="spectroscopy">spectroscopy</option>
          </select>
          <select
            name="answerD"
            id="answerD"
            required
            className="answer"
            value={this}
          >
            <option value="">--Please choose an answer for (d)--</option>
            <option value="absorbed">Absorbed</option>
            <option value="matter">Matter</option>
            <option value="reflected">Reflected</option>
            <option value="spectroscopy">spectroscopy</option>
          </select>
        </div>

        <div className="questionBox">
          <fieldset>
            <p className="QuestionNumber">Question 2:</p>
            <legend>What are two types of meteorites:</legend>

            <div>
              <input
                type="checkbox"
                id="iron"
                name="iron"
                className="answer"
                checked={this}
              />
              <label htmlFor="iron">Iron</label>

              <input
                type="checkbox"
                id="chondritic"
                name="chondritic"
                className="answer"
                checked={this}
              />
              <label htmlFor="chondritic">Chondritic Stony</label>

              <input
                type="checkbox"
                id="ice"
                name="ice"
                className="answer"
                checked={this}
              />
              <label htmlFor="ice giants">Ice Giants</label>

              <input
                type="checkbox"
                id="none"
                name="none"
                className="answer"
                checked={this}
              />
              <label htmlFor="noneOfAbove">None of these</label>
            </div>
          </fieldset>
        </div>
        <h3>Match the term to its correct definition</h3>
        <div className="questionBox three">
          <fieldset name="one">
            <legend>Matter with slow velocity and low energy.</legend>

            <p>Question 3</p>
            <input
              type="radio"
              id="darkMatter"
              name="one"
              value="darkMatter"
              className="answer"
              checked={this}
            />
            <label htmlFor="darkMatter">Dark Matter</label>

            <input
              type="radio"
              id="coldDarkMatter"
              name="one"
              value="coldDarkMatter"
              className="answer"
              checked={this}
            />
            <label htmlFor="coldDarkMatter">Cold dark matter</label>

            <input
              type="radio"
              id="hotDarkMatter"
              name="one"
              value="hotDarkMatter"
              className="answer"
              checked={this}
            />
            <label htmlFor="hotDarkMatter">Hot dark matter</label>

            <input type="radio" id="inflation" name="one" value="inflation" />
            <label htmlFor="inflation">Inflation</label>

            <input
              type="radio"
              id="standardModel"
              name="one"
              value="standardModel"
              className="answer"
              checked={this}
            />
            <label htmlFor="standardModel">Standard model</label>

            <input
              type="radio"
              id="recombination"
              name="one"
              value="recombination"
              className="answer"
              checked={this}
            />
            <label htmlFor="recombination">recombination</label>
          </fieldset>
        </div>
        <div className="questionBox three">
          <fieldset>
            <p>Question 3a</p>

            <legend>A period of time when hydrogen was formed.</legend>
            <div>
              <input
                type="radio"
                id="darkMatter"
                name="two"
                value="darkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="darkMatter">Dark Matter</label>
            </div>

            <div>
              <input
                type="radio"
                id="coldDarkMatter"
                name="two"
                value="coldDarkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="coldDarkMatter">Cold dark matter</label>
            </div>

            <div>
              <input
                type="radio"
                id="hotDarkMatter"
                name="two"
                value="hotDarkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="hotDarkMatter">Hot dark matter</label>
            </div>
            <div>
              <input type="radio" id="inflation" name="two" value="inflation" />
              <label htmlFor="inflation">Inflation</label>
            </div>
            <div>
              <input
                type="radio"
                id="standardModel"
                name="two"
                value="standardModel"
                className="answer"
                checked={this}
              />
              <label htmlFor="standardModel">Standard model</label>
            </div>
            <div>
              <input
                type="radio"
                id="recombination"
                name="two"
                value="recombination"
                className="answer"
                checked={this}
              />
              <label htmlFor="recombination">recombination</label>
            </div>
          </fieldset>
        </div>
        <div className="questionBox three">
          <fieldset>
            <p>Question 3b</p>

            <legend>
              A period of time where the universe was expanding exponentially.
            </legend>
            <div>
              <input
                type="radio"
                id="darkMatter"
                name="three"
                value="darkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="darkMatter">Dark Matter</label>
            </div>

            <div>
              <input
                type="radio"
                id="coldDarkMatter"
                name="three"
                value="coldDarkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="coldDarkMatter">Cold dark matter</label>
            </div>

            <div>
              <input
                type="radio"
                id="hotDarkMatter"
                name="three"
                value="hotDarkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="hotDarkMatter">Hot dark matter</label>
            </div>
            <div>
              <input
                type="radio"
                id="inflation"
                name="three"
                value="inflation"
                className="answer"
                checked={this}
              />
              <label htmlFor="inflation">Inflation</label>
            </div>
            <div>
              <input
                type="radio"
                id="standardModel"
                name="three"
                value="standardModel"
                className="answer"
                checked={this}
              />
              <label htmlFor="standardModel">Standard model</label>
            </div>
            <div>
              <input
                type="radio"
                id="recombination"
                name="three"
                value="recombination"
                className="answer"
                checked={this}
              />
              <label htmlFor="recombination">recombination</label>
            </div>
          </fieldset>
        </div>
        <div className="questionBox three">
          <fieldset>
            <p>Question 3c</p>

            <legend>Matter with fast velocity and high energy.</legend>
            <div>
              <input
                type="radio"
                id="darkMatter"
                name="four"
                value="darkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="darkMatter">Dark Matter</label>
            </div>

            <div>
              <input
                type="radio"
                id="coldDarkMatter"
                name="four"
                value="coldDarkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="coldDarkMatter">Cold dark matter</label>
            </div>

            <div>
              <input
                type="radio"
                id="hotDarkMatter"
                name="four"
                value="hotDarkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="hotDarkMatter">Hot dark matter</label>
            </div>
            <div>
              <input
                type="radio"
                id="inflation"
                name="four"
                value="inflation"
                className="answer"
                checked={this}
              />
              <label htmlFor="inflation">Inflation</label>
            </div>
            <div>
              <input
                type="radio"
                id="standardModel"
                name="four"
                value="standardModel"
                className="answer"
                checked={this}
              />
              <label htmlFor="standardModel">Standard model</label>
            </div>
            <div>
              <input
                type="radio"
                id="recombination"
                name="four"
                value="recombination"
                className="answer"
                checked={this}
              />
              <label htmlFor="recombination">recombination</label>
            </div>
          </fieldset>
        </div>
        <div className="questionBox three">
          <fieldset>
            <p>Question 3d</p>

            <legend>
              Unknown matter that composes around 27% of the universe's energy.
            </legend>
            <div>
              <input
                type="radio"
                id="darkMatter"
                name="five"
                value="darkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="darkMatter">Dark Matter</label>
            </div>

            <div>
              <input
                type="radio"
                id="coldDarkMatter"
                name="five"
                value="coldDarkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="coldDarkMatter">Cold dark matter</label>
            </div>

            <div>
              <input
                type="radio"
                id="hotDarkMatter"
                name="five"
                value="hotDarkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="hotDarkMatter">Hot dark matter</label>
            </div>
            <div>
              <input
                type="radio"
                id="inflation"
                name="five"
                value="inflation"
                className="answer"
                checked={this}
              />
              <label htmlFor="inflation">Inflation</label>
            </div>
            <div>
              <input
                type="radio"
                id="standardModel"
                name="five"
                value="standardModel"
                className="answer"
                checked={this}
              />
              <label htmlFor="standardModel">Standard model</label>
            </div>
            <div>
              <input
                type="radio"
                id="recombination"
                name="five"
                value="recombination"
                className="answer"
                checked={this}
              />
              <label htmlFor="recombination">recombination</label>
            </div>
          </fieldset>
        </div>
        <div className="questionBox three">
          <fieldset>
            <p>Question 3e</p>

            <legend>
              A particle physics theory which include all normal matter
              particles, like electrons and neutrons.
            </legend>
            <div>
              <input
                type="radio"
                id="darkMatter"
                name="six"
                value="darkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="darkMatter">Dark Matter</label>
            </div>

            <div>
              <input
                type="radio"
                id="coldDarkMatter"
                name="six"
                value="coldDarkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="coldDarkMatter">Cold dark matter</label>
            </div>

            <div>
              <input
                type="radio"
                id="hotDarkMatter"
                name="six"
                value="hotDarkMatter"
                className="answer"
                checked={this}
              />
              <label htmlFor="hotDarkMatter">Hot dark matter</label>
            </div>
            <div>
              <input type="radio" id="inflation" name="six" value="inflation" />
              <label htmlFor="inflation">Inflation</label>
            </div>
            <div>
              <input
                type="radio"
                id="standardModel"
                name="six"
                value="standardModel"
                className="answer"
                checked={this}
              />
              <label htmlFor="standardModel">Standard model</label>
            </div>
            <div>
              <input
                type="radio"
                id="recombination"
                name="six"
                value="recombination"
                className="answer"
                checked={this}
              />
              <label htmlFor="recombination">recombination</label>
            </div>
          </fieldset>
        </div>
        <button type="button" onClick={(e) => videoRestart(e)}>
          Submit Your answers{" "}
        </button>
      </form>
    </>
  );
}