import React from "react";
import volunteerTeam from "../../../images/functionality/main/volunteer/volunteeringTeam.webp";
import "./Mainstyling/Volunteering.css";

//{ MetaTags } from "react-meta-tags";

function Volunteering() {
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      {/*
      <MetaTags>
        <title>Volunteering at Off the Curriculum</title>
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta name="robots" content="index, follow" />
        <meta name="title" content="Volunteer at Off the Curriculum" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Our Seminar and EDUCO volunteers are a fundamental aspect of our charity and play a key role in ensuring we meet our educational standards"
        />
        <meta
          name="keywords"
          content="Education, Charity, News, Educational News, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Volunteering"
        />
        <meta property="og:title" content="Volunteer at Off the Curriculum" />
        <meta
          property="og:description"
          content="Our Seminar and EDUCO volunteers are a fundamental aspect of our charity and play a key role in ensuring we meet our educational standards"
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/logos/OTC_logo_small_colour_with_background.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/logos/OTC_logo_small_colour_with_background.webp"
        />
        <meta property="og:image:alt" content="The Off the Curriculum logo." />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Volunteering"
        />
        <meta
          property="twitter:title"
          content="Volunteer at Off the Curriculum"
        />
        <meta
          property="twitter:description"
          content="Our Seminar and EDUCO volunteers are a fundamental aspect of our charity and play a key role in ensuring we meet our educational standards"
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/logos/OTC_logo_small_colour_with_background.webp"
        />
        <meta
          property="twitter:image:alt"
          content="The Off the Curriculum logo."
        />
      </MetaTags>
  */}
      <div className="centre-frame">
        <div className="second-second-Vol">
          <h1 className="educationHeader">
            Volunteering at Off the Curriculum
          </h1>
          <p className="volunteerTemplate">
            Our Seminar and EDUCO volunteers are a fundamental aspect of our
            charity and play a key role in ensuring we meet our educational
            standards. As a volunteer at Off the Curriculum you will receive
            training and development opportunities to ensure you are adequately
            prepared to support our participants in their educational journey.
          </p>
          <h2 className="educationHeader" id="Role-descriptions">
            Role descriptions
          </h2>
          <p className="volunteerTemplate">
            Our Seminar and EDUCO volunteers are a fundamental aspect of our
            charity and play a key role in ensuring we meet our educational
            standards. As a volunteer at Off the Curriculum you will receive
            training and development opportunities to ensure you are adequately
            prepared to support our participants in their educational journey.
          </p>
          <h2
            className="educationHeader"
            id="What-does-it-mean-to-volunteer-at-Off the Curriculum?"
          >
            What does it mean to volunteer at Off the Curriculum?
          </h2>
          <p className="volunteerTemplate">
            Being a volunteer at Off the Curriculum, means you have the
            opportunity to inspire and mentor future leaders on the subject you
            have chosen to study for your further education. There are four
            types of voluntary opportunities at Off the Curriculum: Seminar,
            EDUCO, Academic (EPQ) and Professional mentoring.
          </p>
          <h2 className="educationHeader" id="Seminar-Volunteers">
            Seminar Volunteers
          </h2>
          <p className="volunteerTemplate">
            Being a seminar volunteer at Off the Curriculum you will have the
            opportunity to interact with our seminar Guest speakers and
            participants during our termly seminar series. The core of our
            educational ethos is engagement and as a result, all seminars will
            incorporate break-out room activities, where the Seminar Volunteers
            role will be to assist and facilitate discussions between enrolled
            participants. An example of a break-out room activity would be the
            creation of Zine describing a specific Neurological Disorder, which
            was conducted during our Neurology Seminar led by Nicole Levermore
            and Nicole Curtis (Officers at the Charity). During this activity
            seminar volunteers were expected to use the G Suite platform,
            specifically Google Docs, to collaboratively write a Zine with
            participants in their breakout room.
            <br />
            To best prepare yourself for each individual seminar, during your
            Induction, you will receive an Off the Curriculum Welcome Pack,
            which will explain each seminar of the series in further detail,
            necessary technological training (revolving around boosting your
            familiarity with the G Suite platform), and additional training on
            how best to facilitate breakout room discussion.
          </p>
          <h2 className="educationHeader" id="EDUCO-Volunteers">
            EDUCO Volunteers
          </h2>
          <p className="volunteerTemplate">
            EDUCO volunteers are the core of our EDUCO platform. This role
            requires individuals to create educational content, by writing
            scripts which are three to five minutes in duration on topics
            relating to the seminar series schedule. These scripts will be
            internally reviewed by our Education Department, to ensure their
            scientific accuracy and clarity of explanation, with a focus on the
            video's target audience being 16 to 18 year old's.
            <br />
            Volunteers based in the London area will have the opportunity to
            meet with our Videographers to film the delivery of your scripts on
            camera, while individuals outside of London can, if they so wish,
            film themselves using their own mobile phones or camera devices, if
            they have access to such devices.
            <br />
            Captured footage will be collaboratively edited with the EDUCO
            volunteer and Video-editing Team at Off the Curriculum so as to
            produce educational content of the highest quality. Each EDUCO
            volunteer is free to create as many or as few videos as they wish,
            and each volunteer has complete freedom on the subject matter of
            each video (so long as it is aligned to our seminar series). This is
            an opportunity to be creative and think-outside-of-the-box. We can’t
            wait to see what you create!
          </p>
          <h2 className="educationHeader" id="Academic-Mentoring">
            Academic Mentoring
          </h2>
          <p className="volunteerTemplate">
            Off the Curriculum has established a partnership with the Lambeth
            Academy revolving around the recruitment of volunteers to act as
            Expert Mentors for students undertaking their Extended Project
            Qualification (EPQ). The EPQ is an independent research project
            worth half an A-level (find out more about the {" "}
            <a className="Article_title" href="/EPQ" alt='EPQ link'>
            EPQ   here
            </a>
            ).
            <br />
            This academic mentoring opportunity is over a year-long period, with
            the frequency of scheduled mentoring sessions being entirely up to
            the mentor and mentees. Throughout this Academic Mentoring
            programme, Off the Curriculum will continually seek both the Mentor
            and Mentee’s feedback, assist in session scheduling, facilitate a
            safe-space for learning, and uphold the privacy and safeguarding
            requirements of all involved parties.
            <br />
            This academic mentoring opportunity is available to Ph.D. candidates
            enrolled at Universities across the United Kingdom and assignment of
            mentor to a mentee is based on the appropriateness of the
            prospective mentor's background and the area of the Mentees EPQ
            project focus.
            <br />
            Training opportunities will be available to both the Mentee and
            Mentor prior to- and throughout the mentoring programme, including
            exploration of the GROW, OSKAR and APPROCESS models of mentoring,
            access to professional development schemes, safeguarding training,
            and soft-skill enhancement, i.e., communication and interpersonal
            skills.
          </p>
          <h2 className="educationHeader" id="Professional-Mentoring">
            Professional Mentoring
          </h2>
          <p className="volunteerTemplate">
            Off the Curriculum will offer our participants the opportunity to
            enroll in a professional development mentoring scheme. Enrolled
            participants will be matched with their Professional Mentor based on
            the alignment of the participants career aspirations and the
            prospective mentors employment history. This professional mentoring
            scheme will occur over two-years (the duration of which each student
            is enrolled on the programme) and Mentors and Mentees, at a minimum,
            are expected to meet once every quarter (six sessions over a two
            year period) to discuss employability and professional development.
            Similar to our Academic Mentoring programme, both, Mentors and
            Mentees will have access to the training opportunities to
            appropriately prepare for this mentoring scheme.
          </p>

          <h2 className="educationHeader" id="Professional-Mentoring">
            Interested in volunteering for Off the Curriculum?
          </h2>
          <p className="volunteerTemplate">
            Off the Curriculum believes educational empowerment opens up
            previously unnoticeable doors and assumed unattainable
            opportunities. Any adolescent, regardless of their predetermined
            circumstances, can achieve success if given the opportunity and
            guidance. We believe connecting students and professionals can make
            the transition into adulthood much smoother and exciting for all
            those involved, and we are always looking for individuals to join
            the Off the Curriculum team. If you would like to know more about
            Off the Curriculum, the positions we are looking to fill and what
            they entail, please visit our{" "}
            <a className="Article_title" href="/Careers">
              careers page.
            </a>
          </p>
        </div>
        <div className="one-second-Vol">
          <div className="columnVolOne">
            <img src={volunteerTeam} className="author_article_image" alt='Volunteer icon'></img>
   {/*<ul className="news_article_summary">
              <br />
              <div></div>
            </ul>*/}
            <div className="news-icon">
              <div className="footer_center"></div>
            </div>
            <h2 className="About_the_author">About the team:</h2>
            <p className="prose_about_the_author">
              The Volunteering team is in charge of recruiting and managing
              short and long term volunteers for the Charity, whilst
              establishing partnerships with Universities across the UK. From
              their recruitment onwards, the team ensures that all volunteers
              enjoy their time with us and use their volunteering experience to
              grow personally and professionally.
            </p>
          </div>
          <div className="columnEducationTwo">
            <h3 className="educationHeader">Article quick links:</h3>
            <ul>
            <li className="news_article">
              <a className="news_article" href="#Volunteering-at-OTC">
                Volunteering at OTC
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Role-descriptions">
                Role descriptions
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Seminar-Volunteers">
                Seminar Volunteers
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#EDUCO-Volunteers">
                EDUCO Volunteers
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Academic-Mentoring">
                Academic Mentoring
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Professional-Mentoring">
                Professional Mentoring
              </a>
            </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Volunteering;
