const objHistoryVolunteer = {
  title: "History EDUCO Volunteer",
  subtitle: "London or Remote",
  desc: `create educational content, i.e. short videos that will be uploaded on 
        Educo, OTC’s own educational platform. Educo was created to improve the 
        participants’ experience and aid them in exploring The History of the African Diaspora. The 
        “Educo” Volunteers will therefore be asked to create - with the help of 
        OTC’s personnel - video content on their area of study/interest.`,
  descFull: ``,
  skills: ["N/A"],
  where: {
    location: "London, SE12DU",
    desc: "Mainly at home, or remote opportunity.",
  },
  time: {
    workTime: "Either in or out of office hours",
    timeNeeded:
      "10-15 hours total (however much needed to create and film 5 scripts/videos)",
  },
  whatWillYouBeDoing: {
    p: `The main duties of the role:`,
    ul: [
      `To write scripts for educational videos (2-5 minutes long) on your area 
        of study or interest, in collaboration with our Educational team.`,
      `To meet with our Educo officers in Central London, to film the delivery 
        of your scripts.`,
    ],
  },
  whatAreWeLookingFor: {
    p: ``,
    ul: [],
  },
  whatDifferenceWillYouMake: {
    p: ``,
    ul: [],
  },
  whatsInItForTheVolunteer: {
    p: `The volunteers who fulfill this role will have the opportunity:`,
    ul: [
      `To engage with younger people and have a positive impact on their educational experience`,
      `To develop their communication skills, both written and oral, by creating 
        the educational scripts and delivering the content in front of a camera 
        for an online audience`,
      `To be part of an organisation that aims to build a supportive and actualised community`,
    ],
  },
};

export default objHistoryVolunteer;
