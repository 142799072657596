import Stars from '../../../../../images/functionality/educo/icons/term-1/astronomy/stars.webp'
import Planets from '../../../../../images//functionality/educo/icons/term-1/astronomy/planets.webp'
import Protoplanets from '../../../../../images/functionality/educo/icons/term-1/astronomy/protoplanets.webp'
import Solarsystem from '../../../../../images/functionality/educo/icons/term-1/astronomy/solarsystem.webp'
import Telescope from '../../../../../images/functionality/educo/icons/term-1/astronomy/telescope.webp'
import Moon from '../../../../../images/functionality/educo/icons/term-1/astronomy/moon.webp'

const objArtificialIntelligenceRobotics = {
    webpagedesc: ``, 
    webpagekeywords: ``, 
    webpagepageurl: ``, 
    webpagethumbnail: ``, 
    webpagethumbnailAlt:``, 
    organisationtwitter: ``, 
    title: 'Artificial Intelligence and Robotics', 
    courseDesc: 
        `
        During the Artificial Intelligence and Robotic Seminar Series you will explore topics such as their use in everyday life, how to build AI and Robotic systems, the ethics of using such technologies, and attempt to write your own programmes. 
        `
    ,
    quickLinkOneDesc:
    `
    AI in Action: Making a Very Simple AI
    `
    ,
    quickLinkOne: `
    /ArtificialIntelligenceRoboticsInEveryDayLife
    `, 
    quickLinkTwoDesc: 
    `
    Ethics of AI and Robitic Technology
    `, 
    quickLinkTwo:
    `
    /ArtificialIntelligenceEthicsofAIAndRobitics
    `, 
    quickLinkThreeDesc:
    `
    AI and Robotics In Every Day Life Part One
    `, 
    quickLinkThree: 
    `
    /ArtificialIntelligenceRoboticsInEveryDayLifePartOne
    `, 
    quickLinkFourDesc:`
    AI and Robotics In Every Day Life Part Two
    `, 
    quickLinkFour: 
    `
    /ArtificialIntelligenceRoboticsInEveryDayLifePartTwo
    `, 
    quickLinkFiveDesc: 
    `
    AI and Robotics In Every Day Life Part One
    `, 
    quickLinkFive: 
    `
    /ArtificialIntelligenceRoboticsInEveryDayLifePartOne
    `, 
    courses:[
    {
        key: 1, 
        sectionimage: Telescope, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        AI and Robotics in Everyday Life
        `, 
        sectiondesc: `
        In this seminar series, student volunteers should discuss how robots and AI are used in everyday life. They should consider the daily uses of robots and AI in the fields of medicine, engineering, education, agriculture, and technology. Specifically, volunteers could discuss the usage of robots and AI in reference to smartphones, social media, advertising, etc. This discussion of the usage of robots and AI in everyday life should outline the specific usage of robots/AI, their impact, criticism, and the future of robots and AI. 
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
                [
                    {
                        key: 1, 
                        videoTitle: 'AI in Action: Making a Very Simple AI',
                        videoDesc: `Karina explores how the streaming platform, Netflix, uses clustering (unsupervised learning) based algorithms to produce a unique user experience. Feeding users with a variety of video content tailored to their personal preferences and interests. Enjoy!`,
                        videoInfo: `100 views | 10 days ago | Karina Dangsinghani Wadhwani`, 
                        videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FartificialIntelligenceRobotics%2FArtificialIntelligenceMakingAVerySimpleAI%2FArtificialIntelligenceMakingAVerySimpleAIThumbnail.webp?alt=media&token=32beec63-aef4-4e0f-9662-6733fd429b64',
                        videoImageAlt: `AI in Action: Making a Very Simple AI Thumbnail`, 
                        videohref: `/ArtificialIntelligenceMakingAVerySimpleAIPartOne`, 
                    }, 
                    {
                        key: 2, 
                        videoTitle: 'AI and Robotics In Every Day Life Part Two',
                        videoDesc: `Yuxin explores the algorithm behind predictive texts. Here, technology companies make use of Generative models, to generate predictions on the next word an individual will likely use. An mathematic explanation would be Generative models are used to generate new data instances, based on the currently available dataset.`,
                        videoInfo: `100 views | 10 days ago | Yuxin Shi`, 
                        videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FartificialIntelligenceRobotics%2FArtificialIntelligenceRoboticsInEveryDayLifePartTwo%2FArtificialIntelligenceRoboticsInEveryDayLifePartTwoThumbnail.webp?alt=media&token=344830cc-d745-44c1-bf8b-976cb41b6c9c',
                        videoImageAlt: `A Journey Through The Solar System Thumbnail`, 
                        videohref: `/ArtificialIntelligenceRoboticsInEveryDayLifePartTwo`, 
                    }, 
                ]
    }, 
    {
        key: 2, 
        sectionimage: Solarsystem, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        Building a robot or AI system: from design to finished product
        `, 
        sectiondesc: `
        This seminar series will explain how robots are built from design to finished project. Advancing chronologically, the seminar series will begin by exploring the design process and the usage of software such as CAD (computer aided design). Next, student volunteers should explore the role of electronics and power sources in robot creation by outlining the importance and main electronic tools used. The coding and programming process should then be explained. Student volunteers will discuss the usage of coding platforms such as Python and briefly explain the coding process. Finally, the fabrication and assembly stage should be outlined. Usage of technologies such as laser cutters, 3D printers, and CNC mills will be explored as well as some of the logical challenges to assembling robots. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: '',
                videoDesc: ``,
                videoInfo: ``, 
                videoImage: '',
                videoImageAlt: ``, 
                videohref: ``, 
            }, 
        ]
    }, 
    {
        key: 3, 
        sectionimage: Stars, 
        sectionimageAlt: `
        Climate Policy
        `, 
        sectiontitle: `
        Robotic Programming
        `, 
        sectiondesc: `
        In this seminar series, robotic programming should be explored. Student volunteers should provide a general overview of what robotic programming is. More specifically, student volunteers should explain the role of computer software, the different coding languages and their uses, and the process of robotic programming. Current examples of what robotic programming looks like today should be discussed. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'AI in Action: Making a Very Simple AI',
                videoDesc: `Karina explores how the streaming platform, Netflix, uses clustering (unsupervised learning) based algorithms to produce a unique user experience. Feeding users with a variety of video content tailored to their personal preferences and interests. Enjoy!`,
                videoInfo: `100 views | 10 days ago | Karina Dangsinghani Wadhwani`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FartificialIntelligenceRobotics%2FArtificialIntelligenceRoboticsInEveryDayLifePartOne%2FaiAndRobiticsInEverydayLifeThumbnail.webp?alt=media&token=e65827ea-b2f6-43c4-8a76-cc906ba00a5b',
                videoImageAlt: `A Journey Through The Solar System Thumbnail`, 
                videohref: `/ArtificialIntelligenceRoboticsInEveryDayLifePartOne`, 
            }, 
        ]
    }, 
    {
        key: 4, 
        sectionimage: Stars, 
        sectionimageAlt: `
        Climate Policy
        `, 
        sectiontitle: `
        Ethics of AI and Robotic Technology
        `, 
        sectiondesc: `
        This seminar series will critically consider the impact and ethics of AI and other robotic technology on society. Student volunteers should consider the ethics of privacy, surveillance, bias, autonomy, and behavioural manipulation in reference to AI and robotics. Real world examples should be used to enhance discussion and current political and social ethical debates should be explored. The implications of AI ethics should also be considered. 
 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'Privacy and Surveillance Ethics in AI and Robotics',
                videoDesc: `Yuxin will explore how machine learning algorithms have developed to protect individual's privacy when using the Internet.`,
                videoInfo: `100 views | 10 days ago | Yuxin Shi`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FartificialIntelligenceRobotics%2FArtificialIntelligencePrivacyandSurveillanceEthics%2FArtificialIntelligencePrivacyandSurveillanceEthicsThumbnail.webp?alt=media&token=178e96c1-bb0e-41d9-9ee3-f9ef49837e8f',
                videoImageAlt: `A Journey Through The Solar System Thumbnail`, 
                videohref: `/ArtificialIntelligencePrivacyandSurveillanceEthics`, 
            }, 
            {
                key: 2, 
                videoTitle: 'Ethics of AI and Robitic Technology',
                videoDesc: `The ethics of using AI is a very complex issue and there a lot of examples of how bias introduced into AI systems have brought about recruiting, or racialised issues. Here, we will cover an introduce you to the world of Ethics in AI.`,
                videoInfo: `100 views | 10 days ago | Karina Dangsinghani Wadhwani`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FartificialIntelligenceRobotics%2FArtificialIntelligenceEthicsofAIAndRobitics%2FArtificialIntelligenceEthicsofAIAndRobiticsThumbnail.webp?alt=media&token=f22b2882-11fe-409e-9902-abef7c80248d',
                videoImageAlt: `Ethics of AI and Robitic Technology Thumbnail`, 
                videohref: `/ArtificialIntelligenceEthicsofAIAndRobitics`, 
            }, 
        ]
    },
    {
        key: 5, 
        sectionimage: Stars, 
        sectionimageAlt: `
        Climate Policy
        `, 
        sectiontitle: `
        AI in action: Making a very simple AI
        `, 
        sectiondesc: `
        In this seminar series, the basics of creating AI should be explained. Student volunteers should discuss key terms and processes including machine learning, neural networks, algorithms, the usage of programming, and the available platforms to run AI with. Supervised versus unsupervised AI learning should also be considered. Examples of simple AI in our daily lives could be explored. In this section of the seminar series, student-volunteers may want to note the differences and similarities between creating AI and robotics. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'AI in Action: Making a Very Simple AI',
                videoDesc: `Karina explores how the streaming platform, Netflix, uses clustering (unsupervised learning) based algorithms to produce a unique user experience. Feeding users with a variety of video content tailored to their personal preferences and interests. Enjoy!`,
                videoInfo: `100 views | 10 days ago | Karina Dangsinghani Wadhwani`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FartificialIntelligenceRobotics%2FArtificialIntelligenceMakingAVerySimpleAI%2FArtificialIntelligenceMakingAVerySimpleAIThumbnail.webp?alt=media&token=32beec63-aef4-4e0f-9662-6733fd429b64',
                videoImageAlt: `AI in Action: Making a Very Simple AI Thumbnail`, 
                videohref: `/ArtificialIntelligenceRoboticsInEveryDayLifePartOne`, 
            }, 
            {
                key: 2,
                videoTitle: "Making a Very Simple AI Part Two",
                videoDesc: `In this video you will learn the key algorithms currently used by AI experts ranging from supervised to unsupervised learning and their current use cases.`,
                videoInfo: `100 views | 10 days ago | Yuxin Shi`,
                videoImage:
                  "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FartificialIntelligenceRobotics%2FArtificialIntelligenceMakingAVerySimpleAIPartTwo%2FArtificialIntelligenceMakingAVerySimpleAIPartTwoThumbnail.webp?alt=media&token=da78eb92-ad5b-4e2d-99e7-6aed73a4abb6",
                videoImageAlt: `AI in Action: Making a Very Simple AI Part Two Thumbnail`,
                videohref: `/ArtificialIntelligenceMakingAVerySimpleAIPartTwo`,
              },
        ]
    },
    ],
}

export default objArtificialIntelligenceRobotics; 
