const objPolicyOfficer = {
  title: 'Policy Officer',
  subtitle: 'London or Remote',
  desc: `ensure the safeguarding of young people enrolled in our programme. `,
  descFull: ``,
  skills: [
    'Governance',
    'Strategic Development and Planning',
    'Legal',
    'Data Protection / GDPR',
    'Health and Safety',
    'Safeguarding',
  ],
  where: {
    location: 'London, SE12DU',
    desc: 'Mainly at home, or remote opportunity.',
  },
  time: {
    workTime: 'Either in or out of office hours',
    timeNeeded: '3-7 hours / week',
  },
  whatWillYouBeDoing: {
    p: `The Policy Officer is required to have a working knowledge of UK 
        workplace policies, especially for educational Charities, with an 
        ability to manage confidential data and knowledge of policy procedures. 
        Your chief responsibilities will be to develop and enforce policies on`,
    ul: [
      'Safeguarding',
      'Data protection & GDPR - online learning platform',
      'Health & safety',
      'RIDDOR - reporting of injuries, diseases and dangerous occurances',
      'Equality and diversity',
      'DSE - display screen equipment',
    ],
  },
  whatAreWeLookingFor: {
    p: `The volunteer will have the opportunity to explore and identify UK 
        policies for charities. They will play an active role in writing 
        policies for the Charity, collaborate with other members of the Charity 
        to continually develop new policies and manage existing policies. The 
        volunteer will be given the opportunity to take ownership of personal 
        and professional growth, develop excellent communication and 
        organisational skills, enhance their time-management, independent and 
        team player skills. They will also be able to help and connect with the 
        local community and build prosperous relationships.`,
    ul: [],
  },
  whatDifferenceWillYouMake: {
    p: `The volunteer will have the opportunity to develop new policies for the 
        Charity and ensure that the existing policies are up to date, thereby 
        help the Charity to carry out its mission. `,
    ul: [],
  },
  whatsInItForTheVolunteer: {
    p: ``,
    ul: [],
  },
};

export default objPolicyOfficer;
