import React from "react";
import valuesDiagram from "../../../images/functionality/main/Ourmotto/values_diagram.webp";
import "./mainStyling/Ourmotto.css";
import {
  beliefsatOTC,
  charityAims,
  ourMottoDesc,
  timeline,
} from "./media/Ourmotto";

function nicole_trial() {
  function enlarge() {
    var modal = document.getElementById("myModal");
    var img = document.getElementById("myImg1");
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");
    img.onclick = function () {
      modal.style.display = "block";
      modalImg.src = this.src;
      captionText.innerHTML = this.alt;
    };
    var span = document.getElementsByClassName("close")[0];
    span.onclick = function () {
      modal.style.display = "none";
    };
  }
  return (
    /*You will not be able to see this text.*/
    <React.Fragment>
      {/*<MetaTags>
        <title>Our Motto at Off the Curriculum</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta
          name="description"
          content="Off the Curriculum's motto. Off the Curriculum will conduct its work for the public benefit, to encourage young people to forge new paths by…"
        />
        <meta
          name="keywords"
          content="Education, Charity, News, Educational News, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Ourmotto"
        />
        <meta property="og:title" content="Our Motto at Off the Curriculum" />
        <meta
          property="og:description"
          content="Off the Curriculum's motto. Off the Curriculum will conduct its work for the public benefit, to encourage young people to forge new paths by…"
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/about_us/values_diagram.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/about_us/values_diagram.webp"
        />
        <meta property="og:image:alt" content="The Off the Curriculum logo." />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Ourmotto"
        />
        <meta
          property="twitter:title"
          content="Our Motto at Off the Curriculum"
        />
        <meta
          property="twitter:description"
          content="Off the Curriculum's motto. Off the Curriculum will conduct its work for the public benefit, to encourage young people to forge new paths by…"
        />
        <meta
          property="twitter:description"
          content="Find the latest news about Off the Curriculum, our team, our journey, fundraising, governance, management, communications and social initiatives."
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/about_us/values_diagram.webp"
        />
        <meta
          property="twitter:image:alt"
          content="The Off the Curriculum values, mission, and vision."
        />
  </MetaTags>*/}

      <div className="centre-frame">
        <h1 className="our-motto">{ourMottoDesc.title}</h1>
        <p className="our-motto">{ourMottoDesc.desc}</p>
        <h2 className="our-motto">{charityAims.title}</h2>
        <div className="c">
          <ul className="about_values">
            <li className="about_values">{charityAims.listOne}</li>
            <li className="about_values">{charityAims.listTwo}</li>
            <li className="about_values">{charityAims.listThree}</li>
          </ul>
        </div>

        <div className="row">
          <div className="columnourMotto">
            <img
              src={valuesDiagram}
              alt="Figure caption: Off the Curriculum's Values, Mission, and Vision."
              className="motto"
              onClick={() => {
                enlarge();
              }}
              id="myImg1"
            ></img>
            <div
              id="myModal"
              className="modal"
              onClick={() => {
                enlarge();
              }}
            >
              <span
                className="close"
                onClick={() => {
                  enlarge();
                }}
              >
                &times;
              </span>
              <img
                className="modal-content"
                id="img01"
                onClick={() => {
                  enlarge();
                }}
                alt="Off the Curriculum's Values, Mission, and Vision."
              />
              <div
                id="caption"
                onClick={() => {
                  enlarge();
                }}
              ></div>
            </div>
            <p className="figure-caption">
              <b>Figure 1.</b> Off the Curriculum's Values, Mission, and Vision.
            </p>
          </div>
          <div className="columnourMotto">
            <h3 className="our-motto">{beliefsatOTC.valueTitle}</h3>
            <p className="beliefsOTC">{beliefsatOTC.valuesDesc}</p>
            <h3 className="our-motto">{beliefsatOTC.missionTitle}</h3>
            <p className="beliefsOTC">{beliefsatOTC.missionDesc}</p>
            <h3 className="our-motto">{beliefsatOTC.visionTitle}</h3>
            <p className="beliefsOTC">{beliefsatOTC.visionDesc}</p>
          </div>
        </div>
        <br />
        <div className="newDiv">
          <h1 className="our-motto">{timeline.title}</h1>
          <p className="our-motto">{timeline.desc}</p>
        </div>
        <section id="conference-timeline">
          <div className="timeline-start">Founded in 2020</div>
          <div className="conference-center-line"></div>
          <div className="conference-timeline-content">
            <div className="timeline-article">
              <div className="content-left-container">
                <div className="content-left">
                  <p className="vertical_timeline">
                    <b>The Beginning.</b>
                    Dr. Joseph M Levermore founded the charity and began
                    recruiting officers to the Off the Curriculum team.
                    <span className="article-number">01</span>
                  </p>
                </div>
                <span className="timeline-author"></span>
              </div>
              <div className="content-right-container">
                <div className="content-right">
                  <p>
                    <b>The New Team and First Stage of Development.</b>
                    Off the Curriculum welcomed Raymond Chabvuka, Nicole
                    Levermore, Nicole Curtis, and Edward Freeman to the team and
                    we instantly began to finalise our method and practice of
                    teaching, explore the organisations' goals and aims, develop
                    our online capabilities, and recruit additional officers.
                    <span className="article-number">02</span>
                  </p>
                </div>
                <span className="timeline-author"></span>
              </div>
              <div className="meta-date">
                <span className="month">FEB</span>
                <span className="year">2020</span>
              </div>
            </div>

            <div className="timeline-article">
              <div className="content-left-container">
                <div className="content-left">
                  <p>
                    <b>A New Member.</b>
                    Off the Curriculum welcomed Sinead Murphy to lead our
                    Finance team.<span className="article-number">03</span>
                  </p>
                </div>
                <span className="timeline-author"></span>
              </div>
              <div className="meta-date">
                <span className="month">JUN</span>
                <span className="year">2020</span>
              </div>
            </div>

            <div className="timeline-article">
              <div className="content-right-container">
                <div className="content-right">
                  <p>
                    <b>The Review and New Year Objectives.</b>
                    Off the Curriculum hosted its first annual progress review
                    and critically evaluated the strategic direction of the
                    charity.
                    <span className="article-number">04</span>
                  </p>
                </div>
                <span className="timeline-author"></span>
              </div>
              <div className="meta-date">
                <span className="month">DEC</span>
                <span className="year">2020</span>
              </div>
            </div>

            <div className="timeline-article">
              <div className="content-left-container">
                <div className="content-left">
                  <p>
                    <b>
                      Off the Curriculum successfully elects its Trustee board.
                    </b>
                    Off the Curriculum welcomes Dr. Ian Mudway, Dr. Rashada
                    Harry, Dr. Sweta Raghavan, Dr. Stephanie Wright, and
                    Mushfiqur Chowdhury to our Trustee board.
                    <span className="article-number">05</span>
                  </p>
                </div>
                <span className="timeline-author"></span>
              </div>
              <div className="meta-date">
                <span className="month">May</span>
                <span className="year">2021</span>
              </div>
            </div>

            <div className="timeline-article">
              <div className="content-right-container">
                <div className="content-right">
                  <p>
                    <b>Off the Curriculum engages with the Community.</b>
                    Off the Curriculum has begun to establish links with schools
                    in the Lambeth and Southwark area, UK-based universities,
                    potential guest speakers, and local businesses.
                    <span className="article-number">06</span>
                  </p>
                </div>
                <span className="timeline-author"></span>
              </div>
              <div className="meta-date">
                <span className="month">JUL</span>
                <span className="year">2021</span>
              </div>
            </div>

            <div className="timeline-article">
              <div className="content-left-container">
                <div className="content-left">
                  <p>
                    <b>Off the Curriculum prepares for launch.</b>
                    Off the Curriculum will provide its program to the first
                    cohort of participants from September 2021 to June 2023.
                    <span className="article-number">07</span>
                  </p>
                </div>
                <span className="timeline-author"></span>
              </div>
              <div className="meta-date">
                <span className="month">Sep</span>
                <span className="year">2021</span>
              </div>
            </div>

            <div className="timeline-article">
              <div className="content-right-container">
                <div className="content-right">
                  <p>
                    <b>
                      Future: Program Reflection - September 2023 to December
                      2023.
                    </b>
                    After the first cohort OTC will enter a period of reflection
                    and assessment, focusing on the analysis of outcomes based
                    on the participants academic and personal development
                    (September 2023 to March 2024).
                    <span className="article-number">08</span>
                  </p>
                </div>
                <span className="timeline-author"></span>
              </div>
              <div className="meta-date">
                <span className="month">SEP</span>
                <span className="year">2023</span>
              </div>
            </div>

            <div className="timeline-article">
              <div className="content-left-container">
                <div className="content-left">
                  <p>
                    <b>Expansion - December 2023 to September 2024.</b>
                    Off the Curriculum will recruit its second cohort of
                    participants and further establish itself across the United
                    Kingdom - with plans to expand internationally. The second
                    cohort will attempt to involve a larger number of
                    participants and acquire a larger funding scheme to aid
                    program improvements (June 2024 - September 2026). The
                    overall aim is to turn Off the Curriculum into an
                    internationally recognised charity, offering a renowned
                    educational program to all.
                    <span className="article-number">09</span>
                  </p>
                </div>
                <span className="timeline-author"></span>
              </div>
              <div className="meta-date">
                <span className="month">SEP</span>
                <span className="year">2024</span>
              </div>
            </div>
          </div>

          <div className="timeline-end">To be continued..</div>
        </section>
      </div>
    </React.Fragment>
  );
}
export default nicole_trial;
