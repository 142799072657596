

import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OfftheCurriculumLogo from '../../../images/functionality/logos/OTC_full_logo.webp'; 
import React, { useState } from 'react';
import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LoginContext } from "../../../App";
import { useContext } from "react";
import ProfilePageButton from './profilePageButton/ProfilePageButton';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { GetData } from '../../../firebase/firebaseProfileGet';

export function NavDropdownExample() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    window.addEventListener(
      "resize", 
      () => {
        const width = window.innerWidth;
        if (width < 991) {
          setIsMobile(true);
          setIsDesktop(false);
        } else {
          setIsDesktop(true);
          setIsMobile(false); 
        }
      }, 
      []
    );
  }, [])

  const buttonMobile = {
    color: '#000000', 
    marginRight:'0.5rem', 
    marginLeft:'0.5rem', 
    background: '#ffbaab',
    fontSize:'1.20rem', 
    paddingLeft: '2.5%',
    paddingTop: '0rem', 
    marginTop:'0rem',
    borderColor: '#ffbaab', 
  };

  const buttonLaptop = {
    color: '#000000', 
    paddingLeft:'10%', 
    background: '#ffbaab',
    fontSize:'1.20rem', 
    borderColor: '#ffbaab', 
  };
  const dropMobile = {
    background: '#ffbaab', 
    border: '#ffbaab',  
    fontSize:'1.25rem', 
    marginBottom:'0.32rem', 
    borderColor: '#ffbaab', 
    color: 'black'
  };
  const dropLaptop = {
    background: '#ffbaab', 
    border: '#ffbaab',  
    fontSize:'1.25rem', 
    marginBottom:'0.32rem', 
    marginTop:'0.32rem',
    color: 'black'
  };
  const handleSelect = (eventKey) => alert(`selected ${eventKey}`);
  function mailProtector() {
    const p = "mailto:enxquixres@offthecurrxiculumx.cox.uxk";
    const mailProtected = p.replace('mailto:enxquixres@offthecurrxiculumx.cox.uxk', 'mailto:enquiries@offthecurriculum.co.uk');
    document.getElementById("navEmail").href = mailProtected;
  }; 

  const isUserSignedIn = useContext(LoginContext);
  var location = (!isUserSignedIn) ? '/' : '/Profile';

  return (
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className='align-middle colorNav' sticky="top" variant='dark' bg="navBar">
          <Container fluid>
          <Navbar.Brand href={location}
           style={
          isMobile ? buttonMobile
          : isDesktop ? buttonLaptop 
          : buttonMobile}
          >
            <ProfilePageButton /> 
          </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} aria-labelledby='toggle menu button'/>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-label= 'toggle menu button'
              aria-labelledby= 'toggle menu button'
              placement="end"
              style={{color: '#000'}}
              scroll={true}
            >
              <Offcanvas.Header closeButton style={{}}>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <div>
                        <img src={OfftheCurriculumLogo} alt="Off the Curriculum Logo" className='logoMobile' />
                    </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{width:'91%'}}>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Dropdown className="d-inline mx-2"autoClose={true}>
                    <Dropdown.Toggle id="dropdown-autoclose-true" style={
                      isMobile ? dropMobile
                      : isDesktop ? dropLaptop 
                      : dropMobile}
                      > 
                        About Us
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="/Ourmotto">Our Motto</Dropdown.Item>
                        <Dropdown.Item href="/Theteam">The Team</Dropdown.Item>
                        <Dropdown.Item href="/Ourtrustees">Our Trustees</Dropdown.Item>
                        <Dropdown.Item href="/News">News</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Navbar.Brand 
                style={{
                  color: '#000000', 
                  fontWeight:'bold', 
                  paddingTop:'0.4rem',
                  }}>
                  <Button style={
                                isMobile ? buttonMobile
                                : isDesktop ? buttonLaptop 
                                : buttonMobile
                              }
                              type='button' id="Profile" onClick={(e)=> GetData(e)}
                              href="/Educo" 
                              >
                      EDUCO
                  </Button>
                </Navbar.Brand>
                <Dropdown className="d-inline mx-2" autoClose={true} style={{}}>
                    <Dropdown.Toggle id="dropdown-autoclose-inside" style={
                                    isMobile ? dropMobile
                                    : isDesktop ? dropLaptop 
                                    : dropMobile}
                                    >
                    Education
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    <Dropdown.Item href="/Education">Education</Dropdown.Item>
                    <Dropdown.Item href="/programme">The OTC Programme</Dropdown.Item>
                    <Dropdown.Item href="/Wellbeingprogramme">Wellbeing Programme</Dropdown.Item>
                    <Dropdown.Item href="/EPQ">Extended Project Qualification</Dropdown.Item>
                    <Dropdown.Item href="/Accreditation">Accreditation Programme</Dropdown.Item>
                    <Dropdown.Item href="/Enrol">Enrol</Dropdown.Item>
                    <Dropdown.Item href="/Programme_testimonial">Programme Testimonials</Dropdown.Item>
                    <Dropdown.Item href="/Partneredsixthforms">Partnered Schools</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="d-inline mx-2" autoClose={true}>
                    <Dropdown.Toggle id="dropdown-autoclose-outside" style={
                                                                    isMobile ? dropMobile
                                                                    : isDesktop ? dropLaptop 
                                                                    : dropMobile}
                                                                    >
                    Volunteering
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    <Dropdown.Item href="/Volunteering">Volunteer with OTC</Dropdown.Item>
                    <Dropdown.Item href="/Training">Available Training</Dropdown.Item>
                    <Dropdown.Item href="/Careers">Careers</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="d-inline mx-2" autoClose={true} >
                    <Dropdown.Toggle id="dropdown-autoclose-false" style={
                                                                          isMobile ? dropMobile
                                                                          : isDesktop ? dropLaptop 
                                                                          : dropMobile}
                                                                          >
                    Outreach
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item href="#">South London Gallery</Dropdown.Item>
                    <Dropdown.Item href="#">University Connections</Dropdown.Item>
                    <Dropdown.Item href="/Collaborations">Collaborations</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Navbar.Brand href="/Donate" style={{color: '#000000'}}>
                  <Button style={
                  isMobile ? buttonMobile
                  : isDesktop ? buttonLaptop 
                  : buttonMobile}
                                  >
                    Donate
                  </Button>
                </Navbar.Brand>
                </Nav>
                
                <div className='popFooter'>
                  <div className='rowFooter'>
                    <div className='columnNav'>
                      <b>Charity Number: </b>1196701
                    </div>
                    <div className='columnNav'>
                    <span className="nav-information"><a className="nav_email" onClick={mailProtector} id="navEmail" href='mailProtector:;'><b>Email Us!</b></a></span>
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
        
      );
    }