import { useContext } from "react";
import { LoginContext } from "../../../../App";
import { signOut, getAuth } from "firebase/auth";
import { auth } from '../../../../firebase/firebase'
import Navbar from 'react-bootstrap/Navbar';
import { GetData } from "../../../../firebase/firebaseProfileGet";

const ProfilePageButton = () => {

  const isUserSignedIn = useContext(LoginContext);
  //console.log(isUserSignedIn)

  return (
    <button className="" type='button' id="Profile" onClick={(e)=> GetData(e)} >
      {!isUserSignedIn ? "Home" : "Profile"}
    </button>
  );
};



export default ProfilePageButton;