import React from "react";

function NotFound() {
  return (
    /*You will not be able to see this text.*/
    <React.Fragment>
      <div>
        <h2 className="error-404">Page Not Found!</h2>
        <p className="error-404-p">
          The page may be temporarily unavailable or may no longer exist. Did
          you try searching? Enter a keyword(s) in the search field above. Or,
          here are a few things you can try:
        </p>
        <p className="error-404-p">
          Return to the{" "}
          <a className="error-404-p" href="https://www.offthecurriculum.co.uk/">
            Home Page
          </a>
        </p>
        <p className="error-404-p">Try one of the links below:</p>
      </div>

      <div className="row">
        <h2>About Off the Curriculum:</h2>
        <div className="index-error-404-column-01">
          <div className="row-information-top-navbar">
            <a className="error-404-p" href="/Ourmotto">
              <p>Our Motto</p>
            </a>
            <a className="error-404-p" href="/Theteam">
              <p>The Team</p>
            </a>
          </div>
        </div>
        <div className="index-error-404-column-01">
          <div className="row-information-top-navbar">
            <a className="error-404-p" href="Ourtrustees">
              <p>Our Trustees</p>
            </a>
            <a className="error-404-p" href="/News">
              <p>News</p>
            </a>
          </div>
        </div>
      </div>

      <div className="row">
        <h2>Education at Off the Curriculum:</h2>
        <div className="index-error-404-column-01">
          <div className="row-information-top-navbar">
            <a className="error-404-p" href="/Education">
              <p>Education</p>
            </a>
            <a className="error-404-p" href="/Educo">
              <p>Educo</p>
            </a>
          </div>
        </div>
        <div className="index-error-404-column-01">
          <div className="row-information-top-navbar">
            <a className="error-404-p" href="/Collaborations">
              <p>Collaborations</p>
            </a>
          </div>
        </div>
      </div>

      <div className="row">
        <h2>Contact us:</h2>
        <div className="index-error-404-column-01">
          <div className="row-information-top-navbar">
            <a className="error-404-p" href="/Enrol">
              <p>Enrol</p>
            </a>
            <a className="error-404-p" href="/Volunteering">
              <p>Volunteering</p>
            </a>
          </div>
        </div>
        <div className="index-error-404-column-01">
          <div className="row-information-top-navbar">
            <a className="error-404-p" href="/Careers">
              <p>Become an Officer</p>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default NotFound;
