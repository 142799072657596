import React, { useState, useEffect } from 'react';
import { useFirebaseData } from '../../MainPage/firebaseData';
import { Link } from 'react-router-dom';
import './EPQTrainingVideo.css'
const EPQTrainingVideo = () => {
    const { trainingVideo } = useFirebaseData();
    useEffect(() => {
        // Access the video data
      }, [trainingVideo]);
      
        return (
          <div>
            {trainingVideo ? (
              <div className='EPQContainer'>
                <img
                  src={trainingVideo["videoImage"]}
                  className="lastViewed"
                  alt={trainingVideo["videoImageAlt"]}
                />
                <br />
                <span id="views">Views: {trainingVideo["count"]} | </span>
                <span>Likes: {trainingVideo["likes"]}</span>
                <p className="watchHistory">{trainingVideo["videoDesc"]}</p>
                <a href={"/" + trainingVideo["videohref"]} className="watchHistory">
                  <button className="fileDownloader">Watch here...</button>
                </a>
              </div>
            ) : 
            <>
            <p>Explore EDUCO content today! <Link to='/educo'>Click here!</Link></p>
            </>
            } 
          </div>
        );
};


export default EPQTrainingVideo;
