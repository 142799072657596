import { useContext } from "react";
import { LoginContext } from "../../../App";
import { signOut, getAuth } from "firebase/auth";

const SignInBtn = () => {
  const auth = getAuth()
  
  const isUserSignedIn = useContext(LoginContext);
  const handleLogin = () => {
    if (!isUserSignedIn) {
      ///This opens the Educo page instead of the google authenticator 
      window.location.href = "/Educo";
      // Call email at signIn
    } else {
      signOut(auth).then(() => {

      })
    }
  };

  return (
    <button className="topnavbtn" id="signedIn" onClick={handleLogin}>
      {!isUserSignedIn ? "Log In" : "Log Out"}
    </button>
  );
};

export default SignInBtn;