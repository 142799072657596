import React from "react";
import { useState, useEffect } from "react";
import calendar from "../../../../images/functionality/main/social-media/calendar.webp";
import clock from "../../../../images/functionality/main/social-media/clock.webp";
import person from "../../../../images/functionality/main/social-media/person.webp";
import nicoleCurtis from "../../../../images/functionality/main/meet-the-team/nicoleCurtis.webp";
//{ MetaTags } from "react-meta-tags";


// CSS
import "./articleText/stylingSheet/Articletemplate.css";
// Markdown
import Markdown from "markdown-to-jsx";
import TrusteeAtOTC from "./articleText/TrusteeAtOTC.md";

function Trusteesatotc() {
  let [content, setContent] = useState({ md: "" });

  useEffect(() => {
    fetch(TrusteeAtOTC)
      .then((response) => response.text())
      .then((md) => {
        setContent({ md });
      });
  }, []);
  return (
    /*include text inside the React.Fragment*/
    <React.Fragment>
      {/*<MetaTags>
        <title>Welcome Our Newly Recruited Trustees</title>
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Having access to truly inspiring and successful individuals is a rarity. Let alone having five...Trustees committed to educational empowerment..."
        />
        <meta
          name="keywords"
          content="Education, Charity, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta name="title" content="Welcome Our Newly Recruited Trustees" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Trusteesatotc"
        />
        <meta
          property="og:title"
          content="Welcome Our Newly Recruited Trustees"
        />
        <meta
          property="og:description"
          content="Having access to truly inspiring and successful individuals is a rarity. Let alone having five...Trustees committed to educational empowerment..."
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/news/trustee_board/trustee_board.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/news/trustee_board/trustee_board.webp"
        />
        <meta
          property="og:image:alt"
          content="Trustees at Off the Curriculum figure."
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Trusteesatotc"
        />
        <meta
          property="twitter:title"
          content="Welcome Our Newly Recruited Trustees"
        />
        <meta
          property="twitter:description"
          content="Having access to truly inspiring and successful individuals is a rarity. Let alone having five...Trustees committed to educational empowerment..."
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/news/trustee_board/trustee_board.webp"
        />
        <meta
          property="twitter:image:alt"
          content="The Off the Curriculum logo."
        />
  </MetaTags>*/}

      <div className="centre-frame">
        <div className="first-third">
          <img
            className="new-article-image"
            src={nicoleCurtis}
            alt="nicole"
          ></img>
          <ul className="news_article_summary">
            <div>
              <img className="reelIcons" src={calendar} alt="calendar"></img>
              <span>12th June 2021</span>
            </div>
            <br />
            <div>
              <img className="reelIcons" src={clock} alt="clock"></img>
              <span>10 minutes read</span>
            </div>
            <br />
            <div>
              <img className="reelIcons" src={person} alt="person"></img>
              <span>Nicole Curtis</span>
            </div>
          </ul>
          <br />

          <h2 className="About_the_author">About the author:</h2>
          <p className="prose_about_the_author">
            I take care of all Marketing and Communications at Off the
            Curriculum. I was born in London but moved to Spain as a child,
            growing up there until university. Currently, I am an MSc student
            reading Psychology and Neuroscience of Mental Health at King’s
            College London. I work in Child and Adolescent Mental Health and
            Addiction Rehabilitation. With a passion for education, I jumped at
            the opportunity to be a part of OTC. Curious by nature, I am
            passionate about mental health and wellbeing, the creative and
            performing arts, food, travel, and culture.
          </p>
        </div>

        <div className="second-third">
          {/* Markdown Start */}
          <p className="articlePtemplate">
            <Markdown>{content && content.md}</Markdown>
          </p>
          {/* Markdown End */}
        </div>

        <div className="third-third">
          <div className="containerOne">
            <h1 className="rec_title">Recommended Articles</h1>
            <div>
              <a className="Article_title" href="/Mindfulness">
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Feduco_recruiting.webp?alt=media&token=5fd65aed-a6e8-4092-a689-f7a977a13c20'
                  className="iconDetails"
                  alt="volunteer placeholder"
                />
              </a>
            </div>
            <div className="containerTwo">
              <a className="Article_title" href="mindfulness.html">
                <h4 className="recommended_articles">We are recruiting!</h4>
              </a>
              <div className="section_text">Category: Education</div>
              <br />
              <div className="section_text">Nicole Curtis</div>
            </div>
          </div>
          <div className="containerOne">
            <div>
              <a className="Article_title" href="/Learning_to_learn_at_home">
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6'
                  className="iconDetails"
                  alt="learning to learn placeholder"
                />
              </a>
            </div>
            <div className="containerTwo">
              <a
                className="Article_title"
                href="learning_to_learn_at_home.html"
              >
                <h4 className="recommended_articles">
                  Learning to Learn from home
                </h4>
              </a>
              <div className="section_text">Category: Education</div>
              <br />
              <div className="section_text">Nicole T Levermore</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Trusteesatotc;
