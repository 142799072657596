const objAnimationOfficer = {
  title: 'Animation Officer',
  subtitle: 'London or Remote',
  desc: `help develop and produce the visual effects, drawing and illustrations, 
        and modelling of the video content published on Educo, OTC's educational 
        platform.`,
  descFull: ``,
  skills: ['Graphic Design', 'Illustration'],
  where: {
    location: 'London, SE12DU',
    desc: 'Mainly at home, or remote opportunity.',
  },
  time: {
    workTime: 'Either in or out of office hours',
    timeNeeded: '3-7 hours / week',
  },
  whatWillYouBeDoing: {
    p: `The main duties of the role:`,
    ul: [
      `To conceptualise ideas for characters, scenes, backgrounds and other animation elements`,
      `To edit and create 2D or 3D or computer-generated images of our educational videos for Educo`,
      `To streamline all future development of footage, making the processes more efficient with time`,
      `To aid in the animation and creation of a variety of videos for Educo`,
      `To support the film crew and other creatives including designers and photographers  in the production of high-quality content`,
      `To ensure all content produced and made public on our social media and website is visually engaging and of top calibre`,
      `To collaborate with our Social Media team, to generate posts for Instagram, LinkedIn, Twitter, YouTube on a regular basis and engage with our audience`,
    ],
  },
  whatAreWeLookingFor: {
    p: ``,
    ul: [
      `Proficiency using Davinci Resolve, with the skills across other animation software`,
      `Excellent multi-channel communication, knowledge of principles of animation including staging, timing, ease in/out, anticipation`,
      `Independent and team skills`,
      `Good time management, with specific attention to detail`,
    ],
  },
  whatDifferenceWillYouMake: {
    p: `Through their role at OTC, the volunteer will build prosperous 
        relationships with the local community and all those involved with the 
        charity, be part of an organisation that aims to build a supportive and 
        actualised community and receive the relevant inductions, guidance, 
        training & support for the particular role. Since the majority of the 
        role is online, the candidate will have full support to carry out their 
        responsibilities in a flexible way and a manner of their choosing.`,
    ul: [],
  },
  whatsInItForTheVolunteer: {
    p: ``,
    ul: [],
  },
  whatsInItForTheVolunteer: {
    p: ``,
    ul: [],
  },
};

export default objAnimationOfficer;
