import React from "react";
//{ MetaTags } from "react-meta-tags";
import "./MainStyling/EducationStyling.css";
import { programme } from "./Media/Programme";

function Programme() {
  function collapsible() {
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        this.classList.toggle("activeCol");
        var content = this.nextElementSibling;
        if (content.style.display === "block") {
          content.style.display = "none";
        } else {
          content.style.display = "block";
        }
      });
    }
  }
  return (
    <React.Fragment>
      {/* 
         <MetaTags>
        <title>The Off the Curriculum Programme</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta
          name="description"
          content="Our wide ranging, challenging, and exciting educational programme for 2021 to 2022 is all about aiding the participants to reach new heights:…"
        />
        <meta
          name="keywords"
          content="Education, Charity, News, Educational News, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta name="title" content="The Off the Curriculum Programme" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Programme"
        />
        <meta property="og:title" content="The Off the Curriculum Programme" />
        <meta
          property="og:description"
          content="Our wide ranging, challenging, and exciting educational programme for 2021 to 2022 is all about aiding the participants to reach new heights:…"
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/education/headers/our_programme.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/education/headers/our_programme.webp"
        />
        <meta
          property="og:image:alt"
          content="Our Off the Curriculum programme."
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Programme"
        />
        <meta
          property="twitter:title"
          content="The Off the Curriculum Programme"
        />
        <meta
          property="twitter:description"
          content="Our wide ranging, challenging, and exciting educational programme for 2021 to 2022 is all about aiding the participants to reach new heights:…"
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/education/headers/our_programme.webp"
        />
        <meta
          property="twitter:image:alt"
          content="Our Off the Curriculum programme."
        />
      </MetaTags>
  */}
      <div className="centre-frame">
        <h1 className="news_article" id="Education-at-OTC">
          {programme.page_title}
        </h1>
        <p className="programme">
          {programme.programme_desc}
          <br />
        </p>
        <h2>{programme.yearTwotitle}</h2>
        <p className="programme">{programme.yearTwoprogramme}</p>

        <button
          type="button"
          className="collapsible"
          onClick={() => {
            collapsible();
          }}
        >
          {programme.summerTermOneTitle}
        </button>
        <div className="content">
          <p className="collapsible_text">
            Date: Thursday 1<sup>st</sup> September to 20<sup>th</sup> October
            2022
          </p>
          <p className="collapsible_text">Programme plan: Term Five</p>
          <p className="collapsible_text">{programme.summerTermOnedesc}</p>
          <table className="seminar_dates">
            <tr>
              <th>Seminar Number</th>
              <th>Date and Time</th>
            </tr>
            <tr>
              <td>Seminar One</td>
              <td>
                Thursday 8<sup>th</sup> September 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Seminar Two</td>
              <td>
                Thursday 15<sup>th</sup> September 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Seminar Three</td>
              <td>
                Thursday 22<sup>nd</sup> September 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Seminar Four</td>
              <td>
                Thursday 29<sup>th</sup> September 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Wellbeing Programme: Seminar One</td>
              <td>
                Thursday 6<sup>th</sup> October 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Seminar Five</td>
              <td>
                Thursday 13<sup>th</sup> October 2022, 16:00 to 17:30
              </td>
            </tr>
          </table>
          <br />
          <table className="seminar_topics">
            <tr>
              <th>Title</th>
              <th>Seminar One</th>
              <th>Seminar Two</th>
              <th>Seminar Three</th>
              <th>Seminar Four</th>
              <th>Seminar Five</th>
            </tr>
            <tr>
              <td>{programme.summerT1ChoiceOneTitle}</td>
              <td>{programme.summerT1C1S1}</td>
              <td>{programme.summerT1C1S2}</td>
              <td>{programme.summerT1C1S3}</td>
              <td>{programme.summerT1C1S4}</td>
              <td>{programme.summerT1C1S5}</td>
            </tr>
            <tr>
              <td>{programme.summerT1ChoiceTwoTitle}</td>
              <td>{programme.summerT1C2S1}</td>
              <td>{programme.summerT1C2S2}</td>
              <td>{programme.summerT1C2S3}</td>
              <td>{programme.summerT1C2S4}</td>
              <td>{programme.summerT1C2S5}</td>
            </tr>
            <tr>
              <td>{programme.summerT1ChoiceThreeTitle}</td>
              <td>{programme.summerT1C3S1}</td>
              <td>{programme.summerT1C3S2}</td>
              <td>{programme.summerT1C3S3}</td>
              <td>{programme.summerT1C3S4}</td>
              <td>{programme.summerT1C3S5}</td>
            </tr>
          </table>
        </div>
        <button
          type="button"
          className="collapsible"
          onClick={() => {
            collapsible();
          }}
        >
          {programme.summerTermTwoTitle}
        </button>
        <div className="content">
          <p className="collapsible_text">
            Date: Saturday February 4<sup>th</sup> 2023
          </p>
          <p className="collapsible_text">Programme plan: Term Six</p>
          <p className="collapsible_text">{programme.summerTermTwodesc}</p>
          <table className="seminar_dates">
            <tr>
              <th>Seminar Number</th>
              <th>Date and Time</th>
            </tr>
            <tr>
              <td>Seminar One</td>
              <td>
                Saturday February 4<sup>th</sup> 2023, 10:00 to 11:00
              </td>
            </tr>
            <tr>
              <td>Seminar Two</td>
              <td>
                Saturday February 4<sup>th</sup> 2023, 11:00 to 12:00
              </td>
            </tr>
            <tr>
              <td>Seminar Three</td>
              <td>
                Saturday February 4<sup>th</sup> 2023, 13:00 to 14:00
              </td>
            </tr>
            <tr>
              <td>Seminar Four</td>
              <td>
                Saturday February 4<sup>th</sup> 2023, 14:00 to 15:00
              </td>
            </tr>
            <tr>
              <td>Seminar Five</td>
              <td>
                Saturday February 4<sup>th</sup> 2023, 15:00 to 16:00
              </td>
            </tr>
          </table>
          <p className="collapsible_text">
            Here is a further breakdown of the seminar topics by their
            individual seminars:
          </p>
          <table className="seminar_topics">
            <tr>
              <th>Title</th>
              <th>Seminar One</th>
              <th>Seminar Two</th>
              <th>Seminar Three</th>
              <th>Seminar Four</th>
              <th>Seminar Five</th>
            </tr>
            <tr>
              <td>{programme.summerT2ChoiceOneTitle}</td>
              <td>{programme.summerT2C1S1}</td>
              <td>{programme.summerT2C1S2}</td>
              <td>{programme.summerT2C1S3}</td>
              <td>{programme.summerT2C1S4}</td>
              <td>{programme.summerT2C1S5}</td>
            </tr>
            <tr>
              <td>{programme.summerT2ChoiceTwoTitle}</td>
              <td>{programme.summerT2C2S1}</td>
              <td>{programme.summerT2C2S2}</td>
              <td>{programme.summerT2C2S3}</td>
              <td>{programme.summerT2C2S4}</td>
              <td>{programme.summerT2C2S5}</td>
            </tr>
            <tr>
              <td>{programme.summerT2ChoiceThreeTitle}</td>
              <td>{programme.summerT2C3S1}</td>
              <td>{programme.summerT2C3S2}</td>
              <td>{programme.summerT2C3S3}</td>
              <td>{programme.summerT2C3S4}</td>
              <td>{programme.summerT2C3S5}</td>
            </tr>
          </table>
        </div>
        <button
          type="button"
          className="collapsible"
          onClick={() => {
            collapsible();
          }}
        >
          {programme.autumnTermThreeTitle}
        </button>
        <div className="content">
          <p className="collapsible_text">
            Date: Saturday March 25<sup>th</sup>, 2022 | 10:00 - 16:00
          </p>
          <p className="collapsible_text">Programme plan: Term Seven</p>
          <p className="collapsible_text">{programme.autumnTermThreedesc}</p>
          <table className="seminar_dates">
            <tr>
              <th>Seminar Number</th>
              <th>Date and Time</th>
            </tr>
            <tr>
              <td>Seminar One</td>
              <td>
                Saturday March 25<sup>th</sup>, 2022 | 10:00 - 11:00
              </td>
            </tr>
            <tr>
              <td>Seminar Two</td>
              <td>
                Saturday March 25<sup>th</sup>, 2022 | 11:00 - 12:00
              </td>
            </tr>
            <tr>
              <td>Seminar Three</td>
              <td>
               Saturday March 25<sup>th</sup>, 2022 | 13:00 - 14:00
              </td>
            </tr>
            <tr>
              <td>Seminar Four</td>
              <td>
                Saturday March 25<sup>th</sup>, 2022 | 14:00 - 15:00
              </td>
            </tr>
            <tr>
              <td>Seminar Five</td>
              <td>
                Saturday March 25<sup>th</sup>, 2022 | 15:00 - 16:00
              </td>
            </tr>
          </table>
          <p className="collapsible_text">
            Here is a further breakdown of the seminar topics by their
            individual seminars:
          </p>
          <table className="seminar_topics">
            <tr>
              <th>Topic</th>
              <th>Seminar One</th>
              <th>Seminar Two</th>
              <th>Seminar Three</th>
              <th>Seminar Four</th>
              <th>Seminar Five</th>
            </tr>
            <tr>
              <td>{programme.autumnT3ChoiceOneTitle}</td>
              <td>{programme.autumnT3C1S1}</td>
              <td>{programme.autumnT3C1S2}</td>
              <td>{programme.autumnT3C1S3}</td>
              <td>{programme.autumnT3C1S4}</td>
              <td>{programme.autumnT3C1S5}</td>
            </tr>
            <tr>
              <td>{programme.autumnT3ChoiceTwoTitle}</td>
              <td>{programme.autumnT3C2S1}</td>
              <td>{programme.autumnT3C2S2}</td>
              <td>{programme.autumnT3C2S3}</td>
              <td>{programme.autumnT3C2S4}</td>
              <td>{programme.autumnT3C2S5}</td>
            </tr>
            <tr>
              <td>{programme.autumnT3ChoiceThreeTitle}</td>
              <td>{programme.autumnT3C3S1}</td>
              <td>{programme.autumnT3C3S2}</td>
              <td>{programme.autumnT3C3S3}</td>
              <td>{programme.autumnT3C3S4}</td>
              <td>{programme.autumnT3C3S5}</td>
            </tr>
          </table>
        </div>

        <h2>{programme.yearOnetitle}</h2>
        <p className="programme">{programme.yearOneprogramme}</p>

        <button
          type="button"
          className="collapsible"
          onClick={() => {
            collapsible();
          }}
        >
          Autumn Term One: Induction Seminar Series
        </button>
        <div className="content">
          <p className="collapsible_text">
            Date: Thursday 4<sup>th</sup> November to Friday 10<sup>th</sup>{" "}
            December 2021
          </p>
          <p className="collapsible_text">Induction Seminar Series</p>
          <p className="collapsible_text">
            During the Induction term, participants will engage with taster
            lectures on <b>Immunology</b>, <b>Astronomy</b>,{" "}
            <b>Neuroscience and Psychology</b>, <b>Music</b>,{" "}
            <b>Entrepreneurship</b>, and <b>History of African Diaspora</b>! The
            schedule for our induction term is described below:
          </p>
          <table className="seminar_topics">
            <tr>
              <th>Seminar</th>
              <th>Date and Time</th>
              <th>Seminar Topic</th>
              <th>Seminar Title</th>
              <th>Guest Speaker</th>
              <th>Institution affiliation</th>
            </tr>
            <tr>
              <td>Induction Seminar One</td>
              <td>
                Thursday 4<sup>th</sup> November 2021, 16:30 to 18:00
              </td>
              <td>Immunology</td>
              <td>Immunology 101</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.imperial.ac.uk/people/i.mudway"
                >
                  <b>Dr. Ian Mudway</b>
                </a>
              </td>
              <td>Imperial College London</td>
            </tr>
            <tr>
              <td>Induction Seminar Two</td>
              <td>
                Thursday 11<sup>th</sup> November 2021, 16:30 to 18:00
              </td>
              <td>Astrophysics</td>
              <td>Searching for Life on Mars and The Story of the Axion</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.imperial.ac.uk/be-inspired/schools-outreach/secondary-schools/stem-in-action/beyond-our-planet/sara--profile/"
                >
                  <b>Sara Montaghian</b>
                </a>{" "}
                and{" "}
                <a
                  className="nav_email"
                  href="https://www.kcl.ac.uk/people/liina-jukko"
                >
                  <b>Liina Jukko</b>
                </a>
              </td>
              <td>Imperial College London and King's College London</td>
            </tr>
            <tr>
              <td>Induction Seminar Three</td>
              <td>
                Thursday 18<sup>th</sup> November 2021, 16:30 to 18:00
              </td>
              <td>Neuroscience and Psychology</td>
              <td>Brain plasticity for alternative hand function</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.ucl.ac.uk/icn/people/tamar-makin"
                >
                  <b>Prof. Tamar Makin</b>
                </a>
              </td>
              <td>University College London</td>
            </tr>
            <tr>
              <td>Induction Seminar Four</td>
              <td>
                Thursday 25<sup>th</sup> November 2021, 16:30 to 18:00
              </td>
              <td>Music</td>
              <td>
                Interview with Judithg Weir CBE and The Anatomy and Physiology
                of Hearing
              </td>
              <td>
                <a className="nav_email" href="https://www.judithweir.com/">
                  <b>Prof. Judith Weir CBE</b>
                </a>{" "}
                and{" "}
                <a
                  className="nav_email"
                  href="https://www.researchgate.net/profile/Nicole-Levermore"
                >
                  <b>Nicole Levermore, MSc</b>
                </a>
              </td>
              <td>University of Cambridge and Brighton University</td>
            </tr>
            <tr>
              <td>Induction Seminar Five</td>
              <td>
                Thursday 2<sup>nd</sup> December 2021, 16:30 to 18:00
              </td>
              <td>Entrepreneurship</td>
              <td>Business and Entrepreneurship</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.crunchbase.com/person/gabriel-paunescu"
                >
                  <b>Gabriel Paunesc</b>
                </a>
              </td>
              <td>Standford University</td>
            </tr>
            <tr>
              <td>Induction Seminar Six</td>
              <td>
                Friday 10<sup>th</sup> December 2021, 16:30 to 18:00
              </td>
              <td>History</td>
              <td>The History of South Africa from pre-1600's to 1890's</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.soas.ac.uk/staff/staff30873.php"
                >
                  <b>Dr. Wayne Dooling</b>
                </a>
              </td>
              <td>University College London (SOAS)</td>
            </tr>
            <p className="collapsible_text">
              The programme for the induction seminar series can be seen below:
            </p>
          </table>
        </div>
        <button
          type="button"
          className="collapsible"
          onClick={() => {
            collapsible();
          }}
        >
          Winter Term Two: Psychology and Neuroscience
        </button>
        <div className="content">
          <p className="collapsible_text">
            Date: Tuesday 4<sup>th</sup> January to Friday 11<sup>th</sup>{" "}
            February 2022
          </p>
          <p className="collapsible_text">Psychology and Neuroscience</p>
          <p className="collapsible_text">
            During the Psychology and Neuroscience seminar series, participants
            explored how to unlock the secrets of the brain, and how neuronal
            connections change and reorganise to ultimately impact human
            behaviour. The schedule for our Psychology and Neuroscience seminar
            series is described below:
          </p>
          <table className="seminar_topics">
            <tr>
              <th>Presentation Title</th>
              <th>Seminar Theme</th>
              <th>Guest Speaker</th>
              <th>Institution affiliation</th>
              <th>Date and Time</th>
            </tr>
            <tr>
              <td>Learning and Memory</td>
              <td>Unlocking the secrets of the human brain</td>
              <td>
                <a
                  className="nav_email"
                  href="https://iris.ucl.ac.uk/iris/browse/profile?upi=RGORD00"
                >
                  <b>Dr. Rebecca Gordon</b>
                </a>
              </td>
              <td>University College London</td>
              <td>
                Thursday 6<sup>th</sup> January 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Resilience and mental health</td>
              <td>Mental Health and Wellbeing</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.ucl.ac.uk/the-mind-of-the-universe/dr-francisco-diego"
                >
                  <b>Ms. Denisse Levermore</b>
                </a>
              </td>
              <td>Worcester University</td>
              <td>
                Thursday 13<sup>th</sup> January 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Neurological conditions</td>
              <td>Neurological Anatomy and Neurological Conditions</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.linkedin.com/in/nicole-curtis-1585b3a3/?originalSubdomain=uk"
                >
                  <b>Ms. Nicole Curtis</b>
                </a>{" "}
                and{" "}
                <a
                  className="nav_email"
                  href="https://www.researchgate.net/profile/Nicole-Levermore"
                >
                  <b>Ms. Nicole Levermore</b>
                </a>
              </td>
              <td>King's College London and Brighton University</td>
              <td>
                Thursday 20<sup>th</sup> January 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Neurodiversity</td>
              <td>Human behaviour</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.rcpch.ac.uk/about-us/our-team/dr-max-davie-course-lead-education-professional-development"
                >
                  <b>Dr. Max Davie</b>
                </a>
              </td>
              <td>University of Cambridge</td>
              <td>
                Thursday 27<sup>th</sup> January 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Space Policy</td>
              <td>Wild Card Event</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.linkedin.com/in/jules-varma-9a584282/?trk=public_profile_similar-profiles_profile-result-card_result-card_full-click&originalSubdomain=at"
                >
                  <b>Jules Varma</b>
                </a>
              </td>
              <td>University College London</td>
              <td>
                Thursday 3<sup>rd</sup> February 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>From Addiction to Dopamine</td>
              <td>A research symposium</td>
              <td>
                <a
                  className="nav_email"
                  href="https://jencooklab.com/people-2/"
                >
                  <b>Dr. Bianca Schuster</b>
                </a>{" "}
                and{" "}
                <a
                  className="nav_email"
                  href="https://psch.uic.edu/diversity/the-black-scholar-series/mrs-angeline-dukes/"
                >
                  <b>Angeline Dukes</b>
                </a>
              </td>
              <td>
                University of Birmingham and University of California Irvine
              </td>
              <td>
                Thursday 10<sup>th</sup> February 2021, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Self-determined Assessment Session</td>
              <td>Assessment Session</td>
              <td>
                <a className="nav_email" href="/Theteam">
                  <b>Officers at Off the Curriculum</b>
                </a>
              </td>
              <td>Off the Curriculum</td>
              <td>
                Thursday 24<sup>th</sup> February 2021, 16:00 to 17:30
              </td>
            </tr>
            <p className="collapsible_text">
              The programme for the induction seminar series can be seen below:
            </p>
          </table>
        </div>
        <button
          type="button"
          className="collapsible"
          onClick={() => {
            collapsible();
          }}
        >
          Spring Term Three: Climate Change and Sustainability
        </button>
        <div className="content">
          <p className="collapsible_text">
            Date: Thursday 3<sup>rd</sup> March to 7<sup>th</sup> April 2022
          </p>
          <p className="collapsible_text">Climate Change and Sustainability</p>
          <p className="collapsible_text">
            Off the Curriculum have designed this seminar series to provide our
            participants with a holistic understanding of this globally
            impacting issue. In this seminar series, we will explore the
            uniqueness of our earth in the context of the universe and galaxy,
            discuss the 'pseudo-scientific' arguments for Climate Change being a
            non-anthropogenic phenomenon, discover the importance of
            appreciating nature for our health, uncover the mathematics of
            Climate modelling, critically evaluate Climate policies, and
            collaboratively create solutions for combating Climate Change. The
            schedule for our Climate Change and Sustainability term is described
            below:
          </p>
          <table className="seminar_topics">
            <tr>
              <th>Seminar Title</th>
              <th>Seminar Topic</th>
              <th>Guest Speaker</th>
              <th>Institution affiliation</th>
              <th>Date and Time</th>
            </tr>
            <tr>
              <td>One Paradise, One Humanity</td>
              <td>Implications of Climate Change to Humanity</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.imperial.ac.uk/people/i.mudway"
                >
                  <b>Dr. Francisco Diego</b>
                </a>
              </td>
              <td>Imperial College London</td>
              <td>
                Thursday 3<sup>rd</sup> March 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Debate the Deniers Workshop</td>
              <td>Politics of Climate Science</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.ucl.ac.uk/the-mind-of-the-universe/dr-francisco-diego"
                >
                  <b>Led by Off the Curriculum's seminar volunteers</b>
                </a>
              </td>
              <td>University College London</td>
              <td>
                Thursday 10<sup>th</sup> March 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Uncertainity Quantification in Climate Systems</td>
              <td>The science of Climate Change</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.kcl.ac.uk/people/fiona-turner"
                >
                  <b>Dr. Fiona Turner</b>
                </a>
              </td>
              <td>King's College London</td>
              <td>
                Thursday 17<sup>th</sup> March 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Shinrin Yoku</td>
              <td>Wellbeing Programme</td>
              <td>
                <a className="nav_email" href="https://www.judithweir.com/">
                  <b>Nicole Curtis</b>
                </a>{" "}
                and{" "}
                <a
                  className="nav_email"
                  href="https://nurturewitheducation.my.canva.site/"
                >
                  <b>Sinead Reed-Forester</b>
                </a>
              </td>
              <td>King's College London and University Arts London</td>
              <td>
                Thursday 24<sup>th</sup> March 2021, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Old Operating Theatre</td>
              <td>Wild Card Seminar</td>
              <td>
                <a
                  className="nav_email"
                  href="https://oldoperatingtheatre.com/"
                >
                  <b>OOT Volunteers</b>
                </a>
              </td>
              <td>Old Operating Theatre</td>
              <td>
                Thursday 31<sup>st</sup> March 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Climate Policy</td>
              <td>The policy of Climate Change</td>
              <td>
                <a
                  className="nav_email"
                  href="https://www.lse.ac.uk/granthaminstitute/profile/elena-almeida/"
                >
                  <b>Elena Almeida</b>
                </a>
              </td>
              <td>London School of Economics</td>
              <td>
                Friday 7<sup>th</sup> April 2022, 16:00 to 17:30
              </td>
            </tr>
            <tr>
              <td>Self-determined Assessment Session</td>
              <td>Assessment Session</td>
              <td>
                <a className="nav_email" href="/Theteam">
                  <b>Officers at Off the Curriculum</b>
                </a>
              </td>
              <td>Off the Curriculum</td>
              <td>
                Thursday 21<sup>st</sup> February 2021, 16:00 to 17:30
              </td>
            </tr>
            <p className="collapsible_text">
              The programme for the induction seminar series can be seen below:
            </p>
          </table>
        </div>
        <button
          type="button"
          className="collapsible"
          onClick={() => {
            collapsible();
          }}
        >
          Summer Term Four: Politics
        </button>
        <div className="content">
          <p className="collapsible_text">
            Date: Thursday 28<sup>th</sup> April to 16<sup>th</sup> June 2022
          </p>
          <p className="collapsible_text">Politics</p>
          <p className="collapsible_text">
            In this seminar series participants have nominated to study Politics
            as their topic of interest. The schedule for our Politics term is
            described below:
          </p>
          <table className="seminar_topics">
            <tr>
              <th>Seminar Title</th>
              <th>Date and Time</th>
              <th>Seminar Topic</th>
              <th>Guest Speaker</th>
              <th>Institution affiliation</th>
            </tr>
            <tr>
              <td>Comparative Government</td>
              <td>
                Thursday 28<sup>th</sup> April 2022, 16:00 to 17:30
              </td>
              <td>Geopolitics</td>
              <td>TBC</td>
              <td>TBC</td>
            </tr>
            <tr>
              <td>Political Theory</td>
              <td>
                Thursday 5<sup>th</sup> May 2022, 16:00 to 17:30
              </td>
              <td>Politcs</td>
              <td>TBC</td>
              <td>TBC</td>
            </tr>
            <tr>
              <td>Capitalism and Marxism: A power struggle</td>
              <td>
                Thursday 12<sup>th</sup> May 2022, 16:00 to 17:30
              </td>
              <td>Political discourse</td>
              <td>TBC</td>
              <td>TBC</td>
            </tr>
            <tr>
              <td>Labour economics and inequality</td>
              <td>
                Thursday 19<sup>th</sup> May 2022, 16:00 to 17:30
              </td>
              <td>Politics</td>
              <td>TBC</td>
              <td>TBC</td>
            </tr>
            <tr>
              <td>Wild Card Seminar</td>
              <td>
                Thursday 26<sup>th</sup> May 2022, 16:00 to 17:30
              </td>
              <td>TBC</td>
              <td>TBC</td>
              <td>TBC</td>
            </tr>
            <tr>
              <td>Political thought: Plato to Rousseau</td>
              <td>
                Thursday 9<sup>th</sup> June 2022, 16:00 to 17:30
              </td>
              <td>Politics in action</td>
              <td>TBC</td>
              <td>TBC</td>
            </tr>
            <tr>
              <td>Self Determined Assessment Session</td>
              <td>
                Thursday 16<sup>th</sup> June 2022, 16:00 to 17:30
              </td>
              <td>Politics</td>
              <td>TBC</td>
              <td>TBC</td>
            </tr>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Programme;
