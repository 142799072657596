import create from "zustand";
import { persist } from "zustand/middleware";

export const DEFAULT_PROFILE = {
  id: "",
  name: "",
  email: "",
  pic: "",
  color: "",
  seminar: "",
  seminarId: "",
  seminarTime: Number,
};

type ProfileType = {
  id: String;
  name: String;
  email: String;
  pic: String;
  color: String;
  seminar: String;
  seminarId: String;
  seminarTime: Number;
};

interface ProfileStoreState {
  profile: ProfileType[];

  addProfile: (profile: ProfileType) => void;

  name: NameType | null;
  setName: (next: NameType | null) => void;

  email: EmailType | null;
  setEmail: (next: EmailType | null) => void;

  pic: PicType | null;
  setPic: (next: PicType | null) => void;

  color: ColorType | null;
  setColor: (next: ColorType | null) => void;

  seminar: SeminarType | null;
  setSeminar: (next: SeminarType | null) => void;

  seminarId: SeminarIdType | null;
  setSeminarId: (next: SeminarIdType | null) => void;

  seminarTime: SeminarTimeType | null;
  setSeminarTime: (next: SeminarTimeType | null) => void;

  deleteProfile: (id: Number) => void;
}

export const useProfileStore = create<ProfileStoreState>()(
  persist(
    (set) => ({
      profile: [] as ProfileType[],

      addProfile: (profile) =>
        set((state) => {
          return { profile: [...state.profile, profile] };
        }, true),
      name: null,
      setName: (next) => set(() => ({ name: next }), true),
      email: null,
      setEmail: (next) => set(() => ({ email: next }), true),
      pic: null,
      setPic: (next) => set(() => ({ pic: next }), true),
      color: null,
      setColor: (next) => set(() => ({ color: next }), true),
      seminar: null,
      setSeminar: (next) => set(() => ({ seminar: next }), true),
      seminarId: null,
      setSeminarId: (next) => set(() => ({ seminarId: next }), true),
      seminarTime: null,
      setSeminarTime: (next) => set(() => ({ seminarTime: next }), true),

      setProfile: (profile)=>
      set((state)=> {
    return  {    profile:[ ...state.profile, profile ]  };
      }, true),

      deleteProfile: () => set({}, true),
    }),

    {
      name: "profile-storage",
      serialize: (state) => JSON.stringify(state),
    }
  )

);

export type NameType = { name: string };
export type EmailType = { email: string };
export type PicType = { pic: string };
export type SeminarType = { seminar: string };
export type SeminarTimeType = { seminarTime: Number };
export type ColorType = { color: String };
export type SeminarIdType = { seminarId: String };