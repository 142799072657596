import React from "react";
import Trusteedeclarationforfitandproperpersonspolicy from "../Policy-folder-pdf/Trustee_Declaration_for_Fit_and_Proper_Persons.pdf";

function Trusteedeclarationforfitandproperpersons() {
  return (
    /*include text inside the React.Fragment*/
    <React.Fragment>
      <div>
        <h1>
          Trustee declaration for fit and proper persons policy at Off the
          Curriculum
        </h1>
        <iframe
        title='Our Trustee declaration for fit and proper persons policy'
          src={Trusteedeclarationforfitandproperpersonspolicy}
          className="policy"
        ></iframe>
      </div>
    </React.Fragment>
  );
}
export default Trusteedeclarationforfitandproperpersons;
