import React from "react";
import Safeguardingyoungpeoplepolicy from "../Policy-folder-pdf/Safeguarding_young_people.pdf";

function Safeguardingyoungpeople() {
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <div>
        <h1>Safeguarding young people policy at Off the Curriculum</h1>
        <iframe
          title="Our Safeguarding young people policy"
          src={Safeguardingyoungpeoplepolicy}
          className="policy"
        ></iframe>
      </div>
    </React.Fragment>
  );
}
export default Safeguardingyoungpeople;
