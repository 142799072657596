import { VideoGet } from "../../../firebase/firebaseGetStorage";

export function VideoSort(item, i) {
 //console.log(item, i);

  if (i > 0) {
let list = document.createElement('li')
list.id= i
list.innerHTML= item
list.className= 'videoFolders'

let page = document.getElementById('listVideo')
page.appendChild(list)
  }
  return (
    <>
      <div>
        <button type="button" onClick={VideoGet}>
          Click here
        </button>

        <h1>videos</h1>
        <div id="listVideo"></div>
      
      </div>
    </>
  );
}
