import React, { Fragment } from 'react';
import { summerFundraising, EDUCO, endofYear } from './Media/Index';
import { db } from '../../../firebase/firebase';
import { ref, onValue, query } from 'firebase/database';
import { useState, useEffect, useRef, createContext } from 'react';
import './Carousel.css';
import EllipsisText from '../../../pages/educo/Components/ellipsisText/EllipsisText';

const Carousel = () => {
  const [videoDataList, setVideoDataList] = useState([]);
  const videoRef = query(ref(db, 'video/name/'));

  useEffect(() => {
    onValue(videoRef, (snapshot) => {
      const videoData = snapshot.val();
      const arr = Object.entries(videoData);
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
      const start = 1;
      const end = 4;
      const slicedArray = arr.slice(start, end);
      const slicedObject = Object.fromEntries(slicedArray);
      //console.log(slicedArray)
      setVideoDataList(slicedObject);
    });
  }, []);
  const ImageComponent = ({ imageSrc, videoSrc }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <img
          src={imageSrc}
          alt="My Image"
          style={{
            opacity: isHovered ? 0 : 1,
            transition: 'opacity 500ms',
          }}
        />

        {isHovered && (
          <video
            autoPlay
            muted
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: isHovered ? 1 : 0,
              transition: 'opacity 10ms ease-in-out',
            }}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        )}
      </div>
    );
  };
  return (
    <div>
      <h1 className="eventsHeader">EDUCO</h1>
      <h4 className="eventsHeader">Explore our educational content today!</h4>
      <div className="EDUCOLandingpageCarouselContainer">
        {Object.entries(videoDataList).map(([video, data]) => (
          <div className="soloSeminar" key={video}>
            <a href={'/' + data.videohref}>
              <ImageComponent
                imageSrc={data.videoImage}
                videoSrc={data.videoSnippet}
              />
              <span>{data.author} | </span>
              <span id="views">Views: {data.count} | </span>
              <span>Likes: {data.likes}</span>
              <EllipsisText text={data.videoDesc} tag="p" maxLength={25} />
              <button className="educoLongFormContentButton btn-hover">
                Watch this Seminar...
              </button>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;