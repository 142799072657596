import GetStudent from "../../firebase/getStudent";
import MentorLogin from "./mentorLogin";
import { useEffect, useState, useLayoutEffect } from "react";
import { UpdateView } from "../../firebase/getStudent";
import MenteeChat from "../educo/profile/ProfileComponents/EPQChat/menteeChat";
let mentees = document.querySelectorAll(".Students");

export default function MentorProfile() {
  const [mentee, setMentee] = useState(null);

  let mentor = document.getElementById("mentorProfile");

  useEffect(() => {}, [mentor]);
  function Update(e) {
    let section = document.getElementById("Students");
    section.style.visibility = "visible";

    let stuButtons = document.querySelectorAll(".mentee");

    stuButtons.forEach((stu) => {
      stu.addEventListener("click", () => {
        let chat = document.getElementById("chat");
        setMentee(stu.id);
        chat.style.visibility = "visible";
      });
    });
  }

  return (
    <>
      <div className="mentorMain" id="mentorForm">
        <MentorLogin />
      </div>
      <div className="mentorProfile" id="mentorProfile">
        <div>
          <h4>Get your student details</h4>
          <form onSubmit={(e) => GetStudent(e)}>
            <label htmlFor="studentId">Enter your student's email</label>
            <input type="email" />
            <input type="submit" />
          </form>
        </div>
        <div id="StudentDetailsArea">
          <div>
            <UpdateView />
          </div>
        </div>

        <section>
          <button type="button" onClick={(e) => Update(e)}>
            See your mentees
          </button>
        </section>
        <div id="chat">
          <MenteeChat props={mentee} />
        </div>
      </div>
    </>
  );
}