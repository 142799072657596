import React, { useEffect, useRef, useState } from "react";
import "./LandingPageVideo.css";

function LandingPageVideo() {
  const videoRef = useRef(null);
  const [isVideoReady, setVideoReady] = useState(false);

  useEffect(() => {
    function setVideoSize() {
      const video = document.getElementById("videoPlayer");
      const aspectRatio = 9 / 16; // Set your video's aspect ratio here (e.g., 16:9)

      // Get the window's inner width and height
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Calculate the height based on the aspect ratio and the window's width
      const videoHeight = windowWidth * aspectRatio;

      // Set the width and height of the video element
      video.style.width = (windowWidth-10) + "px";
      video.style.height = (videoHeight-6) + "px";

      // Update the text position based on the window width
      const overlayText = document.getElementById("overlayText");
      const textTopOffset = windowWidth * 0.02; // Adjust the percentage as needed
      overlayText.style.top = `calc(50% - ${textTopOffset}px)`;
      const textLeftOffset = windowWidth * 0.02; // Adjust the percentage as needed
      overlayText.style.left = `calc(25% + ${textLeftOffset}px)`;
    }

    // Call the function on initial load and on window resize
    setVideoSize();
    window.addEventListener("resize", setVideoSize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", setVideoSize);
    };
  }, []);

  useEffect(() => {
    function handleVideoPlay() {
      const overlayText = document.getElementById("overlayText");
      overlayText.style.display = "none"; // Hide the overlay text when video starts playing
    }

    videoRef.current.addEventListener("play", handleVideoPlay);

    return () => {

    };
  }, []);


  const customPoster = "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FLandingPoster.png?alt=media&token=b91ec6a4-a390-4663-b9d5-b4ea0a12ed6d"; // Replace with the URL of your custom poster image



  return (
    <div className="LandingPageContainer">
      <video
        ref={videoRef}
        
        id="videoPlayer"
        className="LandingPageVideo"
        controls
        controlsList="nodownload"
        poster={customPoster}
        alt=""
        onContextMenu={(e) => e.preventDefault()}
      >
        <source
          src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FofftheCurriculumProjectSeminarSeries.mp4?alt=media&token=3d5f4299-f6e8-4494-81a4-49d06b0d5926"
          type="video/mp4"
        />
      </video>
      <div id="overlayText" className="OverlayText">
        Welcome to Off the Curriculum!
      </div>
    </div>
  );
}

export default LandingPageVideo;
