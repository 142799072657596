import React from "react";
import events from "./Registration-Index.js";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Title, Description } from "react-head-meta";
import './stylingSheet/RegistrationTemplatePage.css';

function RegistrationTemplate(props) {
  const name = props.name;
  let event = {};
  let eventTime = {};
  let eventWhere = {};
  if (events[name]) event = events[name];
  if (event.where) eventWhere = event.where;
  if (event.time) eventTime = event.time;

  return (
    <React.Fragment>
      {/**new way of doing meta */}
      <Title title={event.title} />
      <Description description="up coming events at Off The Curriculum" />
      {/*<div className= "float-parent-element">
        <div className= "heading">
          <div className="event-header">
            <p>{event.title}</p>
          </div>
        </div>
    </div>*/
      /*Event summary*/}
      <div className="centre-frame">
        <h2 className="EventPageHeader">
            {event.title}
        </h2>
        <center>
            <h4 className="EventPageHeader">
                {event.subtitle}
            </h4>
        </center>
        <p className="registrationTemplatePage">
            {event.shortDescription}
        </p>
        <center>
        <div className="form_center form_full_height">
          <iframe
            src={event.formLink}
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            className="assessment-frame"
            title='Register sign up form'
          >
            Loading…
          </iframe>
        </div>          
        </center>
      </div>
    </React.Fragment>
  );
}

export default RegistrationTemplate;
