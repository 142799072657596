import { useContext } from "react";
import { LoginContext } from "../../../App";
import { ProfilePage } from "./ProfileComponents/profilePage";

/** need to stop this running before checkvoted finished without infinite loop */

/** Loading the page into a splide*/
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { CreateAProfile } from "./createAProfile";

import { GetData, UseProfile } from "../../../firebase/firebaseProfileGet";

export  function VotingStatus(x) {
  let voteShow = document.getElementById("votingShow");
  let voteDone = document.getElementById("votingDone");
  let result = document.getElementById('result');
  
  {/*
    Voter status is made visible, when the user clicks the status button!
  */}
  voteShow.style.visibility = "visible";
  voteDone.style.visibility = "visible";

 //console.log(x); 
    if (x === false) {
     //console.log("false", x);
   return   voteDone.style.visibility = "hidden";
    } else if (x === true) {
     //console.log("true", x);
   return   voteShow.style.visibility = "hidden";
    }
 
}
export function Profile() {
  const UserSignedIn = useContext(LoginContext);
  
  return (
    <>
    <title>Profile</title>
       {UserSignedIn ? <GetData /> : <CreateAProfile />}
    </>
  );
}