import React from "react";
////{ MetaTags } from "react-meta-tags";
import "./mainStyling/Trustee.css";
import {Title, Description} from 'react-head-meta'
import {
  ianMudwayDesc,
  trusteesIntro,
  rashadaHarryDesc,
  swetaRaghavanDesc,
  stephanieWrightDesc,
  mushfiqurChowdhuryDesc,
} from "./media/Trustees";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  ianMudway,
  mushfiqurChowdhury,
  rashadaHarry,
  shaunBeham,
  swetaRaghavan,
  stephanieWright,
} from "../../../images/functionality/main/Trustees/index.js";
import Twitter from "../../../images/functionality/main/social-media/twitter.webp";
import Email from "../../../images/functionality/main/social-media/message.webp";
import Linkedin from "../../../images/functionality/main/social-media/linkedin.webp";

function Ourtrustees() {
  const width = "100%";
  const alt = "Trustee at Off the Curriculum.";
  function mailProtector() {
    const p = "mailto:truxsxxtxxxexexs@offthecurrxiculumx.cox.uxk";
    const trusteeEmail = p.replace(
      "mailto:truxsxxtxxxexexs@offthecurrxiculumx.cox.uxk",
      "mailto:trustees@offthecurriculum.co.uk"
    );
    document.getElementById("trusteeEmail").href = trusteeEmail;
    const trusteeEmailONe = p.replace(
      "mailto:truxsxxtxxxexexs@offthecurrxiculumx.cox.uxk",
      "mailto:trustees@offthecurriculum.co.uk"
    );
    document.getElementById("trusteeEmailONe").href = trusteeEmailONe;
    const trusteeEmailTwo = p.replace(
      "mailto:truxsxxtxxxexexs@offthecurrxiculumx.cox.uxk",
      "mailto:trustees@offthecurriculum.co.uk"
    );
    document.getElementById("trusteeEmailONe").href = trusteeEmailTwo;
    const trusteeEmailThree = p.replace(
      "mailto:truxsxxtxxxexexs@offthecurrxiculumx.cox.uxk",
      "mailto:trustees@offthecurriculum.co.uk"
    );
    document.getElementById("trusteeEmailThree").href = trusteeEmailThree;
    const trusteeEmailFour = p.replace(
      "mailto:truxsxxtxxxexexs@offthecurrxiculumx.cox.uxk",
      "mailto:trustees@offthecurriculum.co.uk"
    );
    document.getElementById("trusteeEmailFour").href = trusteeEmailFour;
    const trusteeEmailFive = p.replace(
      "mailto:truxsxxtxxxexexs@offthecurrxiculumx.cox.uxk",
      "mailto:trustees@offthecurriculum.co.uk"
    );
    document.getElementById("trusteeEmailFive").href = trusteeEmailFive;
  }
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <Title title='Our Trustees' />
      <Description description='Here you will find a list of our trustees and a short piece of background information on them'  />
      {/*} <MetaTags>
        <title>Trustees at Off the Curriculum</title>
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta name="robots" content="index, follow" />
        <meta name="title" content="Trustees at Off the Curriculum" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Off the Curriculum 'appointed the strongest candidates' for the trustee positions, says Chief Executive... aiding in the development of a bold new strategy that will facilitate our success."
        />
        <meta
          name="keywords"
          content="Education, Charity, News, Educational News, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Ourtrustees"
        />
        <meta property="og:title" content="Trustees at Off the Curriculum" />
        <meta
          property="og:description"
          content="Off the Curriculum 'appointed the strongest candidates' for the trustee positions, says Chief Executive... aiding in the development of a bold new strategy that will facilitate our success."
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/logos/OTC_logo_small_colour_with_background.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/logos/OTC_logo_small_colour_with_background.webp"
        />
        <meta property="og:image:alt" content="The Off the Curriculum logo." />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Ourtrustees"
        />
        <meta
          property="twitter:title"
          content="Trustees at Off the Curriculum"
        />
        <meta
          property="twitter:description"
          content="Off the Curriculum 'appointed the strongest candidates' for the trustee positions, says Chief Executive... aiding in the development of a bold new strategy that will facilitate our success."
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/logos/OTC_logo_small_colour_with_background.webp"
        />
        <meta
          property="twitter:image:alt"
          content="The Off the Curriculum logo."
        />
  </MetaTags>*/}
      <div className="centre-frame">
        <h1 className="trustees">{trusteesIntro.Header}</h1>
        <p className="trustees">{trusteesIntro.desc}</p>
        <ul className="grid cs-style-1">
          <h2 className="meettheteam">Meet our Trustees</h2>
          <li>
            <figure>
              <div className="team_image">
                <LazyLoadImage
                  src={ianMudway} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Dr. Ian Mudway</h4>
              <h4 className="card-position">Senior Lecturer in Immunology</h4>
              <h4 className="card-qoute">Imperial College London</h4>
              <figcaption>
                <h3>About Ian</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="trusteeEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/company/offthecurriculum-otc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="linkedin" />
                  </a>
                </div>
                <p className="card-text">{ianMudwayDesc.desc}</p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <LazyLoadImage
                  src={rashadaHarry} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Dr. Rashada Harry (LLB, LLM)</h4>
              <h4 className="card-position">Enterprize Technologist</h4>
              <h4 className="card-qoute">Amazon Web Services</h4>
              <figcaption>
                <h3>About Rashada</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="trusteeEmailONe"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/company/offthecurriculum-otc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="linkedin" />
                  </a>
                </div>
                <p className="card-text">{rashadaHarryDesc.desc}</p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <LazyLoadImage
                  src={swetaRaghavan} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Dr. Sweta Raghavan</h4>
              <h4 className="card-position">Research and Development</h4>
              <h4 className="card-qoute">Chairman's Core Team</h4>
              <figcaption>
                <h3>About Sweta</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="trusteeEmailTwo"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/company/offthecurriculum-otc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="linkedin" />
                  </a>
                </div>
                <p className="card-text">{swetaRaghavanDesc.desc}</p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <LazyLoadImage
                  src={stephanieWright} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Dr. Stephanie Wright</h4>
              <h4 className="card-position">
                Lecturer in Environmental Toxicology
              </h4>
              <h4 className="card-qoute">Imperial College London</h4>
              <figcaption>
                <h3>About Stephanie</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="trusteeEmailThree"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/company/offthecurriculum-otc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="linkedin" />
                  </a>
                </div>
                <p className="card-text">{stephanieWrightDesc.desc}</p>
              </figcaption>
            </figure>
          </li>

          <li>
            <figure>
              <div className="team_image">
                <LazyLoadImage
                  src={shaunBeham} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Mr. Shaun Behan</h4>
              <h4 className="card-position">Head of Information Systems</h4>
              <h4 className="card-qoute">Competition Commission</h4>
              <figcaption>
                <h3>About Shaun</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="trusteeEmailFour"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/company/offthecurriculum-otc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I am the founder of Off the Curriculum and a researcher at
                  Imperial College London, with a keen interest in educational
                  empowerment as a vehicle for social mobility. Upon realising
                  the limited opportunities for educational activities provided
                  to young people in Lambeth and Southwark London, we decided to
                  take action. I am passionate about sports, science (physics
                  and chemistry), and philosophy.
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <LazyLoadImage
                  src={mushfiqurChowdhury} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Mr. Mushfiqur Chowdhury</h4>
              <h4 className="card-position">Client Service Manager</h4>
              <h4 className="card-qoute">Global Fund Analytics</h4>
              <figcaption>
                <h3>About Mushfiqur</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="trusteeEmailFive"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/company/offthecurriculum-otc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="linkedin" />
                  </a>
                </div>
                <p className="card-text">{mushfiqurChowdhuryDesc.desc}</p>
              </figcaption>
            </figure>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}
export default Ourtrustees;
