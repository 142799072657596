import React from "react";
import Reimbursementofexpensesandpurchasespolicy from "../Policy-folder-pdf/Reimbursement_of_expenses_and_purchases_ Policies_and_Procedures.pdf";

function Reimbursementofexpensesandpurchases() {
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <div>
        <h1>
          Reimbursement of expenses and purchases policy at Off the Curriculum
        </h1>
        <iframe
        title='Our Reimbursement of expenses and purchases policy'
          src={Reimbursementofexpensesandpurchasespolicy}
          className="policy"
        ></iframe>
      </div>
    </React.Fragment>
  );
}
export default Reimbursementofexpensesandpurchases;
