import { MentorProfileForm } from "../../firebase/firebaseMentorWrite";
import { Link } from "react-router-dom";
import { Pics } from "../educo/profile/profilePictureCreate";


export default function MentorSignUp() {
  return (
    <>
      <title>Mentor sign up</title>
      <div className="mentorProfile">
        <Link to="/mentorLogin">Mentor login</Link>
        <h3>Create your Profile</h3>
        <div className="mentor" id="mentor">
          <Pics />
          <MentorProfileForm />
        </div>
      </div>
    </>
  );
}

export function studentMentored(email) {}