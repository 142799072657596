const objBritishSignLangOfficer = {
  title: 'British Sign Language Interpreting Volunteers',
  subtitle: 'London or Remote',
  desc: `interpret educational videos in English into British Sign Language.`,
  descFull: ``,
  skills: ['Teaching / Education'],
  where: {
    location: 'London, SE12DU',
    desc: 'Mainly at home, or remote opportunity.',
  },
  time: {
    workTime: 'Either in or out of office hours',
    timeNeeded: '3-7 hours / week',
  },
  whatWillYouBeDoing: {
    p: `The main duties of the role:`,
    ul: [
      `To interpret educational videos into BSL, on subjects ranging from 
        Science, Business, Arts to History or Commerce `,
      `To film themselves interpreting the content into BSL`,
      `To support the Video Production Team to finalise the videos including 
        the interpreting in BSL`,
    ],
  },
  whatAreWeLookingFor: {
    p: ``,
    ul: [
      `Fluent in English`,
      `Fluent in British Sign Language`,
      `Experience in interpreting in BSL`,
    ],
  },
  whatDifferenceWillYouMake: {
    p: `‘Educo’ is OTC’s own educational platform, created to improve the 
        participants’ experience and aid them in exploring various topics over 
        the course of 2 years. The subjects range from Science, Business, Arts 
        to History or Commerce. The BSL interpreters will therefore be asked to 
        translate a wide variety of video content into BSL to help make 
        educational content available to everyone, including deaf children and 
        people with hearing loss, and have a positive impact on younger people’s 
        educational experience.`,
    ul: [],
  },
  whatsInItForTheVolunteer: {
    p: ``,
    ul: [],
  },
};

export default objBritishSignLangOfficer;
