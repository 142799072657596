import React from "react";
import { useState, useEffect } from "react";
import calendar from "../../../../images/functionality/main/social-media/calendar.webp";
import clock from "../../../../images/functionality/main/social-media/clock.webp";
import person from "../../../../images/functionality/main/social-media/person.webp";
import nicoleLevermore from "../../../../images/functionality/main/meet-the-team/nicoleLevermore.webp";
//{ MetaTags } from "react-meta-tags";

// CSS
import "./articleText/stylingSheet/Articletemplate.css";
// Markdown
import Markdown from "markdown-to-jsx";
import mdEducoVolunteers from "./articleText/LearningtoLearn.md";

function Learningtolearn() {
  let [content, setContent] = useState({ md: "" });

  useEffect(() => {
    fetch(mdEducoVolunteers)
      .then((response) => response.text())
      .then((md) => {
        setContent({ md });
      });
  }, []);
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      {/*<MetaTags>
        <title>Learning to Learn from Home with Nicole Levermore</title>
        <meta
          name="title"
          content="Learning to Learn from Home with Nicole Levermore"
        />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Our ‘learning style’ can influence “success” throughout higher education, the question is what learning style are you."
        />
        <meta
          name="keywords"
          content="Education, Charity, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Learningtolearn"
        />
        <meta
          property="og:title"
          content="Learning to Learn from Home with Nicole Levermore"
        />
        <meta
          property="og:description"
          content="Our ‘learning style’ can influence “success” throughout higher education, the question is what learning style are you."
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/news/learning_to_learn/learn_to_learn.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/news/learning_to_learn/learn_to_learn.webp"
        />
        <meta
          property="og:image:alt"
          content="Learning to learn at home figure."
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Learningtolearn"
        />
        <meta
          property="twitter:title"
          content="Learning to Learn from Home with Nicole Levermore"
        />
        <meta
          property="twitter:description"
          content="Our ‘learning style’ can influence “success” throughout higher education, the question is what learning style are you."
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/news/learning_to_learn/learn_to_learn.webp"
        />
        <meta
          property="twitter:image:alt"
          content="Learning to learn at home figure."
        />
  </MetaTags>*/}
      <div className="centre-frame">
        <div className="first-third">
          <img
            src={nicoleLevermore}
            className="new-article-image"
            alt=" Nicole Levermore"
          ></img>
          <ul className="news_article_summary">
            <div>
              <img
                className="reelIcons"
                src={calendar}
                alt="Calendar photo, demonstrating date of publication"
              ></img>
              <span>1st February 2021</span>
            </div>
            <br />
            <div>
              <img
                className="reelIcons"
                src={clock}
                alt="Article time to read"
              />
              <span>10 minutes read</span>
            </div>
            <br />
            <div>
              <img className="reelIcons" src={person}></img>
              <span>Nicole T Levermore, MSc, BSc</span>
            </div>
          </ul>
          <div className="news-icon">
            <div className="footer_center">
              <a
                href="https://twitter.com/otclambeth/"
                target="_blank"
                rel="noreferrer"
              ></a>
              <a
                href="https://www.linkedin.com/in/nicole-l-aa60301b5"
                target="_blank"
                rel="noreferrer"
              ></a>
              <a
                href="https://www.instagram.com/offthecurriculumlondon/"
                target="_blank"
                rel="noreferrer"
              ></a>
            </div>
          </div>
          <br />

          <h2 className="About_the_author">About the author:</h2>
          <p className="prose_about_the_author">
            I am the Education Officer at Off the Curriculum. I am a Ph.D.
            Auditory Neuroscience candidate at the University of Brighton.
            Before this, I read an MSc in Translational Neuroscience at the
            University of Sheffield and a BSc in Neuroscience with Psychology at
            Keele University. I am originally from Birmingham. A love of mine is
            classical music, and I play the cello in my spare time. Fun fact:
            During my time at Keele University, I helped run the ‘Wholock’
            Society- a society for fans of the BBC TV shows Doctor Who and
            Sherlock.
          </p>
        </div>

        <div className="second-third">
          {/* Markdown Start */}
          <p className="articlePtemplate">
            <Markdown>{content && content.md}</Markdown>
          </p>

          {/* Markdown End */}
        </div>
        <div className="third-third">
          <div className="containerOne">
            <h1 className="rec_title">Recommended Articles</h1>
            <div>
              <a className="Article_title" href="/Mindfulness">
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Fmindfulness.webp?alt=media&token=f8585110-94e6-42ea-8a3b-ad1979e436d4'
                  className="iconDetails"
                  alt="Mindfulness article header"
                />
              </a>
            </div>
            <div className="containerTwo">
              <a className="Article_title" href="/Mindfulness">
                <h4 className="recommended_articles">Mindfulness</h4>
              </a>
              <div className="section_text">Category: Education</div>
              <br />
              <div className="section_text">Nicole T Levermore</div>
            </div>
          </div>
          <div className="containerOne">
            <div>
              <a className="Article_title" href="/Educovolunteers">
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Feduco_recruiting.webp?alt=media&token=5fd65aed-a6e8-4092-a689-f7a977a13c20'
                  className="iconDetails"
                  alt="EDUCO recruitment image"
                />
              </a>
            </div>
            <div className="containerTwo">
              <a className="Article_title" href="/Educovolunteers">
                <h4 className="recommended_articles">We are recruiting!</h4>
              </a>
              <div className="section_text">Category: Education</div>
              <br />
              <div className="section_text">Nicole Curtis</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Learningtolearn;
