import React from "react";
import coursePages from "../../courseIndex";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "../../educoMedia/Educo.css";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import ShortsDiv from "../shortsDiv/shortsDiv";
import './EducoCourseTemplate.css'
const CourseTemplate = (props) => {

  const Responsive = responsiveHOC()(LinesEllipsis);
  const name = props.name;
  let course = {};
  if (coursePages[name]) course = coursePages[name];

  return (
    <div>
      {/*} <MetaTags>
        <title>{course.title} at Off the Curriculum</title>
        <meta name="title" content={course.title} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta name="description" content={course.webpagedesc} />
        <meta name="keywords" content={course.webpagekeywords} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={course.webpageurl} />
        <meta property="og:title" content={course.title} />
        <meta property="og:description" content={course.webpagedesc} />
        <meta property="og:image" content={course.webpageimage} />
        <meta property="og:image:secure_url" content={course.webpageimage} />
        <meta property="og:image:alt" content={course.webpageimagealt} />
        <meta property="twitter:card" content={course.webpageimagealt} />
        <meta property="twitter:url" content={course.webpageurl} />
        <meta property="twitter:title" content={course.title} />
        <meta property="twitter:description" content={course.webpagedesc} />
        <meta property="twitter:site" content="@otc_london" />
        <meta property="twitter:image" content={course.webpageimagealt} />
        <meta property="twitter:image:alt" content={course.webpageimagealt} />
  </MetaTags>*/}
      <div className="centre-frame">
          <h1>{course.title}</h1>
          <p className="educoContent">{course.courseDesc}</p>
          <h2>
            Astronomy shorts
          </h2>
          <ShortsDiv />
          {course.courses.map((items) => (
            <div className="card-course">
              <div className="card-horizontal">
                <div className="card-body">
                  <div className="card-educo-title">
                    <img
                      className="course-infographic"
                      src={items.sectionimage}
                      alt="course placeholder"
                    ></img>
                    <span className="card-educo-course-title">
                      <a
                        id={items.sectionQuickLinkhref}
                        href="sectionQuickLink:;"
                      ></a>
                      {items.sectiontitle}
                    </span>
                  </div>
                  <hr className="educo-subject-course" />
                  <p className="educoContent">{items.sectiondesc}</p>
                  <div>
                    <Splide
                      options={{
                        rewind: true,
                        gap: "1rem",
                        autoplay: false,
                        perMove: 1,
                        rows: 2,
                        grid: 2,
                        perPage: 3,
                        breakpoints: {
                          730: {
                            perPage: 1,
                          },
                          990: {
                            perPage: 2,
                          },
                          1024: {
                            perPage: 2,
                          },
                        },
                      }}
                    >
                      {items.educovideo.map((items, i) => (
                        <SplideSlide className="slide">
                          <div className="courseSeminarCard" key={i}>
                            <a className="nav_email" href={items.videohref}>
                              <img
                                src={items.videoImage}
                                alt={items.videoImageAlt}
                                className="eventscarouselImage"
                              />
                              <p className="eventsP">
                                <Responsive
                                  text={items.videoInfo}
                                  maxLine="1"
                                  basedOn="letters"
                                />
                              </p>
                              <p className="eventsLocation">
                                <Responsive
                                  text={items.videoDesc}
                                  maxLine="1"
                                  basedOn="letters"
                                />
                              </p>
                              <button className="courseSeminarButton">
                                Watch this Seminar...
                              </button>
                            </a>
                          </div>
                        </SplideSlide>
                      ))}
                    </Splide>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default CourseTemplate;
