import { useState, useEffect } from "react";
import calendar from "../../../../images/functionality/main/social-media/calendar.webp";
import clock from "../../../../images/functionality/main/social-media/clock.webp";
import person from "../../../../images/functionality/main/social-media/person.webp";
import nicoleLevermore from "../../../../images/functionality/main/meet-the-team/nicoleLevermore.webp";
//{ MetaTags } from "react-meta-tags";


// CSS
import "./articleText/stylingSheet/Articletemplate.css";
// Markdown
import Markdown from "markdown-to-jsx";
import mdMindfulness from "./articleText/Mindfullness.md";

const Mindfulness = () => {
  let [content, setContent] = useState({ md: "" });

  useEffect(() => {
    fetch(mdMindfulness)
      .then((response) => response.text())
      .then((md) => {
        setContent({ md });
      });
  }, []);

  return (
    <>
      {/*<MetaTags>
        <title>Mindfulness with Off the Curriculum</title>
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="An awareness that arises when paying attention to the present moment in a nonjudgmental manner…"
        />
        <meta
          name="keywords"
          content="Education, Charity, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta name="title" content="Mindfulness with Off the Curriculum" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Mindfulness"
        />
        <meta
          property="og:title"
          content="Mindfulness with Off the Curriculum"
        />
        <meta
          property="og:description"
          content="An awareness that arises when paying attention to the present moment in a nonjudgmental manner…"
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/news/mindfulness/mindfulness.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/news/mindfulness/mindfulness.webp"
        />
        <meta
          property="og:image:alt"
          content="Learning to learn at home figure."
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Mindfulness"
        />
        <meta
          property="twitter:title"
          content="Mindfulness with Off the Curriculum"
        />
        <meta
          property="twitter:description"
          content="An awareness that arises when paying attention to the present moment in a nonjudgmental manner…"
        />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/news/mindfulness/mindfulness.webp"
        />
        <meta
          property="twitter:image:alt"
          content="Learning to learn at home figure."
        />
  </MetaTags>*/}
      <div className="centre-frame">
        <div className="first-third">
          <img src={nicoleLevermore} className="new-article-image" alt='news article placeholder'></img>
          <ul className="news_article_summary">
            <div>
              <img className="reelIcons" src={calendar} alt='date created'></img>
              <span>1st February 2021</span>
            </div>
            <br />
            <div>
              <img className="reelIcons" src={clock} alt='time takes to read'></img>
              <span>10 minutes read</span>
            </div>
            <br />
            <div>
              <img className="reelIcons" src={person} alt='author placeholder'></img>
              <span>Nicole T Levermore, MSc, BSc</span>
            </div>
          </ul>
          <div className="news-icon">
            <div className="footer_center">
              <a
                href="https://twitter.com/otclambeth/"
                target="_blank"
                rel="noreferrer"
              ></a>
              <a
                href="https://www.linkedin.com/in/nicole-l-aa60301b5"
                target="_blank"
                rel="noreferrer"
              ></a>
              <a
                href="https://www.instagram.com/offthecurriculumlondon/"
                target="_blank"
                rel="noreferrer"
              ></a>
            </div>
          </div>
          <br />

          <h2 className="About_the_author">About the author:</h2>
          <p className="prose_about_the_author">
            I am the Education Officer at Off the Curriculum. I am a Ph.D.
            Auditory Neuroscience candidate at the University of Brighton.
            Before this, I studied MSc Translational Neuroscience at the
            University of Sheffield and BSc Neuroscience with Psychology at
            Keele University.
          </p>
        </div>
        <div className="second-third">
          {/* Markdown Start */}
          <p className="articlePtemplate">
            <Markdown>{content && content.md}</Markdown>
          </p>
          {/* Markdown End */}
        </div>
        <div className="third-third">
          <div className="containerOne">
            <h1 className="rec_title">Recommended Articles</h1>
            <div>
              <a className="Article_title" href="/Mindfulness">
                <img src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Feduco_recruiting.webp?alt=media&token=5fd65aed-a6e8-4092-a689-f7a977a13c20' className="iconDetails" alt='volunteer placeholder' />
              </a>
            </div>
            <div className="containerTwo">
              <a className="Article_title" href="/Mindfulness">
                <h4 className="recommended_articles">We are recruiting!</h4>
              </a>
              <div className="section_text">Category: Education</div>
              <br />
              <div className="section_text">Nicole Curtis</div>
            </div>
          </div>
          <div className="containerOne">
            <div>
              <a className="Article_title" href="/Learningtolearn">
                <img src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6'
                 className="iconDetails" alt='learning to learn placeholder'/>
              </a>
            </div>
            <div className="containerTwo">
              <a
                className="Article_title"
                href="learning_to_learn_at_home.html"
              >
                <h4 className="recommended_articles">
                  Learning to Learn from home
                </h4>
              </a>
              <div className="section_text">Category: Education</div>
              <br />
              <div className="section_text">Nicole T Levermore</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mindfulness;
