const objOutreachOfficer = {
  title: 'Outreach Officer',
  subtitle: 'London or Remote',
  desc: `help foster relationships with our surrounding community and encourage 
        participation with external organisations.`,
  descFull: ``,
  skills: [
    'Event Management',
    'Public Relations',
    'Fundraising Events',
    'Fundraising Strategy',
    'Strategic Development and Planning',
  ],
  where: {
    location: 'London, SE12DU',
    desc: 'Mainly at home, or remote opportunity.',
  },
  time: {
    workTime: 'Either in or out of office hours',
    timeNeeded: '3-7 hours / week',
  },
  whatWillYouBeDoing: {
    p: `The main duties of the role:`,
    ul: [
      `Nurture new and old relationships with collaborative partners`,
      `Schedule regular engagements (fundraisers, events, public appearances and 
        workshops) with the local communities`,
      `Maintain clear records and calendars of outreach activities (including 
        fundraisers, events, workshops and meetings)`,
      `Prepare budget forecasts for future Outreach and Engagement activities 
        (if applicable)`,
      `Keep clear records of all Outreach funding and spending`,
      `Serve as a first point of contact for external providers`,
      `Continuously attempt to build new relationships and develop new 
        opportunities for the charity and the community it aims to serve`,
    ],
  },
  whatAreWeLookingFor: {
    p: ``,
    ul: [
      `Excellent multi-channel communication and organisational skills`,
      `Ability to manage a multitude of ongoing activities`,
      `Independent and team player skills`,
      `Experience in partnership development`,
      `Experience in organising fundraising events`,
    ],
  },
  whatDifferenceWillYouMake: {
    p: `The Outreach Officer will have direct impact on the growth of the 
        Charity, fostering relationships with our surrounding community and 
        therefore improving the educational experience of the participants 
        enrolled in OTC's programme and the support the Charity can bring them.`,
    ul: [],
  },
  whatsInItForTheVolunteer: {
    p: ``,
    ul: [],
  },
};

export default objOutreachOfficer;
