import React from 'react';
import { MetaTags } from 'react-meta-tags';
import './Mainstyling/Volunteering.css'; 
import { training } from './Media/Training'; 
import {Title , Description} from 'react-head-meta'

function Training () {
    return ( 
            /*include text inside the React.Fragment*/
    <React.Fragment>
     
        <Title title='Volunteer training at Off the Curriculum'/>
        <Description description='Explanation of the training we provide for our volunteers' />
    
        <div className='centre-frame'>
            <h1 className='trainingHeader'>Training</h1> 
                <p className="volunteerTemplate">
                    {training.desc}
                </p>
            <h2 className='trainingHeader'>Induction</h2> 
            
        </div>
    </React.Fragment>
    );
}
export default Training