import { ref, query, onValue, set, push } from "firebase/database";
import { db } from "./firebase";
import { VotingHider } from "../pages/voting/votinghider";
import { TermOne } from "../pages/voting/termOne";

import { VotingStatus } from "../pages/educo/profile/profile";

/** */
export function votingResults(name, term) {
  //+ term +'/' +name

  let num;
  const dbRef = query(ref(db, "voting/term/" + term));

  onValue(
    dbRef,
    (snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();

        for (let [course, counter] of Object.entries(data)) {
          if (course === name) {
            let count = parseInt(counter.count);

            num = count + 1;
          }
        }

        if (num !== undefined) {
          set(ref(db, "voting/term/" + term + "/" + name), {
            token: true,
            count: num,
          });
        }
      }
    },
    {
      onlyOnce: true,
    }
  );
}

export function MarkedVoted(id) {
  const dbRef = query(ref(db, "voted/ids"));

  const newVotedRef = push(dbRef);

  set(newVotedRef, {
    token: true,
    ID: id,
  });
}

/**checking if user voted or not */
export function CheckForVoted() {
  const dbRef = query(ref(db, "voted/ids"));

  onValue(dbRef, (snapshot) => {
    if (snapshot.exists()) {
      let data = snapshot.val();
      
      let dataValue = Object.entries(data)[0][1];
    
      /**not voted option 1 */
      if (dataValue === "string") {
        VotingStatus(false);
      } else {
        /**user has voted */
        let storage = JSON.parse(localStorage.getItem("profile-storage"));
        let userId = storage.state.profile[0].id;
        let  count = 0
       let total = Object.entries(data).length
        for (let [Id, user] of Object.entries(data)) {
          count ++
          let id = user.ID;
          
      
 
          let fullId = userId.replaceAll("-", "").replaceAll("_", "");
          let givenId = id.replaceAll("-", "");
          
          if (fullId.trim() === givenId.trim()) {
          
           return VotingStatus(true);
         
          }
          if( count === total -1){
        
         return VotingStatus(false)
          }
        }
      }   
    }
  });
 
}