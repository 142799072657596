import React, { useState, useEffect, useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Link } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import { ref, onValue, query, orderByChild } from "firebase/database";
import { db } from "./firebase";
import "../components/common/events/Events.css";
import EllipsisText from "../pages/educo/Components/ellipsisText/EllipsisText";

const clock = "../../../../icons/clock-24px.webp";
const pin = "../../../../icons/pin-drop.webp";

export default function GetEvents() {
  const [dataField, setDataField] = useState([]);
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

  let data = useRef();

  useEffect(() => {
    const dbRef = query(ref(db, "Events/"), orderByChild("time"));
    onValue(dbRef, (snapshot) => {
      if (snapshot.val() === null) {
        alert("Profile not found, check if you entered the correct details");
      } else if (snapshot.val() !== null) {
        data.current = snapshot.val();
        setDataField(data.current);
      } else {
        console.log("nope");
      }
    });
  }, []);
 
  
  return (
    <>
      <h1 className="eventsHeader">Upcoming Events</h1>
      <div>
        <p>
          {dataField === null ? (
            <div>'loading'</div>
          ) : (
            <div>
              <div>
                <Splide
                  options={{
                    rewind: true,
                    gap: "1rem",
                    autoplay: true,
                    perMove: 1,
                    rows: 2,
                    grid: 2,
                    perPage: 3,
                    breakpoints: {
                      640: {
                        perPage: 1,
                      },
                      990: {
                        perPage: 2,
                      },
                      1024: {
                        perPage: 3,
                      },
                    },
                  }}
                >
                  {Object.entries(dataField).map(([key, item], i) => (
                    <SplideSlide
                      className="slide"
                      key={i}
                      style={{ height: "50vh" }}
                    >
                      <div className="splideAppereance">
                        <a className="nav_email" href={item.buttonHref}>
                          <img
                            src={item.src}
                            alt={item.alt}
                            className="eventscarouselImage"
                            height="100"
                          />
                            <EllipsisText
                              text={item.header}
                              tag='h2'
                              maxLength={9}
                            />
                          <p className="eventsLocation">
                            <img src={pin} alt="location" className="pin" />
                            {item.location}
                          </p>
                          <p className="eventsP">
                            <img src={clock} alt="time" />
                            {item.time}
                          </p>
                          <button className="EventRegisterButton">
                            <Link
                              to={item.buttonHref}
                              className="EventRegisterButton"
                            >
                              <p className="EventRegisterButton">
                                Register here..
                              </p>
                            </Link>
                          </button>
                        </a>
                      </div>
                    </SplideSlide>
                  ))}
                </Splide>
                <div className="splide__progress">
                  <div className="splide__progress__bar" />
                </div>
              </div>
            </div>
          )}
        </p>
      </div>
    </>
  );
}
