import Courses from "./courseDiv"

const courses = [
    {
        id: 1, 
        courseTitle: 'AI and Robotics', 
        courseDesc: 'During the Artificial Intelligence and Robotic Seminar Series you will explore topics such as their use in everyday life, how to build AI and Robotic systems, the ethics of using such technologies, and attempt to write your own programmes.', 
        courseLink: '/ArtificialIntelligenceRobotics', 
        courseIcon: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FartificialIntelligenceRobotics%2Ficons%2FArtificialIntelligenceRoboticsIcon.webp?alt=media&token=d784c354-9cc8-4f0c-b5eb-08d0a2ec66bb`, 
    }, 
    {
        id: 2, 
        courseTitle: 'Astronomy', 
        courseDesc: `During the astronomy/astrophysics online seminar series, you
        will explore the solar system and learn about the beauty of
        the cosmos.`, 
        courseLink: '/Astronomy', 
        courseIcon: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3`, 
    }, 
    {
        id: 3, 
        courseTitle: 'Business', 
        courseDesc: `Here you uncover content on topics aligned to Business and Enterprise, covering: Basic Accounting and Policy; Marketing; Entrepreneurship; Business process mapping; and Principles of Strategy.`, 
        courseLink: '/Business',
        courseIcon: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fbusiness%2FIcons%2Fbusiness.webp?alt=media&token=8395ab25-f29f-4fa3-ac00-063b0d64c878`, 
    }, 
    {
        id: 4, 
        courseTitle: 'Climate Change', 
        courseDesc: `Climate Change and Sustainability explored topics such as model uncertainty in the context of sea-level rise predictions, central banks' role in halting biodiversity loss, and explored the effects of Climate Change may have on future generations.`, 
        courseLink: `/ClimateChange`, 
        courseIcon: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FclimatechangeSustainability%2FIcons%2Fclimate-change-sustainability-icon.webp?alt=media&token=c8dddce4-143a-420d-ac12-7751deaef89a`, 
    }, 
    , 
    {
        id:5, 
        courseTitle: 'African History', 
        courseDesc: `The History seminar series, you will explore the History, the Politics, the Scientists, and the Music of the African Diaspora.`, 
        courseLink: `/HistoryAfricanDiaspora`,
        courseIcon: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fhistory%2FHistoryAfricanDiaspora%2Ftribal-mask.webp?alt=media&token=4dacd625-1323-496f-8986-e5ace1f5dfe7',
    }, 
    {
        id: 6, 
        courseTitle: 'Immunology', 
        courseDesc: `During this online immunology series, you will explore viral strategies, how 'pollutants' evade our natural defenses, and the misinformation of anti-vax propaganda.`, 
        courseLink:`/Immunology`, 
        courseIcon: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fimmunology%2FImmunology%2Fviral.webp?alt=media&token=7525987b-c578-4d50-971f-81851570f434', 
    }, 
    {
        id: 7, 
        courseTitle: 'Psychology & Neuroscience', 
        courseDesc: `During the neuroscience and psychology seminar series, you will explore how to unlock the secrets of the brain, and how neuronal connections change and reorganise to ultimately impact human behaviour.`, 
        courseLink: `/PsychologyNeuroscience`,
        courseIcon: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2Ficons%2Fbrain.webp?alt=media&token=6108cc9c-c7dc-4f22-81ea-f915a2886749',
    }, 
    {
        id: 8, 
        courseTitle: 'Politics', 
        courseDesc: `Heres to exploring the politics of Comparative Government, Political Theory, Capitalism and Marxism: A Power Struggle, Labour Economics and Inequality, and Political Thought: Plato to Rousseau.`, 
        courseLink: `/Politics`, 
        courseIcon: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fpolitics%2Fpolitics%2FPoliticsIcon.webp?alt=media&token=68e1c0d6-3339-4086-8d49-7b2939ee4a36', 
    }, 

    
]
const CourseDiv = () => {
    return <Courses courses={courses} />;
}
export default CourseDiv; 