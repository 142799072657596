import { fbStorage,db } from "./firebase";
import { ref, getBlob } from "firebase/storage";
import { ref as refs, onValue, query, equalTo, orderByChild } from "firebase/database";

///Error is thrown when this is called


/** this is getting the encrypted data need to get from firebase storage */
export default function GetStudent(e) {
  e.preventDefault();
  ////console.log(e);
  let email = e.target[0].value;
 // AESRunner(email, e, "mentor");
 const dbRef = query(
  refs(db, "person/"),
  orderByChild("Email"),
  equalTo(email)
);
console.log(dbRef)
onValue(
  dbRef,
  (snapshot) => {
    if (snapshot.val() == null) {
      alert("Profile not found check you entered the correct details");
    } else {
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();

        let data = childData;
        //console.log(data,childKey);
        ShowWork(e,childKey)
      })
    }
  }
)
}
/** this is pulling the file down from firebase */
export function ShowWork(e, names) {
  e.preventDefault();
console.log(names)
  const StudentReference = ref(fbStorage, "Student/Name/"+ names )
  //console.log(StudentReference)


  getBlob(StudentReference).then((res) => {
    //console.log(res)
    
    const url = window.URL.createObjectURL(res,{type:'application/pdf'});
               
    let folderShow = document.getElementById("filesTwo")
    const link = document.createElement('a');
    link.setAttribute('download', 'file.pdf');
    link.href = url
    folderShow.append(link);
    link.click();
  })
/*const FileReader = new BlobReader(res)
const Writer = new TextWriter();
    const zipReader = new ZipReader(FileReader);
    if (zipReader !== null) {
      const firstEntry = zipReader.getEntries().then((res) => {
      
       
        // decoders(res);
      });
    }
  });
  UpdateView();*/
}

/*function decoders(results) {
  const worldWriter = new TextWriter();
console.log(results)
  results.map((res) => {
    res.getData(worldWriter).then((res) => {
      let folderShow = document.getElementById("filesTwo");
      folderShow.insertAdjacentHTML("beforebegin", res);
    });
  });
}
*/

export function UpdateView() {
  return (
    <>
      <div id="filesTwo"></div>
      <div id="files"></div>
      <iframe
        title="files"
        src={""}
        alt="student file"
        id="files"
        embed="true"
      ></iframe>
    </>
  );
}

/**console.log(FileViewer.prototype)
 *  let alphabet = [
  "A",
  "a",
  "B",
  "b",
  "C",
  "c",
  "D",
  "d",
  "E",
  "e",
  "F",
  "f",
  "G",
  "g",
  "H",
  "h",
  "I",
  "i",
  "J",
  "j",
  "K",
  "k",
  "L",
  "l",
  "M",
  "m",
  "N",
  "n",
  "O",
  "o",
  "P",
  "p",
  "Q",
  "q",
  "R",
  "r",
  "S",
  "s",
  "T",
  "t",
  "U",
  "u",
  "V",
  "v",
  "W",
  "w",
  "X",
  "x",
  "Y",
  "y",
  "Z",
  "z",
];
      <iframe
        title="files"
        src={""}
        alt="student file"
        id="files"
        embed="true"
      ></iframe>
  let area = document.getElementById('StudentDetailsArea')
  area.insertAdjacentText('beforebegin' , 'Loaded')  
   /**  const reader = new FileReader();
  reader.onload = (evt) => {
    /*console.log(evt.end((new Buffer.from(evt.body.toString())).toString("base64")));*/
/*};
  reader.addEventListener(
    "load",
    () => {
      // this will then display a text file
      let holder = document.getElementById("files");
     ////console.log(reader);
      let filetype = document.createElement("p");
      filetype.textContent = blob.type;
     //console.log(filetype);
      let textField = document.createElement("p");
      let newString = reader.result;
      textField.textContent = newString;
      filetype.append(textField);
      holder.append(filetype);
      // holder.append(textField);
    },
    false
  );
  //reader.readAsText(blob ),
 ////console.log(reader.readAsBinaryString(blob, Uint8Array));
 ////console.log(reader.readAsArrayBuffer(blob));
 //console.log(reader.readAsText(blob));*/

/* function fileReader(blob, buff) {
  //console.log(buff,buff.byteLength)
  
   let size = buff[[Uint8Array]]
  var newBluff = new   Uint8Array(buff,1);
 //console.log( size,newBluff)
  
 
 //console.log(new Uint8Array(buff)[0])
  var reader = new FileReader();
  let arrTurn = new TextDecoder();
  let decoded = arrTurn.decode( newBluff)
  reader.readAsArrayBuffer = (evt) => {
 //console.log(evt.result);
  return reader.result
  }
  reader(buff)
  let buffer = reader.result
 //console.log(buffer)
  let view = new Int8Array(buffer)
 //console.log(view)
 //console.log(view[0])
  //let decoded = decodeURIComponent(dec)
  //let decodeds = decodeURIComponent(newBluff)
  
 // let decoded = arrturn(newBluff)
 ////console.log(decoded)
  for (let i = 0; i < decoded.length; i++) {
    if (alphabet.includes(decoded[i])) {
   //  //console.log(decoded[i],i);
      let holder = document.getElementById("files")
 holder.innerHTML +=  decoded[i]
    }
  }
  ////console.log(  arrTurn.decode(newBluff,Uint8Array))
  ////console.log(  arrTurn.decode(buff,Uint8Array))
  // //console.log(  newBluff.includes("document.xml"));
  ////console.log(arrTurn.decode(buff))
  /*let datafield = JSON.parse(
    String.fromCharCode.apply(null,newBluff))
      
 //console.log(datafield)*/
//  let holder = document.getElementById("files");
// reader.onloadend = function () {
////console.log("text: ",reader.result);
//   holder.textContent = arrTurn.decode(reader.result)

// reader.readAsText(blob);

/*}*/
/**this is the problem trying to view the file bought down */
/*export function FileViewer() {
  let source = FileViewer.source;
  let blob = FileViewer.blob;
  let type = FileViewer.type;
  
  if (blob !== undefined) {
  }
//console.log("viewer", type, source);
  if (!source) {
    return <div>Loading...</div>;
  }
  //console.log(
    "https://docs.google.com/viewer?url=https://" + source 
  );
 let holder=document.getElementById('filesTwo')
  holder.setAttribute('src' ,source)
 holder.setAttribute(('type', type ) + "&embedded=true")
  return (
    <div className="fileViewer">
      <iframe
        src={
          "https://docs.google.com/viewer?url=https://" +
          source 
        }
        title="file"
        width="100%"
        height="600"
        type={type}
      ></iframe>
      <img src={source} alt='student file'></img>
    </div>
  );
}*/

