import React from 'react';

import {
  Carousel,
  Events,
  Parallax,
  Navigator,
  ParallaxTwo,
  Donate,
  LandingPageVideo,
  NewsLandingPage,
  NewsletterBanner,
  JoinBanner
} from './components/common/index.js';
import GetEvents from './firebase/firebaseGetEvents.js';

import { Title, Description } from 'react-head-meta';

function homePage() {
  return (
    /*You will not be able to see this text.*/
    <React.Fragment>
      <Title title="Off the Curriculum" />
      <Description description="Home page of Off the Curriculum" />

      <LandingPageVideo />
      <div className="carouseleventsContainer">
        <GetEvents />
      </div>
      < JoinBanner />
      <div className="carouseleventsContainer">
        <Carousel />
      </div>
      {/* <Parallax /> */}

      <NewsletterBanner />
      <div className="carouseleventsContainer">
        <NewsLandingPage />
      </div>
      <div className="donateContainer">
        <Donate />
      </div>
    </React.Fragment>
  );
}
export default homePage;