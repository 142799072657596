import Brain from "../../../../../images/functionality/educo/icons/term-1/psy-neuro/brain.webp";
import Brainplasticity from "../../../../../images/functionality/educo/icons/term-1/psy-neuro/brain-plasticity.webp";
import Diseasedbrain from "../../../../../images/functionality/educo/icons/term-1/psy-neuro/diseased-brain.webp";
import Humanbehaviour from "../../../../../images/functionality/educo/icons/term-1/psy-neuro/human-behaviour.webp";
import Milestonesofhumandev from "../../../../../images/functionality/educo/icons/term-1/psy-neuro/milestones-of-human-development.webp";
import Unlocksecrets from "../../../../../images/functionality/educo/icons/term-1/psy-neuro/unlocking-the-secrets.webp";

const objPsychologyNeuroscience = {
    courseDesc: ``, 
    courseKeywords: ``, 
    coursepageURL: ``, 
    courseThumbnail: ``, 
    courseThumbnailAlt:``, 
    courseotcTwitter: ``,
Likes: 0,
Views: 0,  
    title: `Psychology and Neuroscience`, 
    courseDesc:`
    During the neuroscience and psychology seminar series, you will
    explore how to unlock the secrets of the brain, and how neuronal
    connections change and reorganise to ultimately impact human
    behaviour.
    `, 
    quickLinkOneDesc: `
    Induction seminar
    `, 
    quickLinkOne:`
    /PsychologyNeuroscienceInductionSeminar
    `,
    quickLinkTwoDesc: `
    Unlocking the secrets of the human brain
    `, 
    quickLinkTwo:`
    /PsychologyNeuroscienceUnlockingtheSecretsoftheHumanBrain
    `,
    quickLinkThreeDesc: `
    Brain Plasticity
    `, 
    quickLinkThree:`
    /PsychologyNeuroscienceBrainPlasticity
    `,
    quickLinkFourDesc: `
    Neurological Disorders
    `, 
    quickLinkFour:`
    /PsychologyNeuroscienceNeurologicalDisorders
    `,
    quickLinkFiveDesc: `
    Human Behaviour
    `, 
    quickLinkFive: `
    /PsychologyNeuroscienceHumanBehaviour
    `, 
    courses:[
        {
            key: 1, 
            sectionimage: Brain,
            sectionimageAlt: `Section one of the psychology and neuroscience course icon`,
            sectiontitle:`
            Induction Seminar
            `, 
            sectiondesc: `
            In this Psychology and Neuroscience Induction seminar, Professor
            Tamar Makin from the University College London, spoke about
            Brain Plasticity in the context of the Human-Robot Augmentation.
            In this lecture you will uncover how limbs are visualised by the
            Brain, how Brain reorganisation occurs due to the lack of- or
            loss of- a limb, and how scientists are using such Brain based
            visualisation pathways to explore the adding of additional
            robotic augments.
            `,     
            sectionlink:`/PsychologyNeuroscienceBrainPlasticity`, 
            sectionlinkdesc: `
            Click here to watch their Lecture
            `, 
            educovideo: [
                {
                    key: 1, 
                    videoTitle: 'Brain plasticity for alternative hand function', 
                    videoDesc:`Ruby explores the recent developments in Brain Computer Interface (BCIs). Demonstrating how such BCIs have been installed in Macaques, who are now unknowingly able to play Pong using their own mind. Enjoy!                    `, 
                    videoInfo: `100 views | 10 days ago | Professor Tamar Makin`, 
                    videoImage: `
                    https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2FPsychologyNeuroscienceInductionSeminar%2FpsychologyNeuroscienceInductionSeminarThumbnail.webp?alt=media&token=50ce82ae-eba9-463a-9556-7c376bbf624a
                    `,
                    videoImageAlt:`Brain plasticity for alternative hand function thumbnail`, 
                    videohref:`/PsychologyNeuroscienceInductionSeminar`, 
                }, 
            ]
        }, 
        {
            key: 2,
            sectionimage: Brainplasticity,
            sectionimageAlt: `Section two of the psychology and neuroscience course icon`,
            sectiontitle:`
            Unlocking the secrets of the human brain with Dr. Rebecca Gordon
            `, 
            sectiondesc: `
            Theoretical physicist Michio Kaku famously regarded the human
            brain as the most complicated object in the known universe. How
            and when did humanity begin to study the human brain? What
            secrets have we discovered about its structure and function, and
            what are we yet to find out?
            `,     
            sectionlink:`/PsychologyNeuroscienceUnlockingtheSecretsoftheHumanBrain`, 
            sectionlinkdesc: `
            Click here to watch their Lecture
            `, 
            educovideo: [
                {
                    key: 1, 
                    videoTitle: 'Unlocking the secrets of the human brain', 
                    videoDesc:`Led by Dr. Rebecca Gordon in this seminar, you will explore the mechanisms involved in learning. So what actually happens when we have to pay attention to stuff and then learn it, so that we can recall it later?`, 
                    videoInfo: `100 views | 10 days ago | Dr. Rebecca Gordon`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2FPsychologyNeuroscienceUnlockingthesecretsoftheHumanBrain%2FpsychologyNeuroscienceUnlockingthesecretsoftheHumanBrainThumbnail.webp?alt=media&token=2cc58f96-0d23-4469-b38d-27ea84ab8bc1',
                    videoImageAlt:`NeuraLink Thumbnail`, 
                    videohref:`/PsychologyNeuroscienceUnlockingthesecretsoftheHumanBrain`, 
                },     
                {
                    key: 2, 
                    videoTitle: 'NeuraLink', 
                    videoDesc:`Ruby explores the recent developments in Brain Computer Interface (BCIs). Demonstrating how such BCIs have been installed in Macaques, who are now unknowingly able to play Pong using their own mind. Enjoy!                    `, 
                    videoInfo: `100 views | 10 days ago | Ruby Morton`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2FPsychologyNeuroscienceBrainComputerInterface%2FpsychologyNeuroscienceBrainComputerInterfaceThumbnail.webp?alt=media&token=65feeb00-afb0-4907-b35c-333738c3f6d9',
                    videoImageAlt:`NeuraLink Thumbnail`, 
                    videohref:`/PsychologyNeuroscienceBrainComputerInterface`, 
                },                 
                {
                    key: 3, 
                    videoTitle: 'Dopamine and Motivation', 
                    videoDesc:`Ewa explores the role Dopamine plays in motivation. We will discover tips and tricks we can use to reward ourselves to ensure we remain motivated to on only complete our tasks, but succeed in doing so.`, 
                    videoInfo: `100 views | 10 days ago | Ruby Morton`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2FPsychologyNeuroscienceDopamineAndMotivation%2FpsychologyNeuroscienceDopamineAndMotivation.webp?alt=media&token=2585a076-deef-4066-ba69-5b9b4628d0f1',
                    videoImageAlt:`Dopamine and MotivationD Thumbnail`, 
                    videohref:`/PsychologyNeuroscienceDopamineAndMotivation`, 
                }, 
            ]
        }, 
        {
            key: 3,
            sectionimage: Diseasedbrain,
            sectionimageAlt: `Section three of the psychology and neuroscience course icon`,
            sectiontitle:`
            Resilience and mental health with Ms. Denisse Levermore
            `, 
            sectiondesc: `
            Brain plasticity has been a fundamental area of study within
            Neuroscience, and as a result, the brain has been shown to
            change and reorganise neuronal connections. These connections
            can be strengthened over time and reinforced with learning,
            making you better and quicker at completing a specific task.
            `,     
            sectionlink:`/PsychologyNeuroscienceResilience`, 
            sectionlinkdesc: `
            Click here to watch their Lecture
            `, 
            educovideo: [
                {
                    key: 1, 
                    videoTitle: 'Resilience', 
                    videoDesc:`Ruby Morton explores Brain plasticity; you will hear about the seminal research conducted by Donald Hebb, in-famous for coining the phrase “cells that wire together fire together!”, and how brain plasticity can be split into two types: structural and functional.`, 
                    videoInfo: `100 views | 10 days ago | Ms. Denisse Levermore`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2FPsychologyNeuroscienceResilienceandMentalHealth%2FpsychologyNeuroscienceResilienceandMentalHealthThumbnail.webp?alt=media&token=49ff5ce0-2114-4b95-9f31-9d294653443e',
                    videoImageAlt:`Resilience and Mental Health Thumbnail`, 
                    videohref:`/PsychologyNeuroscienceResilienceandMentalHealth`, 
                },   
                {
                    key: 2, 
                    videoTitle: 'Brain Plasticity', 
                    videoDesc:`Ruby Morton explores Brain plasticity; you will hear about the seminal research conducted by Donald Hebb, in-famous for coining the phrase “cells that wire together fire together!”, and how brain plasticity can be split into two types: structural and functional.`, 
                    videoInfo: `100 views | 10 days ago | Ruby Morton`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2FPsychologyNeuroscienceBrainPlasticity%2FbrainPlasticityReelThumbnailRM.webp?alt=media&token=c2938427-423f-480b-b4d8-60cf69d99686',
                    videoImageAlt:`Brain Plasticity Thumbnail`, 
                    videohref:`/PsychologyNeuroscienceBrainPlasticity`, 
                },   
            ]
        }, 
        {
            key: 4,
            sectionimage: Humanbehaviour,
            sectionimageAlt: `Section four of the psychology and neuroscience course icon`,
            sectiontitle:`
            Neurological conditions with Nicole Levermore and Nicole Curtis
            `, 
            sectiondesc: `
            Brain diseases such as multiple sclerosis, dementia, and
            Parkinson's disease affect millions of people worldwide.
            Research into brain disease is crucial to decipher how these
            disorders develop and how best to treat them. In this seminar,
            you will learn about various brain diseases and what current
            neuroscientific research has discovered thus far.
            `,     
            sectionlink:`/PsychologyNeuroscienceNeurologicalConditions`, 
            sectionlinkdesc: `
            Click here to watch their Lecture
            `, 
            educovideo: [
                {
                    key: 1, 
                    videoTitle: `Neurological conditions with Nicole Levermore and Nicole Curtis`, 
                    videoDesc:`In this seminar, you wil learn about the Anatomy of the Brain and how impacts`, 
                    videoInfo: `100 views | 10 days ago | Nicole Levermore and Nicole Curtis`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2FPsychologyNeuroscienceNeurologicalAnatomyandConditions%2FpsychologyNeuroscienceNeurologicalAnatomyandConditionsThumbnail.webp?alt=media&token=4df1c06d-b50f-48ca-be8e-ab785a2afe8b',
                    videoImageAlt:`Neurodiversity and Neurodivergence Thumbnail`, 
                    videohref:`/PsychologyNeuroscienceNeurologicalAnatomyandConditions`, 
                }, 
                {
                    key: 2, 
                    videoTitle: `Parkinson's Disease with Ruby Morton`, 
                    videoDesc:`Parkinson's disease and explains how it affects around 1% of adults aged above 65 years.`, 
                    videoInfo: `100 views | 10 days ago | Ruby Morton`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2FPsychologyNeuroscienceParkinsons%2FparkinsonsDiseaseThumbnail.webp?alt=media&token=ac05e745-95b1-466e-83b1-5433dfa33d74&_gl=1*102znqx*_ga*MTY1MTkzNDY3Ni4xNjk0MjU0MjU1*_ga_CW55HF8NVT*MTY5NTg5NTcwMy43NS4xLjE2OTU5MDI4NjMuMzkuMC4w',
                    videoImageAlt:`Neurodiversity and Neurodivergence Thumbnail`, 
                    videohref:`/PsychologyNeuroscienceParkinsons`, 
                }, 
            ]
        }, 
        {
            key: 5,
            sectionimage: Milestonesofhumandev,
            sectionimageAlt: `Section four of the psychology and neuroscience course icon`,
            sectiontitle:`
            Human behaviour: Neurodiversity with Dr. Max Davie
            `, 
            sectiondesc: `
            Why have certain traits or behaviours been evolutionarily
            successful and others not? Behaviours like sexual selection,
            aggression, formation of culture, and even eating habits are all
            topics evolutionary psychology is interested in. In this
            seminar, you will explore what it is to be human in the context
            of evolution.
            `,     
            sectionlink:`/PsychologyNeuroscienceNeurodiversity`, 
            sectionlinkdesc: `
            Click here to watch their Lecture
            `, 
            educovideo: [
                {
                    key: 1, 
                    videoTitle: `Neurodiversity and Neurodivergence`, 
                    videoDesc:`Dr. Max Davie spoke about neurodiversity and neurodivergent, and what they are.`, 
                    videoInfo: `100 views | 10 days ago | Dr. Max Davie`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2FPsychologyNeuroscienceHumanBehaviour%2FpsychologyNeuroscienceHumanBehaviourThumbnail.webp?alt=media&token=c8dda716-59a8-46d9-92cf-18ba1e390002',
                    videoImageAlt:`Neurodiversity and Neurodivergence Thumbnail`, 
                    videohref:`/PsychologyNeuroscienceHumanBehaviour`, 
                }, 
                {
                    key: 2, 
                    videoTitle: 'Brain Plasticity', 
                    videoDesc:`Ruby Morton explores Brain plasticity; you will hear about the seminal research conducted by Donald Hebb, in-famous for coining the phrase “cells that wire together fire together!”, and how brain plasticity can be split into two types: structural and functional.`, 
                    videoInfo: `100 views | 10 days ago | Ruby Morton`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2FPsychologyNeuroscienceBrainPlasticity%2FbrainPlasticityReelThumbnailRM.webp?alt=media&token=c2938427-423f-480b-b4d8-60cf69d99686',
                    videoImageAlt:`Brain Plasticity Thumbnail`, 
                    videohref:`/PsychologyNeuroscienceBrainPlasticity`, 
                },   
            ]
        }, 
        {
            key: 6,
            sectionimage: Unlocksecrets,
            sectionimageAlt: `Section four of the psychology and neuroscience course icon`,
            sectiontitle:`
            From Addiction to Dopamine: A research symposium
            `, 
            sectiondesc: `
            At what point in human development does a child first look into
            a mirror and recognise the reflection they see is themselves?
            When does a child first become aware of another humans' thoughts
            and feelings? In this seminar, you will explore these key
            milestones in early human development.  
            `,     
            sectionlink:`/PsychologyNeuroscienceSymposium`, 
            sectionlinkdesc: `
            Click here to watch their Lecture
            `, 
            educovideo: [
                {              
                    key: 1, 
                    videoTitle: 'From Addiction to Dopamine: A research symposium', 
                    videoDesc:`James spoke about Dopamine and how this quasi-hormonal neurotransmitter acts primarily across the mesolimbic and mesocortical pathway to influence motivation. Enjoy!`, 
                    videoInfo: `100 views | 10 days ago | Dr. Bianca Schuster and Dr. Angeline Dukes`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2FPsychologyNeuroscienceResearchSymposium%2FpsychologyNeuroscienceResearchSymposiumThumbnail.webp?alt=media&token=ef0cdee7-f188-4196-8059-9cbf212dbcb0',
                    videoImageAlt:`Dopamine - A Neurotransmitter Thumbnail`, 
                    videohref:`/PsychologyNeuroscienceResearchSymposium`, 
                }, 
                {              
                    key: 2, 
                    videoTitle: 'Dopamine - A Neurotransmitter', 
                    videoDesc:`James spoke about Dopamine and how this quasi-hormonal neurotransmitter acts primarily across the mesolimbic and mesocortical pathway to influence motivation. Enjoy!`, 
                    videoInfo: `100 views | 10 days ago | James Madden`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FpsychologyNeuroscience%2FPsychologyNeuroscienceDopamineAndReward%2FpsychologyNeuroscienceDopamineAndRewardThumbnail.webp?alt=media&token=66acf0c6-90d3-4ee9-a104-09793700aa89',
                    videoImageAlt:`Dopamine - A Neurotransmitter Thumbnail`, 
                    videohref:`/PsychologyNeuroscienceDopamineAndReward`, 
                }, 
            ]
        }, 
    ]
}

export default objPsychologyNeuroscience; 