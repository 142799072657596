import React from 'react';
import eductionTeam from '../../../images/functionality/main/education/education/eductionTeam.webp';
import { accreditationPage } from './Media/Accreditation';

function Accrediation() {
    return ( 
            /*You will not be able to see this text.*/
    <React.Fragment>
        <div>
            <div className='centre-frame'>
                <div className="second-second-Education">
                    <h1 className='educationHeader'>
                        {accreditationPage.pageTitle}
                    </h1>
                    <p className="educationP">
                        {accreditationPage.pageIntro}
                    </p>
                    <h2 className='educationHeader'>
                        {accreditationPage.subHeadingOne}
                    </h2>
                    <p className="educationP">
                        {accreditationPage.subHeadingDesc}
                    </p>
                    <h2 className='educationHeader'>
                        {accreditationPage.subHeadingTwo}
                    </h2>
                    <p className="educationP">
                        {accreditationPage.subHeadingTwoDesc}
                    </p>
                    <ul>
                        <li className='education'>
                            <b>Technology Award</b> - The winner of this award will have demonstrated that they have made the best use of technology to facilitate, create their proposal or process. 
                        </li>
                        <li className='education'>
                            <b>Creativity Award</b> - This award serves to highlight participants who demonstrate exceptional efforts to learn new things, develop new skills and competencies, and expand their work responsibilities, as appropriate.
                        </li>
                        <li className='education'>
                            <b>Communication Award</b> - This award recognises imaginative, engaging and informative approaches to the communication of their passion about a particular topic.
                        </li>
                        <li className='education'>
                            <b>Hard work and Outstanding effort award</b> - This award is for individuals who have demonstrated their hard and persistence throughout a particular seminar series. 
                        </li>
                        <li className='education'>
                            <b>Expert Award</b> - The Expert Award is our way of recognizing the participants who have exhibited leadership, professionalism, and an outstanding intellectual rigour. 
                        </li>
                    </ul>
                    <p className="educationP">
                        {accreditationPage.digitalBagding}
                        Each series the individual's associated with each award change, below an example of our available badges for the Psychology and Neuroscience seminar series:
                    </p>
                    <p className="educationP">
                        To find out more about the participants who won said Digital Badges from the Psychology and Neuroscience seminar series, please see <a className="nav_email" href="/PsyNeuro-Digital-badge-winners"><b>here</b></a>. 
                    </p>
                    <h2 className='educationHeader'>
                        {accreditationPage.subHeadingThree}
                    </h2>
                    <p className="educationP">
                        {accreditationPage.subHeadingThreeDesc}
                    </p>
                </div>
                <div className="one-second-Education">
                    <div className='columnEducationOne'>
                        <img src={eductionTeam} className="authorEducation" alt="Education Team header for Off the Curriculum"></img>
                        <ul className="news_article_summary">
                    </ul>
                    <div className="news-icon">
                        <div className="footer_center">
                        </div>
                    </div>
                        <h2 className="About_the_author">About the team:</h2>
                        <p className="prose_about_the_author">
                        The Education team is in charge of developing Off the Curriculum's pedagogic philosophy. The Education team is responsible for our Seminar Series, reviewing EDUCO content, and facilitating the EPQ programme. 
                        </p>
                        </div>
                        <div className='columnEducationTwo'>
                        <h4 className="educationquickLinks">
                            Article quick links:
                        </h4>
                            <li className="news_article"><a className="news_article" href="#Seminar-Series-at-OTC">Seminar Series at OTC</a></li>
                            <li className="news_article"><a className="news_article" href="#Seminar-Structure-at-OTC">Seminar Structure at OTC</a></li>
                            <li className="news_article"><a className="news_article" href="#Topic-selection">Topic selection</a></li>
                            <li className="news_article"><a className="news_article" href="#Pomdoro-Technique-at-OTC">Pomodoro Technique</a></li>
                            <li className="news_article"><a className="news_article" href="#Wild-Card-Seminar">Wild-Card Seminar</a></li>
                            <li className="news_article"><a className="news_article" href="#Self-determined-assessment-session">Self-determined assessment session</a></li>
                            <li className="news_article"><a className="news_article" href="#Accrediation-at-OTC">Accreditation at OTC</a></li>
                            <li className="news_article"><a className="news_article" href="#Seminar-Series-Award">Seminar Series Award</a></li>
                            <li className="news_article"><a className="news_article" href="#Off-the-Curriculum-Award">Off the Curriculum Award</a></li>
                            <li className="news_article"><a className="news_article" href="#Academic-mentoring-at-OTC">Academic Mentoring</a></li>
                            <li className="news_article"><a className="news_article" href="#EDUCO">EDUCO</a></li>
                            </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
}
export default Accrediation