import React from "react";

function Policies() {
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <p className="charity_introduction">
        Off the Curriculum is committed to providing a transparent educational
        charity. We pledge that producing a healthy learning environment is a
        priority for Off the Curriculum, and all individuals engaged with our
        programme will ensure this is maintained. Please follow the below list
        of hyperlinks to read more about our currently enforced policies:
      </p>
      <div className="row-information-top-navbar">
        <a className="policy" href="/Saferecruitment">
          <p>Safe recruitment policy</p>
        </a>
        <a className="policy" href="/Bullyingandharassment">
          <p>Bullying and Harassment policy</p>
        </a>
        <a className="policy" href="/Codeofbehaviour">
          <p>Code of Behaviour policy</p>
        </a>
        <a className="policy" href="/Trusteeprivacy">
          <p>Trustee privacy policy</p>
        </a>
      </div>

      <div className="row-information-top-navbar">
        <a className="policy" href="/Conflictsofinterest">
          <p>Conflicts of Interest policy</p>
        </a>
        <a className="policy" href="/Equalopportunities">
          <p>Equal Opportunities policy</p>
        </a>
        <a className="policy" href="/Reimbursementofexpensesandpurchases">
          <p>Reimbursement of expenses and purchases policy</p>
        </a>
        <a className="policy" href="/Whistleblowing">
          <p>Whistle blowing policy</p>
        </a>
      </div>
      <div className="row-information-top-navbar">
        <a className="policy" href="/Safeguardingyoungpeople">
          <p>Safeguarding young people policy</p>
        </a>
        <a className="policy" href="/Socialetiquetteinonlinegatherings">
          <p>Social etiquette in online gatherings policy</p>
        </a>
        <a className="policy" href="/Supervision">
          <p>Supervision policy</p>
        </a>
      </div>
      <div className="row-information-top-navbar">
        <a className="policy" href="/Trusteecontract">
          <p>Trustee contract</p>
        </a>
        <a className="policy" href="/Trusteedeclarationforfitandproperpersons">
          <p>Trustee declaration for fit and proper persons</p>
        </a>
        <a className="policy" href="/Trusteedeclarationonappointment">
          <p>Trustee declaration on appointment</p>
        </a>
      </div>
    </React.Fragment>
  );
}
export default Policies;
