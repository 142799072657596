import React from "react";
import "./Donate.css";
import { supportUs } from "./Media/Index";

function donate() {
  return (
    <React.Fragment>
      <div className="donateCard">
        <div className="cardDesc">
          <h1 className="donateTitle">{supportUs.Title}</h1>
          <br />
          <p className="donateDesc">{supportUs.desc}</p>
          <div className="button-wrapper">
            <div className="floated run">
                <button className="buttonOne">
                  <a href="/Donate" className="Fund" aria-label='donate £10'>
                  Support us today!
                  </a>
                </button>
            </div>
            
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default donate;
