import React from "react";
import "./Footer.css";
import LondonYouth from "../../../images/functionality/main/footer/LYMember.webp";

function Footer() {
  function mailProtector() {
    const p = "mailto:enxquixres@offthecurrxiculumx.cox.uxk";
    const mailProtected = p.replace(
      "mailto:enxquixres@offthecurrxiculumx.cox.uxk",
      "mailto:enquiries@offthecurriculum.co.uk"
    );
    document.getElementById("enquiries").href = mailProtected;
  }
  <script
    type="text/javascript"
    src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
  ></script>;
  function subscribe($) {
    let fnames;
    let ftypes;

    window.fnames = new []();
    window.ftypes = new []();
    fnames[0] = "EMAIL";
    ftypes[0] = "email";
    fnames[1] = "FNAME";
    ftypes[1] = "text";
    fnames[2] = "LNAME";
    ftypes[2] = "text";
  }

  return (
    /*You will not be able to see this text.*/
    <React.Fragment>
      <hr className="footerHR" />
      <div className="footer">
        <div className="rowFooter">
          <div className="footer-column-01">
            <h1 className="footer-left">Off the Curriculum</h1>
            <p className="footer-left">
              <b>Charity number:</b>{" "}
              <a
                className="charity-hyperlink"
                href="https://register-of-charities.charitycommission.gov.uk/charity-search/-/charity-details/5189608"
              >
                1196701
              </a>
            </p>
            <div>
              <img
                className="footer-image-1"
                src="./icons/pin-drop.svg"
                alt="location pin"
              />
              <span>Lambeth, London, United Kingdom</span>
            </div>
            <br />
            <div>
              <img
                className="footer-image-1"
                src="./icons/message.svg"
                alt="Email"
              />
              <span className="nav-information">
                <a
                  className="nav_email"
                  onClick={mailProtector}
                  id="enquiries"
                  href={"mailProtector:;"}
                >
                  enquiries@offthecurriculum.co.uk
                </a>
              </span>
            </div>
            <br />
            <img
              src={LondonYouth}
              alt="London youth membership certificate"
              height={75}
              className='footer-image-2'
            />
            <img
              src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FFooter%2FPurple_Badge_PolicyBee.webp?alt=media&token=eed7901b-7ce4-4cf2-8caa-65339ce55b61'
              alt="Insured through PolicyBee certificate"
              height={75}
              className='footer-image-2'
            />
          </div>
          <div className="footer-column-02">
            <h2 className="footer">Useful links</h2>
            <p className="footer">
              Here is a list of useful links across our website:
            </p>
            <ul className="footer">
              <li className="footer">
                <a href="/Ourmotto" className="footer">
                  About us
                </a>
              </li>
              <li className="footer">
                <a href="/Careers" className="footer">
                  Donate
                </a>
              </li>
              <li className="footer">
                <a href="/Enrol" className="footer">
                  Enrol
                </a>
              </li>
              <li className="footer">
                <a href="/Comments" className="footer">
                  Feedback
                </a>
              </li>
              <li className="footer">
                <a href="/Policies" className="footer">
                  Policies
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column-03">
            <h3 className="footer">Subscribe to our newsletter today!</h3>
            <div className="contact_us_footer">
              <link
                href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
                rel="stylesheet"
                type="text/css"
              ></link>

              <div id="mc_embed_signup">
                <form
                  action="https://offthecurriculum.us14.list-manage.com/subscribe/post?u=eda1471915b675cfc40023b97&amp;id=85a626e92b"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  target="_blank"
                  noValidate
                >
                  <div id="mc_embed_signup_scroll">
                    <div className="indicates-required">
                      <span className="asterisk">*</span> indicates required
                    </div>
                    <div className="mc-field-group">
                      <label htmlFor="mce-EMAIL">
                        Email Address <span className="asterisk">*</span>
                      </label>
                      <input
                        type="email"
                        //value=""
                        name="EMAIL"
                        className="required email"
                        id="mce-EMAIL"
                      />
                    </div>
                    <div id="mce-responses" className="clear">
                      <div className="response" id="mce-error-response"></div>
                      <div className="response" id="mce-success-response"></div>
                    </div>

                    <div className="clear">
                      <input
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id=""
                        className="buttonOTC"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="socialMediaIcon">
        <div className="footer_center">
          <p className="footer">Follow us on:</p>
          <a
            href="https://twitter.com/otc_london/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="./icons/twitter.svg"
              className="footer-image"
              alt="twitter link"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/offthecurriculum-otc/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="./icons/linkedin.svg"
              className="footer-image"
              alt="linkedin link"
            />
          </a>
          <a
            href="https://www.instagram.com/offthecurriculumlondon/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="./icons/instagram.svg"
              className="footer-image"
              alt="instagram link"
            />
          </a>
        </div>
      </div>
      <h4 className="page_end">Off the Curriculum established in 2020.</h4>
      <h4 className="page_end">
        Powered by{" "}
        <a
          className="nav_email_bold"
          href="https://www.levermoreandcode.co.uk/"
        >
          Levermore and Code.
        </a>
      </h4>
    </React.Fragment>
  );
}
export default Footer;
