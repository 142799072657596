import React from "react";
import { programmeTestimonial } from "./Media/Testimonial";

function Programme_testimonial() {
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <div className="centre-frame">
        <h1 className="educationHeader" id="Partnered-sixth-forms">
          {programmeTestimonial.pageHeader}
        </h1>
        <h2 className="news_article" id="Partnered-sixth-forms">
          Student Testimonials
        </h2>
        <center>
          <p className="testimonial">{programmeTestimonial.testimonialOne}</p>
        </center>
        <div className="left-align">
          <p>{programmeTestimonial.testimonialOneAuthor}</p>
          <p>
            <b>{programmeTestimonial.testimonialOneDesc}</b>
          </p>
        </div>
        <center>
          <p className="testimonial">{programmeTestimonial.testimonialTwo}</p>
        </center>
        <div className="left-align">
          <p>{programmeTestimonial.testimonialTwoAuthor}</p>
          <p>
            <b>{programmeTestimonial.testimonialTwoAuthor}</b>
          </p>
        </div>
        <h2 className="educationHeader" id="Partnered-sixth-forms">
          {programmeTestimonial.teacherTestimonial}
        </h2>
        <center>
          <p className="testimonial">{programmeTestimonial.testimonialThee}</p>
        </center>
        <div className="left-align">
          <p>{programmeTestimonial.testimonialThreeAuthor}</p>
          <p>{programmeTestimonial.testimonialThreeDesc}</p>
          <p>
            <b>{programmeTestimonial.testimonialTheeInstitute}</b>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Programme_testimonial;
