import { fbStorage } from "./firebase";
import { ref, listAll } from "firebase/storage";

import { VideoSort } from "../components/common/videoStorage/videos";

export const EducoReference = ref(fbStorage, "Educo/");

let Folders = [];

listAll(EducoReference).then((res) => {
    res.prefixes.forEach((folderRef) => {
      Folders.push(folderRef);
    });
 

 
});

export function VideoGet() {




if (Folders.length > 0) {

    VideoSort(Folders);
  
}

}
