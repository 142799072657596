import React from "react";
//{ MetaTags } from "react-meta-tags";
import { enrolPage } from "./Media/Enrol";
function Enrol() {
  function mailProtector() {
    const p = "mailto:truxsxxtxxxexexs@offthecurrxiculumx.cox.uxk";
    const nicoleEmail = p.replace(
      "mailto:truxsxxtxxxexexs@offthecurrxiculumx.cox.uxk",
      "mailto:nicolelevermore@offthecurriculum.co.uk"
    );
    document.getElementById("nicoleEmail").href = nicoleEmail;
  }
  return (
    <React.Fragment>
      {/*<MetaTags>
        <title>Enrol at Off the Curriculum</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta
          name="description"
          content="A newly enrolled Sixth Form student at a Lambeth or Southwark school? Enrol with us and reach your full potential with Off the Curriculum today."
        />
        <meta
          name="keywords"
          content="Education, Charity, News, Educational News, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta name="title" content="Enrol at Off the Curriculum" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Enrol"
        />
        <meta property="og:title" content="Enrol at Off the Curriculum" />
        <meta
          property="og:description"
          content="A newly enrolled Sixth Form student at a Lambeth or Southwark school? Enrol with us and reach your full potential with Off the Curriculum today."
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/contact_us/participant/participant.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/contact_us/participant/participant.webp"
        />
        <meta
          property="og:image:alt"
          content="Enrol in the Off the Curriculum programme."
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Enrol"
        />
        <meta property="twitter:title" content="Enrol at Off the Curriculum" />
        <meta
          property="twitter:description"
          content="A newly enrolled Sixth Form student at a Lambeth or Southwark school? Enrol with us and reach your full potential with Off the Curriculum today."
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/contact_us/participant/participant.webp"
        />
        <meta
          property="twitter:image:alt"
          content="Enrol in the Off the Curriculum programme."
        />
  </MetaTags>*/}
      <div className="centre-frame">
        <h1 className="educationHeader">{enrolPage.enrolpageTitle}</h1>
        <p className="educationP">{enrolPage.enrolpageDesc}</p>
        <p className="educationP">
          Still interested in being involved in Off the Curriculum? To apply to
          be a participant, please complete the below "Off the Curriculum
          Participant Enrolment form" and send an up-to-date copy of your
          Curriculum Vitae (CV) to our{" "}
          <a
            className="nav_email"
            onClick={mailProtector}
            id="nicoleEmail"
            target="_blank"
            rel="noreferrer"
            href="mailProtector:;"
          >
            <b>Nicole Levermore. </b>
          </a>{" "}
          If you would like advice on how to write a CV, please click{" "}
          <a
            href="https://www.ucas.com/careers/getting-job/how-write-cv"
            className="nav_email"
          >
            <b>here.</b>
          </a>{" "}
          We look forward to hearing from you!
        </p>
        <div className="form_center">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSdb1TIbvXU0XNkjI6TXRNfgv4GGXqcqq3mq7NsHeGZroqaLQw/viewform?embedded=true"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            className="assessment-frame"
            title="enrolment form"
          >
            Loading…
          </iframe>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Enrol;
