import React from "react";
import Tabs from "./components/Tabs";
import "./Donate.css";
import { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import pic5 from "../../../images/functionality/main/support_us/img1.webp";
import pic3 from "../../../images/functionality/main/support_us/img2.webp";
import pic4 from "../../../images/functionality/main/support_us/img3.webp";
import MetaTags from "react-meta-tags";

function Donate() {
  const [active, setActive] = useState(-1);
  const getCardClassName = (index) => {
    if (active > -1 && index !== active) return "fadeOut";
    return "fadeIn";
  };
  const Card1 = ({
    imageSrc = pic5,
    title = "Donate £10",
    text = "Provides classroom stationary such as whiteboards & flashcards for in-person learning.",
    ...props
  }) => (
    <div className="columnDonate">
      <a
        href="https://link.justgiving.com/v1/charity/donate/charityId/3210680?tipScheme=TipJar2.1&reference=givingcheckout_tj21&suggestedAmountDescription=10:Provides%20classroom%20stationary%20such%20as%20whiteboards%20%26%20flashcards.&suggestedAmountDescription=20:Provides%20Seminar%20Graduation%20Certificates.&suggestedAmountDescription=50:Helps%20us%20advertise%20for%20seminar%20volunteers.&suggestedAmountDescription=100:Provides%20OTC%20t-shirts%20for%20seminar%20volunteers.&amount=20"
       
        {...props}
      >
        <div className="card-donate">
          <img
          
            className="card-img-top"
            src={imageSrc}
            width="100%"
            height="100%"
            alt="Unsplash Random"
          />
          <div className="card-body">
            <h5 className="card-title-donate">{title}</h5>
            <p className="card-text">{text}</p>
          </div>
        </div>
      </a>
    </div>
  );

  const Card2 = ({
    imageSrc = pic4,
    title = "Donate £20",
    text = "Prints t-shirts for our seminar volunteers and certificates for our students. \n ",
    ...props
  }) => (
    <div className="columnDonate">
      <a
        href="https://link.justgiving.com/v1/charity/donate/charityId/3210680?tipScheme=TipJar2.1&reference=givingcheckout_tj21&suggestedAmountDescription=10:Provides%20classroom%20stationary%20such%20as%20whiteboards%20%26%20flashcards.&suggestedAmountDescription=20:Provides%20Seminar%20Graduation%20Certificates.&suggestedAmountDescription=50:Helps%20us%20advertise%20for%20seminar%20volunteers.&suggestedAmountDescription=100:Provides%20OTC%20t-shirts%20for%20seminar%20volunteers.&amount=20"
      
        {...props}
      >
        <div className="card-donate">
          <img
          fetchpriority="high"
            className="card-img-top"
            src={imageSrc}
            width="100%"
            height="100%"
            alt="Unsplash Random"
          />
          <div className="card-body">
            <h5 className="card-title-donate">{title}</h5>
            <p className="card-text">{text}</p>
          </div>
        </div>
      </a>
    </div>
  );

  const Card3 = ({
    imageSrc = pic3,
    title = "Donate £50",
    text = "Recruits additional volunteers and camera rental to facilitate the production of EDUCO content.",
    ...props
  }) => (
    <div className="columnDonate">
      <a
        href="https://link.justgiving.com/v1/charity/donate/charityId/3210680?tipScheme=TipJar2.1&reference=givingcheckout_tj21&suggestedAmountDescription=10:Provides%20classroom%20stationary%20such%20as%20whiteboards%20%26%20flashcards.&suggestedAmountDescription=20:Provides%20Seminar%20Graduation%20Certificates.&suggestedAmountDescription=50:Helps%20us%20advertise%20for%20seminar%20volunteers.&suggestedAmountDescription=100:Provides%20OTC%20t-shirts%20for%20seminar%20volunteers.&amount=20"
      
        {...props}
      >
        <div className="card-donate">
          <img
          fetchpriority="high"
            className="card-img-top"
            src={imageSrc}
            width="100%"
            height="100%"
            alt="Unsplash Random"
          />
          <div className="card-body">
            <h5 className="card-title-donate">{title}</h5>
            <p className="card-text">{text}</p>
          </div>
        </div>
      </a>
    </div>
  );

  return (
    <div>
      {/*<MetaTags>
        <title>Donate to Off the Curriculum</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta
          name="description"
          content="Be it a teacher at a school based in Lambeth or Southwark, or a local business looking to engage with their local community, here’s what we have to offer…"
        />
        <meta
          name="keywords"
          content="Education, Charity, News, Educational News, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta name="title" content="Collaborate with Off the Curriculum" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/education/collaborations.html"
        />
        <meta
          property="og:title"
          content="Collaborate with Off the Curriculum"
        />
        <meta
          property="og:description"
          content="Be it a teacher at a school based in Lambeth or Southwark, or a local business looking to engage with their local community, here’s what we have to offer…"
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/education/headers/collaborate.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/education/headers/collaborate.webp"
        />
        <meta
          property="og:image:alt"
          content="Collaborate with Off the Curriculum."
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/education/collaborations.html"
        />
        <meta
          property="twitter:title"
          content="Collaborate with Off the Curriculum"
        />
        <meta
          property="twitter:description"
          content="Be it a teacher at a school based in Lambeth or Southwark, or a local business looking to engage with their local community, here’s what we have to offer…"
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/education/headers/collaborate.webp"
        />
        <meta
          property="twitter:image:alt"
          content="Collaborate with Off the Curriculum."
        />
  </MetaTags>*/}
      <div className="centre-frame donate">
        <div className="rowS">
          <h1>Donate Today!</h1>
          <Tabs>
            <div label="Once off donation">
              <div className="rowC">
                <Card1
                  key={1}
                  className={`${getCardClassName(1)}`}
                  onMouseOver={() => {
                    setActive(1);
                  }}
                  onMouseOut={() => {
                    setActive(-1);
                  }}
                />
                <Card2
                  key={2}
                  className={` ${getCardClassName(1)}`}
                  onMouseOver={() => {
                    setActive(1);
                  }}
                  onMouseOut={() => {
                    setActive(-1);
                  }}
                />
                <Card3
                  key={3}
                  className={` ${getCardClassName(1)}`}
                  onMouseOver={() => {
                    setActive(1);
                  }}
                  onMouseOut={() => {
                    setActive(-1);
                  }}
                />
              </div>
            </div>
            <div label="Monthly donation">
              <div className="rowC">
                <Card1
                  key={1}
                  className={`${getCardClassName(1)}`}
                  onMouseOver={() => {
                    setActive(1);
                  }}
                  onMouseOut={() => {
                    setActive(-1);
                  }}
                />
                <Card2
                  key={2}
                  className={` ${getCardClassName(1)}`}
                  onMouseOver={() => {
                    setActive(1);
                  }}
                  onMouseOut={() => {
                    setActive(-1);
                  }}
                />
                <Card3
                  key={3}
                  className={` ${getCardClassName(1)}`}
                  onMouseOver={() => {
                    setActive(1);
                  }}
                  onMouseOut={() => {
                    setActive(-1);
                  }}
                />
              </div>
            </div>
          </Tabs>
          </div>
          <div id="amznCharityBanner">
            Or alternatively why not donate as you shop and register Off the
            Curriculum with your{" "}
            <a
              className="course-subject"
              href="https://smile.amazon.co.uk/ch/1196701-0"
            >
              Amazon Smiles Account? To do so please click here.
            </a>
         
        </div>
      </div>
    </div>
  );
}

export default Donate;
