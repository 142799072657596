import Stars from '../../../../../images/functionality/educo/icons/term-1/astronomy/stars.webp'
import Solarsystem from '../../../../../images/functionality/educo/icons/term-1/astronomy/solarsystem.webp'
import Telescope from '../../../../../images/functionality/educo/icons/term-1/astronomy/telescope.webp'


const objBusiness = {
    webpagedesc: ``, 
    webpagekeywords: ``, 
    webpagepageurl: ``, 
    webpagethumbnail: ``, 
    webpagethumbnailAlt:``, 
    organisationtwitter: ``, 
    title: 'Business', 
    courseDesc: 
        `
        In this online seminar series, the student-volunteers will create content on topics aligned to Business and Enterprise, covering: Basic Accounting and Policy; Marketing; Entrepreneurship; Business process mapping; and Principles of Strategy. This document will detail example EDUCO videos a volunteer could create, this should be taken as guidance and we encourage you to think of topics aligned to these areas that you find most interesting. 
        `
    ,
    quickLinkOneDesc:
    `
    One Humanity, One Paradise
    `
    ,
    quickLinkOne: `
    /AstronomyInductionSeminar
    `, 
    quickLinkTwoDesc: 
    `
    Uncertainty Quantification in Climate Systems
    `, 
    quickLinkTwo:
    `
    /AstronomyTheStars
    `, 
    quickLinkThreeDesc:
    `
    Climate Policy
    `, 
    quickLinkThree: 
    `
    /AstronomyDwarfPlanets
    `, 
    quickLinkFourDesc:`
    TheMoons
    `, 
    quickLinkFour: 
    `
    /AstronomyTheMoons
    `, 
    quickLinkFiveDesc: 
    `
    Space Policy
    `, 
    quickLinkFive: 
    `
    /AstronomySpacePolicy
    `, 
    courses:[
    {
        key: 1, 
        sectionimage: Telescope, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        Business and Enterprise
        `, 
        sectiondesc: `
        In the series we will explore: Business objectives and strategies using a Case Study, and also explain how corporate strategists use Boston Consulting group's product portfolio (BCG) matrix to acquire a quick picture of which business units are doing well and which are not. 
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
                [
                    {
                        key: 1, 
                        videoTitle: `Founding a 'Start up'`,
                        videoDesc: `Gabriel Paunescu led our first wild card event for Off the
                        Curriculum on Business and Entrepreneurship. Gabriel spoke about
                        the steps needed to validate the success of your start-up
                        business, exploring all things digital marketing from Search
                        Engine Optimization to Email marketing. He emphasised the need
                        to build a network of engaged consumers to demonstrate to
                        investors that your business will succeed!`,
                        videoInfo: `100 views | 10 days ago | Gabriel Paunescu`, 
                        videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fbusiness%2FBusinessFoundingAStartUp%2FgabrielPaunescuBusinessThumbnail.webp?alt=media&token=8f161f58-d354-400e-8d87-89c2e54553fb',
                        videoImageAlt: `Founding a 'Start Up' Thumbnail`, 
                        videohref: `/BusinessFoundingAStartUp`, 
                    }, 
        
                ]
    }, 
    {
        key: 2, 
        sectionimage: Solarsystem, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        Basic Accounting and Policy
        `, 
        sectiondesc: `
        In this series we will discuss the importance of accounting for Business enterprises. You could explore income statements, balance sheets, a profit and loss statement, cash flow statements, and reconciliations. 

        In addition to this you will also learn about the principles of accounting, explaining the 10 key principles: Utmost Good Faith, Materiality, Periodicity, Continuity, Prudence, Non-compensation, Permanence of Methods, Sincerity, Consistency, and Regularity. 
        
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'A Journey Through The Solar System', 
                videoDesc:`Why not uncover extraterrestrial missions to Mars, Jupiter and beyond!`, 
                videoInfo: `100 views | 10 days ago | Teresa Su`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EDUCO%2Fastronomy%2Fteresa-su%2Fthe-solar-system%2FtheSolarSystemTS.png?alt=media&token=c1a9f7f5-abb5-48f3-a952-573b712f167b',
                videoImageAlt:`A Journey Through The Solar System Thumbnail`, 
                videohref:`/AstronomyTheSolarSystem`, 
            },
        ]
    }, 
    {
        key: 3, 
        sectionimage: Stars, 
        sectionimageAlt: `
        Climate Policy
        `, 
        sectiontitle: `
        Marketing
        `, 
        sectiondesc: `
        In this series, we will discuss the fundamentals of Marketing, how to create annual and bi-annual integrated marketing communications strategies. Exploring the key parameters to do so: Understanding your target audience; defining your unique selling proposition; determining your marketing communications mix; defining your brand elements; and defining success metrics; and execution. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'The Stars', 
                videoDesc:`We will explore how Stars evolve from a pre-main sequence star to a Supernova (depending on the Stars size). The rise and death of a Star is an incredibly dramatic episode, one we are sure you are going to enjoy exploring!`, 
                videoInfo: `100 views | 10 days ago | Teresa Su`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EDUCO%2Fastronomy%2Fteresa-su%2Fthe-stars%2FtheStarsThumbnail.png?alt=media&token=ef70cb2d-53b0-4321-a7aa-4eadcdc0a2ee',
                videoImageAlt:`
                videoTitle: 'The Stars Thumbnail`, 
                videohref:`/AstronomyTheStars`, 
            },
        ]
    }, 
    {
        key: 4, 
        sectionimage: Stars, 
        sectionimageAlt: `
        Entrepreneurship
        `, 
        sectiontitle: `
        Entrepreneurship
        `, 
        sectiondesc: `
        In this series we will explore Sastre-castillo et al., 2015 publication in Nonprofit Management and Leadership, describing the social entrepreneur. Where they define the social entrepreneur as an individual who engages in activities, with the intention of solving social problems. This series will also explain the concepts, defining Schwartz’s theory of universal values, defining types of entrepreneurs, and how the social entrepreneur differs from the classical entrepreneur. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'The Stars', 
                videoDesc:`We will explore how Stars evolve from a pre-main sequence star to a Supernova (depending on the Stars size). The rise and death of a Star is an incredibly dramatic episode, one we are sure you are going to enjoy exploring!`, 
                videoInfo: `100 views | 10 days ago | Teresa Su`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EDUCO%2Fastronomy%2Fteresa-su%2Fthe-stars%2FtheStarsThumbnail.png?alt=media&token=ef70cb2d-53b0-4321-a7aa-4eadcdc0a2ee',
                videoImageAlt:`
                videoTitle: 'The Stars Thumbnail`, 
                videohref:`/AstronomyTheStars`, 
            },
        ]
    }, 
    {
        key: 5, 
        sectionimage: Stars, 
        sectionimageAlt: `
        Principles of Strategy
        `, 
        sectiontitle: `
        Principles of Strategy
        `, 
        sectiondesc: `
        In this series, you will discover the ten principles for a good strategic design: Less not more; Honesty; Unobtrusive; Unstanderable; Useful; Long-lasting; Thorough; Innovative; Aesthetic; and environmentally friendly. Other EDUCO videos on this topic could explore each one in more detail, i.e., the importance of environmental consciousness strategy designs. 
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'The Stars', 
                videoDesc:`We will explore how Stars evolve from a pre-main sequence star to a Supernova (depending on the Stars size). The rise and death of a Star is an incredibly dramatic episode, one we are sure you are going to enjoy exploring!`, 
                videoInfo: `100 views | 10 days ago | Teresa Su`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EDUCO%2Fastronomy%2Fteresa-su%2Fthe-stars%2FtheStarsThumbnail.png?alt=media&token=ef70cb2d-53b0-4321-a7aa-4eadcdc0a2ee',
                videoImageAlt:`
                videoTitle: 'The Stars Thumbnail`, 
                videohref:`/AstronomyTheStars`, 
            },
        ]
    }, 
    ],
}

export default objBusiness; 
