import React from "react";
import MetaTags from "react-meta-tags";
import { outreach } from "./Main/Outreach";
import "./Mainstyling/Outreach.css";

function Collaborations() {
  return (
    <div>
      <MetaTags>
        <title>Collaborate with Off the Curriculum</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta
          name="description"
          content="Be it a teacher at a school based in Lambeth or Southwark, or a local business looking to engage with their local community, here’s what we have to offer…"
        />
        <meta
          name="keywords"
          content="Education, Charity, News, Educational News, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta name="title" content="Collaborate with Off the Curriculum" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Collaborations"
        />
        <meta
          property="og:title"
          content="Collaborate with Off the Curriculum"
        />
        <meta
          property="og:description"
          content="Be it a teacher at a school based in Lambeth or Southwark, or a local business looking to engage with their local community, here’s what we have to offer…"
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/education/headers/collaborate.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/education/headers/collaborate.webp"
        />
        <meta
          property="og:image:alt"
          content="Collaborate with Off the Curriculum."
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Collaborations"
        />
        <meta
          property="twitter:title"
          content="Collaborate with Off the Curriculum"
        />
        <meta
          property="twitter:description"
          content="Be it a teacher at a school based in Lambeth or Southwark, or a local business looking to engage with their local community, here’s what we have to offer…"
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/education/headers/collaborate.webp"
        />
        <meta
          property="twitter:image:alt"
          content="Collaborate with Off the Curriculum."
        />
      </MetaTags>
      <div className="centre-frame">
        <h1>{outreach.title}</h1>
        <p className="outreach">{outreach.desc}</p>
      </div>
    </div>
  );
}

export default Collaborations;
