import { db, auth } from "./firebase";
import { ref, push, set, update } from "firebase/database";
import { useEffect } from "react";


export function StoreVideo(name, like, views) {
  //console.log("saving");
  let videoName = name;
  let count = views;
  let likes = like;

  set(ref(db, "video/name/" + videoName), {
    key: videoName,
    token: true,
    count: count,
    likes: likes,
  });
}

export function UpdateLikes(e) {
  let name =
  e.target.parentElement.nextSibling.lastChild.firstChild.firstChild.innerText; 
  let likesString = e.target.previousSibling.innerHTML;
  //console.log(e.target.parentElement.nextSibling.innerText)

  let likesNum = likesString.split(":");
  //console.log(likesNum)

  let l = parseInt(likesNum[1]);

  let likesUpdater = l + 1;

  const updates = {};
  updates["/video/name/" + name + "/likes"] = likesUpdater;

  return update(ref(db), updates);
}


export function UpdateViews(e) {
 //console.log('updating');
  let name = e.target.nextElementSibling.nextSibling.lastChild.firstChild.firstChild.innerText;
 // console.log('here', name)
  let viewsString = e.target.nextElementSibling.childNodes[0].outerText;
  let viewNum = viewsString.split("Views:");
 //console.log(e,name,viewsString,viewNum)
  let viewCount = viewNum[1].split('\n')
 // console.log(viewCount)
// console.log(viewCount)
  let v = parseInt(viewCount[0]);
 //console.log(v)
  let viewsUpdater = v + 1;
 //console.log(viewsUpdater)
// console.log(viewsUpdater)
 
  const updates = {};
  updates["/video/name/" + name + "/count"] = viewsUpdater;
  updates["/video/name/" + name + '/token'] = true
 //console.log(updates, update(ref(db), updates).Promise)
  return update(ref(db), updates);
}