const careersPage = {
    officerPositions: `
    Off the Curriculum believes educational empowerment opens up previously assumed unattainable opportunities. We believe any young-person, regardless of their circumstances, can achieve success if given the opportunity and guidance. To do this, we believe connecting students and professionals can make the transition into adulthood much smoother and exciting for all those involved, and we are always looking for individuals to join the Off the Curriculum team permanently, and are currently recruiting for the following Officer positions:
    `, 
    volunteerPosition: 
    `
    Off the Curriculum is recruiting passionate future and/current educators to support the development of our online learning portal, Educo. Our educational programme is designed to make the transition from Sixth form to adult life easier, with bespoke courses aligned to a students interest. If you wish to be an educational content creator for our Educo platform, we would love to hear from you. To be a EDUCO content creator with OTC, you must be a university student or graduate studying a subject relating to the topics shown below:
    `, 
}

export {
    careersPage, 
}