const objWebdesignOfficer = {
  title: 'Web Design Officer',
  subtitle: 'London or Remote',
  desc: `work with Education, Outreach, and Social Media teams to deliver targets set by Charity’s education board.`,
  descFull: ``,
  skills: [
    'Network / System Management',
    'SEO and Analytics',
    'Software and Web Development',
    'Web Design (including UX/UI)',
  ],
  where: {
    location: 'London, SE12DU',
    desc: 'Mainly at home, or remote opportunity.',
  },
  time: {
    workTime: 'Either in or out of office hours',
    timeNeeded: '3-7 hours / week',
  },
  whatWillYouBeDoing: {
    p: `The main duties of the role:`,
    ul: [
      `To create and edit content for the website, including the design and layout, reflecting the Charity’s branding and aesthetics`,
      `To create graphics and content to support the educational videos generated for Educo (OTC’s educational platform)`,
      `To streamline all future development of footage, making the processes more efficient with time`,
      `To collect and analyse data on the website to improve performance`,
      `To support the Educo, Video Production, Outreach, and Social Media teams in delivering targets`,
      `To collaborate with the Social Media team to generate posts for the Charity’s social media (Instagram, LinkedIn, Twitter, YouTube) on a regular basis and engage with our audience`,
      `To ensure all content produced and made public on our social media is visually engaging and of top calibre`,
    ],
  },
  whatAreWeLookingFor: {
    p: ``,
    ul: [
      `Experience in web design`,
      `Proficiency using JavaScript and/or other software`,
      `Excellent multi-channel communication`,
      `Independent and team working skills`,
      `Good time management`,
    ],
  },
  whatDifferenceWillYouMake: {
    p: `The web developer will have direct impact on the Charity's image, trying 
        to develop its visibility and increase its audience.`,
    ul: [],
  },
  whatsInItForTheVolunteer: {
    p: ``,
    ul: [],
  },
};

export default objWebdesignOfficer;
