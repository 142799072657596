import { db } from "./firebase";
import { ref, set, push } from "firebase/database";
import { auth } from "./firebase";
import { UserEmail } from "../App";


function HandleSubmitt(e) {



  e.preventDefault()
  let email  = emails(UserEmail.Provider);

  function emails(s) {
    return s.split("").reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }
  let name = e.target[0].value;
 

  let colour = e.target[1].value;

 
  //console.log(e.target[1].value)
  

  let pics = document.getElementById("image");
  let profileImage = pics.children.picProfile.currentSrc;


  writeUserData(name, email, profileImage, colour);
}

function writeUserData(name, email, profileImage,  colour) {

 
  const userRef = ref(db, "mentor/" + email);
  //console.log(userRef)

  const newUserRef = push(userRef);

  if (auth.currentUser === null) {
  
    alert("Sorry no account found please sign in or register");
  } else if (auth.currentUser !== null) {
   
    let uid = auth.currentUser.uid;
    let key = userRef.key;
    set(newUserRef, {
    token: true,
    user_id: uid,
    key:key,
    Name: name,
    Email: email,
    picture: profileImage,
    colour: colour,
    
  })
  .then(() => {
    window.location.href = "/congrats";
  })
  .catch((error) =>{
   //console.log(error)
 
  })

  }
}
export function MentorProfileForm() {
  return (
    <>
      <h1>Once you are happy with your avatar, please fill in the below details</h1>
      <form onSubmit={(e) => HandleSubmitt(e)} className="mentorDetails">
        <input
          id="name1"
          type="text"
          name="firstname"
          placeholder="Enter Your Full Name"
          required
        />
      <label htmlFor="colour">Whats your Favourite colour:</label>

        <select name="colour" id="colour" required>
          <option value="">--Please choose an option--</option>

          <option value="blue">Blue</option>
          <option value="green">Green</option>
          <option value="orange">Orange</option>
          <option value="pink">Pink</option>
          <option value="purple">Purple</option>
          <option value="red">Red</option>
          <option value="yellow">Yellow</option>
        </select>

        <input type="submit" value="Save your details"></input>
      </form>
    </>
  );
}