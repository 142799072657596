import React, { useState, useRef, useEffect } from "react";
import './courseDiv.css';

const Courses = ({ courses }) => {
    return (
        <>
        <div className="educo-carousel">
          <div className="carouselInner">
            {courses.map((course, index) => (
          <div className="item" key={course.id}>
            <div className="viewport-one">
              <div className="card-educo">
                <figure className="educo">
                  <a
                    href={course.courseLink}
                    className="nav_email"
                    data-link
                  >
                    
                    <h2 className="topic_header">
                        {course.courseTitle}
                    </h2>
                  </a>
                  <a
                    href={course.courseLink}
                    className="nav_email"
                    data-link
                  >
                    <img src={course.courseIcon} className="courseIcons"></img>
                  </a>
                  <div className="card-text-container">
                  <p className="topic_description_2">
                    {course.courseDesc}
                  </p>
                  </div>
                  <a href={course.courseLink} data-link>
                    <button className="courseButton">See more here...</button>
                  </a>
                </figure>
              </div>
            </div>
          </div>
                      ))}
                      </div>
          </div>
        </>
    );
};

export default Courses; 