import React from "react"; 
import './stylesheet/PageLoader.css';   

function PageLoader(){

    return(
        <React.Fragment>
            <div className="LoadingPage">
                <h2 className="LoadingPage">
                    Loading...
                </h2>
                <img className="LoadingPage" src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPageLoader%2FloadingScreen.gif?alt=media&token=8e963982-1ec0-4b64-b2d9-efdbbf5115f0" alt="" />
                </div>
        </React.Fragment>

    );
}
export default PageLoader
