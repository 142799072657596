import { votingResults } from "../../firebase/getVoting";
import { MarkedVoted } from "../../firebase/getVoting";


/** NOT UPDATED  */
export function HandleSubmit(e) {
     
  e.preventDefault();
  let storage = JSON.parse(localStorage.getItem("profile-storage"));
  let userId = storage.state.profile[0].id
  let name;
  if (e.target.length > 0) {
    for (let i = 0; i < e.target.length; i++) {
      if (e.target[i].checked === true) {
        name = e.target[i].value;
      }
    }
  }
  let term = e.target[0].attributes[0].nodeValue;
  votingResults(name, term);
  MarkedVoted(userId)
  alert('Congratulations, you have successfully voted!!!');
}
export function TermOne() {

//  let userId=''
    let storage = JSON.parse(localStorage.getItem("profile-storage"));
   if (storage.state.profile[0] !== undefined) {
  // userId = storage.state.profile[0].id
   }

   

    return(
        <>
           <form onSubmit={(e) => HandleSubmit(e)} className= 'termForm'>
            <fieldset value="one">
              <legend >Time to vote for the next workshop topic:</legend>
              <input
                type="radio"
                id="historylinear"
                name="voteTermOne"
                value="historylinear"
                onChange={this}
                required/>
              <label htmlFor="radio-label">Is history cyclical or linear?</label>

              <input
                type="radio"
                id="climatechange"
                name="voteTermOne"
                value="climatechange"
                onChange={this}
              />
              <label htmlFor="climatechange">Mitigating environmental catastrophe</label>

              <input

                onChange={this}
                type="radio"
                id="historypeople"
                name="voteTermOne"
                value="historypeople"
              />
              <label htmlFor="historypeople">A peoples history</label>

            </fieldset>
            <input type="Submit" placeholder="Submit" onclick="return clicked();"/>
          </form>
        </>
    )
}