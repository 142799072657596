import React from "react";
import Codeofbehaviourpolicy from "../Policy-folder-pdf/Code_of_Behaviour.pdf";

function Codeofbehaviour() {
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <div>
        <h1>Code of Behaviour Policy at Off the Curriculum</h1>
        <iframe
          title=" Our Code of behaviour policy"
          src={Codeofbehaviourpolicy}
          className="policy"
        ></iframe>
      </div>
    </React.Fragment>
  );
}
export default Codeofbehaviour;
