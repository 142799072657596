import { LandingPageVideo } from "../common";
import "./stylingsheet/Quiz.css";

import AstrophysicsQuestion from "./quizPapers/quizQuestionSheets/AstrophysicsQuestionSheet";
import AstroAnswer from "./quizPapers/quizAnswerSheets/Astronomy/AstrophysicsAnswerSheet";

export function QuizMain() {
  let restartTime;

  window.addEventListener("load", () => {
   //console.log("loaded");
    let video = document.getElementById("videoPlayer");

    video.addEventListener("mousedown", () => {
      video.addEventListener("playing", VideoTime);
     //console.log("playing");
      
    });
    function VideoTime() {
      video.addEventListener("timeupdate", () => {
        let currentTime = video.currentTime;

        if (currentTime > 50.0 && currentTime < 55.0) {
          restartTime = currentTime;
          video.pause();
          video.style.display = "none";
          let quiz = document.getElementById("quiz");
          quiz.style.visibility = "visible";
        }
      });
    }
  });


  return (
    <>
      <div>
        <LandingPageVideo />
      </div>
      <div id="quiz">
        <h1>Time for a Quiz</h1>

        <AstrophysicsQuestion/>
        
      </div>
    </>
  );
}

