import { MentorData } from "../../firebase/mentorDataGet";
import { UserEmail } from "../../App";

import { useEffect, useState } from "react";


export default function MentorLogin() {
  const [email, setEmail] = useState(null);
  let mail = UserEmail.Provider;
  console.log(mail)

  useEffect(() => {
    setEmail(UserEmail.Provider.email);
    
  }, [email, mail]);

  console.log(email)   

  if (email !== null) {    
    console.log('logging', email)
    let e = document.getElementById("profileLoginBox");
    
    MentorData(e, email);
  } else {
    return (
      <>
        <div className="rowProfile" id="profileLoginBox">
          <div className="columnProfileLogin">
            <div className="columnCentreLogin">
              <h1 className="profileLoginOne">Sign in!</h1>
              <div className="data">
                <form id="profileLogin" onSubmit={(e) => MentorData(e)}>
                  <label htmlFor="name">Enter Your Full Name</label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    onInput={this}
                  ></input>

                  <input type="submit" placeholder="Submit"></input>
                </form>
                <a href="/mentorSignup" className="profileRegisterHere">
                  Or Register Here
                </a>
                <div id="profileData"></div>
              </div>
            </div>
          </div>
          <div className="columnProfileLogin">
            <div className="columnCentreText">
              <h1 className="profileLogin">Two-step verification</h1>
              <p className="login">
                Two-Step verification is an additional layer of security used to
                ensure only you gain access to your an account information.
                Please enter your Full Name and email. Thank you!
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}