import React, { useState } from "react";
import "firebase/compat/auth";
//import { auth } from 'firebaseui';
import { sendPasswordResetEmail } from "firebase/auth";
import { getAuth } from "firebase/auth";

const auth = getAuth();
const SignIn = () => {
  const SignInWithGoogle = () => {
    var google_provider = new auth.GoogleAuthProvider();
    auth
      .signInWithPopup(google_provider)
      .then((re) => {
      })
      .catch((err) => {
      });
  };

  const [registerEmail, setRegisterEmail] = useState("");

  const forgotPassword = async () => {
    sendPasswordResetEmail(auth, registerEmail);
    return alert('Please check your emails to change your password')
  };

  return (
    <>
      <div className="outer">
        <div className="login-container" id="login-container">
          <div className="form-container log-in-container">
            <form onsubmit="login(event)" className="login">
              <h1>Forgot Password</h1>
              <div className="social-container"></div>
              <span>Email:</span>
              <input
                placeholder="Email..."
                onChange={(event) => {
                  setRegisterEmail(event.target.value);
                }}
              />
              <btn
                type="sumbit"
                className="login-button"
                onClick={forgotPassword}
              >
                <a href="/Educo">Submit</a>
              </btn>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-right">
                <h1 className="login">Welcome to EDUCO!</h1>
                <p className="login">Forgot your password, reset that here!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
