import Historyartist from '../../../../../images/functionality/educo/icons/term-1/AD-history/History-artist.webp'
import Historyhistory from '../../../../../images/functionality/educo/icons/term-1/AD-history/History-history.webp'
import Historymusic from '../../../../../images/functionality/educo/icons/term-1/AD-history/History-music.webp'
import Historypolitics from '../../../../../images/functionality/educo/icons/term-1/AD-history/History-politics.webp'
import Historyscience from '../../../../../images/functionality/educo/icons/term-1/AD-history/History-science.webp'
import Tribalmask from '../../../../../images/functionality/educo/icons/term-1/AD-history/tribal-mask.webp'

const objHistoryAfricanDiaspora = {
    webpagedesc: ``, 
    webpagekeywords: ``, 
    webpagepageurl: ``, 
    webpageimage: ``, 
    webpageimagealt:``, 
    organisationtwitter: ``, 
    title: `History of the African Diaspora`, 
    courseDesc:`
    During the History seminar series, participants will explore the History, the Politics, the Scientists, and the Music of the African Diaspora.
    `, 
    quickLinkOneDesc: `
    Induction seminar
    `, 
    quickLinkOne:`
    /HistoryInductionSeminar
    `,
    quickLinkTwoDesc: `
    Ancient Egyptian Mathematics
    `, 
    quickLinkTwo:`
    /HistoryAncientEgyptianMathematics
    `,
    quickLinkThreeDesc: `
    History Claude McKay
    `, 
    quickLinkThree:`
    /HistoryClaudeMcKay
    `,
    quickLinkFourDesc: `
    Prehistoric Africa
    `, 
    quickLinkFour:`
    /HistoryPrehistoricAfrica
    `,
    quickLinkFiveDesc: `
    Eleventh to Sixteenth Century
    `, 
    quickLinkFive: `
    /HistoryEleventhtoSixteenthCentury
    `, 
    courses: [
        {
            key: 1, 
            sectionimage: Historyartist, 
            sectiontitle: `
            Induction Seminar
            `,
            sectionimageAlt: `Section one of the history of the african diaspora course icon`,
            sectiondesc: `
            In our History of the African Diaspora Induction seminar series, Dr Wayne Dooling, a Senior Lecturer in African History at the School of Oriental and African Studies explored the history of colonial rule in South Africa. In this seminar you'll hear of the early beginnings of the South African Colony through to the end of slavery, and how the Khoikhoi and San peoples were impacted by the slave trade and the apartheid state.
            `, 
            sectionlink: `
            /HistoryInductionSeminarSeries
            `, 
            coursedesc: `
            Click here to watch their Lecture
            `,
            educovideo:
            [
                {
                    key: 1, 
                    videoTitle: 'The history of South Africa', 
                    videoDesc:`Marwa explores the 'The Swahili States', which were a number of major cities on the East African coast between the 9th and 16th centuries.`, 
                    videoInfo: `100 views | 10 days ago | Marwa Haddou`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fhistory%2FHistoryInductionSeminar%2FhistoryInductionSeminarThumbnail.webp?alt=media&token=06a019e4-6be9-4514-b2f7-6ca529ced2f5',
                    videoImageAlt:`
                    the history of South Africa Thumbnail`, 
                    videohref:`/HistoryInductionSeminar`, 
                },
            ],
        }, 
        {
            key: 2, 
            sectionimage: Historyhistory, 
            sectiontitle: `
            The History
            `,
            sectionimageAlt: `Section one of the history of the african diaspora course icon`,
            sectiondesc: `
            From Sangoans and the Afroasiatic Urheimat, through to the fight for independence, you will delve into the continent's rich history. The seminar will be temporally separated into epochs from the continent's history, wherein your groups will explore three influential individuals from each time point, the continent they inhabited, and their historical contributions.
            `, 
            sectionlink: `
            /HistoryInductionSeminarSeries
            `, 
            coursedesc: `
            Click here to watch their Lecture
            `,
            educovideo:
            [
                {
                    key: 1, 
                    videoTitle: 'Ruins of Gedi', 
                    videoDesc:`Marwa explores the 'The Swahili States', which were a number of major cities on the East African coast between the 9th and 16th centuries.`, 
                    videoInfo: `100 views | 10 days ago | Marwa Haddou`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fhistory%2FHistoryRuinsofGedi%2FhistoryRuinsofGediThumbnail.webp?alt=media&token=ab3f9485-d117-4203-97db-50c795c0d6d9',
                    videoImageAlt:`Ruins of Gedi Thumbnail`, 
                    videohref:`/HistoryRuinsofGedi`, 
                },
                {
                    key: 2, 
                    videoTitle: 'Pre-Historic to Ancient Civilisations', 
                    videoDesc:`Jia explores the 'The Swahili States', which were a number of major cities on the East African coast between the 9th and 16th centuries.`, 
                    videoInfo: `100 views | 10 days ago | Jia Yue Ong`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fhistory%2FHistoryPrehistoricAfrica%2FhistoryPrehistoricAfricaThumbnail.webp?alt=media&token=f693d6f7-b112-44ea-aa26-bc332146a5c3',
                    videoImageAlt:`Ruins of Gedi Thumbnail`, 
                    videohref:`/HistoryPrehistoricAfrica`, 
                },
                {
                    key: 3, 
                    videoTitle: '7th to 11th Century', 
                    videoDesc:`Marwa explores the 'The Swahili States', which were a number of major cities on the East African coast between the 9th and 16th centuries.`, 
                    videoInfo: `100 views | 10 days ago | Jia Yue Ong`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fhistory%2FHistorySeventhtotheEleventhCentury%2FhistorySeventhtotheEleventhCenturyThumbnail.webp?alt=media&token=54b46a75-243d-413f-8cc9-44c84f2326f1',
                    videoImageAlt:`Ruins of Gedi Thumbnail`, 
                    videohref:`/HistorySeventhtotheEleventhCentury`, 
                },
                {
                    key: 4, 
                    videoTitle: '11th to 16th Century', 
                    videoDesc:`Marwa explores the 'The Swahili States', which were a number of major cities on the East African coast between the 9th and 16th centuries.`, 
                    videoInfo: `100 views | 10 days ago | Jia Yue Ong`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fhistory%2FHistoryEleventhtoSixteenthCentury%2FhistoryEleventhtoSixteenthCenturyThumbnail.webp?alt=media&token=58e71f70-c7fd-4516-a211-e6fd620c37a0',
                    videoImageAlt:`Ruins of Gedi Thumbnail`, 
                    videohref:`/HistoryEleventhtoSixteenthCentury`, 

                },
            ],
        }, 
        {
            key: 3, 
            sectionimage: Historymusic, 
            sectiontitle: `
            The Music
            `,
            sectionimageAlt: `Section one of the history of the african diaspora course icon`,
            sectiondesc: `
            Africans have had a global influence on music. A note-worthy individual is John Blanke (1501-1511), who was a black musician and features in 'The 1511 Westminster Tournament Roll'. During this seminar, you will be guided through a 'Musical History of Africa', exploring the beats and rhythms of its many differing cultures. Be ready to play some tunes as you have the opportunity to interact with some traditional African instruments.
            `, 
            educovideo:
            [
                {
                    key: 1, 
                    videoTitle: 'From the Mbira to Burna Boy', 
                    videoDesc:`Marwa explores the 'The Swahili States', which were a number of major cities on the East African coast between the 9th and 16th centuries.`, 
                    videoInfo: `100 views | 10 days ago | Marwa Haddou`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fhistory%2FHistoryMbiratoBurnaBoy%2FhistoryMbiratoBurnaBoyThumbnail.webp?alt=media&token=c5f05d84-7948-47da-a86f-ad991d9e7288',
                    videoImageAlt:`From the Mbira to Burna Boy Thumbnail`, 
                    videohref:`/HistoryMbiratoBurnaBoy`, 
                },
            ],
        }, 
        {
            key: 4, 
            sectionimage: Historypolitics, 
            sectiontitle: `
            The Politics
            `,
            sectionimageAlt: `Section one of the history of the african diaspora course icon`,
            sectiondesc: `
            Generations of political activity have culminated in the "freedoms" the African diaspora have today. Here, we will venture through the empires of West Africa in the 11th Century through to the political establishment of the Apartheid state and on to the troubled present. We will end the seminar with a critical analysis of whether the current political systems adopted by the modern African states produced the "freedoms" they were designed to create.
            `, 
            educovideo:
            [
                {
                    key: 1, 
                    videoTitle: 'Burkina Faso and the Songhai Empire', 
                    videoDesc:`Marwa uncovers the politics of the Mossi Constitution in the 11th century, and describe the political arrangement of the Mossi Nation.`, 
                    videoInfo: `100 views | 10 days ago | Marwa Haddou`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fhistory%2FHistoryBurkinaFasoandtheSonghaiEmpire%2FhistoryBurkinaFasoandtheSonghaiEmpireThumbnail.webp?alt=media&token=676e40ec-ebc5-4fb8-a08e-3f56956bc877',
                    videoImageAlt:`Burkina Faso and the Songhai Empire Thumbnail`, 
                    videohref:`/HistoryBurkinaFasoandtheSonghaiEmpire`, 
                },
            ],
        },
        {
            key: 5, 
            sectionimage: Historyscience, 
            sectiontitle: `
            The Science
            `,
            sectionimageAlt: `Section one of the history of the african diaspora course icon`,
            sectiondesc: `
            Africa is home to the world's oldest known archaeoastronomical device, located in the Nabta Playa basin, and was built by the Nubians in 4800  BCE. From the contents Agraigan beginnings, with the Lebombo bone being one of the oldest known mathematical artefacts, to supersymmetry, during this seminar you will delve into the scientific discoveries and contributions made by the African diaspora.
            `, 
            educovideo:
            [
                {
                    key: 1, 
                    videoTitle: 'Ancient Egyptian Mathematics', 
                    videoDesc:`You will discover the contributions the Ancient Egyptians made to mathematics. Here we will explore the Rhind and Golenishchev papyrus, and hear about how the Ancient Egyptians approached mathematics. Enjoy!`, 
                    videoInfo: `100 views | 10 days ago | Marwa Haddou`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fhistory%2FHistoryAncientEgyptianMathematics%2FhistoryAncientEgyptianMathematicsThumbnail.webp?alt=media&token=26e40baf-07ad-4df8-b245-698b557a8745',
                    videoImageAlt:`Ancient Egyptian Math Thumbnail`, 
                    videohref:`/HistoryAncientEgyptianMathematics`, 
                },
            ],
        },
        {
            key: 6, 
            sectionimage: Tribalmask, 
            sectiontitle: `
            The Artists
            `,
            sectionimageAlt: `Section one of the history of the african diaspora course icon`,
            sectiondesc: `
            A generation of firsts. During this seminar, you will explore the artistic contributions made by Africans and their diaspora. The seminar will introduce you to the contributions made by many individuals to all themes of art, with a focus on sculpting, tapestry, and Cubism. You may even have the opportunity to make your own sculptures... this is definitely not a seminar to miss! 
            `, 
            educovideo:
            [
                {
                    key: 1, 
                    videoTitle: 'Claude McKay', 
                    videoDesc:`Marwa explores Jamaican poet, Claude McKay’s ‘The Lynching’. This sonnet is globally known for its literary contribution to the ‘Harlem Renaissance’.`, 
                    videoInfo: `100 views | 10 days ago | Marwa Haddou`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fhistory%2FHistoryClaudeMcKay%2FhistoryClaudeMcKayThumbnail.webp?alt=media&token=8ade4dca-917b-4173-97c6-5e8711a99a52',
                    videoImageAlt:`Claude McKay Thumbnail`, 
                    videohref:`/HistoryClaudeMcKay`, 
                },
                {
                    key: 2, 
                    videoTitle: 'John Agard', 
                    videoDesc:`John Agrad’s ‘Checking Out Me History’ a globally renowned sonnet for identifying the importance of knowing one’s history.`, 
                    videoInfo: `100 views | 10 days ago | Marwa Haddou`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fhistory%2FHistoryJohnAgard%2FhistoryJohnAgardThumbnail.webp?alt=media&token=e7f4a9ad-d525-4ec9-9e9a-287473bda55c',
                    videoImageAlt:`John Agard Thumbnail`, 
                    videohref:`/HistoryJohnAgard`, 
                },
                {
                    key: 3, 
                    videoTitle: 'The Art', 
                    videoDesc:`Jia explores Jamaican poet, Claude McKay’s ‘The Lynching’. This sonnet is globally known for its literary contribution to the ‘Harlem Renaissance’.`, 
                    videoInfo: `100 views | 10 days ago | Marwa Haddou`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fhistory%2FHistoryTheArt%2FhistoryTheArtThumbnail.webp?alt=media&token=b602bd10-42d1-4940-b02d-190a53c73067',
                    videoImageAlt:`The Art Thumbnail`, 
                    videohref:`/HistoryTheArt`, 
                },
            ],
        },
    ]
}


export default objHistoryAfricanDiaspora; 