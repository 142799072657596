const objSocialmediaOfficer = {
  title: "Social Media Officer",
  subtitle: "London or Remote",
  desc: `help create content and promote our various endeavours across our 
        social media platforms.`,
  descFull: ``,
  skills: [
    "Marketing Strategy",
    "Social Media Marketing",
    "Graphic Design",
    "Illustration",
  ],
  where: {
    location: "London, SE12DU",
    desc: "Mainly at home, or remote opportunity.",
  },
  time: {
    workTime: "Either in or out of office hours",
    timeNeeded: "3-7 hours / week",
  },
  whatWillYouBeDoing: {
    p: `The main duties of the role include:`,
    ul: [
      `To assist in the creation of social media content (i.e., static and video 
        posts, stories, etc.)`,
      `To engage with our audiences across all social media platforms (i.e., 
        likes, comments, shares, etc.)`,
      `To help curate content which promotes OTC fundraisers, events, workshops 
        and any other kind of public facing events`,
      `To support the Social Media Team with any creative or communicative tasks`,
      `To work collaboratively in developing paid advertising campaigns`,
      `To support all departments of the charity in relation to front-facing 
        communication (i.e., supporting with proof-reading, etc.)`,
    ],
  },
  whatAreWeLookingFor: {
    p: ``,
    ul: [
      `Excellent multi-channel communication and network building`,
      `Enhancement of independent and team working skills`,
      `Good time management`,
      `Knowledge of Social Media (Instagram, Facebook, LinkedIn, Twitter)`,
      `IT skills (Davinci Resolve, Inkscape, Google Ads) would be a plus`,
    ],
  },
  whatDifferenceWillYouMake: {
    p: `The Social Media officer will have direct impact on the image of the 
        Charity, helping increase its visibility and contributing to its 
        development. The volunteer will be part of an organisation which aims to 
        build a supportive and actualised community, and help build prosperous 
        relationships with the local community.`,
    ul: [],
  },
  whatsInItForTheVolunteer: {
    p: ``,
    ul: [],
  },
};

export default objSocialmediaOfficer;
