import React from "react";
import { useState, useEffect, useRef } from "react";
import { db } from "../../firebase/firebase";
import { ref, onValue, query, get, child, set } from "firebase/database";
import { videoTitles } from "../../components/common/educoCarousel/Media/videoTitles";

export class LikesViews extends React.Component{
  constructor(){
    super(); 
    this.state = {
      likesData: []
    }
  }
  componentWillMount(){
    const dbRef = query(ref(db, "video/name/"));

    let vName;
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot=>{
        let keyName = childSnapshot.key;
        let data = childSnapshot.val();
        records.push({"key": keyName, "data": data}); 
       //console.log(records)
      });
      this.setState({likesData: records});
    })
  }
  render(){
    return(
      <>
          {this.state.likesData.map((row, index)=>{
            if (row.key === videoTitles[0].videoTitle){
             //console.log('success:', row.key)
            }
            return (
              <>
              <p>
                  {row.key}, 
                  {row.data.count}, 
                  {row.data.likes}, 
                  {row.data.videoDesc}, 
              </p>
              <img src = {row.data.videoImage} alt= {row.data.videoImageAlt} />
            </>
            )
          })}

      </>
    )
  }
}
