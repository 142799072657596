const outreach = {
    title: `Industry partnerships`, 
    desc: ` 
    In creating our knowledge-building community, Off the Curriculum is committed to engaging with academic and business institutions. Establishment of these relations will bring about collaboration or knowledge exchange. Off the Curriculum believes involving all stakeholders in the education process will inspire our participants to explore new horizons. 
    The establishment of such relationships will offer Off the Curriculum the opportunity to recruit guest speakers for our live seminar events. Off the Curriculum hopes to foster links with UK-based Universities to recruit our team of student volunteers. Engagement with businesses will provide an opportunity for enrolled participants to explore life after formal 'education' by investigating what the working world has to offer. 
    In addition, volunteers will also have the opportunity to demonstrate their astute skill set to potential employers. This multi-stakeholder community will act as a vehicle for social mobility for both enrolled participants and student-volunteers. While offering business and academic institutions the opportunity to change lives, be an involved member in social mobility, inspire the next generation, increase brand recognition, and directly engage with their target audience. 
    Off the Curriculum will prioritise engagement with institutions in the Lambeth and Southwark community, though we are willing to establish relationships with all interested businesses and academic institutions. This multilateral community is hoped to act as a vehicle for growth and development for all members involved and is not an opportunity to be missed!`,
}

export {
    outreach,
}