import React from "react";
import Conflictsofinterestpolicy from "../Policy-folder-pdf/Conflicts_of_Interest.pdf";

function Conflictsofinterest() {
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <div>
        <h1>Conflicts of Interest policy at Off the Curriculum</h1>
        <iframe
          title ='Our Conflicts of interest policy'
          src={Conflictsofinterestpolicy}
          className="policy"
        ></iframe>
      </div>
    </React.Fragment>
  );
}
export default Conflictsofinterest;
