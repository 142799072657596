const educationPage ={
    title: `
    Education for Social Empowerment
    `, 
    seminarSeriesTitle:`
    Project Seminar Series
    `, 
    seminarSeriesDesc:`
    Seminars at Off the Curriculum are changing, evolving from a guest speaker lead affair into a student-focused project based series. 
    Our repeating five-week Project Seminar Series, will consist of five seminars where you will propose solutions to some of your generations 
    biggest questions. Topics will range from 'Assessing COVID-19's impact on human civilisation', 'Navigating a Post-Truth World', and 'Inequality in the AI age'. 
    An example structure of our Project Seminar Series can be seen below:
    `

}
export {
    educationPage, 
}