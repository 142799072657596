import { useContext } from "react";
import { GetData } from "../../../firebase/firebaseProfileGet";
import { LoginContext } from "../../../App";
import { CreateAProfile } from "./createAProfile";
import { ProfileCollectLogin } from "./ProfileComponents/profileCollect";

export function ProfileLogin() {
  let profileStorage = JSON.parse(localStorage.getItem("profile-storage"));
  
  // Using the google auth context provider
  const UserSignedIn = useContext(LoginContext);

  let sp = document.getElementById("studentProfile");
  let e = sp;

//Ok error it is being stored in a data array - which is causing conditional storing and messing up the load function


  //Load in the profile component from the ListData/
  return (
    <>
          {/*{!UserSignedIn ? <ProfileCollectLogin /> : <CreateAProfile />}*/}
    </>
  )
  
  {/*if (
    profileStorage.state.color !== null
  ) {
    let e = sp;
    ListData(e, profileStorage.state.profile[0].email);
  } else {
    //automatically send them to the set-up profile page section here <create  />
    return (
      <>
      <CreateAProfile />
      </>
    );
  }
*/}; 
}