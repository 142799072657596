import React from "react";
import Bullyingandharassmentpolicy from "../Policy-folder-pdf/Bullying_and_Harassment.pdf";

function Bullyingandharassment() {
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <div>
        <h1>Bullying and Harassment Policy at Off the Curriculum</h1>
        <iframe
          title = 'Our Bullying and harassment policy'
          src={Bullyingandharassmentpolicy}
          className="policy"
        ></iframe>
      </div>
    </React.Fragment>
  );
}
export default Bullyingandharassment;
