const accreditationPage ={
    pageTitle: `
        Accreditation at Off the Curriculum
    `, 
    pageIntro: `
    Off the Curriculum, have created an Accreditation programme to reward students for their continued engagement in, what is honestly, a challenging educational programme. The Accreditation programme consists of Seminar Series Certification; a Digital Badging Scheme and the Off the Curriculum Award.
    More about these three areas of the Accreditation programme can be seen below. 
    `, 
    subHeadingOne: `
    Seminar Series Certificates
    `, 
    subHeadingDesc:`
    Participant's will receive a Seminar Series qualification upon the completion of every series you participate in. To earn such a qualification participants are required to maintain an 80% attendance rate for the Seminar Series, and participate in the assessment session. 
    `, 
    subHeadingTwo:`
    Digital Badging Scheme
    `, 
    subHeadingTwoDesc:`
    To motivate and reward, learning, participation, and achievement, Off the Curriculum has created our Digital Badges Award. In can be argued, although physical badging schemes have been employed for previously mentioned reasons for hundreds of years, online badging schemes originate from online role-playing games, which enabled users to ‘level-up’ by acquiring various virtual attributes or powers, which proved to be a powerful motivator for continued engagement (Manninen and Kujanpaa, 2007). 
    Each seminar series, during our self-determined assessment session, students will have the opportunity to win one of the following Digital Badges: 
    `, 
    digitalBagding: `
    Each series the individual's associated with each award change, below is an example of available badges for the Psychology and Neuroscience seminar series:
    `, 
    subHeadingThree:`
    Off the Curriculum Award
    `, 
    subHeadingThreeDesc:`
    Off the Curriculum is a two-year educational programme and we ask our participants to maintain an 80% attendance rate to receive an Off the Curriculum Award and graduate from the programme. The participant's graduation will be a University-esque graduation ceremony, flush with cap and gowns, and celebrations to boot.
    `
}

export {
    accreditationPage, 
}