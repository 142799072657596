import React from "react";
import SignInBtn from "../signin/SignInBtn";
import { SignInWithGoogle } from "../signin/sign-in-out";
import NavDropdownExample from "../navbar/Navbar";
import "./Header.css";
import SearchBar from "../searchBar/Searchbar";
import { Search } from "../searchBar/Searchbar";

export function Header() {
  function mailProtector() {
    const emailMappings = [
      {
        elementId: "demo", // Assuming the button has an id of "demo"
        email: "enquiries@offthecurriculum.co.uk"
      }
    ];
  
    emailMappings.forEach(({ elementId, email }) => {
      const button = document.getElementById(elementId);
      if (button) {
        button.addEventListener('click', (e) => {
          e.preventDefault(); // Prevent the default button click behavior
          window.location.href = "mailto:" + email; // Open the default email client with the protected email address
        });
      }
    });
  }
  return (
    /*You will not be able to see this text.*/
    <React.Fragment>
      <div className="top-navbar">
        <div className="container-top-navbar">
          <div className="row-top-navbar">
            <a className="navImage" href="/">
              <img
               className="nav"
                src="./icons/otc-icon.svg"
                alt="link to home page"
                aria-label="link to home page"
                title="link to home page"
              ></img>
            </a>
          </div>
        </div>
        <div className="column-top-navbar">
          <div className="row-information-top-navbar">
            <div className="icon-email-top-navbar">
              <div className="topnav_text">
                <span className="bold">
                    Email
                </span>
                <br />
                <span className="nav-information">
                <button className="nav_email button-size" onClick={mailProtector} id="demo" alt="email icon">
                enquiries@offthecurriculum.co.uk
              </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="column-top-navbar">
          <div className="row-information-top-navbar">
           
            <SignInBtn/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
