import Stars from '../../../../../images/functionality/educo/icons/term-1/astronomy/stars.webp'
import Planets from '../../../../../images//functionality/educo/icons/term-1/astronomy/planets.webp'
import Protoplanets from '../../../../../images/functionality/educo/icons/term-1/astronomy/protoplanets.webp'
import Solarsystem from '../../../../../images/functionality/educo/icons/term-1/astronomy/solarsystem.webp'
import Telescope from '../../../../../images/functionality/educo/icons/term-1/astronomy/telescope.webp'
import Moon from '../../../../../images/functionality/educo/icons/term-1/astronomy/moon.webp'

const objMightyMinds = {
    webpagedesc: ``, 
    webpagekeywords: ``, 
    webpagepageurl: ``, 
    webpagethumbnail: ``, 
    webpagethumbnailAlt:``, 
    organisationtwitter: ``, 
    title: 'Mighty Minds', 
    courseDesc: 
        `
        During the Climate Change and Sustainability explored topics such as
        model uncertainty in the context of sea-level rise predictions,
        central banks' role in halting biodiversity loss, and explored the
        effects of Climate Change may have on future generations
        `
    ,
    quickLinkOneDesc:
    `
    One Humanity, One Paradise
    `
    ,
    quickLinkOne: `
    /AstronomyInductionSeminar
    `, 
    quickLinkTwoDesc: 
    `
    Uncertainty Quantification in Climate Systems
    `, 
    quickLinkTwo:
    `
    /AstronomyTheStars
    `, 
    quickLinkThreeDesc:
    `
    Climate Policy
    `, 
    quickLinkThree: 
    `
    /AstronomyDwarfPlanets
    `, 
    quickLinkFourDesc:`
    TheMoons
    `, 
    quickLinkFour: 
    `
    /AstronomyTheMoons
    `, 
    quickLinkFiveDesc: 
    `
    Space Policy
    `, 
    quickLinkFive: 
    `
    /AstronomySpacePolicy
    `, 
    courses:[
    {
        key: 1, 
        sectionimage: Telescope, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        One Humanity, One Paradise
        `, 
        sectiondesc: `
        In this captivating seminar, we will learn about the timespan of
        our universe, from the very beginning to present day, and where
        the story of our Earth begins and progresses along that
        timeline. Dr. Francisco Diego invites discussion and inward
        thinking as he reveals to us how the Earth has been mistreated
        in light of climate change, in a talk that shares an important
        and highly relevant message with the human race.
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
                [
                    {
                        key: 1, 
                        videoTitle: 'A Journey Through The Solar System', 
                        videoDesc:`Why not uncover extraterrestrial missions to Mars, Jupiter and beyond!`, 
                        videoInfo: `100 views | 10 days ago | Teresa Su`, 
                        videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EDUCO%2Fastronomy%2Fteresa-su%2Fthe-solar-system%2FtheSolarSystemTS.png?alt=media&token=c1a9f7f5-abb5-48f3-a952-573b712f167b',
                        videoImageAlt:`A Journey Through The Solar System Thumbnail`, 
                        videohref:`/AstronomyTheSolarSystem`, 
                    },
                    {
                        key: 2, 
                        videoTitle: 'The Mystery of Dark Matter', 
                        videoDesc:`Explore's the term “Dark matter”, and how it composes around 27% of the universe’s energy.`, 
                        videoInfo: `100 views | 10 days ago | Liina Jukko`, 
                        videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EDUCO%2Fastronomy%2Fliina-jukko%2Fthe-mystery-of-Dark-Matter%2FmysterydarkMatter.png?alt=media&token=485b0a64-34da-417f-aff4-a17aebd4910e',
                        videoImageAlt:`The Mystery of Dark Matter Thumbnail`, 
                        videohref:`/AstronomyDarkMatter`, 
                    },
                ]
    }, 
    {
        key: 2, 
        sectionimage: Solarsystem, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        Uncertainty
        Quantification in Climate Systems
        `, 
        sectiondesc: `
        After recently completing a PhD in statistics, Dr. Fiona Turner
        is based at King’s College London, where her current research
        focuses on uncertainty quantification in climate systems. In
        this seminar, Dr. Turner will shed light on the fascinating way
        in which researchers model the climate- with a particular focus
        on modelling the size of the world’s ice sheets and glaciers in
        light of mapping their contributions to sea level.
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'A Journey Through The Solar System', 
                videoDesc:`Why not uncover extraterrestrial missions to Mars, Jupiter and beyond!`, 
                videoInfo: `100 views | 10 days ago | Teresa Su`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EDUCO%2Fastronomy%2Fteresa-su%2Fthe-solar-system%2FtheSolarSystemTS.png?alt=media&token=c1a9f7f5-abb5-48f3-a952-573b712f167b',
                videoImageAlt:`A Journey Through The Solar System Thumbnail`, 
                videohref:`/AstronomyTheSolarSystem`, 
            },
            {
                key: 2, 
                videoTitle: 'The Mystery of Dark Matter', 
                videoDesc:`Explore's the term “Dark matter”, and how it composes around 27% of the universe’s energy.`, 
                videoInfo: `100 views | 10 days ago | Liina Jukko`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EDUCO%2Fastronomy%2Fliina-jukko%2Fthe-mystery-of-Dark-Matter%2FmysterydarkMatter.png?alt=media&token=485b0a64-34da-417f-aff4-a17aebd4910e',
                videoImageAlt:`The Mystery of Dark Matter Thumbnail`, 
                videohref:`/AstronomyDarkMatter`, 
            },
        ]
    }, 
    {
        key: 3, 
        sectionimage: Stars, 
        sectionimageAlt: `
        Climate Policy
        `, 
        sectiontitle: `
        Climate Policy
        `, 
        sectiondesc: `
        Elena is a Policy Analyst at the LSE Grantham Research
        Institute, specialising in biodiversity and central banking.
        Before this, she completed degrees in Economics from the
        University of Sussex and International Management from the
        London School of Economics and Political Science. In this
        seminar, we will explore the role policy development and
        legislation has in mitigating Climate Change.
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'The Stars', 
                videoDesc:`We will explore how Stars evolve from a pre-main sequence star to a Supernova (depending on the Stars size). The rise and death of a Star is an incredibly dramatic episode, one we are sure you are going to enjoy exploring!`, 
                videoInfo: `100 views | 10 days ago | Teresa Su`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EDUCO%2Fastronomy%2Fteresa-su%2Fthe-stars%2FtheStarsThumbnail.png?alt=media&token=ef70cb2d-53b0-4321-a7aa-4eadcdc0a2ee',
                videoImageAlt:`
                videoTitle: 'The Stars Thumbnail`, 
                videohref:`/AstronomyTheStars`, 
            },
            {
                key: 2, 
                videoTitle: 'An Astronomical Object: Black Hole', 
                videoDesc:`According to the theory of general relativity, the fabric of the universe is a 4-dimensional spacetime.`, 
                videoInfo: `100 views | 10 days ago | Liina Jukko`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EDUCO%2Fastronomy%2Fliina-jukko%2Fan-astrophysical-object-black-hole%2FanAstronomicalObjectLJ.png?alt=media&token=97269adf-0e57-4fc8-b0f0-9dbe40c30128',
                videoImageAlt:`An Astronomical Object: Black Hole Thumbnail`, 
                videohref:`/AstronomyBlackHoles`, 
            },
        ]
    }, 
    ],
}

export default objMightyMinds; 
