//import { app } from "../../../firebase/firebase";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
//, getRedirectResult, signInWithRedirect

const Auth = getAuth();

const SignInWithGoogle = () => {
  var google_provider = new GoogleAuthProvider();

  signInWithPopup(Auth, google_provider)
    .then((result) => {


      const credential = google_provider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      //const email = error.customData.email;

      const credential = GoogleAuthProvider.credentialFromError(error);
    });

  const auth = getAuth();
};
export { SignInWithGoogle, signOut };

/* signOut = () => {
  Auth.signOut();
};



// old version//
/*import { firebase } from "../../../firebase/firebase";

const SignInWithGoogle = () => {
  var google_provider = new firebase.auth.GoogleAuthProvider();
  firebase
    .auth()
    .signInWithPopup(google_provider)
    .then((re) => {
     //console.log(re);
    })
    .catch((err) => {
     //console.log(err);
    });
};

const signOut = () => {
  firebase.auth().signOut();
};

export { SignInWithGoogle, signOut };*/

/* signInWithRedirect(auth, google_provider);
    getRedirectResult(auth)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access Google APIs.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;

    // The signed-in user info.
    const user = result.user;

   //console.log(token, user)
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
   //console.log(errorCode, errorMessage,  credential)
  });*/
