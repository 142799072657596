import { ProfileLogin } from "../profileLogIn";
//import { VotingHider, Term } from "../../voting/votinghider";
import { TermOne } from "../../../voting/termOne";
import { CheckForVoted } from "../../../../firebase/getVoting";
import { HandleSubmit } from "../../../voting/termOne";
import "../profile.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
/** neeed to stop this running before checkvoted finished 
 without infinate loop */

import Fileupload from "../../../../components/fileUpload/Fileupload";
import {
  ref,
  onValue,
  query,
  set,
  getDatabase,
  child,
  get,
} from "firebase/database";
import { db } from "../../../../firebase/firebase";
/** need to stop this running before checkvoted finished without infinite loop */

/** Loading the page into a splide*/
import { Splide, SplideSlide } from "@splidejs/react-splide";

import GetQuizResult from "../../../../firebase/firebaseQuizResultGet";
//import WatchHistory from "./WatchHistory/WatchHistory";
import { VideoGet } from "../../../../firebase/firebaseGetStorage";

// reference is the value coming from the database and passed as a prop
export function ProfilePage(reference) {
  /** History page code update */
  const [video, setVideo] = useState(null);
  const [videoName, setVideoName] = useState(null);
  const dbRef = ref(db);

  useEffect(() => {
    if (reference.reference.current !== null) {
      setVideoName(reference.reference.lastViewed);
  
      get(child(dbRef, "video/name/" + reference.reference.lastViewed))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setVideo(data);
          } else {
            // Handle case when snapshot doesn't exist
          }
        })
        .catch((error) => {
          // Handle error
        });
    }
  }, [reference]);
  
  /**End of function update  check in splides 
   * line 132 for further update*/

  

  return (
    <>
      <title>Profile</title>
      <div className="centre-frame" id="form">
        <ProfileLogin />
        <div className="studentProfile" id="studentProfile">
          <div className="studentProfileCenter">
            {/* 
              <h4>Continue watching the seminars </h4>
              <h4>Your accomplishments</h4>
              <h4>Your quiz scores</h4>
              */}

            <Splide
              options={{
                gap: "1rem",
                perMove: 1,
                rows: 2,
                grid: 2,
                perPage: 1,
              }}
            >
              <SplideSlide>
                <div className="votingSection">
                  <h4>Upcoming workshop series!</h4>
                  <div className="votes" id="votes">
                    <p>
                      The upcoming workshop series will be on Saturday, February
                      4<sup>th</sup> 2023, from 10:00 to 13:00; during which you
                      have a choice to explore one of the following topics:
                    </p>
                    <ul>
                      <li>Is history cyclical or linear?</li>
                      <li>Mitigating environmental catastrophe</li>
                      <li>A peoples history</li>
                    </ul>
                    <button
                      type="button"
                      className="votingChecker"
                      onClick={(e) => CheckForVoted(e)}
                    >
                      Click here to see if you have voted!
                    </button>
                  </div>
                  <div className="result" id="result">
                    <div id="votingShow" className="alignment">
                      <TermOne />
                    </div>
                    <div id="votingDone" className="alignment">
                      <h4>Congratulations!</h4>
                      <p>
                        Congrats you have already voted for the topic of the
                        upcoming series, please wait till next term to vote
                        again! Please ensure you registered your attendance, by
                        signing up{" "}
                        <Link to="/WorkshopOne" className="votingEducoLink">
                          here.
                        </Link>
                        <br />
                        In the meantime, why not check out our fantastic{" "}
                        <Link to="/Educo" className="votingEducoLink">
                          Educo content.
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="votingSection">
                  <h4>EDUCO History</h4>

                  {/**  history updates  */}

                  <h1>Continue watching:</h1>
                  {video && (
                    <>
                      <img
                        src={video["videoImage"]}
                        className="watchHistory"
                        alt={video["videoImageAlt"]}
                      />
                      <br />
                      <span id="views">Views:{video["count"]}</span>
                      <span>Likes:{video["likes"]}</span>
                      <p className="watchHistory">{video["videoDesc"]}</p>
                      <a
                        href={"/" + video["videohref"]}
                        className="watchHistory"
                      >
                        <button className="watchHistoryButton">
                          Watch here...
                        </button>
                      </a>
                    </>
                  )}
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="votingSection">
                  <h4>EDUCO Analytics</h4>
                  <GetQuizResult />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="votingSection">
                  <h4>Extended Project Qualification</h4>
                  <h5>
                    <b>Milestone:</b> Review of your final draft!
                  </h5>
                  <h6>
                    <b>Deadline:</b> February 3<sup>rd</sup>, 2023
                    <br />
                    <b>Feedback received by:</b> February 24<sup>th</sup>, 2023
                  </h6>
                  <div className="EPQ">
                    <p className="profileDesc">
                      The upcoming milestone in your EPQ journey is to circulate
                      your final draft to your mentor for their reviewal. To do
                      so, please upload a '.pdf' version of your final draft
                      using the below widget.
                    </p>
                  </div>
                  <div id="epq" className="epqMain">
                    <Fileupload />
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </div>
    </>
  );
}