const objWorkShopOne = {
    eventDate: `Saturday, September, 23rd 2023`,
    title: 'Cosmic Horizons',
    imgSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Seminar-series%2FCosmicHorizons%2FWebsiteLandingPage%2FEventRegistrationPage%2FCosmic%20Horizons%20Our%20Ever-Expanding%20Universe.webp?alt=media&token=3049cb0a-1426-4a02-be2a-2cf113e40a92`,
    subtitle: 'Our Ever-Expanding Universe',
    shortDesc: `
    Are you curious about the secrets of the Universe and how it all works? It's amazing how all the technologies we use today are actually part of the magic shows that astronomers put on.In this one-day project seminar series, you will deep dive into Astronomy, Cosmology, and Artificial Intelligence; hearing from experts in the field and interacting with budding experts from London based Universities, e.g., Imperial College London, University College London, and King's College London. To find out more about our full-day project seminar series - read more below. 
    `,
    guestSpeaker: `Guest Speakers: Professor Richard Ellis, Dr. Luca Franci, Dhaval Shah MSc, and Dr. Joseph Levermore `,
    location: `Anthony de Rothschild Lecture Theatre, St Marys Campus, Imperial College London, W2 1NY`,
    time: 'Saturday, September 23rd, 2023 |  10:00 - 15:30',
    embeddedMap:`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1512.407914952127!2d-0.1754029640962397!3d51.51724122406897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc3a08fced25f6d88!2zNTHCsDMxJzAzLjYiTiAwwrAxMCcyMi40Ilc!5e0!3m2!1sen!2suk!4v1670673309862!5m2!1sen!2suk
    `,
    eventSummary: `
    Event Summary
    `,
    fulldesc: `Are you curious about the secrets of the Universe and how it all works? Homo sapiens throughout time have gazed at the stars and wondered what was out there. From Eratosthenes to Edwin Hubble people have attempted to uncover the mysteries of the Universe. Have you ever wondered about the mysteries it may hold? The good news Off the Curriculum has launched an Astrophysics Masterclass to teach you about the wonders of Space. Bringing the magic of astronomy right to your fingertips. To confirm your place, register by clicking the pink button on-screen and filling out the google form!`,
    aboutEvent: `About this event`,
    registrationPage: `/CosmicHorizonsRegistration`,
    eventDescription: [
      {
        key: 1,
        sectionTitle: `
        About our guest speakers
        `,
        sectionText: `
        <b>Professor Richard Ellis</b>, has had a diverse career in astrophysics, starting with my BSc in Astronomy from UCL in 1971. Following my DPhil at Oxford, where he researched stellar atmospheres and astronomy instrumentation, he joined the University of Durham, establishing research in extragalactic astronomy. Later, he became a Professor of Astronomy at Durham, served as Plumian Professor at Cambridge, and directed the Institute of Astronomy. After 16 years there, he returned to Europe with an ERC Advanced Research Grant, spending time at ESO before becoming a Professor of Astrophysics at UCL in 2017.
        <br />
        <b>Dr. Luca Franci</b>, I am a computational astrophysicist with research interests in Solar Physics, Space Plasma Physics, and High Performance Computing (HPC).
        <br />
        <b>Dhaval Shah</b> is an Astrophysics Expert Panel member at Off the Curriculum. I have completed my MSc in Data Intensive Astrophysics from Cardiff University. I am currently carrying out my research work on multi-messenger gravitational waves. During my spare time, I look at the sky and get lost for hours.
        <br />
        `,
      },
      {
        key: 2,
        sectionTitle: `
        Who are we
        `,
        sectionText: `
        We at OTC aim to aid young people along their academic journey by providing them access to leading intellectuals in the form of weekly seminar series and an online educational portal, Educo. The topics nominated and explored during our educational programme are student-determined, decided via a democratic vote. In our second series, we have established the one-day project seminar series events which will condense four educational sessions into one-day. Each one-day project seminar series will be full catered and being hosted at Imperial College London's St Mary's Campus. In the upcoming series, you have the choice of either 'Is history cyclical or linear'; 'Mitigating environmental catastrophe'; and 'A people's history'. Vote via your Profile page! 
        `,
      },
      {
        key: 3,
        sectionTitle: `
        Our programme
        `,
        sectionText: `
        OTC presently works with Sixth Form students, ages 15 to 18, across London. We provide Sixth Form students from low-socioeconomic backgrounds with the opportunity to interact with experts with expertise aligned to the chosen topic. If you are interested in joining February's first one-day project series, please register using the hovering box to the right of your screen!! We look forward to seeing you on Saturday February 4th 2023. 
        `,
      }
    ],
    registerHere: `
    Why not register today!
    `,
    whatWillYouBeDoing: {
      p: `The main duties of the role:`,
      ul: [
        `To conceptualise ideas for characters, scenes, backgrounds and other animation elements`,
        `To edit and create 2D or 3D or computer-generated images of our educational videos for Educo`,
        `To streamline all future development of footage, making the processes more efficient with time`,
        `To aid in the animation and creation of a variety of videos for Educo`,
        `To support the film crew and other creatives including designers and photographers  in the production of high-quality content`,
        `To ensure all content produced and made public on our social media and website is visually engaging and of top calibre`,
        `To collaborate with our Social Media team, to generate posts for Instagram, LinkedIn, Twitter, YouTube on a regular basis and engage with our audience`,
      ],
    },
    whatAreWeLookingFor: {
      p: ``,
      ul: [
        `Proficiency using Davinci Resolve, with the skills across other animation software`,
        `Excellent multi-channel communication, knowledge of principles of animation including staging, timing, ease in/out, anticipation`,
        `Independent and team skills`,
        `Good time management, with specific attention to detail`,
      ],
    },
    whatDifferenceWillYouMake: {
      p: `Through their role at OTC, the volunteer will build prosperous 
          relationships with the local community and all those involved with the 
          charity, be part of an organisation that aims to build a supportive and 
          actualised community and receive the relevant inductions, guidance, 
          training & support for the particular role. Since the majority of the 
          role is online, the candidate will have full support to carry out their 
          responsibilities in a flexible way and a manner of their choosing.`,
      ul: [],
    },

    whatsInItForTheVolunteer: {
      p: ``,
      ul: [],
    },
  };
  
  export default objWorkShopOne;
  