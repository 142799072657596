import "./App.css";

import React, {
  useState,
  useEffect,
  createContext,
  useLayoutEffect,
  useContext,
  useRef,
} from "react";
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import Home from "./Home";
//test

import { QuizMain } from "./components/quizzes/Quiz";
import { QuizTemplate } from "./components/quizzes/quizPapers/quizTemplate/quizTemplate";

/*Education */
import Educo from "./pages/educo";
import ShortsDiv from "./pages/educo/Components/shortsDiv/shortsDiv";
import TrialProfile from "./pages/educo/profile/MainPage/TrialProfile";

import Education from "./pages/main/education/Education";
import Partneredsixthforms from "./pages/main/education/Partneredsixthforms";
import Programme from "./pages/main/education/Programme";
import EPQ from "./pages/main/education/EPQ";
import Enrol from "./pages/main/education/Enrol";
import Wellbeingprogramme from "./pages/main/education/Wellbeingprogramme";
import Programme_testimonial from "./pages/main/education/Programme-testimonial";
import Accreditation from "./pages/main/education/Accreditation";


import EducoPageTemplate from "./pages/educo/Components/educoPage/EducoPageTemplate";
import CourseTemplate from "./pages/educo/Components/coursePage/EducoCourseTemplate";
import RegistrationTemplate from "./components/common/events/RegistrationPortal/RegistrationTemplatePage";

/** end of Education */

/*Events*/
import EventTemplate from "./components/common/events/Pages/EventTemplate";
import { LikesViews } from "./pages/trailPage/trial";
/*About folder*/
import Ourmotto from "./pages/main/about-us/Ourmotto";
import Theteam from "./pages/main/about-us/Theteam";
import Ourtrustees from "./pages/main/about-us/Ourtrustees";

/*Outreach*/
import Collaborations from "./pages/main/Outreach/Collaborations";

/*News*/
import News from "./pages/main/about-us/News";
import Trusteesatotc from "./pages/main/about-us/News/Trusteesatotc";
import Educovolunteers from "./pages/main/about-us/News/Educovolunteers";
import Learningtolearn from "./pages/main/about-us/News/Learningtolearn";
import Mindfulness from "./pages/main/about-us/News/Mindfulness";
import YoungBlackAndNeurodiverse from "./pages/main/about-us/News/YoungBlackAndNeurodiverse";
import AdviceOnPreparingForExaminations from "./pages/main/about-us/News/AdviceOnPreparingForExaminations";
import AdviceforYoungPeopleHowtoBestPrepareforExams from "./pages/main/about-us/News/AdviceforYoungPeopleHowtoBestPrepareforExams";
import AdviceforYoungPeopleStudingforATwoHourPeriod from "./pages/main/about-us/News/AdviceforYoungPeopleStudingforATwoHourPeriod";
import AdviceforYoungPeopleBestRevisionTechniques from "./pages/main/about-us/News/AdviceforYoungPeopleBestRevisionTechniques";
import AdviceforYoungPeopleWriteAGreatAlevelEssay from "./pages/main/about-us/News/AdviceforYoungPeopleWriteAGreatAlevelEssay";
import AdviceforYoungPeopleBreathWorkAndMeditation from "./pages/main/about-us/News/AdviceforYoungPeopleBreathWorkAndMeditation";
import AdviceforYoungPeopleGuidedMeditation from "./pages/main/about-us/News/AdviceforYoungPeopleGuidedMeditation";
import AdviceforYoungPeoplePowerOfSelfGratitude from "./pages/main/about-us/News/AdviceforYoungPeoplePowerOfSelfGratitude";
import NovusDeus from "./pages/main/about-us/News/NovusDeus";

/*Volunteering*/
import Careers from "./pages/main/volunteering/Careers";
import Training from "./pages/main/volunteering/Training";
import Volunteering from "./pages/main/volunteering/Volunteering";

/*Doncations*/
import Donate from "./pages/main/donations/Donate";

/*Policies*/
import Policies from "./pages/main/policies/Policies";
import Saferecruitment from "./pages/main/policies/Policy/Saferecruitment";
import Bullyingandharassment from "./pages/main/policies/Policy/Bullyingandharassment";
import Codeofbehaviour from "./pages/main/policies/Policy/Codeofbehaviour";
import Trusteeprivacy from "./pages/main/policies/Policy/Trusteeprivacy";
import Conflictsofinterest from "./pages/main/policies/Policy/Conflictsofinterest";
import Equalopportunities from "./pages/main/policies/Policy/Equalopportunities";
import Reimbursementofexpensesandpurchases from "./pages/main/policies/Policy/Reimbursementofexpensesandpurchases";
import Whistleblowing from "./pages/main/policies/Policy/Whistleblowing";
import Safeguardingyoungpeople from "./pages/main/policies/Policy/Safeguardingyoungpeople";
import Socialetiquetteinonlinegatherings from "./pages/main/policies/Policy/Socialetiquetteinonlinegatherings";
import Supervision from "./pages/main/policies/Policy/Supervision";
import Trusteecontract from "./pages/main/policies/Policy/Trusteecontract";
import Trusteedeclarationforfitandproperpersons from "./pages/main/policies/Policy/Trusteedeclarationforfitandproperpersons";
import Trusteedeclarationonappointment from "./pages/main/policies/Policy/Trusteedeclarationonappointment";

/*Careers platform*/
import CareerTemplate from "./pages/main/volunteering/Careers-platform/CareerTemplate";
import BecomeAnOfficer from "./pages/main/volunteering/Careers-platform/BecomeAnOfficer";
import NotFound from "./pages/main/NotFound";

/** student profile components */
import Student from "./pages/studentIndex/Student";
import { CreateAProfile } from "./pages/educo/profile/createAProfile";
import { Profile } from "./pages/educo/profile/profile";
import { VideoSort } from "./components/common/videoStorage/videos";

import Congrats from "./pages/educo/profile/congrats";
import { ChangeProfile } from "./pages/educo/profile/changeProfile";
import "./pages/educo/profile/profileVoting.css";
import "./pages/educo/profile/profile.css";
/** end of student components */

/** mentor pages components */
import MentorProfile from "./pages/mentor/mentorProfile";
import MentorSignUp from "./pages/mentor/mentorSignUp";
import "./pages/mentor/mentor.css";
/**End of mentor components */

/**tools components */
import MentorLogin from "./pages/mentor/mentorLogin";
import Comments from "./pages/CommentsPageForm/CommentsForm";
import SignIn from "./SignIn";
import Registerhere from "./Registerhere";
import Forgotpassword from "./Forgotpassword";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Header } from "./components/common/header/Header";
import { Footer } from "./components/common/index";
import { NavDropdownExample } from "./components/common/navbar/Navbar";
import GA4React from "ga-4-react";
import "./components/common/navbar/Navbar.css";
import { VideoCheckPlays } from "./components/common/checkVideoPlays/videoPlays";

/*EDUCO Load */
import TrialEducoPageTemplate from "./pages/educo/Components/educoPage/trialPageTemplate";
import TrainingVideoTemplate from "./pages/educo/Components/TrainingVideos/TrainingVideoTemplate";

/**end of tools components */
import RadarPage from "./pages/educo/Components/RadarPlot/RadarPage";
import RecommendationEngine from "./pages/educo/Components/RecommendationEngine/RecommendationEngine";
import AdminPage from "./pages/Admin/CharityOfficials/admin";
import { EPQChatBox } from "./pages/educo/profile/MainPage/TrialProfile";
import { useFirebaseData } from "./pages/educo/profile/MainPage/firebaseData";
import { UserDetails } from "./firebase/getUser";

export const LoginContext = createContext();



const ga4react = new GA4React("G-18FPSPY08Q", {debug_mode: true});
function ScrollToTop({ children }) {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}

export const UserEmail = createContext();


function App() {
  const [isUserSignedIn, setIsUserSignedIn] = useState(true);
  const [email, setEmail] = useState("");
  const auth = getAuth();

// google analytics
  useEffect(() => {
    ga4react.initialize().then(
      (ga4) => {
        ga4.pageview("path");
        ga4.gtag("pageview", "path"); // or your custom gtag event
      },
      (err) => {
      }
    );
  });

  // Logs the current user out when page is closed. 
  //auth.setPersistence(auth.Auth.Persistence.SESSION)
  //.then(() => {
  //  return ;
  // })
  // use onValue to create routes based on firebase database entries
  const usePathname = () => {
    const location = useLocation();
    return location.pathname 
  }


  onAuthStateChanged(auth, (user) => {
    if (user) {
      setEmail(user.email);
      UserEmail.Provider = user.email;
      return setIsUserSignedIn(true);      
    }
    setIsUserSignedIn(false);
  });

      if (isUserSignedIn === true) {
      return (
        <ScrollToTop>
          <LoginContext.Provider value={isUserSignedIn}>
            <Header />
            <NavDropdownExample />
            <VideoCheckPlays />
            <Routes>
              {/*If signed in*/}
              <Route path="/RecommendationEngine" element={<RecommendationEngine />} />
              <Route path="/Profile" element={<TrialProfile />} />
              {/*<Route path="/Trial" element={<LikesViews />} />*/}
              <Route
              path="/AdminPage"
              element={<AdminPage />}
              />
              <Route path="/QuizTemplate" element={<QuizTemplate />} />
              <Route path="/Quiz" element={<QuizMain />} />
              <Route path="/congrats" element={<Congrats />} />
              <Route path="/Educo" element={<Educo />} />
              <Route path="/Registerhere" element={<Educo />} />
              <Route path="/" element={<Home />} />
              <Route path="/Student" element={<Student />} />
              <Route
                path="/createAProfile"
                exact
                element={<CreateAProfile />}
              />  
              <Route path="/mentorProfile" exact element={<MentorProfile />} />
              <Route path="/mentorSignUp" exact element={<MentorSignUp />} />
              <Route path="/mentorLogin" exact element={<MentorLogin />} />
              <Route path="/Comments" element={<Comments />} />
              <Route path="/profile" exact element={<Profile />} />
              <Route path="/video" exact element={<VideoSort />} />
              <Route path="/changeProfile" element={<ChangeProfile />} />
              <Route path="/CosmicHorizonsRegistration" 
            element={<RegistrationTemplate name={'objAstrophysicsRegistration'} />}/> 
            <Route
              path="/AstrophysicsMasterClass"
              element={<EventTemplate name="objWorkShopOne" />}
            />
              {/*a Courses*/}
              <Route
                path="/Astronomy"
                element={<CourseTemplate name={"objAstronomy"} />}
              />
                <Route
                path="/ArtificialIntelligenceRobotics"
                element={
                  <CourseTemplate name={"objArtificialIntelligenceRobotics"} />
                }
              />
              <Route
                path="/Business"
                element={<CourseTemplate name={"objBusiness"} />}
              />
                <Route
                path="/ClimateChange"
                element={<CourseTemplate name={"objClimateChange"} />}
              />
              <Route
                path="/Immunology"
                element={<CourseTemplate name={"objImmunology"} />}
              />
              <Route
                path="/PsychologyNeuroscience"
                element={<CourseTemplate name={"objPsychologyNeuroscience"} />}
              />
              <Route
                path="/HistoryAfricanDiaspora"
                element={<CourseTemplate name={"objHistoryAfricanDiaspora"} />}
              />
              <Route
                path="/Music"
                element={<CourseTemplate name={"objMusic"} />}
              />
              <Route
                path="/Politics"
                element={<CourseTemplate name={"objPolitics"} />}
              />
              <Route
                path="/MightyMinds"
                element={<CourseTemplate name={"objMightyMinds"} />}
              />
              {/*EDUCO Pages*/}
            <Route
                path="/AstronomyBlackHoles"
                element={<TrialEducoPageTemplate name={"An Astronomical Object: Black Hole"} />}
              />
              <Route
                path="AstronomyDarkMatter"
                element={<TrialEducoPageTemplate name={"The Mystery of Dark Matter"} />}
              />
              <Route
                path="AstronomyDwarfPlanets"
                element={
                  <TrialEducoPageTemplate name={"Dwarf Planet"} />
                }
              />
              <Route
                path="AstronomyInductionSeminar"
                element={
                  <TrialEducoPageTemplate name={"Searching For Life On Mars and The Story of the Axion: Astronomy"} />
                }
              />
              <Route
                path="AstronomySpacePolicy"
                element={<TrialEducoPageTemplate  name={"Space Policy"} />}
              />
              <Route
                path="AstronomyTheMoons"
                element={<TrialEducoPageTemplate name={"The Moons"} />}
              />
              <Route
                path="AstronomyThePlanets"
                element={<TrialEducoPageTemplate name={"The Planets"} />}
              />
              <Route
                path="AstronomyTheSolarSystem"
                element={
                  <TrialEducoPageTemplate name={"The Solar System "} />
                }
              />
              <Route
                path="AstronomyTheStars"
                element={<TrialEducoPageTemplate name={"The Stars"} />}
              />
              <Route
                path="/ImmunologyInductionSeminar"
                element={
                  <TrialEducoPageTemplate name={"Immunology 101"} />
                }
              />
              <Route
                path="/ImmunologyAllThingsViral"
                element={
                  <TrialEducoPageTemplate name={"Viruses: All things viral"} />
                }
              />
              <Route
                path="/ImmunologyInnateImmunityPhagocytosis"
                element={
                  <TrialEducoPageTemplate
                    name={"Innate Immunity Phagocytosis"}
                  />
                }
              />
              <Route
                path="/PsychologyNeuroscienceBrainComputerInterface"
                element={
                  <TrialEducoPageTemplate
                    name={"Brain Computer Interface and Neuralink"}
                  />
                }
              />
              <Route
                path="/PsychologyNeuroscienceDopamineAndMotivation"
                element={
                  <TrialEducoPageTemplate
                    name={"Dopamine and Motivation"}
                  />
                }
              />
              <Route
                path="/PsychologyNeuroscienceDopamineAndReward"
                element={
                  <TrialEducoPageTemplate
                    name={"Dopamine - A Neurotransmitter"}
                  />
                }
              />
              <Route
                path="/PsychologyNeuroscienceInductionSeminar"
                element={
                  <TrialEducoPageTemplate
                    name={"Neuroscience of Brain plasticity"}
                  />
                }
              />
              <Route
                path="/PsychologyNeuroscienceParkinsons"
                element={
                  <TrialEducoPageTemplate
                    name={"Parkinsons"}
                  />
                }
              />
              <Route
                path="/PsychologyNeuroscienceUnlockingthesecretsoftheHumanBrain"
                element={
                  <TrialEducoPageTemplate
                    name={
                      "Unlocking the secrets of the human brain"
                    }
                  />
                }
              />
              <Route
                path="/PsychologyNeuroscienceResilienceandMentalHealth"
                element={
                  <TrialEducoPageTemplate
                    name={"Resilience"}
                  />
                }
              />
              <Route
                path="/PsychologyNeuroscienceNeurologicalAnatomyandConditions"
                element={
                  <TrialEducoPageTemplate
                    name={"Neurological Anatomy and Conditions"}
                  />
                }
              />
              <Route
                path="/PsychologyNeuroscienceHumanBehaviour"
                element={
                  <TrialEducoPageTemplate
                    name={"Neurodiversity and Neurodivergence"}
                  />
                }
              />
              <Route
                path="/PsychologyNeuroscienceResearchSymposium"
                element={
                  <TrialEducoPageTemplate
                    name={"From Addiction to Dopamine: A research symposium"}
                  />
                }
              />
              <Route
                path="/PsychologyNeuroscienceBrainPlasticity"
                element={
                  <TrialEducoPageTemplate
                    name={"Brain Plasticity"}
                  />
                }
              />
              <Route
                path="/HistoryAncientEgyptianMathematics"
                element={
                  <TrialEducoPageTemplate
                    name={"Ancient Egyptian Mathematics"}
                  />
                }
              />
              <Route
                path="/HistoryClaudeMcKay"
                element={<TrialEducoPageTemplate name={"Claude McKay"} />}
              />
              <Route
                path="/HistoryInductionSeminar"
                element={
                  <TrialEducoPageTemplate name={"The history of South Africa"} />
                }
              />
              <Route
                path="/HistoryJohnAgard"
                element={<TrialEducoPageTemplate name={"John Agard"} />}
              />
              <Route
                path="/HistoryMbiratoBurnaBoy"
                element={
                  <TrialEducoPageTemplate name={"From the Mbira to Burna Boy"} />
                }
              />
              <Route
                path="/HistoryPrehistoricAfrica"
                element={
                  <TrialEducoPageTemplate name={"Pre-Historic to Ancient Civilisations"} />
                }
              />
              <Route
                path="/HistoryRuinsofGedi"
                element={<TrialEducoPageTemplate name={"Ruins of Gedi"} />}
              />
              <Route
                path="/HistorySeventhtotheEleventhCentury"
                element={
                  <TrialEducoPageTemplate
                    name={"7th to 11th Century"}
                  />
                }
              />
              <Route
                path="/HistoryBurkinaFasoandtheSonghaiEmpire"
                element={
                  <TrialEducoPageTemplate
                    name={"Burkina Faso and the Songhai Empire"}
                  />
                }
              />
              <Route
                path="/HistoryEleventhtoSixteenthCentury"
                element={
                  <TrialEducoPageTemplate
                    name={"11th to 16th Century"}
                  />
                }
              />
              <Route
                path="/HistoryTheArt"
                element={<TrialEducoPageTemplate name={"The Art"} />}
              />
              <Route
                path="/ClimateChangeCentralBanking"
                element={
                  <TrialEducoPageTemplate name={"Climate Policy"} />
                }
              />
              <Route
                path="/ClimateChangeUncertaintyQuantification"
                element={
                  <TrialEducoPageTemplate
                    name={"Uncertainty Quantification"}
                  />
                }
              />
              <Route
                path="/ClimateChangeOneHumanityOneParadise"
                element={
                  <TrialEducoPageTemplate
                    name={"One paradise, One humanity"}
                  />
                }
              />
              <Route
                path="/MusicInductionSeminar"
                element={
                  <TrialEducoPageTemplate name={"Interview with the Queen's Master of Music"} />
                }
              />
              <Route
                path="/ArtificialIntelligencePrivacyandSurveillanceEthics"
                element={
                  <TrialEducoPageTemplate
                    name={
                      "Privacy and Surveillance Ethics in AI and Robotics"
                    }
                  />
                }
              />
              <Route
                path="/ArtificialIntelligenceRoboticsInEveryDayLifePartOne"
                element={
                  <TrialEducoPageTemplate
                    name={
                      "AI and Robotics In Every Day Life Part One"
                    }
                  />
                }
              />
              <Route
                path="/ArtificialIntelligenceRoboticsInEveryDayLifePartTwo"
                element={
                  <TrialEducoPageTemplate
                    name={
                      "AI and Robotics In Every Day Life Part Two"
                    }
                  />
                }
              />
              <Route
                path="/ArtificialIntelligenceEthicsofAIAndRobitics"
                element={
                  <TrialEducoPageTemplate
                    name={"Ethics of AI and Robotic Technology"}
                  />
                }
              />
              <Route
                path="/ArtificialIntelligenceMakingAVerySimpleAIPartOne"
                element={
                  <TrialEducoPageTemplate
                    name={"Making a Very Simple AI Part One"}
                  />
                }
              />
              <Route
                path="/ArtificialIntelligenceMakingAVerySimpleAIPartTwo"
                element={
                  <TrialEducoPageTemplate
                    name={"Making a Very Simple AI Part Two"}
                  />
                }
              />

              <Route
                path="/PoliticsMarxism"
                element={<TrialEducoPageTemplate name={"Marxism"} />}
              />
              <Route
                path="/PoliticsEgalitarianism"
                element={
                  <TrialEducoPageTemplate name={"Egalitarianism"} />
                }
              />
              <Route
                path="/PoliticsComparativeGovernment"
                element={
                  <TrialEducoPageTemplate
                    name={"Comparative Government"}
                  />
                }
              />
              <Route
                path="/PoliticsCriticalTheory"
                element={
                  <TrialEducoPageTemplate name={"Politics And Critical Theory"} />
                }
              />
              <Route
                path="/PoliticsPrivateProperty"
                element={
                  <TrialEducoPageTemplate name={"Private Property"} />
                }
              />

              <Route
                path="/BusinessFoundingAStartUp"
                element={
                  <TrialEducoPageTemplate name={"Founding a 'Start up"} />
                }
              />
              <Route
                path="/MightyMindsShinrinYoku"
                element={
                  <TrialEducoPageTemplate name={"Shinrin Yoku"} />
                }
              />
              {/*Training*/}
              <Route
                path="/EPQTraining"
                element={
                  <TrainingVideoTemplate name={"EPQTrainingVideo"} />
                }
              />

              {/*About us*/}
              <Route path="/Ourmotto" element={<Ourmotto />} />
              <Route path="/Theteam" element={<Theteam />} />
              <Route path="/Ourtrustees" element={<Ourtrustees />} />
              {/*News*/}
              <Route path="/News" element={<News />} />
              <Route path="/Trusteesatotc" element={<Trusteesatotc />} />
              <Route path="/Educovolunteers" element={<Educovolunteers />} />
              <Route path="/Learningtolearn" element={<Learningtolearn />} />
              <Route path="/Mindfulness" element={<Mindfulness />} />
              <Route path="/YoungBlackAndNeurodiverse" element={<YoungBlackAndNeurodiverse />} />
              <Route path="/AdviceOnPreparingForExaminations" element={<AdviceOnPreparingForExaminations />} />
              <Route path="/AdviceforYoungPeopleHowtoBestPrepareforExams" element={<AdviceforYoungPeopleHowtoBestPrepareforExams />} />
              <Route path="/AdviceforYoungPeopleStudingforATwoHourPeriod" element={<AdviceforYoungPeopleStudingforATwoHourPeriod />} />
              <Route path="/AdviceforYoungPeopleBestRevisionTechniques" element={<AdviceforYoungPeopleBestRevisionTechniques />} />
              <Route path="/AdviceforYoungPeopleWriteAGreatAlevelEssay" element={<AdviceforYoungPeopleWriteAGreatAlevelEssay />} />
              <Route path="/AdviceforYoungPeopleBreathWorkAndMeditation" element={<AdviceforYoungPeopleBreathWorkAndMeditation />} />
              <Route path="/AdviceforYoungPeopleGuidedMeditation" element={<AdviceforYoungPeopleGuidedMeditation />} />
              <Route path="/AdviceforYoungPeoplePowerOfSelfGratitude" element={<AdviceforYoungPeoplePowerOfSelfGratitude />} />
              {/*<Route path="/NovusDeus" element={<NovusDeus />} />*/}

              
              {/*Education*/}
              <Route path="/Education" element={<Education />} />
              <Route path="/EPQ" element={<EPQ />} />
              <Route path="/Enrol" element={<Enrol />} />
              <Route
                path="/Wellbeingprogramme"
                element={<Wellbeingprogramme />}
              />
              <Route path="/Programme" element={<Programme />} />
              <Route path="/Accreditation" element={<Accreditation />} />
              <Route path="/Educo" element={<Educo />} />
              <Route
                path="/Partneredsixthforms"
                element={<Partneredsixthforms />}
              />
              <Route
                path="/Programme_testimonial"
                element={<Programme_testimonial />}
              />
              {/*Volunteering*/}
              <Route path="/Careers" element={<Careers />} />
              <Route path="/Training" element={<Training />} />
              <Route path="/Volunteering" element={<Volunteering />} />
              <Route
                path="/recruitmentOfficer"
                element={<CareerTemplate name="objRecruitmentOfficer" />}
              />
              <Route
                path="/socialmediaOfficer"
                element={<CareerTemplate name="objSocialmediaOfficer" />}
              />
              <Route
                path="/policyOfficer"
                element={<CareerTemplate name="objPolicyOfficer" />}
              />
              <Route
                path="/britishSignLangOfficer"
                element={<CareerTemplate name="objBritishSignLangOfficer" />}
              />
              <Route
                path="/fundraisingOfficer"
                element={<CareerTemplate name="objFundraisingOfficer" />}
              />
              <Route
                path="/outreachOfficer"
                element={<CareerTemplate name="objOutreachOfficer" />}
              />
              <Route
                path="/webdesignOfficer"
                element={<CareerTemplate name="objWebdesignOfficer" />}
              />
              <Route
                path="/aminationOfficer"
                element={<CareerTemplate name="objAnimationOfficer" />}
              />
              <Route
                path="/videographyOfficer"
                element={<CareerTemplate name="objVideographyOfficer" />}
              />
              <Route
                path="/ImmunologyVolunteer"
                element={<CareerTemplate name="objImmunologyVolunteer" />}
              />
              <Route
                path="/PsychologyVolunteer"
                element={<CareerTemplate name="objPsychologyVolunteer" />}
              />
              <Route
                path="/AstrophysicsVolunteer"
                element={<CareerTemplate name="objAstrophysicsVolunteer" />}
              />
              <Route
                path="/MusicVolunteer"
                element={<CareerTemplate name="objMusicVolunteer" />}
              />
              <Route
                path="/HistoryVolunteer"
                element={<CareerTemplate name="objHistoryVolunteer" />}
              />
              <Route
                path="/ClimateVolunteer"
                element={<CareerTemplate name="objClimateVolunteer" />}
              />
              <Route
                path="/BusinessVolunteer"
                element={<CareerTemplate name="objBusinessVolunteer" />}
              />
              <Route
                path="/HistoryoflangVolunteer"
                element={<CareerTemplate name="objHistoryoflangVolunteer" />}
              />
              <Route
                path="/LawandthejudicialsystemVolunteer"
                element={
                  <CareerTemplate name="objLawandthejudicialsystemVolunteer" />
                }
              />
              <Route
                path="/HorticultureandbotanyVolunteer"
                element={
                  <CareerTemplate name="objHorticultureandbotanyVolunteer" />
                }
              />
              <Route path="/become-an-officer" element={<BecomeAnOfficer />} />
              [ /*Donations*/]
              <Route path="/Donate" element={<Donate />} />
              {/*}  <Route path="/Sponsorships" element={<Sponsorships />} />*/}
              {/*Outreach*/}
              <Route path="/Collaborations" element={<Collaborations />} />
              {/*Events*/}
              <Route
                path="/TheMusicalBrain"
                element={<EventTemplate name="objTheMusicalBrain" />}
              />
              {/*Policies*/}
              <Route path="/Policies" element={<Policies />} />
              <Route path="/Saferecruitment" element={<Saferecruitment />} />
              <Route
                path="/Bullyingandharassment"
                element={<Bullyingandharassment />}
              />
              <Route path="/Codeofbehaviour" element={<Codeofbehaviour />} />
              <Route path="/Trusteeprivacy" element={<Trusteeprivacy />} />
              <Route
                path="/Conflictsofinterest"
                element={<Conflictsofinterest />}
              />
              <Route
                path="/Equalopportunities"
                element={<Equalopportunities />}
              />
              <Route
                path="/Reimbursementofexpensesandpurchases"
                element={<Reimbursementofexpensesandpurchases />}
              />
              <Route path="/Whistleblowing" element={<Whistleblowing />} />
              <Route
                path="/Safeguardingyoungpeople"
                element={<Safeguardingyoungpeople />}
              />
              <Route
                path="/Socialetiquetteinonlinegatherings"
                element={<Socialetiquetteinonlinegatherings />}
              />
              <Route path="/Supervision" element={<Supervision />} />
              <Route path="/Trusteecontract" element={<Trusteecontract />} />
              <Route
                path="/Trusteedeclarationforfitandproperpersons"
                element={<Trusteedeclarationforfitandproperpersons />}
              />
              <Route
                path="/Trusteedeclarationonappointment"
                element={<Trusteedeclarationonappointment />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </LoginContext.Provider>
        </ScrollToTop>
      );
    } else {
      return (
        <ScrollToTop>
          <Header />
          <NavDropdownExample />
          <LoginContext.Provider value={isUserSignedIn}>
            <Routes>
              {/*If signed out*/}
              <Route path="/" element={<Home />} />
              <Route
                path="/CreateAProfile"
                element={<SignIn />}
              />
             <Route path="/Profile" element={<SignIn />}/>
              <Route path="/Registerhere" element={<Registerhere />} />
              <Route path="/Forgotpassword" element={<Forgotpassword />} />
              <Route path="/Educo" element={<SignIn />} />
              <Route path="/Education" element={<Education />} />
              <Route path="/Student" element={<Student />} />
              {/*EDUCO*/
              /*Term-1*/
              /*Astronomy*/}
              <Route
                path="/Astronomy"
                element={<CourseTemplate name={"objAstronomy"} />}
              />
              <Route path="AstronomyBlackHoles" element={<SignIn />} />
              <Route path="AstronomyDarkMatter" element={<SignIn />} />
              <Route path="AstronomyDwarfPlanets" element={<SignIn />} />
              <Route path="AstronomyInductionSeminar" element={<SignIn />} />
              <Route path="AstronomySpacePolicy" element={<SignIn />} />
              <Route path="AstronomyTheMoons" element={<SignIn />} />
              <Route path="AstronomyThePlanets" element={<SignIn />} />
              <Route path="AstronomyTheSolarSystem" element={<SignIn />} />
              <Route path="AstronomyTheStars" element={<SignIn />} />

              <Route path="/Immunology" element={<SignIn />} />
              <Route
                path="/ImmunologyInductionSeminar"
                element={
                  <SignIn />
                }
              />
              <Route path="/ImmunologyAllThingsViral" element={<SignIn />} />
              <Route
                path="/ImmunologyInnateImmunityPhagocytosis"
                element={<SignIn />}
              />

              <Route path="/PsychologyNeuroscience" element={<SignIn />} />
              <Route
                path="/PsychologyNeuroscienceBrainComputerInterface"
                element={<SignIn />}
              />
              <Route
                path="/PsychologyNeuroscienceDopamineAndMotivation"
                element={<SignIn />}
              />
              <Route
                path="/PsychologyNeuroscienceDopamineAndReward"
                element={<SignIn />}
              />
              <Route
                path="/PsychologyNeuroscienceInductionSeminar"
                element={<SignIn />}
              />
              <Route
                path="/PsychologyNeuroscienceUnlockingthesecretsoftheHumanBrain"
                element={<SignIn />}
              />
              <Route
                path="/PsychologyNeuroscienceResilienceandMentalHealth"
                element={<SignIn />}
              />
              <Route
                path="/PsychologyNeuroscienceNeurologicalAnatomyandConditions"
                element={<SignIn />}
              />
              <Route
                path="/PsychologyNeuroscienceHumanBehaviour"
                element={<SignIn />}
              />
              <Route
                path="/PsychologyNeuroscienceResearchSymposium"
                element={<SignIn />}
              />
              <Route
                path="/PsychologyNeuroscienceBrainPlasticity"
                element={<SignIn />}
              />
              <Route
                path="/PsychologyNeuroscienceParkinsons"
                element={<SignIn />}
              />

              <Route path="/HistoryAfricanDiaspora" element={<SignIn />} />
              <Route
                path="/objHistoryAncientEgyptianMathematics"
                element={<SignIn />}
              />
              <Route path="/HistoryClaudeMcKay" element={<SignIn />} />
              <Route path="/HistoryInductionSeminar" element={<SignIn />} />
              <Route path="/HistoryJohnAgard" element={<SignIn />} />
              <Route path="/HistoryMbiratoBurnaBoy" element={<SignIn />} />
              <Route path="/HistoryPrehistoricAfrica" element={<SignIn />} />
              <Route path="/HistoryRuinsofGedi" element={<SignIn />} />
              <Route
                path="/HistorySeventhtotheEleventhCentury"
                element={<SignIn />}
              />
              <Route
                path="/HistoryBurkinaFasoandtheSonghaiEmpire"
                element={<SignIn />}
              />
              <Route
                path="/HistoryEleventhtoSixteenthCentury"
                element={<SignIn />}
              />
              <Route path="/HistoryTheArt" element={<SignIn />} />

              <Route path="/ClimateChange" element={<SignIn />} />
              <Route path="/ClimateChangeCentralBanking" element={<SignIn />} />
              <Route
                path="/ClimateChangeUncertaintyQuantification"
                element={<SignIn />}
              />
              <Route
                path="/ClimateChangeOneHumanityOneParadise"
                element={<SignIn />}
              />

              <Route path="/Music" element={<SignIn />} />
              <Route path="/MusicInductionSeminar" element={<SignIn />} />

              <Route
                path="/ArtificialIntelligenceRobotics"
                element={<SignIn />}
              />
              <Route
                path="/ArtificialIntelligencePrivacyandSurveillanceEthics"
                element={<SignIn />}
              />
              <Route
                path="/ArtificialIntelligenceRoboticsInEveryDayLifePartOne"
                element={<SignIn />}
              />
              <Route
                path="/ArtificialIntelligenceRoboticsInEveryDayLifePartTwo"
                element={<SignIn />}
              />
              <Route
                path="/ArtificialIntelligenceEthicsofAIAndRobitics"
                element={<SignIn />}
              />
              <Route
                path="/ArtificialIntelligenceMakingAVerySimpleAI"
                element={<SignIn />}
              />
              <Route
                path="/ArtificialIntelligenceMakingAVerySimpleAIPartOne"
                element={<SignIn />}
              />
                            <Route
                path="/ArtificialIntelligenceMakingAVerySimpleAIPartTwo"
                element={<SignIn />}
              />
              <Route path="/Politics" element={<SignIn />} />
              <Route path="/PoliticsMarxism" element={<SignIn />} />
              <Route path="/PoliticsEgalitarianism" element={<SignIn />} />
              <Route
                path="/PoliticsComparativeGovernment"
                element={<SignIn />}
              />
              <Route path="/PoliticsCriticalTheory" element={<SignIn />} />
              <Route
                path="/PoliticsMarxism"
                element={<SignIn />}
              />
              <Route
                path="/PoliticsEgalitarianism"
                element={<SignIn />}
              />
              <Route
                path="/PoliticsPrivateProperty"
                element={<SignIn />}
              />
              
              
              <Route path="/Business" element={<SignIn />} />
              <Route path="/BusinessFoundingAStartUp" element={<SignIn />} />

              <Route path="/MightyMinds" element={<SignIn />} />
              <Route path="/MightyMindsShinrinYoku" element={<SignIn />} />
              {/*Training*/}
              <Route
                path="/EPQTraining"
                element={<SignIn />} />
              {/*About us*/}
              <Route path="/Ourmotto" element={<Ourmotto />} />
              <Route path="/Theteam" element={<Theteam />} />
              <Route path="/Ourtrustees" element={<Ourtrustees />} />
              {/*News*/}
              <Route path="/News" element={<News />} />
              <Route path="/Trusteesatotc" element={<Trusteesatotc />} />
              <Route path="/Educovolunteers" element={<Educovolunteers />} />
              <Route path="/Learningtolearn" element={<Learningtolearn />} />
              <Route path="/Mindfulness" element={<Mindfulness />} />
              <Route path="/YoungBlackAndNeurodiverse" element={<YoungBlackAndNeurodiverse />} />
              <Route path="/AdviceOnPreparingForExaminations" element={<AdviceOnPreparingForExaminations />} />
              
              <Route path="/AdviceforYoungPeopleHowtoBestPrepareforExams" element={<AdviceforYoungPeopleHowtoBestPrepareforExams />} />
              <Route path="/AdviceforYoungPeopleStudingforATwoHourPeriod" element={<AdviceforYoungPeopleStudingforATwoHourPeriod />} />
              <Route path="/AdviceforYoungPeopleBestRevisionTechniques" element={<AdviceforYoungPeopleBestRevisionTechniques />} />
              <Route path="/AdviceforYoungPeopleWriteAGreatAlevelEssay" element={<AdviceforYoungPeopleWriteAGreatAlevelEssay />} />
              <Route path="/AdviceforYoungPeopleBreathWorkAndMeditation" element={<AdviceforYoungPeopleBreathWorkAndMeditation />} />
              <Route path="/AdviceforYoungPeopleGuidedMeditation" element={<AdviceforYoungPeopleGuidedMeditation />} />
              <Route path="/AdviceforYoungPeoplePowerOfSelfGratitude" element={<AdviceforYoungPeoplePowerOfSelfGratitude />} />
               {/*<Route path="/NovusDeus" element={<NovusDeus />} />*/}
              {/*Outreach*/}
              <Route path="/Collaborations" element={<Collaborations />} />
              {/*Events*/}
              <Route
                path="/TheMusicalBrain"
                element={<EventTemplate name="objTheMusicalBrain" />}
              />


              {/*Education*/}
              <Route path="/Education" element={<Education />} />
              <Route path="/EPQ" element={<EPQ />} />
              <Route path="/Enrol" element={<Enrol />} />
              <Route
                path="/Wellbeingprogramme"
                element={<Wellbeingprogramme />}
              />
              <Route path="/Programme" element={<Programme />} />
              <Route path="/Accreditation" element={<Accreditation />} />
              <Route path="/Educo" element={<Educo />} />
              <Route
                path="/Partneredsixthforms"
                element={<Partneredsixthforms />}
              />
              <Route
                path="/Programme_testimonial"
                element={<Programme_testimonial />}
              />
              <Route path="/CosmicHorizonsRegistration" 
            element={<RegistrationTemplate name={'objAstrophysicsRegistration'} />}/> 
            <Route
              path="/AstrophysicsMasterClass"
              element={<EventTemplate name="objWorkShopOne" />}
            />
              {/*Volunteering*/}
              <Route path="/Careers" element={<Careers />} />
              <Route path="/Training" element={<Training />} />
              <Route path="/Volunteering" element={<Volunteering />} />
              <Route
                path="/recruitmentOfficer"
                element={<CareerTemplate name="objRecruitmentOfficer" />}
              />
              <Route
                path="/socialmediaOfficer"
                element={<CareerTemplate name="objSocialmediaOfficer" />}
              />
              <Route
                path="/policyOfficer"
                element={<CareerTemplate name="objPolicyOfficer" />}
              />
              <Route
                path="/britishSignLangOfficer"
                element={<CareerTemplate name="objBritishSignLangOfficer" />}
              />
              <Route
                path="/fundraisingOfficer"
                element={<CareerTemplate name="objFundraisingOfficer" />}
              />
              <Route
                path="/outreachOfficer"
                element={<CareerTemplate name="objOutreachOfficer" />}
              />
              <Route
                path="/webdesignOfficer"
                element={<CareerTemplate name="objWebdesignOfficer" />}
              />
              <Route
                path="/aminationOfficer"
                element={<CareerTemplate name="objAnimationOfficer" />}
              />
              <Route
                path="/videographyOfficer"
                element={<CareerTemplate name="objVideographyOfficer" />}
              />
              <Route
                path="/ImmunologyVolunteer"
                element={<CareerTemplate name="objImmunologyVolunteer" />}
              />
              <Route
                path="/PsychologyVolunteer"
                element={<CareerTemplate name="objPsychologyVolunteer" />}
              />
              <Route
                path="/AstrophysicsVolunteer"
                element={<CareerTemplate name="objAstrophysicsVolunteer" />}
              />
              <Route
                path="/MusicVolunteer"
                element={<CareerTemplate name="objMusicVolunteer" />}
              />
              <Route
                path="/HistoryVolunteer"
                element={<CareerTemplate name="objHistoryVolunteer" />}
              />
              <Route
                path="/ClimateVolunteer"
                element={<CareerTemplate name="objClimateVolunteer" />}
              />
              <Route
                path="/BusinessVolunteer"
                element={<CareerTemplate name="objBusinessVolunteer" />}
              />
              <Route
                path="/HistoryoflangVolunteer"
                element={<CareerTemplate name="objHistoryoflangVolunteer" />}
              />
              <Route
                path="/LawandthejudicialsystemVolunteer"
                element={
                  <CareerTemplate name="objLawandthejudicialsystemVolunteer" />
                }
              />
              <Route
                path="/HorticultureandbotanyVolunteer"
                element={
                  <CareerTemplate name="objHorticultureandbotanyVolunteer" />
                }
              />
              <Route path="/become-an-officer" element={<BecomeAnOfficer />} />
              {/*Donations*/}
              <Route path="/Donate" element={<Donate />} />
              {/*} <Route path="/Sponsorships" element={<Sponsorships />} />*/}
              {/*Outreach*/}
              <Route path="/Collaborations" element={<Collaborations />} />
              {/*Policies*/}
              <Route path="/Policies" element={<Policies />} />
              <Route path="/Saferecruitment" element={<Saferecruitment />} />
              <Route
                path="/Bullyingandharassment"
                element={<Bullyingandharassment />}
              />
              <Route path="/Codeofbehaviour" element={<Codeofbehaviour />} />
              <Route path="/Trusteeprivacy" element={<Trusteeprivacy />} />
              <Route
                path="/Conflictsofinterest"
                element={<Conflictsofinterest />}
              />
              <Route
                path="/Equalopportunities"
                element={<Equalopportunities />}
              />
              <Route
                path="/Reimbursementofexpensesandpurchases"
                element={<Reimbursementofexpensesandpurchases />}
              />
              <Route path="/Whistleblowing" element={<Whistleblowing />} />
              <Route
                path="/Safeguardingyoungpeople"
                element={<Safeguardingyoungpeople />}
              />
              <Route
                path="/Socialetiquetteinonlinegatherings"
                element={<Socialetiquetteinonlinegatherings />}
              />
              <Route path="/Supervision" element={<Supervision />} />
              <Route path="/Trusteecontract" element={<Trusteecontract />} />
              <Route
                path="/Trusteedeclarationforfitandproperpersons"
                element={<Trusteedeclarationforfitandproperpersons />}
              />
              <Route
                path="/Trusteedeclarationonappointment"
                element={<Trusteedeclarationonappointment />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </LoginContext.Provider>

          <Footer />
        </ScrollToTop>
      );
    }
  }


export default App;
