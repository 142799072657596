const supportUs = {
    alt: 'That is a wrap!',
    Title: "Please consider supporting Off the Curriculum today!",
    desc: "As an recently formed non-profit organisation, we rely on your valuable donations to provide our educational services. Your donations forms more than 80% of our income, and without such we would be unable to provide an educational programme inspiring the next generation to reach their full potential! We have four categories of donors: Frida Kahlo, Chandrasekhara Venkata Raman, and Elisabeth Scott. Be one today!",
    Kahlo:"Kahlo Donor (£10)",
    Raman: "Raman Donor (£20)", 
    Scott: "Scott Donor (£50)",
    href: "/Donate",
}

export {
    supportUs,
};