const trusteesIntro = {
    Header: "Our Trustees",
    desc: "To ensure our continued success, we appointed external members to our charity's Trustee board. The trustee board will be responsible for organisational oversight, leadership, and risk management. To ensure our sustainability, the trustee board will ensure our aims and objectives are clear, effectively enforced, and realistic. The trustee board will ensure the organisation remains transparent and accountable, where appropriate. We are pleased to introduce our Board of Trustees: Dr. Ian Mudway, Dr. Rashada Harry, Dr. Sweta Raghavan, Dr. Stephanie Wright, Mr. Shaun Beghan and Mr. Mushfiqur Chowdhury.",
}
const ianMudwayDesc = {
    desc: "With over 25 years of experience in academia and research, Dr. Ian Mudway is a Senior Lecturer in the School of Public Health at Imperial College London. Passionate about science communication, he has worked extensively with artists and educationalists to promote the public's understanding of the risks associated with environmental pollutants.", 
}

const rashadaHarryDesc = {
    desc: "Having received an LLM from the London School of Economics, Dr. Rashada Harry currently serves as an Enterprise Technologist Leader at Amazon Web Services (AWS). She is also on the Board Trustee at Talawa, EngineeringUK, serves as an Advisory board member at Amazon Black Employee Network UK, Women@AWS, and is a Founding Member of UKBlackTech.", 
}

const swetaRaghavanDesc = {
    desc: "Dr. Raghavan has dedicated her life to science, education, and activism. She received her Ph.D. from King's College London for characterising the biological role of a novel protein, which has implications in unearthing treatment for inflammation arising from respiratory illnesses. Having mentored over 3000 students across the UK & India, 85% of whom are now studying at prestigious universities. Among many accolades, she is recognised as the Most Influential Life Science Expert 2020 by the Global Health Pharma News Magazine.", 
}

const stephanieWrightDesc = {
    desc: "Dr. Stephanie Wright is a Lecturer at the Medical Research Council (MRC) Centre for Environment and Health, School of Public Health at Imperial College London. Commencing with a Ph.D. from the University of Exeter, she joined ICL as an early career researcher, specialising in airborne microplastics and human health.", 
}

const mushfiqurChowdhuryDesc = {
    desc: "Graduating with First Class Honours in MSc Management with Strategic Management, Mr. Mushfiqur Chowdhury has a decorated history working in financial services, the healthcare industry, and European Union (EU). He has experience volunteering, having spent time in Morocco aiding the Original Volunteers Charity (OVC). OVC focuses on aiding the homeless in Morocco by providing education, creating team projects, and providing food and shelter.", 
}
export {
    trusteesIntro,
    ianMudwayDesc, 
    rashadaHarryDesc, 
    swetaRaghavanDesc, 
    stephanieWrightDesc, 
    mushfiqurChowdhuryDesc, 
};