import { CreateProfile } from "../../../firebase/firebaseDataWrite";
import { Link } from "react-router-dom";
import { Pics } from "./profilePictureCreate";

export function CreateAProfile() {


  return (
    <><title>CreateAProfile</title>
      <div className="userProfile">
        <div className="centre-frame">
        <div className="user" id="user">
          <center>
          <h3>Create your profile</h3>
        </center>
          <Pics />
          <CreateProfile />
          </div>
        </div>
       
      
      </div>
    </>
  );
}