const objAstrophysicsRegistration = {
    eventDate: `September, 23rd 2023`,
    title: 'Cosmic Horizons',
    subtitle: `
    Our Ever-Expanding Universe
    `, 
    shortDescription: `
    Are you curious about the secrets of the Universe and how it all works? It's amazing how all the technologies we use today are actually part of the magic shows that astronomers put on. Why not join us at Imperial College London for a full day of excitement exploring the Cosmos on September 23rd, 2023, from 10:00-15:30. To confirm your place, register by clicking the pink button on-screen and filling out the google form!
    `,
    formLink: 'https://docs.google.com/forms/d/e/1FAIpQLSeMWSeygtC5Iu1Fo8bUYcdkrfDd8XH8GL38FPvZIc-btZKvHg/viewform?usp=sf_link',
};
  
export default objAstrophysicsRegistration; //;
