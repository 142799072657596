import React from "react";
import Whistleblowingpolicy from "../Policy-folder-pdf/Whistle_blowing.pdf";

function Whistleblowing() {
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <div>
        <h1>Whistleblowing policy at Off the Curriculum</h1>
        <iframe title='Our Whistle blowing policy' src={Whistleblowingpolicy} className="policy"></iframe>
      </div>
    </React.Fragment>
  );
}
export default Whistleblowing;
