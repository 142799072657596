import React from "react";
import Equalopportunitiespolicy from "../Policy-folder-pdf/Equal_Opportunities.pdf";

function Equalopportunities() {
  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <div>
        <h1>Equal opportunities policy at Off the Curriculum</h1>
        <iframe
          title = 'Our Equal opportunities policy '
          src={Equalopportunitiespolicy}
          className="policy"
        ></iframe>
      </div>
    </React.Fragment>
  );
}
export default Equalopportunities;
