import { AuthErrorCodes } from "firebase/auth";
import React, { useState, useRef, useEffect, useContext } from "react";
import { CreateAProfile } from "../../profile/createAProfile";
import { useProfileStore } from "../../../../components/common/statePath/profileStore.tsx";
import { LoginContext } from "../../../../App";

export const ModProfileCreate = () => {
  const [userProfile, setUserProfile] = useState(null);
  const profileDownloaded = useProfileStore();
  const isUserSignedIn = useContext(LoginContext);

  
  useEffect(() => {
    // Fetch the user profile data
    const fetchUserProfile = () => {
      // Set the userProfile value to true here Tracey
      setUserProfile();
    };
    fetchUserProfile();
  }, []);

  let userEmail = null;
  if (profileDownloaded && profileDownloaded.profile && profileDownloaded.profile.length > 0) {
    userEmail = profileDownloaded.profile[0].email;
  }

  function CreateProfileModal() {
    return (
      <>
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
            overflowY: "auto",
          }}
        >
          <div
            className="modalShorts-content"
            style={{ maxHeight: "900px", maxWidth: "90%" }}
            onClick={(e) => e.stopPropagation()}
          >
            <CreateAProfile />
          </div>
        </div>
      </>
    );
  }

  return <>{!isUserSignedIn && !userEmail && <CreateProfileModal />}</>;
};
