import React, { useState } from "react";
import "firebase/compat/auth";
//import { auth } from 'firebaseui';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "./firebase/firebase";
//const auth = getAuth();
const SignIn = () => {
  const SignInWithGoogle = () => {
    var google_provider = new auth.GoogleAuthProvider();
    auth
      .signInWithPopup(google_provider)
      .then((re) => {
      })
      .catch((err) => {
      });
  };

  const [registerEmail, setRegisterEmail] = useState("");
  const [value, setPassword] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");

  const register = async () => {
    if (value !== registerPassword) {
      alert("Password does not match!");
      return false;
    }
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      if(user !== null) {
        window.location.href= '/createAProfile'
      }
    } catch (error) {
      if (registerEmail === "") {
        alert("Email field is empty");
      }
      if (registerPassword === "") {
        alert("Password cannot be empty");
      }
    } finally {
    }
  };
  return (
    <>
      <div className="outer">
        <div className="login-container" id="login-container">
          <div className="form-container log-in-container">
            <form onsubmit="login(event)" className="login">
              <h1>Register Today</h1>
              <div className="social-container"></div>
              <span>Email:</span>
              <input
                placeholder="Email..."
                onChange={(event) => {
                  setRegisterEmail(event.target.value);
                }}
              />
              <span>Password:</span>
              <input
                placeholder="Password..."
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                type="password"
              />
              <input
                placeholder="Confirm Password..."
                onChange={(event) => {
                  setRegisterPassword(event.target.value);
                }}
                type="password"
              />
              <div className="error_response"></div>
              <btn type="sumbit" className="login-button" onClick={register}>
                Register here
              </btn>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-right">
                <h1 className="login">Welcome to EDUCO!</h1>
                <p className="login">
                  Passwords must contain at least 8 characters
                </p>
              </div>
            </div>
          </div>
          <div id="logged-in">
            <h3>You are logged in</h3>
            <button onclick="logout()"></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;