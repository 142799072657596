const enrolPage ={
    enrolpageTitle:`
    Be a part of history and enrol at Off the Curriculum today!
    `, 
    enrolpageDesc:`
    Are you interested in exploring subjects outside of the National Curriculum? Off the Curriculum places significant emphasis on our educational programme being accessible to all, despite whether you would usually consider yourself an academic person (whatever that may be) or not. 
    Before enrolling in the programme, we invite you to read our Participant Welcome pack below. This will detail what you should expect of Off the Curriculum and what we expect of our programme participants. 
    `, 
}

export {
    enrolPage
}