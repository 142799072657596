import { db } from "../../../firebase/firebase";
import { ref, push, set } from "firebase/database";

export default function EventsForm() {
  function UpdateDatabase(e) {
    e.preventDefault();
    let toUpdate = e.target.attributes[1].nodeValue.toString();
    let time  = e.target[2].value
    const currentRef = ref(db, toUpdate );
    const newCurrentRef = push(currentRef);
       set(newCurrentRef, {
        key:newCurrentRef.key,
        token: true,
        header: e.target[0].value,
        location:e.target[1].value,
        time: time,
        src: e.target[3].value,
        buttonHref:e.target[4].value,
    })
    .then(() => {
        alert('Update successful!')
    })
    .catch((error) => {
        alert("Error updating database:", error)
    })
  }

  return (
    <>
      <form method="dialog" onSubmit={(e) => UpdateDatabase(e)} value="Events">
        <div className="form-example">
          <label htmlFor="eventHeader">Enter the Event header: </label>
          <input type="text" name="eventHeader" id="eventHeader" required />
        </div>
        <div className="form-example">
          <label htmlFor="eventLocation">Enter the Event location: </label>
          <input type="text" name="eventLocation" id="eventLocation" required />
        </div>
        <div className="form-example">
          <label htmlFor="eventDate">Enter the Event Date and time: </label>
          <input type="datetime-local" name="eventDate" id="eventDate" required />
        </div>
        <div className="form-example">
          <label htmlFor="eventLink">Enter the Event image link: </label>
          <input type="text" name="eventLink" id="eventLink" required />
        </div>
        <div className="form-example">
          <label htmlFor="eventregisterHref">
            Enter the Event register href:{" "}
          </label>
          <input
            type="text"
            name="eventregisterHref"
            id="eventregisterHref"
            required
          />
        </div>
        <input type="submit" value="update" id="button" />
      </form>
    </>
  );
}