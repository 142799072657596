import React, { useState, useRef, useEffect } from "react";

const VideoCarousel = ({ videos }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRefs = useRef([]);

  useEffect(() => {
    videoRefs.current.forEach((video) => {
        video.currentTime = 2;
        video.play();
      });
    }, []);



  const openModal = (index) => {
    setCurrentVideoIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleKeyDown = (event) => {
    if (modalOpen && event.key === "ArrowDown") {
      setCurrentVideoIndex((prevIndex) =>
        prevIndex === videos.length - 1 ? 0 : prevIndex + 1
      );
    }
  };



  return (
    <>
      <div
        className="video-carousel"
        style={{ maxHeight: "450px", overflowX: "auto", paddingRight: "10px" }}
      >
         <div className="video-items" style={{ display: "flex" }}>
        {videos.map((video, index) => (
          <div
            key={video.id}
            className="video-card"
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => openModal(index)}
          >
        <img
            key={index}
            style={{ height: "400px", marginRight: "10px" }}
            muted
            src={video.snippet} 
              >
        </img>
          </div>
        ))}
        </div>
      </div>
      {modalOpen && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          }}
          onClick={closeModal}
        >
          <div
            className="modalShorts-content"
            style={{ maxHeight: "550px", maxWidth: "90%" }}
            onClick={(e) => e.stopPropagation()}
          >
            <video style={{ maxHeight: "700px", maxWidth: "90%" }} src={videos[currentVideoIndex].src} autoPlay></video>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoCarousel;
