import { db, auth } from "./firebase";
import { ref, push, set } from "firebase/database";

export  function FeedbackWrite(topic, textinput) {


  if (auth.currentUser === null) {
    alert("Sorry you need to sign in ");
  } else if (auth.currentUser !== null) {
    let uid = auth.currentUser.uid;
   
    if (topic === "Error") {
      const userRef = ref(db, "ErrorReports/" );

      const newUserRef = push(userRef);
  
      let key = userRef.key;
     

      set(newUserRef, {
        token:true,
        user_id: uid,
        key: key,
        report: textinput
      });
    } else if (topic === "suggestion") {
       //console.log('suggestion')
      const userRef = ref(db, "suggestions/");

      const newUserRef = push(userRef);
      let key = userRef.key;
   

      set(newUserRef, {
        token: true,
        user_id: uid,
        key:key,
        suggestion: textinput,
      });
    } else if (topic === "feedback") {
       //console.log('feedback')
      const userRef = ref(db, "feedback/");

      const newUserRef = push(userRef);
      let key = userRef.key;
  

      set(newUserRef, {
        token: true,
        user_id: uid,
        key: key,
        feedback: textinput,
      });
    }
  }
}