import objRecruitmentOfficer from "./recruitment-officer";
import objSocialmediaOfficer from "./socialmedia-officer";
import objPolicyOfficer from "./policy-officer";
import objBritishSignLangOfficer from "./british-sign-lang-officer";
import objFundraisingOfficer from "./fundraising-officer";
import objOutreachOfficer from "./outreach-officer";
import objWebdesignOfficer from "./webdesign-officer";
import objAnimationOfficer from "./animation-officer";
import objVideographyOfficer from "./videography-officer";
import objImmunologyVolunteer from "./immunology-volunteer";
import objPsychologyVolunteer from "./psychology-volunteer";
import objAstrophysicsVolunteer from "./astrophysics-volunteer";
import objMusicVolunteer from "./music-volunteer";
import objHistoryVolunteer from "./history-volunteer";
import objClimateVolunteer from "./climate-volunteer";
import objBusinessVolunteer from "./business-volunteer";
import objHistoryoflangVolunteer from "./historyoflang-volunteer";
import objLawandthejudicialsystemVolunteer from "./lawandthejudicialsystem-volunteer";
import objHorticultureandbotanyVolunteer from "./horticultureandbotany-volunteer";

export default {
  objRecruitmentOfficer,
  objSocialmediaOfficer,
  objPolicyOfficer,
  objBritishSignLangOfficer,
  objFundraisingOfficer,
  objOutreachOfficer,
  objWebdesignOfficer,
  objAnimationOfficer,
  objVideographyOfficer,
  objImmunologyVolunteer,
  objPsychologyVolunteer,
  objAstrophysicsVolunteer,
  objMusicVolunteer,
  objHistoryVolunteer,
  objClimateVolunteer,
  objBusinessVolunteer,
  objHistoryoflangVolunteer,
  objLawandthejudicialsystemVolunteer,
  objHorticultureandbotanyVolunteer,
};
