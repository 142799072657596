import React from "react";
import Trusteeprivacypolicy from "../Policy-folder-pdf/Trustee_Privacy_Policy.pdf";

function Trusteeprivacy() {
  return (
    /*include text inside the React.Fragment*/
    <React.Fragment>
      <div>
        <h1>Trustee privacy policy at Off the Curriculum</h1>
        <iframe title='Our Trustee privacy policy' src={Trusteeprivacypolicy} className="policy"></iframe>
      </div>
    </React.Fragment>
  );
}
export default Trusteeprivacy;
