import Stars from '../../../../../images/functionality/educo/icons/term-1/astronomy/stars.webp'
import Solarsystem from '../../../../../images/functionality/educo/icons/term-1/astronomy/solarsystem.webp'
import Telescope from '../../../../../images/functionality/educo/icons/term-1/astronomy/telescope.webp'

const objClimateChange = {
    webpagedesc: ``, 
    webpagekeywords: ``, 
    webpagepageurl: ``, 
    webpagethumbnail: ``, 
    webpagethumbnailAlt:``, 
    organisationtwitter: ``, 
    title: 'Climate Change and Sustainability', 
    courseDesc: 
        `
        During the Climate Change and Sustainability explored topics such as
        model uncertainty in the context of sea-level rise predictions,
        central banks' role in halting biodiversity loss, and explored the
        effects of Climate Change may have on future generations
        `
    ,
    quickLinkOneDesc:
    `
    One Humanity, One Paradise
    `
    ,
    quickLinkOne: `
    /AstronomyInductionSeminar
    `, 
    quickLinkTwoDesc: 
    `
    Uncertainty Quantification in Climate Systems
    `, 
    quickLinkTwo:
    `
    /AstronomyTheStars
    `, 
    quickLinkThreeDesc:
    `
    Climate Policy
    `, 
    quickLinkThree: 
    `
    /AstronomyDwarfPlanets
    `, 
    quickLinkFourDesc:`
    TheMoons
    `, 
    quickLinkFour: 
    `
    /AstronomyTheMoons
    `, 
    quickLinkFiveDesc: 
    `
    Space Policy
    `, 
    quickLinkFive: 
    `
    /AstronomySpacePolicy
    `, 
    courses:[
    {
        key: 1, 
        sectionimage: Telescope, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        One Humanity, One Paradise
        `, 
        sectiondesc: `
        In this captivating seminar, we will learn about the timespan of
        our universe, from the very beginning to present day, and where
        the story of our Earth begins and progresses along that
        timeline. Dr. Francisco Diego invites discussion and inward
        thinking as he reveals to us how the Earth has been mistreated
        in light of climate change, in a talk that shares an important
        and highly relevant message with the human race.
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
                [
                    {
                        key: 1, 
                        videoTitle: 'One paradise, One humanity',
                        videoDesc: `Why not uncover extraterrestrial missions to Mars, Jupiter and beyond!`,
                        videoInfo: `100 views | 10 days ago | Dr. Francisco Diego`, 
                        videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FclimatechangeSustainability%2FOneHumanityOneParadise%2FfranciscodDiegoClimatechangeThumbnail.webp?alt=media&token=7a056f57-cbb8-4b7d-81fe-eb83bddafb41',
                        videoImageAlt: `A Journey Through The Solar System Thumbnail`, 
                        videohref: `/ClimateChangeOneHumanityOneParadise`, 
                    },
                ]
    }, 
    {
        key: 2, 
        sectionimage: Solarsystem, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        Uncertainty
        Quantification in Climate Systems
        `, 
        sectiondesc: `
        After recently completing a PhD in statistics, Dr. Fiona Turner
        is based at King’s College London, where her current research
        focuses on uncertainty quantification in climate systems. In
        this seminar, Dr. Turner will shed light on the fascinating way
        in which researchers model the climate- with a particular focus
        on modelling the size of the world’s ice sheets and glaciers in
        light of mapping their contributions to sea level.
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'Uncertainty Quantification',
                videoDesc: `Why not uncover extraterrestrial missions to Mars, Jupiter and beyond!`,
                videoInfo: `100 views | 10 days ago | Dr. Fiona Turner`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FclimatechangeSustainability%2FUncertaintyQuantification%2FfionaTurnerClimatechangeThumbnail.webp?alt=media&token=afe00861-08da-4adb-b0c1-7281aa8a3a2e',
                videoImageAlt: `A Journey Through The Solar System Thumbnail`, 
                videohref: `/ClimateChangeUncertaintyQuantification`, 
            }
        ]
    }, 
    {
        key: 3, 
        sectionimage: Stars, 
        sectionimageAlt: `
        Climate Policy
        `, 
        sectiontitle: `
        Climate Policy
        `, 
        sectiondesc: `
        Elena is a Policy Analyst at the LSE Grantham Research
        Institute, specialising in biodiversity and central banking.
        Before this, she completed degrees in Economics from the
        University of Sussex and International Management from the
        London School of Economics and Political Science. In this
        seminar, we will explore the role policy development and
        legislation has in mitigating Climate Change.
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'Climate Policy',
                videoDesc: `Why not uncover extraterrestrial missions to Mars, Jupiter and beyond!`,
                videoInfo: `100 views | 10 days ago | Ms. Elena Almedia`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2FclimatechangeSustainability%2FCentralBanking%2FelenaAlmeidaClimatechangeThumbnail.webp?alt=media&token=096e96f4-66ac-405a-95ec-e66be463a76f',
                videoImageAlt: `A Journey Through The Solar System Thumbnail`, 
                videohref: `/ClimateChangeCentralBanking`, 
            }
        ]
    }, 
    ],
}

export default objClimateChange; 
