import React, { useEffect, useState, useRef } from 'react';
import CommentSection from '../../../../components/common/commentSection/Comments';
import { UpdateLikes } from '../../../../firebase/firebaseStoreVideo';
import '../../educoMedia/Educo.css';
import { Title, Description } from 'react-head-meta';
import { ReactHookDump } from '../../../../firebase/firebaseGetVideo';
//firebase inf
import { db } from '../../../../firebase/firebase';
import {
  ref,
  onValue,
  query,
  update,
  getDatabase,
  child,
  get,
  orderByChild,
  equalTo,
  push,
  set,
} from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import './EducoTemplatePage.css';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import { Link } from 'react-router-dom';

const TrialEducoPageTemplate = (props) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  }, []);
  function emails(s) {
    return s.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  const auth = getAuth();
  let restartTime;
  const name = props.name;
  //console.log(name)

  //Load content from the database
  const [data, setData] = useState(null);
  const [courseTag, setCourseTag] = useState(null);
  const [viewCounter, setViewCounter] = useState(null);
  const [likeClicked, setLikeClicked] = useState(false);
  const [likesUpdate, setLikesUpdate] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(getDatabase());
      const videoRef = child(dbRef, 'video/name/' + name);
      //console.log(videoRef)
      try {
        const snapshot = await get(videoRef);
        if (snapshot.exists()) {
          const videoData = snapshot.val();
          setCourseTag(videoData.courseTag);
          setData(videoData);

          // Increment the count value by one
          const updatedCount = videoData.count + 1;
          setViewCounter(updatedCount);
          // Update the count value in the database
          await update(videoRef, { count: updatedCount });

          //console.log("Count value updated successfully!");
        } else {
          //console.log("No data available");
        }
      } catch (error) {
        //console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleLikeButtonClick = async () => {
    if (!likeClicked) {
      try {
        const dbRef = ref(getDatabase());
        const videoRef = child(dbRef, 'video/name/' + name);

        // Get the current likes value from the state
        const currentLikes = data.likes;

        // Increment the likes value by one
        const updatedLikes = currentLikes + 1;
        setLikesUpdate(updatedLikes);

        // Update the likes value in the database
        await update(videoRef, { likes: updatedLikes });

        //console.log("Likes value updated successfully!");

        // Set the likeClicked state to true to prevent further updates
        setLikeClicked(true);
      } catch (error) {
        //console.error("Error updating likes:", error);
      }
    } else {
      //console.log("You have already liked this video.");
    }
  };
  useEffect(() => {
    handleUpdate();
  }, [currentUser, data]);

  const handleUpdate = async () => {
    if (currentUser && currentUser.email) {
      const email = currentUser.email;
      //console.log(email, data);
      const dbRef = ref(getDatabase());
      const emailHash = emails(email);
      const userRef = child(dbRef, `person/${emailHash}`);
      const currentDate = new Date();
      const currentTime = currentDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });

      // Get the current date in a short date format (MM/DD/YYYY)
      const currentShortDate = currentDate.toLocaleDateString([], {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
      const dateTime = currentShortDate + ' ' + currentTime;

      await update(userRef, {
        lastViewTime: dateTime,
        lastViewed: data.key,
        seminarTagOfLastViewed: data.courseTag,
      });
    }
  };

  function Views() {
    //console.log('weird', posts.length === 0)
    //console.log('weird', posts[4].data)
    //console.log(data)
    return (
      <div>
        {data && (
          <>
            <div className="Views">
              <span className="Views">Views: {viewCounter}</span>
            </div>
          </>
        )}
      </div>
    );
  }

  function LoadingViews() {
    return (
      <div className="LikesViews">
        {
          <>
            <div className="Views">
              <span className="Views">Views:</span>
            </div>
          </>
        }
      </div>
    );
  }

  function Likes() {
    //console.log('weird', posts.length === 0)
    //console.log('weird', posts[4].data)
    //console.log(data)
    return (
      <div>
        {data && (
          <>
            <div className="Likes">
              <span className="Likes">
                Likes: {likeClicked ? likesUpdate : data['likes']}
              </span>
            </div>
          </>
        )}
      </div>
    );
  }

  function LoadingLikes() {
    return (
      <div className="LikesViews">
        {
          <>
            <div className="Likes">
              <span className="Likes">Likes:</span>
            </div>
          </>
        }
      </div>
    );
  }
  const ImageComponent = ({ imageSrc, videoSrc }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <img
          src={imageSrc}
          alt="My Image"
          style={{
            opacity: isHovered ? 0 : 1,
            transition: 'opacity 500ms',
          }}
        />

        {isHovered && (
          <video
            autoPlay
            muted
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: isHovered ? 1 : 0,
              transition: 'opacity 10ms ease-in-out',
            }}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        )}
      </div>
    );
  };

  function VideoEndedPopup({ setShowPopup, nextVideo, data }) {
    // stores first obj in nextVideo obj
    let firstVid;
    if (nextVideo) {
      [firstVid] = Object.values(nextVideo);
    }
    console.log(firstVid);
    return (
      <div className="video-ended-popup">
        {/* <div
          className="close-popup"
          onClick={() => {
            setShowPopup(false);
          }}
        >
          X
        </div> */}
        <div className="popup-item">
          <a
            onClick={() => {
              setShowPopup(false);
            }}
            href={data.videohref}
          >
            Watch Again
          </a>
        </div>
        <div className="popup-item">
          <a
            onClick={() => {
              setShowPopup(false);
            }}
            href="#astronomy-tutorial-2"
            // target="_blank"
            rel="noreferrer"
          >
            Go to the quiz!
          </a>
        </div>
        {nextVideo && (
          <div
            className="popup-item"
            onClick={() => {
              setShowPopup(false);
            }}
          >
            <a href={firstVid.videohref}>Watch Next Video</a>
          </div>
        )}
      </div>
    );
  }

  function LoadedPageContent() {
    const [showPopup, setShowPopup] = useState(false);
    const [nextVideo, setNextVideo] = useState(null);

    function handleVideoEnd() {
      setShowPopup(true);
      console.log('endeddd');
    }
    //console.log(courseTag)
    //Similar videos from the same course
    useEffect(() => {
      const videoRef = query(
        ref(db, 'video/name/'),
        orderByChild('courseTag'),
        equalTo(courseTag)
      );
      onValue(videoRef, (snapshot) => {
        const videoData = snapshot.val();
        const arr = Object.entries(videoData);
        // Shuffle the array using Fisher-Yates shuffle algorithm
        for (let i = arr.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [arr[i], arr[j]] = [arr[j], arr[i]];
        }

        // Define the start and end indices for the slice
        const start = 1;
        const end = 6;

        // Extract the slice from the shuffled array
        const slicedArray = arr.slice(start, end);

        // Convert the sliced array back into an object
        const slicedObject = Object.fromEntries(slicedArray);
        //console.log(slicedArray)
        setNextVideo(slicedObject);
        //console.log(videoData['Private Property'])
      });
    }, []);
    //console.log('what do you think', nextVideo)
    const pageName = data.videohref;
    const canonicalUrl = 'https://offthecurriculum.co.uk/' + pageName;
    // console.log(data);
    // console.log(nextVideo);
    // console.log(typeof nextVideo);
    return (
      <>
        {data && (
          <>
            <Helmet>
              <title>{data['key']}</title>
              <meta name="description" content={data['pageDesc']} />
              <meta property="og:title" content={data['key']} />
              <meta
                property="og:description"
                content="https://www.splashlearn.com/math-vocabulary/wp-content/uploads/2023/01/pp1-2.png"
              />
              <meta property="og:image" content={data['videoImage']} />
              <meta charSet="utf-8" />
              <meta property="og:type" key="og:type" content="website" />
              {/* Add more meta tags as needed */}
            </Helmet>
            <Title title={data['key']} />
            <Description description={data['pageDesc']} />

            <div className="videoCentreframe">
              {/*<! Main page we need the course information>*/}
              <div className="second-educo">
                {/* <VideoComponent
                  links={videoLinks}
                  videoSrc={data['videoSource']}
                /> */}
                <div className="video-container">
                  {showPopup && (
                    <VideoEndedPopup
                      setShowPopup={setShowPopup}
                      nextVideo={nextVideo}
                      data={data}
                    />
                  )}
                  <video
                    id="videoPlayer"
                    className="videoEducoPlayer"
                    controls
                    autoPlay
                    controlsList="nodownload"
                    poster={data['pagePoster']}
                    alt={data['videoAlt']}
                    onContextMenu={(e) => e.preventDefault()}
                    onEnded={handleVideoEnd}
                    onPlay={() => {
                      setShowPopup(false);
                    }} // hide popup when video starts playing
                  >
                    <source src={data['videoSource']} type="video/mp4" />
                    <track default kind="captions" srcLang="English" src="" />
                  </video>
                </div>
                <div value={data['key']}>
                  <div className="videoAnalytics">
                    {data === null ? <LoadingViews /> : <Views />}
                    {data === null ? <LoadingLikes /> : <Likes />}
                    <div>
                      <button className="likeB" onClick={handleLikeButtonClick}>
                        Like &#128077;
                      </button>
                    </div>
                    {/**128151	128149	128147 128148 128150 128152 128159 */}
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginBottom: '0', marginRight: '10px' }}>
                          Share:
                        </p>
                        <FacebookShareButton url={canonicalUrl}>
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton url={canonicalUrl}>
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <LinkedinShareButton url={canonicalUrl}>
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-course-title">
                  <div className="card-horizontal">
                    <div className="cardBody">
                      <h2 className="educoTitle">{data['key']}</h2>
                      <p className="topic_description_3">Date released:</p>
                      <p className="educo-video-introduction">
                        {data['pageDesc']}
                      </p>
                      <CommentSection comments={data['key']} />
                      <hr className="educo-subject-course" />
                      <h3 className="speakerTitle">Guest Speakers</h3>
                      {data['guestSpeaker'].map((items) => (
                        <div className="educoRow">
                          <div className="about-guestspeaker-column">
                            <img
                              className="guest-speaker-infographic"
                              src={items.guestSpeakerImage}
                              alt={items.guestspeakerAlt}
                            ></img>
                            <br />
                          </div>
                          <div className="about-guestspeaker-column">
                            <p className="educo-video-introduction">
                              {items.guestspeakerBio}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <ReactHookDump />
                <div className="card-course">
                  <div className="card-horizontal">
                    <div className="cardBody">
                      <div className="card-educo-title">
                        <img
                          className="course-infographic"
                          src=""
                          alt="Transcription"
                        ></img>
                        <span className="card-educo-course-title">
                          <a
                            href="Transcription"
                            className="educoSectionTitles"
                          >
                            Transcription
                          </a>
                        </span>
                      </div>
                      <hr className="educo-subject-course" />
                      <div className="scrollable-box">
                        {data['videoTranscript'].map((items) => (
                          <div>
                            <h3>{items.sectionHeader}</h3>
                            <p className="educo-transcription">
                              {items.sectionText}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-course">
                  <div className="card-horizontal">
                    <div className="card-body">
                      <div className="card-educo-title">
                        <img
                          className="course-infographic"
                          src=""
                          alt="Assessment"
                        ></img>
                        <span
                          className="card-educo-course-title"
                          id="Assessment"
                        >
                          <a
                            id="astronomy-tutorial-2"
                            href="Assessment"
                            className="educoSectionTitles"
                          >
                            Assessment
                          </a>
                        </span>
                      </div>
                      <hr className="educo-subject-course" />
                      <p className="educo-video-introduction">
                        {data['pageAssessmentDesc']}
                      </p>
                      <p className="educo-video-introduction">
                        If you prefer, you can open the quiz in a new tab by
                        clicking{' '}
                        <a
                          href={data.assessment}
                          target="_blank"
                          rel="noreferrer"
                          className='fullPageQuiz'
                        >
                          here
                        </a>
                        .
                      </p>
                      <div className="assessment-form-center">
                        <iframe
                          title="Assessment"
                          src={data['assessment']}
                          frameBorder="0"
                          marginHeight="0"
                          marginWidth="0"
                          className="assessment-frame"
                        >
                          Loading�
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="first-educo">
                <div className="recommendOverview">
                  <h3 className="educoTitle">Up next:</h3>
                  {nextVideo !== null ? (
                    <div className="carousel-container">
                      <div className="carousel">
                        {Object.entries(nextVideo).map(([video, data]) => (
                          <div className="recommendedSeminars" key={video}>
                            <a href={'/' + data.videohref}>
                              <ImageComponent
                                imageSrc={data.videoImage}
                                videoSrc={data.videoSnippet}
                              />
                              <span id="views">Views: {data.count} | </span>
                              <span>Likes: {data.likes}</span>
                              <button className="educoLongFormContentButton">
                                Watch this Seminar...
                              </button>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <>Loading</>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
  function UnLoadedPageContent() {
    return (
      <>
        <Helmet>
          <title>{name}</title>
          <meta name="description" content="" />
          <meta property="og:title" content="" />
          <meta property="og:description" content="" />
          <meta property="og:image" content="" />
          <meta charSet="utf-8" />
          <meta property="og:type" key="og:type" content="website" />
          {/* Add more meta tags as needed */}
        </Helmet>
        <Title title="" />
        <Description description="" />
        <div className="centre-frame">
          {/*<! Main page we need the course information>*/}
          <div className="second-educo">
            <video
              id="videoPlayer"
              className="videoEducoPlayer"
              controls
              autoPlay
              controlsList="nodownload"
              poster=""
              alt=""
              onContextMenu={(e) => e.preventDefault()}
            >
              <source src="" type="video/mp4" />

              <track default kind="captions" srcLang="English" src="" />
            </video>
          </div>
          <div value="">
            <div className="videoAnalytics">
              {data === null ? <LoadingViews /> : <Views />}
              {data === null ? <LoadingLikes /> : <Likes />}
            </div>
            <button
              className="likeB"
              onClick={(e) => {
                UpdateLikes(e);
              }}
            >
              Like &#128077;
            </button>
            {/**128151	128149	128147 128148 128150 128152 128159 */}
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ marginBottom: '0', marginRight: '10px' }}>
                  Share via
                </p>
                <FacebookShareButton>
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton>
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <LinkedinShareButton>
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
          <div className="card-course-title">
            <div className="card-horizontal">
              <div className="cardBody">
                <h2 className="educoTitle"></h2>
                <p className="topic_description_3">Live event:</p>
                <p className="educo-video-introduction"></p>
                <CommentSection />
                <hr className="educo-subject-course" />
                <h3 className="speakerTitle">Guest Speakers</h3>
                <div className="educoRow">
                  <div className="about-guestspeaker-column">
                    <img
                      className="guest-speaker-infographic"
                      src=""
                      alt=""
                    ></img>
                    <br />
                  </div>
                  <div className="about-guestspeaker-column">
                    <p className="educo-video-introduction"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactHookDump />
          <div className="card-course">
            <div className="card-horizontal">
              <div className="cardBody">
                <div className="card-educo-title">
                  <img
                    className="course-infographic"
                    src=""
                    alt="Transcription"
                  ></img>
                  <span className="card-educo-course-title">
                    <a href="Transcription" className="educoSectionTitles">
                      Transcription
                    </a>
                  </span>
                </div>
                <hr className="educo-subject-course" />
                <div className="scrollable-box">
                  <div>
                    <h3></h3>
                    <p className="educo-transcription"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-course">
            <div className="card-horizontal">
              <div className="card-body">
                <div className="card-educo-title">
                  <img
                    className="course-infographic"
                    src=""
                    alt="Assessment"
                  ></img>
                  <span className="card-educo-course-title">
                    <a
                      id="astronomy-tutorial-2"
                      href="Assessment"
                      className="educoSectionTitles"
                    >
                      Assessment
                    </a>
                  </span>
                </div>
                <hr className="educo-subject-course" />
                <p className="educo-video-introduction"></p>
                <div className="assessment-form-center">
                  <iframe
                    title="Assessment"
                    src=""
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    className="assessment-frame"
                  >
                    Loading�
                  </iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="first-educo">
            <div className="first-educo">
              <div className="recommendOverview">
                <h3 className="educoTitle">Up next:</h3>
                <div className="carousel-container">
                  <div className="carousel">
                    {[1, 2, 3].map((data) => (
                      <div className="recommendedSeminars" key={data}>
                        <a href={'/' + data.videohref}>
                          <ImageComponent
                            imageSrc={data.videoImage}
                            videoSrc={data.videoSnippet}
                          />
                          <span id="views">Views: {data.count} | </span>
                          <span>Likes: {data.likes}</span>
                          <button className="educoLongFormContentButton">
                            Watch this Seminar...
                          </button>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      {data === null ? <UnLoadedPageContent /> : <LoadedPageContent />}
    </React.Fragment>
  );
};

export default TrialEducoPageTemplate;