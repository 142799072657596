const theTeam = {
    title: `
    Meet the Team
    `, 
    desc: `
    OTC has recruited a diverse team of officers who, while pursuing a wide range of careers, all find commonality in that they live and breathe educational empowerment.  Find out about each member below:
    `, 
    seniorOfficers: `
    Senior Officer
    `, 
}

export {
    theTeam, 
}