import React, { useState, useEffect } from 'react';
import { getDatabase, ref, child, get, onValue, query } from "firebase/database";
import { db } from '../../../../firebase/firebase';
import { useProfileStore } from '../../../../components/common/statePath/profileStore.tsx';
import { getAuth } from 'firebase/auth';
import './RecommendationEngine.css';
import EllipsisText from '../ellipsisText/EllipsisText';

const RecommendationEngine = () => {
  const [loadMore, setLoadMore] = useState(false);
  const [videoDataList, setVideoDataList] = useState([]);
  const [userID, setUserID] = useState(null);
  const [userWatchInfo, setUserWatchInfo] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  }, []);
  function emails(s) {
    return s.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }
  const auth = getAuth();

  let pStore = useProfileStore();

  useEffect(() => {
    const dbRef = ref(getDatabase());
    const videoRef = query(ref(db, 'video/name/'));
    const videoLimit = 3;
  
    if (currentUser && currentUser.email) {
      const email = currentUser.email;
      const dbRef = ref(getDatabase());
      const emailHash = emails(email);

  
      setUserID(emailHash);
      // if statement - if user is not in the database create
  
      get(child(dbRef, `person/${emailHash}`)).then((snapshot) => {
        if (!snapshot.exists()) { 
          window.location.replace("/CreateAProfile");
          return;
        }
  
        const tagData = snapshot.val();
        //console.log('hello',tagData.seminarTagOfLastViewed);
        setUserWatchInfo(tagData.seminarTagOfLastViewed);
        const seminarTag = tagData && tagData.seminarTagOfLastViewed; // Add null check here
        const recommendedSeminarTag = calculateVideoTagSimilarity(seminarTag);
        let videos; // Declare the array outside the callback
  
        onValue(videoRef, (snapshot) => {
          const matchingVideos = {};
          const subsetObject = {};
          snapshot.forEach((childSnapshot) => {
            const videoData = childSnapshot.val();
            if (videoData.courseTag === recommendedSeminarTag) {
              matchingVideos[videoData.key] = videoData;
              let count = 0;
              for (const key in matchingVideos) {
                if (count >= videoLimit) {
                  break;
                }
                subsetObject[key] = matchingVideos[key];
                count++;
              }
            }
          });
          setVideoDataList(subsetObject);
        });
      });
    }
  }, [currentUser, currentUser && currentUser.email, userWatchInfo]);
  

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const calculateVideoTagSimilarity = (videoTag) => {
    const tagsToCompare = [
      'Astronomy',
      'ArtificialIntelligence',
      'History',
      'ClimateChange',
      'Business',
      'Immunology',
      'NeuroscienceAndPsychology',
      'Politics'
    ];
    if (!videoTag || videoTag.length === 0) {
      return 0;
    }
    let matchFound = false;

    for (let i = 0; i < tagsToCompare.length; i++) {
      const tag = tagsToCompare[i];
      if (tag === videoTag) {
        matchFound = tag;
        break;
      }
    }
    if (!matchFound) {
      // Match not found handling logic
    }
    return matchFound;
  };

  const ImageComponent = ({ imageSrc, videoSrc }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <img
          src={imageSrc}
          alt="My Image"
          style={{
            opacity: isHovered ? 0 : 1,
            transition: 'opacity 500ms'
          }}
        />

        {isHovered && (
          <video
            autoPlay
            muted
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: isHovered ? 1 : 0,
              transition: 'opacity 10ms ease-in-out'
            }}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        )}
      </div>
    );
  };

  function LoadedCard() {
    return (
      <div id="RecommendationDiv">
        <div id="educoViewer">
          {Object.entries(videoDataList).map(([video, data]) => (
            <div className="soloSeminar" key={video}>
              <a href={'/' + data.videohref}>
                <ImageComponent imageSrc={data.videoImage} videoSrc={data.videoSnippet} />
                <span>{data.author} | </span>
                <span id="views">Views: {data.count} | </span>
                <span>Likes: {data.likes}</span>
                <EllipsisText text={data.videoDesc} tag="p" maxLength={25} />
                <button className="educoLongFormContentButton">Watch this Seminar...</button>
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function LoadingCard() {
    const [videoDataList, setVideoDataList] = useState([]);
    const videoRef = query(ref(db, 'video/name/'));

    useEffect(() => {
      onValue(videoRef, (snapshot) => {
        const videoData = snapshot.val();
        const arr = Object.entries(videoData);
        for (let i = arr.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [arr[i], arr[j]] = [arr[j], arr[i]];
        }
        const start = 1;
        const end = 4;
        const slicedArray = arr.slice(start, end);
        const slicedObject = Object.fromEntries(slicedArray);
        setVideoDataList(slicedObject);
      });
    }, []);

    return (
      <div className="card-container">
        {Object.entries(videoDataList).map(([video, data]) => (
          <div className="soloSeminar" key={video}>
            <a href={'/' + data.videohref}>
              <ImageComponent imageSrc={data.videoImage} videoSrc={data.videoSnippet} />
              <span>{data.author} | </span>
              <span id="views">Views: {data.count} | </span>
              <span>Likes: {data.likes}</span>
              <EllipsisText text={data.videoDesc} tag="p" maxLength={25} />
              <button className="educoLongFormContentButton">Watch this Seminar...</button>
            </a>
          </div>
        ))}
      </div>
    );
  }


//console.log(userWatchInfo.length)
  return (
    <>
      {userWatchInfo.length === 0 ? <LoadingCard /> : <LoadedCard />}
    </>
  );
};

export default RecommendationEngine;
