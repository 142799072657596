/**css import */
import { FeedbackWrite } from "../../firebase/firebaseFeedbackWrite";
import "./CommentsForm.css";


export default function Comments() {
  /**to only allow letters in box e.charCode >= 65 && e.charCode <= 90) || (e.charCode >= 97 && e.charCode <= 122)*/
  let textString = [];

  /** function to stop users submitting harmfull data*/
  function alphaOnly(letters) {
    let alphabet = [
      "A",
      "a",
      "B",
      "b",
      "C",
      "c",
      "D",
      "d",
      "E",
      "e",
      "F",
      "f",
      "G",
      "g",
      "H",
      "h",
      "I",
      "i",
      "J",
      "j",
      "K",
      "k",
      "L",
      "l",
      "M",
      "m",
      "N",
      "n",
      "O",
      "o",
      "P",
      "p",
      "Q",
      "q",
      "R",
      "r",
      "S",
      "s",
      "T",
      "t",
      "U",
      "u",
      "V",
      "v",
      "W",
      "w",
      "X",
      "x",
      "Y",
      "y",
      "Z",
      "z",
    ];
    let e = letters.key;

    if (alphabet.includes(e) || letters.code === "Space") {
      textString.push(e);
      insert(letters, textString);
    } else if (letters.code === "Backspace") {
      textString.pop();
      insert(letters, textString);
    } else {
      let topic = letters.target.form[1].value;
      topic.replace(e, "");
      insert(letters, textString);
    }
  }
  /**replacing any potential harmfull content */
  function insert(letters, e) {
    let newString = textString.join().replaceAll(",", "");
letters.target.form[1].value = newString;
  }
/**sending the final text to the db take user back to home screen after 3 seconds */
  function handleFeedBack(e) {
    e.preventDefault();
let topic = e.target.form[0].value;
    let textInput = e.target.form[1].value;

    let textArea = document.getElementById("textarea");
    let text = document.getElementById("text");

    text.style.display = "none";
    textArea.style.visibility = "visible";
    textArea.textContent =
      "Thankyou for your feedback, you will now be taken back the home page ";
    let button = document.getElementById("button");
    button.style.display = "none";
    FeedbackWrite(topic, textInput);
    setTimeout(() => {
      window.location.replace("/");
    }, "3000");
  }

  return (
    <>
      <div className="comments">
        <h1>FeedBack form for Off the Curriculum Users</h1>

        <div className="feedBackForm">
          <form>
            <label htmlFor="feedbackOptions">Please select an option</label>
            <select
              name="feedback"
              id="feedbackOptions"
              required
              onChange={this}
            >
              <option value="" required>
                --Please choose an option--
              </option>
              <option value="Error">Error report</option>
              <option value="suggestion">Suggestion</option>
              <option value="feedback">Feedback</option>
            </select>
            <label htmlFor="feedbackText">Please enter your feedback</label>
            <p>
              If you are reporting an error please state which page you were on
              or going to when the error occurred. Please note only letters are
              allowed in the text area.
            </p>
            <div id="textarea"></div>
            <textarea
              id="text"
              rows="10"
              cols="30"
              className="textbox"
              type="text"
              name="feedbackText"
              required={true}
              minLength="20"
              maxLength="300"
              onKeyDown={(e) => alphaOnly(e)}
              onKeyUp={(e) => insert(e)}
            ></textarea>
            <input
              type="submit"
              onClick={(e) => handleFeedBack(e)}
              id="button"
            />
          </form>
        </div>
      </div>
    </>
  );
}