import { ref, onValue, query } from "firebase/database";

import { db } from "./firebase";
import { UserEmail } from "../App";
import { ButtonToolbar } from "react-bootstrap";

export let mentorDataBox = [];

let docs = [];

export function MentorData(e, data) {

  let email;
  let emailStart = UserEmail.Provider;

  if (emailStart !== null) {
    // e.preventDefault();
    function emails(s) {
      return s.split("").reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
    }

    email = emails(emailStart);
  }
  console.log(emailStart);
  docs.push(email);
  if (mentorDataBox.length === 0) {
    GetMentorData(email);
  } else if (mentorDataBox.length > 0) {
    MentorDataSort();
  }
}
let mentorArray = [];
export function GetMentorData(email) {
  let colour = [];
  let name = [];
  let pic = [];
  let Email = [];

  /**array to hold  all the values */
  GetMentorData.data = [colour, Email, name, pic];
  GetMentorData.key = [];

  const dbRef = query(ref(db, "mentor/" + email));

  onValue(
    dbRef,
    (snapshot) => {
      if (snapshot.val() == null) {
        alert("Profile not found check you entered the correct details");
      } else {
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;

          mentorArray.push(childSnapshot.val());

          /** pushing the values into [1] for ease of access */
          GetMentorData.data[1].color = mentorArray[0].color;

          GetMentorData.data[1].Email = mentorArray[0].Email;
          GetMentorData.data[1].name = mentorArray[0].Name;
          GetMentorData.data[1].pic = mentorArray[0].picture;
          GetMentorData.data[1].id = childKey;
          GetMentorData.data[1].student = mentorArray[1];
 
        });
      }
      mentorDataBox.push(GetMentorData.data[1]);
      MentorDataSort();
    },
    {
      onlyOnce: false,
    }
  );

  return mentorDataBox[0];
}

export function MentorDataSort(e) {
  if (mentorDataBox.length > 0) {
    let email = docs[0];

    let section = document.createElement("section");
    let ul = document.createElement("ul");

    let instruct = document.createElement("h5");
    instruct.innerText = " Click the mentee name you want to chat with";

    ul.append(instruct);
    mentorDataBox.map((data) => {
      let doc = document.getElementById("mentorForm");
      console.log('hello', doc)
      if (data.Email === email) {
        let documents = document.createElement("h3");
        let image = document.createElement("img");
        let holder = document.createElement("div");

        //  = document.createElement("button");
        Object.entries(data.student).forEach((stu) => {
          if (Object.entries(stu[1]).length === 1) {
            let button = document.createElement("button");
            button.className = "mentee";
            button.innerText = Object.entries(stu[1])[0][0];
            button.id = Object.entries(stu[1])[0][1];
            button.className = "mentee";
          } else {
            Object.entries(stu[1]).forEach((s, i) => {
              let button = document.createElement("button");
              button.className = "mentee";

              button.innerText = s[0];
              button.id = s[1];
              ul.appendChild(button);
            });
          }
        });

        section.append(ul);

        section.id = "Students";
        section.style.visibility = "hidden";
        image.src = data.pic;
        image.className = "pic";
        image.alt = "your avatar";

        documents.id = data.Email;
        documents.innerText = "Welcome " + data.name;
        documents.className = " mentorDataSet";

        holder.append(image);
        holder.className = "mentorUserDetails";
        holder.append(documents);
        holder.append(section);
        doc.classList.add("mentorHeader");
        doc.style.backgroundColor = data.color;

        if (
          data.color === "blue" ||
          data.color === "red" ||
          data.color === "purple"
        ) {
          doc.style.color = "white";
        } else {
          doc.style.color = "black";
        }

        docs = [];

        let sp = document.getElementById("mentorProfile");
        sp.style.visibility = "visible";

        if (sp !== null) {
          if (sp.style.visibility === "visible") {
            let toggle = document.getElementById("toggle");

            return doc.replaceChildren(holder, sp);
          } else {
          }
        }
      } else if (mentorDataBox.length === 0) {
        MentorData(e);
        alert("Sorry profile not found");
      }
    });
  }
}