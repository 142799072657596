

const Articles = [    
  {
    uid: 1,
    title: "Advice for young people: Unleashing the Power of Self-Gratitude",
    imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FunleashingSelfGratitude.webp?alt=media&token=aa5ecbf9-4280-4a7a-ba92-69506094df52`,
    imageSrcAlt: 'Advice for young people: Breath work and meditation for managing stressful times',
    date: `17/05/2023`, 
    author: `Dr. Joseph Levermore`,
    time: `10 minutes read`, 
    articleDesc: `
    It's time to turn up the self-appreciation dial and celebrate the hard work you've put into achieving your goals. Self-gratitude is like giving yourself a high-five, a pat on the back... 
        `,
    articlepagehref: `/AdviceforYoungPeoplePowerOfSelfGratitude`,
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FguidedMeditationSessionbeforeExam.webp?alt=media&token=d34fa26b-7dfa-41ed-aa3d-7cf45ccd73ea',
  }, 
  {
      uid: 2,
      title: "Advice for young people: A guided meditation session before entering an exam",
      imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FguidedMeditationSessionbeforeExam.webp?alt=media&token=d34fa26b-7dfa-41ed-aa3d-7cf45ccd73ea`,
      imageSrcAlt: 'Advice for young people: Breath work and meditation for managing stressful times',
      date: `16/05/2023`, 
      author: `Dr. Joseph Levermore`,
      time: `10 minutes read`, 
      articleDesc: `
      Welcome, fearless exam warrior! Before you step into the battleground of knowledge and conquer that exam, let's take a moment to find your inner calm... 
          `,
      articlepagehref: `/AdviceforYoungPeopleGuidedMeditation`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FguidedMeditationSessionbeforeExam.webp?alt=media&token=d34fa26b-7dfa-41ed-aa3d-7cf45ccd73ea',
    }, 
    {
      uid: 3,
      title: "Advice for young people: Breath work and meditation for managing stressful times",
      imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FbreathWorkAndMeditationforManagingStressfulTimes.webp?alt=media&token=118ec6d3-bf19-4c34-9389-67c7dcdb9b85`,
      imageSrcAlt: 'Advice for young people: Breath work and meditation for managing stressful times',
      date: `15/05/2023`, 
      author: `Dr. Joseph Levermore`,
      time: `10 minutes read`, 
      articleDesc: `
      Exams can be super stressful, right? Your palms get sweaty, your heart starts racing, and your mind goes into overdrive...
          `,
      articlepagehref: `/AdviceforYoungPeopleBreathWorkAndMeditation`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
    }, 
    {
      uid: 4,
      title: "Advice for young people: Essay writing - tips and tricks",
      imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FessayWritingTipsAndTricks.webp?alt=media&token=3caafa09-1b60-4a36-86be-62b6250879db`,
      imageSrcAlt: 'Advice for young people: Essay writing - tips and tricks',
      date: `12/05/2023`, 
      author: `Dr. Joseph Levermore`,
      time: `10 minutes read`, 
      articleDesc: `
      Writing a great A-level essay answer can be a challenging task, but with the right techniques and strategies, it is possible to produce...
          `,
      articlepagehref: `/AdviceforYoungPeopleWriteAGreatAlevelEssay`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
    }, 
    {
        uid: 5,
        title: "Advice for young people: Designing a two-hour study period",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FdesignYourTwoHourStudyPeriod.webp?alt=media&token=367cddcd-a674-4f3d-96be-c99f23b1fe00`,
        imageSrcAlt: 'Advice for young people: Designing a two-hour study period',
        date: `11/05/2023`, 
        author: `Dr. Joseph Levermore`,
        time: `10 minutes read`, 
        articleDesc: `
        Revising for an exam can be a daunting task, especially if you don't have a clear plan or schedule in place. In this article...
            `,
        articlepagehref: `/AdviceforYoungPeopleStudingforATwoHourPeriod`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      }, 
    {
        uid: 6,
        title: "Advice for young people: Best revision techniques",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FbestRevisionTechniques.webp?alt=media&token=b8fa03ea-85e7-4a5d-a2f5-2edf10f5fdf0`,
        imageSrcAlt: 'Advice for young people: How to best prepare for exams Image',
        date: `10/05/2023`, 
        author: `Dr. Joseph Levermore`,
        time: `10 minutes read`, 
        articleDesc: `
        Revising and memorising difficult content can be a challenging task for students, particularly when faced with a large amount of information to learn...
            `,
        articlepagehref: `/AdviceforYoungPeopleBestRevisionTechniques`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      }, 
    {
        uid: 7,
        title: "Advice for parents: How to support your teen in preparation for their examinations.",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FexamPreparation.webp?alt=media&token=e3794c0d-5108-46c3-a9f8-dd33ed991104`,
        imageSrcAlt: 'Advice for parents: How to support your teen in preparation for their examinations.',
        date: `09/05/2023`, 
        author: `Dr. Joseph Levermore`,
        time: `10 minutes read`, 
        articleDesc: `
        As a parent, you want the best for your adolescent child. This includes helping them prepare for their exams. Exams can be a stressful time for both the child and the parent..
            `,
        articlepagehref: `/AdviceOnPreparingForExaminations`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      }, 
    {
      uid: 8,
      title: "Young. Black. And Neurodiverse.",
      imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FyoungBlackNeurodiverse.webp?alt=media&token=e3c4353f-b53b-4d3e-a7bf-93f0666c15e3`,
      imageSrcAlt: 'Young. Black. And Neurodiverse.',
      date: `06/02/2023`, 
      author: `Nicole Curtis`,
      time: `10 minutes read`, 
      articleDesc: `
      Joshua-Tobiloba Olowe, a student attending Off the Curriculum's seminar series wrote a spoke-word about being 'Young Black And Neurodiverse'.
          `,
      articlepagehref: `/YoungBlackAndNeurodiverse`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
    },
    {
        uid: 9,
        title: "Off the Curriculum Welcomes Our Newly Recruited Trustee Boards.",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Ftrustee_board.webp?alt=media&token=493c92ad-ac21-45ef-a7d6-6baf1d000b4c`,
        imageSrcAlt: 'Teach, Watch, and Learn Article header',
        date: `12/06/2021`, 
        author: `Nicole Curtis`,
        time: `10 minutes read`, 
        articleDesc: `
        We at Off the Curriculum are proud to Introduce our incredible board of trustees.
            `,
        articlepagehref: `/Trusteesatotc`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      },
      {
        uid: 10,
        title: "Teach, Watch and Learn: We are recruiting Educo volunteers!",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Feduco_recruiting.webp?alt=media&token=5fd65aed-a6e8-4092-a689-f7a977a13c20`,
        imageSrcAlt: 'Teach, Watch, and Learn Article header',
        date: `12/06/2021`, 
        author: `Nicole Curtis`,
        time: `10 minutes read`, 
        articleDesc: `
        We at Off the Curriculum are recruiting volunteers for our Educo platform, find out more here.
            `,
        articlepagehref: `/Educovolunteers`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      },
      {
        uid: 11,
        title: "Learning to learn at home: tips and tricks",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6`,
        imageSrcAlt: 'Learning to learn at home: tips and tricks image',
        date: `12/06/2021`, 
        author: `Nicole Levermore`,
        time: `10 minutes read`, 
        articleDesc: `
        The pandemic has shifted learning to the home, here's how make the most out of it?
            `,
        articlepagehref: `/Educovolunteers`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      },
      {
        uid: 12,
        title: "Mindfulness",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Fmindfulness.webp?alt=media&token=f8585110-94e6-42ea-8a3b-ad1979e436d4`,
        imageSrcAlt: 'Teach, Watch, and Learn Article header',
        date: `12/06/2021`, 
        author: `Nicole Levermore`,
        time: `10 minutes read`, 
        articleDesc: `
        Practicing mindfulness has been associated with multiple health benefits, why not find out how it can help you?
            `,
        articlepagehref: `/Educovolunteers`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      },
]

export {
    Articles
}