import { Pics } from "./profilePictureCreate";
import { useProfileStore } from "../../../components/common/statePath/profileStore.tsx";
import { ref, update } from "firebase/database";
import { db } from "../../../firebase/firebase";
import { Link } from "react-router-dom";

export function ChangeProfile() {

  
  function profileUpdate(e) {
    let pics = document.getElementById("image");

    e.preventDefault();

    let id = useProfileStore.getState().profile[0].id;
    //let name = useProfileStore.getState().profile[0].name;
    // let email = useProfileStore.getState().profile[0].email;
    //let color = useProfileStore.getState().profile[0].color;

    let pic = pics.children.pic.currentSrc;

    writeUserData(id, pic);
    alert("Success! Your Profile photo has been updated!")
    //useProfileStore.getState().profile=[]
  }

  function writeUserData(id, pic) {
    const updates = {};
    updates["person/" + id + "/picture"] = pic;
    update(ref(db), updates);
  }

  return (
    <>
      <div>
        <form onSubmit={(e) => profileUpdate(e)} className="userDetails">
          <Pics />
        </form>
        <Pics />
      </div>
      <Link to="/Profile">Back to profile login</Link>
    </>
  );
}
