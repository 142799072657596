const BecomeAnOfficer = () => {
  return (
    <>
      <head>
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/become-an-officer"
        />
        <meta property="og:title" content="Career" />
        <meta property="og:description" content="#" />
        <meta property="og:image" content="#" />
        <meta property="og:image:secure_url" content="#" />
        <meta property="og:image:alt" content="#" />
      </head>
      <div className="centre-frame">
        <h1>
          Be a part of history and apply to be an Officer at Off the Curriculum
          today!
        </h1>
        <div className="form_center form_full_height">
          <iframe
            src="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfJpVx50dWKPN0gT1cDcrAysp3bffAiZDa5DLiPq9KBnErAbg/viewform?embedded=true"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            className="assessment-frame"
            title='volunteer sign up form'
          >
            Loading…
          </iframe>
        </div>
      </div>
    </>
  );
};

export default BecomeAnOfficer;
