import { update, ref } from "firebase/database";
import { db } from "../../../../../firebase/firebase";

const subjects = [
    {
      astro: [
        "spectroscopy",
        "matter",
        "reflected",
        "absorbed",
        4,
        5,
        8,
        14,
        20,
        27,
        34,
        40,
      ],
    },
    {
      trial: [
        "spectroscopy",
        "matter",
        "reflected",
        "absorbed",
        4,
        5,
        8,
        14,
        20,
        27,
        34,
        40,
      ],
    },
  ];
  
  export default function QuizChecker(subject, e) {
    let userAnswer = e.target.form.querySelectorAll(".answer");
    let answerHolder = [];
    let results = [];
    let bin1 = "";
    let bin2 = "";
    let score = 0;
    for (let i = 0; i < userAnswer.length; i++) {
      if (userAnswer[i].checked === true) {
        answerHolder.push(i);
      } else if (userAnswer[i].checked === false) {
      } else {
        answerHolder.push(userAnswer[i].value);
      }
    }
  
    for (const [key, value] of Object.entries(subjects)) {
      for (let names in value) {
        if (names === subject) {
          for (const [index, values] of Object.entries(value)) {
            results.push(values);
          }
  
          while (results[0].length > 0) {
            bin2 = answerHolder.shift();
            bin1 = results[0].shift();
  
            if (bin1 === bin2) {
              score += 10;
            } else {

            }
          }
          updateProfileScore(score)
        }
      }
    }
  }

function updateProfileScore(score) {
    let profileStorage = JSON.parse(localStorage.getItem("profile-storage"));
  
    let id = profileStorage.state.profile[0].id;
    const updates = {};
    updates["person/" + id + "/scores" + "/astroScore"] = score;
    updates["person/" + id + "/token"] = true;
    update(ref(db), updates);
  }