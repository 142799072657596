import React from "react";
import AstrophysicsQuestion from "../quizQuestionSheets/AstrophysicsQuestionSheet";
import './quizTemplate.css'; 

export function QuizTemplate (){
    //So here you would get the title page of the form and then find the right form - submit this, and grab the form from where you need it. 
    return (
        <>
        <div className="centre-frame">
            <div className="card-course">
                <div className="card-horizontal">
                <div className="card-body">
                    <div className="card-educo-title">
                    <img
                        className="course-infographic"
                        src=''
                        alt="Assessment"
                    ></img>
                    <span className="card-educo-course-title">
                        <a
                        id="astronomy-tutorial-2"
                        href="Assessment"
                        className="educoSectionTitles"
                        >
                        Time for a quick quiz!
                        </a>
                    </span>
                    </div>
                    <hr className="educo-subject-course" />
                    <p className="educo-video-introduction">
                    Now it is your turn to show us how much you have learned. On what planet are there oceans of boiling water? Which planet did the Juno mission study? And did Curiosity find evidence for liquid water? Log in to the below Google Form and show us your Astrophysics skills!
                    </p>
                    <div className="QuizPageFormScroll">
                        <AstrophysicsQuestion />
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    );
}