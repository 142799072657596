import { ref, onValue, query } from "firebase/database";
import { useEffect, useState } from "react";
import { auth } from "./firebase";
import { db } from "./firebase";
import { ProfilePage } from "../pages/educo/profile/ProfileComponents/profilePage";

export function GetData() {
  const [profile, setProfile] = useState("....Loading");
  const [student, setStudent] = useState([]);
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe(); // Cleanup the subscription
  }, []);

  function emails(s) {
    return s.split("").reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  useEffect(() => {
    if (currentUser) {
      const emailHash = emails(currentUser.email);
      const dbRef = query(ref(db, `person/${emailHash}`));
      onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.val() == null) {
            window.location.replace("/CreateAProfile");
          } else {
            const profileData = snapshot.val();
            setStudent(profileData);
            //console.log("profile data", profileData);
          }
        },
        {
          onlyOnce: true,
        }
      );
    }
  }, [currentUser]);

  useEffect(() => {
    if (student.Name !== undefined) {
      setProfile(
        <div>
          <div style={{ backgroundColor: student.colour }}>
            <img src={student.picture} alt="profile " className="picProfile" />
            <h1> Welcome {student.Name}! </h1>
          </div>
          <ProfilePage reference={student} />
        </div>
      );
    }
  }, [student]);

  return (
    <div id="output">
      {student.pic !== undefined ? (
        <div>...loading</div>
      ) : (
        <div>{profile}</div>
      )}
    </div>
  );
}
