import React from "react";
import lambethAcademy from "../../../images/functionality/main/education/partnered-schools/lambeth-academy-logo.webp";
import harrisAcademy from "../../../images/functionality/main/education/partnered-schools/harris-academy-bermondsey.webp";
import thomasApostles from "../../../images/functionality/main/education/partnered-schools/st-thomas-the-apostle.webp";
import cityofLondon from "../../../images/functionality/main/education/partnered-schools/city-of-london-academy-southwark.webp";
import elmgreenSchool from "../../../images/functionality/main/education/partnered-schools/the-elmgreen-school.webp";
import charterNorth from "../../../images/functionality/main/education/partnered-schools/charter-school-north-dulwich.webp";
import charterEast from "../../../images/functionality/main/education/partnered-schools/charter-school-east-dulwich.webp";
//{ MetaTags } from "react-meta-tags";
import eductionTeam from "../../../images/functionality/main/education/education/eductionTeam.webp";
import { partneredSchoolspage } from "./Media/Partneredschools";

function partneredsixthforms() {
  return (
    <React.Fragment>
      {/*<MetaTags>
        <title>Partnered Sixth Forms at Off the Curriculum</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta
          name="description"
          content="Off the Curriculum is has established links with seven Sixth Form Institutions across the London Boroughs of Lambeth and Southwark. These are…"
        />
        <meta
          name="keywords"
          content="Education, Charity, News, Educational News, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta name="title" content="Education at Off the Curriculum" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Partneredsixthforms"
        />
        <meta
          property="og:title"
          content="Partnered Sixth Forms at Off the Curriculum"
        />
        <meta
          property="og:description"
          content="Off the Curriculum is has established links with seven Sixth Form Institutions across the London Boroughs of Lambeth and Southwark. These are……"
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/education/headers/about_education.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/education/headers/about_education.webp"
        />
        <meta
          property="og:image:alt"
          content="Partnered Sixth Forms at Off the Curriculum."
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Partneredsixthforms"
        />
        <meta
          property="twitter:title"
          content="Education at Off the Curriculum"
        />
        <meta
          property="twitter:description"
          content="Off the Curriculum is has established links with seven Sixth Form Institutions across the London Boroughs of Lambeth and Southwark. These are……"
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/education/headers/about_education.webp"
        />
        <meta
          property="twitter:image:alt"
          content="Partnered Sixth Forms at Off the Curriculum"
        />
      </MetaTags>
  */}
      <div className="centre-frame">
        <div className="second-second-Education">
          <h1 className="news_article" id="Partnered-sixth-forms">
            {partneredSchoolspage.pageTitle}
          </h1>
          <p className="educationP">
            Off the Curriculum is has established links with seven Sixth Form
            Institutions across the London Boroughs of Lambeth and Southwark.
            These are <b>Lambeth Academy</b>, <b>Harris Academy Bermondsey</b>,{" "}
            <b>St Thomas Apostles</b>, <b>South London Academy</b>,{" "}
            <b>Charter School East Dulwich</b>,{" "}
            <b>Charter School North Dulwich</b>, and <b>the ElmGreen School</b>.
            All partnered schools have been given the opportunity to enrol 10
            students from their Year 12 Sixth Form cohorts (2021-2022). Off the
            Curriculum is honoured to deliver an educational platform to ~50
            students and are excited to see what the future holds for these
            incredibly articulate and intelligent young people.
          </p>
          <h2 className="news_article" id="Partnership-role">
            Role in such Partnerships
          </h2>
          <h3 className="educationHeader">
            General services offered to engaged institutions
          </h3>
          <p className="educationP">
            All Sixth Form students enrolled in such Sixth Forms are freely able
            to enrole in our educational programme, attend any of our seminars
            via our <b>Affiliates Programme</b>, and interact with content on
            our online education portal,{" "}
            <a className="nav_email" href="/EDUCO">
              <b>EDUCO</b>
            </a>
            .
          </p>
          <h3 className="educationHeader" id="Affiliates-programme">
            Affiliates Programme
          </h3>
          <p className="educationP">
            {partneredSchoolspage.affiliatesProgramme}
          </p>
          <h3 className="educationHeader" id="Lambeth-academy">
            Partnership with Elm Green, formally Lambeth Academy
          </h3>
          <p className="educationP">
            Off the Curriculum's partnership with Lambeth Academy differs from
            the rest, where we are collaboratively running the EPQ programme for
            students enrolled in their Excellence in Thinking Programme.
          </p>
          <h3 className="educationHeader" id="Expansion-future-plans">
            Expansion and future plans
          </h3>
          <p className="educationP">
            Off the Curriculum is at present interested in expanding our
            services across London from our home constituencies of Lambeth and
            Southwark, and increase our programme participant number to 100
            student. If your institutes
          </p>
          <ul className="grid cs-style-1">
            <h2 className="educationHeader">Partnered Sixth Forms</h2>
            <li>
              <figure>
                <a href="https://www.lambeth-academy.org/">
                  <img
                    src={lambethAcademy}
                    className="partneredSchools"
                    alt="lambeth academy"
                  ></img>
                </a>
                <h5 className="card-title">Lambeth Academy</h5>
                <hr className="partneredSchools"></hr>
                <h4 className="card-qoute">Location: Lambeth</h4>
              </figure>
            </li>
            <li>
              <figure>
                <a href="https://www.harrisbermondsey.org.uk/">
                  <img
                    src={harrisAcademy}
                    className="partneredSchools"
                    alt="harris bermondsey academy"
                  ></img>
                </a>
                <h5 className="card-title">Harris Academy Bermondsey</h5>
                <hr className="partneredSchools"></hr>
                <h4 className="card-qoute">Location: Southwark</h4>
              </figure>
            </li>
            <li>
              <figure>
                <a href="https://www.stac.uk.com/">
                  <img
                    src={thomasApostles}
                    className="partneredSchools"
                    alt="St thomas the apostle"
                  ></img>
                </a>
                <h5 className="card-title">
                  St Thomas Apostles School and Sixth Form College
                </h5>
                <hr className="partneredSchools"></hr>
                <h4 className="card-qoute">Location: Lambeth</h4>
              </figure>
            </li>
            <li>
              <figure>
                <a href="https://www.cityacademy.co.uk/">
                  <img
                    src={cityofLondon}
                    className="partneredSchools"
                    alt="City of London Academy Southwark"
                  ></img>
                </a>
                <h5 className="card-title">City of London Academy Southwark</h5>
                <hr className="partneredSchools"></hr>
                <h4 className="card-qoute">Location: Southwark</h4>
              </figure>
            </li>
            <li>
              <figure>
                <a href="https://www.cityacademy.co.uk/">
                  <img
                    src={charterEast}
                    className="partneredSchools"
                    alt="Charter School East Dulwich"
                  ></img>
                </a>
                <h5 className="card-title">Charter School East Dulwich</h5>
                <hr className="partneredSchools"></hr>
                <h4 className="card-qoute">Location: Southwark</h4>
              </figure>
            </li>
            <li>
              <figure>
                <a href="https://www.cityacademy.co.uk/">
                  <img
                    src={charterNorth}
                    className="partneredSchools"
                    alt="Charter School North Dulwich"
                  ></img>
                </a>
                <h5 className="card-title">Charter School North Dulwich</h5>
                <hr className="partneredSchools"></hr>
                <h4 className="card-qoute">Location: Southwark</h4>
              </figure>
            </li>
            <li>
              <figure>
                <a href="https://www.the-elmgreen-school.org.uk/">
                  <img
                    src={elmgreenSchool}
                    className="partneredSchools"
                    alt="Elmgreen School"
                  ></img>
                </a>
                <h5 className="card-title">Elmgreen School</h5>
                <hr className="partneredSchools"></hr>
                <h4 className="card-qoute">Location: Southwark</h4>
              </figure>
            </li>
          </ul>
        </div>
        <div className="one-second-Education">
          <div className="columnEducationOne">
            <img
              src={eductionTeam}
              className="authorEducation"
              alt="Education team  at Off the Curriculum"
            ></img>
            <ul className="news_article_summary">
              <br />
              <div></div>
            </ul>
            <div className="news-icon">
              <div className="footer_center"></div>
            </div>
            <h2 className="About_the_author">About the team:</h2>
            <p className="prose_about_the_author">
              The Education team is in charge of developing Off the Curriculum's
              pedagogic philosophy. The Education team is responsible for our
              Seminar Series, reviewing EDUCO content, and facilitating the EPQ
              programme.
            </p>
          </div>
          <div className="columnEducationTwo">
            <h4 className="educationquickLinks">Article quick links:</h4>
            <li className="news_article">
              <a className="news_article" href="#Partnered-sixth-forms">
                Partnered Sixth Forms
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Partnership-role">
                Role in such Partnerships
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Affiliates-programme">
                Affiliates programme
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Lambeth-academy">
                Partnership with Lambeth Academy
              </a>
            </li>
            <li className="news_article">
              <a className="news_article" href="#Expansion-future-plans">
                Expansion and future plans
              </a>
            </li>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default partneredsixthforms;
