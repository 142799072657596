import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ref, get, child, getDatabase, connectDatabaseEmulator } from 'firebase/database';
import React from 'react';
import { db } from '../../../../firebase/firebase';
import { getAuth } from 'firebase/auth';
import { CreateAProfile } from '../createAProfile';


const FirebaseDataContext = createContext();

export const useFirebaseData = () => useContext(FirebaseDataContext);

export const FirebaseDataProvider = React.memo(({ videoName, children }) => {
  const [profileData, setProfileData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [video, setVideo] = useState(null);
  const [trainingVideo, setTrainingVideo] = useState(null);
  const dbRef = ref(getDatabase());
  const auth = getAuth();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  }, []);

  const profileDb = useMemo(() => {
    if (currentUser && currentUser.email) {
      const email = currentUser.email;
      const emailHash = emails(email);
      get(child(dbRef, `person/${emailHash}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setProfileData(data);
          } else {
            window.location.replace('/CreateAProfile')
          }
        })
        .catch((error) => {
          console.log(error)
        });
    }
  }, [currentUser]);

  const videoDB = useMemo(() => {
    if (profileData && profileData.lastViewed) {
      get(child(dbRef, `video/name/${profileData.lastViewed}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setVideo(data);
          } else {
            // Handle the case when the data doesn't exist
          }
        })
        .catch((error) => {
          // Handle any errors that occur during fetching the data
        });
    }
  }, [profileData]);

  const trainingDB = useMemo(() => {
    if (profileData && profileData.Mentor === true) {
      get(child(dbRef, `video/trainingVideos/EPQTrainingVideo`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setTrainingVideo(data);
          } else {
            // Handle the case when the data doesn't exist
          }
        })
        .catch((error) => {
          // Handle any errors that occur during fetching the data
        });
    }
  }, [profileData]);
  
  // resources portal 

  function emails(s) {
    return s.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  return (
    <FirebaseDataContext.Provider value={{ profileData, video, trainingVideo }}>
      {children}
    </FirebaseDataContext.Provider>
  );
});
