const objFundraisingOfficer = {
  title: 'Fundraising Officer',
  subtitle: 'London or Remote',
  desc: ``,
  descFull: `We’re really excited to welcome a values-led, creative, and 
            resourceful Fundraising Officer to assist in our fundraising 
            endeavors from our Events & Communities income streams.`,
  skills: ['Accountancy / Auditing', 'Financial Management'],
  where: {
    location: 'London, SE12DU',
    desc: 'Mainly at home, or remote opportunity.',
  },
  time: {
    workTime: 'Either in or out of office hours',
    timeNeeded: '3-7 hours / week',
  },
  whatWillYouBeDoing: {
    p: `The main duties of the role:`,
    ul: [
      `To explore and identify funding opportunities`,
      `To play an active role in the procurement of funding for our organisation`,
      `To provide support to the Board members and senior volunteers in 
        developing relationships with potential donors`,
      `To collaborate with other members of the Charity to boost funding portfolio`,
      `To continuously attempt to build new relationships and develop new 
        opportunities for the Charity and the community it aims to serve`,
      `To review and implement financial policies`,
      `To participate in financial audits`,
    ],
  },
  whatAreWeLookingFor: {
    p: ``,
    ul: [
      `Working knowledge of financial regulations`,
      `Strong ethics`,
      `Ability to manage confidential data, knowledge of financial and 
        accounting procedures, analytical and numerical skills, while a 
        professional qualification (for example ACA/ACCA/CFA) is considered an 
        advantage`,
      `Excellent communication and organisational skills`,
      `Prioritisation and time-management skills`,
      `Independent and team player skills`,
    ],
  },
  whatDifferenceWillYouMake: {
    p: `The Recruitment Officer will have direct impact on the growth of the 
        Charity and the quality of the participants' and volunteers' experience. 
        Helping with recruitment, they will have the opportunity to build 
        prosperous relationships with the local community and all those involved 
        with the Charity.`,
    ul: [],
  },
  whatsInItForTheVolunteer: {
    p: ``,
    ul: [],
  },
};

export default objFundraisingOfficer;
