import Antivax from "../../../../../images/functionality/educo/icons/term-1/immunology/antivax.webp";
import Disease from "../../../../../images/functionality/educo/icons/term-1/immunology/disease.webp";
import Health from "../../../../../images/functionality/educo/icons/term-1/immunology/health.webp";
import Immunity from "../../../../../images/functionality/educo/icons/term-1/immunology/immunity.webp";
import ImmunologyIcon from "../../../../../images/functionality/educo/icons/term-1/immunology/immunology-icon.webp";
import InHealth from "../../../../../images/functionality/educo/icons/term-1/immunology/in-health.webp";
import Viral from "../../../../../images/functionality/educo/icons/term-1/immunology/viral.webp";
const objImmunology = {
    webpagedesc: ``, 
    webpagekeywords: ``, 
    webpagepageurl: ``, 
    webpageimage: ``, 
    webpageimagealt:``, 
    organisationtwitter: ``, 
    title: `Immunology`, 
    courseDesc:`
    During this online immunology series, you will explore viral
    strategies, how 'pollutants' evade our natural defenses, and the
    misinformation of anti-vax propaganda.
    `, 

    quickLinkOneDesc: `
    Induction Seminar
    `, 
    quickLinkOne:`
    #induction-seminar
    `,
    quickLinkTwoDesc: `
    In Health, In Disease
    `, 
    quickLinkTwo:`
    #in-health-in-disease
    `,
    quickLinkThreeDesc: `
    All Things Viral
    `, 
    quickLinkThree:`
    #all-things-viral
    `,
    quickLinkFourDesc: `
    Viral Strategies
    `, 
    quickLinkFour:`
    #viral-strategies
    `,
    quickLinkFiveDesc: `
    Immune Counterattack
    `, 
    quickLinkFive: `
    #immune-counterattack
    `, 
    courses:[
        {
            key: 1, 
            sectionimage: Disease, 
            sectionimageAlt: `Section one of the immunology course icon`,
            sectiontitle: `
            Induction Seminar
            `, 
            sectiondesc: `
            Led by Dr. Ian Mudway, our Induction Seminar on Immunology
            explored the immunological responses to Air Pollution, and how
            such interactions promote cellular and organ responses that harm
            our health.
            `, 
            sectionlink: `
            /ImmunologyInductionSeminarSeries
            `, 
            coursedesc: `
            Click here to watch their Lecture
            `,
            educovideo:
            [
                {
                    key: 1, 
                    videoTitle: 'Immunology 101', 
                    videoDesc:`Marwa explores the 'The Swahili States', which were a number of major cities on the East African coast between the 9th and 16th centuries.`, 
                    videoInfo: `100 views | 10 days ago | Dr. Ian Mudway`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fimmunology%2FImmunologyInductionSeminar%2FimmunologyInductionSeminarThumbnail.webp?alt=media&token=5de504b3-2a90-46f9-86c6-bcbedfc09ea4',
                    videoImageAlt:`
                    Immunology 101 Thumbnail`, 
                    videohref:`/ImmunologyInductionSeminar`, 
                },
            ],
        },
        {
            key: 2, 
            sectionimage: InHealth, 
            sectionimageAlt: `Section one of the immunology course icon`,
            sectiontitle: `
            In Health, In Disease
            `, 
            sectiondesc: `
            In this Educo series, you will discover that the immune system
            has a central role in many aspects of health and disease in both
            plants and animals. While the immune system is critical for
            protecting us from pathogens, it also plays a role in cancer
            surveillance and is the reason why tissue transplantation is
            difficult to achieve. The videos will be focused on the
            development and structure of the immune system, and the role it
            plays in a range of disease and health states.
            `, 
            educovideo:
            [
                {
                    key: 1, 
                    videoTitle: `Innate Immunity: Phagocytosis`, 
                    videoDesc:`The innate immune system has many ways in which it responds to the threat of infection, one of these is phagocytosis`, 
                    videoInfo: `100 views | 10 days ago | Beverley Rodger`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fimmunology%2FImmunologyInnateImmunityPhagocytosis%2FImmunologyInnateImmunityPhagocytosisThumbnail.webp?alt=media&token=79259f2c-4ba7-4c59-add8-b88f9379ea7b',
                    videoImageAlt:`Innate Immunity Phagocytosis Thumbnail`, 
                    videohref:`/ImmunologyInnateImmunityPhagocytosis`, 
                }, 
            ],
        },
        {
            key: 3, 
            sectionimage: Viral, 
            sectionimageAlt: `Section one of the immunology course icon`,
            sectiontitle: `
            All Things Viral
            `, 
            sectiondesc: `
            Viruses have co-evolved with their hosts. Therefore, viruses have
            limited pathogenicity in an immuno-competent natural host. In
            turn, probably as a result of the constant evolutionary pressure
            from viral invaders, vertebrates have developed a complex immune
            system. Only in the last decade have we caught a glimpse of what
            viruses do beyond invading cells for replication. During this
            seminar, you will explore how viruses, which have evolved over
            millions of years to acquire and defend an ecological niche,
            were studied in cell biology and immunology the hard way.
            `, 
            educovideo:
            [
                {
                    key: 1, 
                    videoTitle: `Viruses: All things viral`, 
                    videoDesc:`The innate immune system has many ways in which it responds to the threat of infection, one of these is phagocytosis`, 
                    videoInfo: `100 views | 10 days ago | Beverley Rodger`, 
                    videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fimmunology%2FImmunologyAllThingsViral%2FimmunologyAllThingsViralThumbnail.webp?alt=media&token=c1e8c504-1077-4f20-a243-2897bb2443fc',
                    videoImageAlt:`All things viral Thumbnail`, 
                    videohref:`/ImmunologyAllThingsViral`, 
                }, 
            ],
        },
        {
            key: 4, 
            sectionimage: Health, 
            sectionimageAlt: `Section one of the immunology course icon`,
            sectiontitle: `
            Viral Strategies
            `, 
            sectiondesc: `
            In these EDUCO videos, you will further your knowledge on
            viruses from the previous seminar, and explore the viral
            strategies used by Viruses to ensure their survival. You will
            explore the two forms of viruses (extracellular virion particles
            and intracellular genomes) and immunoregulatory proteins
            (encoded by genes with or without sequence homology to cellular
            genes).
            `, 
            educovideo:
            [
                {

                },
            ],
        },
        {
            key: 5, 
            sectionimage: Immunity, 
            sectionimageAlt: `Section one of the immunology course icon`,
            sectiontitle: `
            Immune Counterattack
            `, 
            sectiondesc: `
            In this EDUCO videos, you will learn that there are two very
            different strategies in harnessing the power of the immune
            system: passive and active immunisation. Passive immunisation
            concerns giving antibodies developed from one immune source to
            protect another vulnerable individual, while in active
            immunisation, the person to be protected makes their own
            antibodies. The lecturer will then speak about Shibasaburo
            Kitasato and Emil von Behring's pioneering research in the late
            1800s on developing Sera (Convalescent Plasma Therapy) to be
            used against diphtheria and anthrax as a case study
            `, 
            educovideo:
            [
                {

                },
            ],
        },
        {
            key: 6, 
            sectionimage: Antivax, 
            sectionimageAlt: `Section one of the immunology course icon`,
            sectiontitle: `
            Immunity and the antivax
            `, 
            sectiondesc: `
            In these EDUCO videos, you will explore common vaccination
            myths: 'vaccines cause autism'; 'too many [vaccinations] may
            overwhelm your immune system'; 'natural immunity is better than
            vaccine-acquired immunity'; 'the unsafe toxins contained in
            vaccinations'; and that 'better hygiene and sanitation is
            responsible for decreased infections and not vaccinations'. Get
            ready for a debate!
            `, 
            educovideo:
            [
                {

                },
            ],
        }
    ]
}

export default objImmunology; 