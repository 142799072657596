import { Profile } from "./profile";
import { ProfileLogin } from "./profileLogIn";
import './profile.css';

export default function Congrats() {
 
  return (
    <>
    <div id='congrats'>
      <h1 className ='congratulationsHeaders'>CONGRATULATIONS</h1>
      </div>
      <Profile />
     
    </>
  );
}
