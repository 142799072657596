import styles from './JoinBanner.module.css';
function JoinBanner() {
    const handleClick = () => {
        // Replace the URL below with the desired destination
        const newLocation = "/Enrol"; 
    
        // Open the new URL in a new window
        window.location.href = newLocation;
      }
  return (
    <section className={styles.banner_container}>
      <div className={styles.content_container}>
        <div className={styles.text_container}>
          <h3>Join Off the Curriculum</h3>
          <p>
          Discover a world of learning beyond the classroom for 15-18 year olds!
          </p>
        </div>
        <div className={styles.link_container}>
        <button className="parallaxButton" onClick={handleClick}>
            &#8594; Join today
          </button>
        </div>
      </div>
    </section>
  );
}

export default JoinBanner;