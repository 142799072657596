const ourMottoDesc = {
    desc: `
    Off the Curriculum conducts it's work for the public benefit, to encourage young people to forge new paths by providing an educational service that incorporates "in-person" and "online" learning experiences.
    `, 
    title: `About Off the Curriculum`, 
}

const charityAims = {
    title: "Charity aims",
    listOne: `
    champion young people enrolled in the program to reach their full potential;
    `, 
    listTwo: `
    promote subject matters not currently explored by the National Curriculum; and
    `, 
    listThree: 
    `
    facilitate the development of tighter bonds between young people and members of London's professional community.
    ` 
}

const beliefsatOTC = {
    title: `What we believe!`, 
    valueTitle: 'Values', 
    valuesDesc: `
    To provide young people with access to outstanding training and support outside of the National Curriculum
    `, 
    missionTitle: 'Mission', 
    missionDesc: `
    To encourage young people to forge new paths through educational empowerment and equal opportunity.
    `, 
    visionTitle: `Vision`, 
    visionDesc: `
    To equip young people across London with the skills they need to succeed.
    `, 
}

const timeline = {
    title: `
    The Off the Curriculum Timeline
    `,  
    desc: `
    Off the Curriculum was founded in February 2020, and has since recruited a dedicated and diverse team, developed an educational philosophy, and has provided it's educational services to individuals across London.
    Here is our journey thus far:
    `, 


}

export {
    charityAims, 
    ourMottoDesc, 
    beliefsatOTC, 
    timeline
}