import React, { useRef, useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import './FileUpload.css'
const Fileupload = () => {
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  }, []);
  const fileInput = useRef(null);


  const handleFileUpload = () => {
    function emails(s) {
      return s.split('').reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
    }
    const email = currentUser.email;
    const emailHash = emails(email);
  
    const file = fileInput.current.files[0];
    const storage = getStorage();
    const fileName = file.name; // Extract the file name
    const storageRef = ref(storage, `/EPQ/MenteeFolder/`+emailHash+`/litReview/${fileName}`);

    uploadBytes(storageRef, file)
      .then(() => {
        alert('File uploaded successfully')
        // File uploaded successfully
        //console.log('File uploaded successfully');
      })
      .catch((error) => {
        // Handle any errors during the upload
        alert('There has been an error uploading file:', error)
        //console.error('Error uploading file:', error);
      });
  };

  return (
    <div>
      <label>
        Select File:
        <input
          className='EPQFileUpload'
          type="file"
          ref={fileInput}
          accept=".pdf, .docx, .msword, .doc"
        />
      </label>
      <br />
      <button type="submit" className="votingChecker" onClick={handleFileUpload}>
        Submit
      </button>
    </div>
  );
};

export default Fileupload;