import React, { useRef, useContext} from "react";
import { getAuth } from "firebase/auth";
import Googlelogo from "./images/functionality/google.webp";
//import { auth } from 'firebaseui';
//import { async } from '@firebase/util';
import { LoginContext } from "./App";
import {
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { SignInWithGoogle } from "../src/components/common/signin/sign-in-out";
import './SignIn.css'


function logout() {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
}

const SignIn = (e) => {
  const UserSignedIn = useContext(LoginContext);
  //e.preventDefault()
  const auth = getAuth();


  const googleLogin = () => {
    SignInWithGoogle(e);
  };

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(
      auth,
      emailRef.current.value,
      passwordRef.current.value
    )
      .then((user) => {

      })
      .catch((err) => {
      });
  };

  //



  return (
    <>
      <div className="outer">
        <div className="login-container" id="login-container">
          <div className="form-container log-in-container">
            <form className="login">
              <h1>Login</h1>
              <div className="social-container">
                <span>Sign in with:</span>
                <br />
                <button class='googlebutt'>
                  <img
                    src={Googlelogo}
                    className="img-login"
                    alt="Google Logo"
                    onClick={googleLogin}
                  />
                </button>
              </div>

              <label htmlFor="email">Email</label>

              <input type="email" placeholder="Email..." ref={emailRef} />

              <label htmlFor="password">Password (8 characters minimum):</label>
              <input
                type="password"
                minLength="8"
                maxLength="20"
                required
                placeholder="Password..."
                ref={passwordRef}
              />
              <div className="error_response"></div>

              <button onClick={signIn}>Log In</button>
              <button>
                <a href="/Registerhere" className="nav_email">
                  Register Here
                </a>
              </button>
              <button>
                <a href="/Forgotpassword" className="nav_email">
                  Forgot password
                </a>
              </button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-right">
                <h1 className="login">Welcome to EDUCO!</h1>
                <p className="login">
                  Powered by Off the Curriculum, EDUCO was created to enable you
                  to succeed! To access the platform, please sign in using your
                  Google account.
                </p>
              </div>
            </div>
          </div>
          <div id="logged-in">
            <h3>You are logged in</h3>
            <button onClick={logout}></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
