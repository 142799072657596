import React from "react";
import "./ParallaxTwo.css";

function Parallax() {
        const handleClick = () => {
          // Replace the URL below with the desired destination
          const newLocation = "/Enrol"; 
      
          // Open the new URL in a new window
          window.location.href = newLocation;
        }
  return (
    <React.Fragment>
      <div className="offthecurriculumParallaxTwo">
        <div className="leftColumn">
            <div className="parallaxText">
            <h1>Join Off the Curriculum</h1>
            <p>
                Discover a world of learning beyond the classroom for 15-18 year olds!
            </p>
            </div>
        </div>

        <div className="rightColumn">
          <button className="parallaxButton" onClick={handleClick}>
            &#8594; Join today
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Parallax;