import React from "react";
////{ MetaTags } from "react-meta-tags";
import "./mainStyling/Theteam.css";
import {Title, Description} from 'react-head-meta'
import chrisSmith from "../../../images/functionality/main/meet-the-team/chrisSmith.webp";
import josephLevermore from "../../../images/functionality/main/meet-the-team/josephLevermore.webp";
import nicoleCurtis from "../../../images/functionality/main/meet-the-team/nicoleCurtis.webp";
import emilyFisher from "../../../images/functionality/main/meet-the-team/emilyFisher.webp";
import dhavalShah from "../../../images/functionality/main/meet-the-team/dhavalShah.webp";
import jawarieSuleman from "../../../images/functionality/main/meet-the-team/jawarieSuleman.webp";
import rashidaBadiwe from "../../../images/functionality/main/meet-the-team/rashidaBadiwe.webp";
import nicoleLevermore from "../../../images/functionality/main/meet-the-team/nicoleLevermore.webp";
import saimelaChotza from "../../../images/functionality/main/meet-the-team/saimelaChotza.webp";
import rikkiGordon from "../../../images/functionality/main/meet-the-team/rikkiGordon.webp";
import sineadMurphy from "../../../images/functionality/main/meet-the-team/sineadMurphy.webp";
import Twitter from "../../../images/functionality/main/social-media/twitter.webp";
import Email from "../../../images/functionality/main/social-media/message.webp";
import Linkedin from "../../../images/functionality/main/social-media/linkedin.webp";
import jingZhao from "../../../images/functionality/main/meet-the-team/jingZhao.webp";
import samuelAnimashaun from "../../../images/functionality/main/meet-the-team/samuelAnimashaun.webp";
import teresaSu from "../../../images/functionality/main/meet-the-team/teresaSu.webp";
import debraMurphy from "../../../images/functionality/main/meet-the-team/debraMurphy.webp";
import joeLo from "../../../images/functionality/main/meet-the-team/joeLo.webp";
import traceyEdwards from "../../../images/functionality/main/meet-the-team/traceyEdwards.webp";
import kieranGill from "../../../images/functionality/main/meet-the-team/kieranGill.webp"
import supriyaHarikumar from "../../../images/functionality/main/meet-the-team/supriyaHarikumar.webp"
import timSiu from "../../../images/functionality/main/meet-the-team/timSiu.webp"
import abdulSufi from "../../../images/functionality/main/meet-the-team/AbdulSufi.webp"
import gracePhang from "../../../images/functionality/main/meet-the-team/gracePhang.webp"
import adedoyinOkeowo from "../../../images/functionality/main/meet-the-team/adedoyinOkeowo.webp"
import mingHo from "../../../images/functionality/main/meet-the-team/MingHo.webp"

import { theTeam } from "./media/Theteam";

function meet_the_team() {
  const width = "100%";
  const alt = "Off the Curriculum team member";
  function mailProtector() {
    const p = "mailto:josxexphxlxxevxxermore@offthecurrxiculumx.cox.uxk";
  
    const emailMappings = [
      {
        email: "mailto:josephlevermore@offthecurriculum.co.uk",
        elementId: "josephEmail"
      },
      {
        email: "mailto:nicolecurtis@offthecurriculum.co.uk",
        elementId: "nicoleEmail"
      },
      {
        email: "mailto:jawariasuleman@offthecurriculum.co.uk",
        elementId: "jawariaEmail"
      },
      {
        email: "mailto:saimelachotza@offthecurriculum.co.uk",
        elementId: "saimelaChotzaEmail"
      },
      {
        email: "mailto:emilyfisher@offthecurriculum.co.uk",
        elementId: "emilyFisherEmail"
      },
      {
        email: "mailto:debramurphy@offthecurriculum.co.uk",
        elementId: "debraMurphyEmail"
      },
      {
        email: "mailto:abdulaibadiwe@offthecurriculum.co.uk",
        elementId: "rashidaBadiweEmail"
      },
      {
        email: "mailto:nicolelevermore@offthecurriculum.co.uk",
        elementId: "nicoleLevermoreEmail"
      },
      {
        email: "mailto:rikkigordon@offthecurriculum.co.uk",
        elementId: "rikkiEmail"
      },
      {
        email: "mailto:dhavalshah@offthecurriculum.co.uk",
        elementId: "dhavalShahEmail"
      },
      {
        email: "mailto:sineadmurphy@offthecurriculum.co.uk",
        elementId: "sineadEmail"
      },
      {
        email: "mailto:jingzhao@offthecurriculum.co.uk",
        elementId: "jingEmail"
      },
      {
        email: "mailto:samuelanimashaun@offthecurriculum.co.uk",
        elementId: "samuelEmail"
      },
      {
        email: "mailto:chrissmith@offthecurriculum.co.uk",
        elementId: "chrisEmail"
      },
      {
        email: "mailto:teresasu@offthecurriculum.co.uk",
        elementId: "teresaEmail"
      },
      {
        email: "mailto:joelo@offthecurriculum.co.uk",
        elementId: "joeEmail"
      },
      {
        email: "mailto:traceyedwards@offthecurriculum.co.uk",
        elementId: "traceyedwardsEmail"
      },
      {
        email: "mailto:kierangill@offthecurriculum.co.uk",
        elementId: "kierangillEmail"
      },
      {
        email: "mailto:andrewwyatt@offthecurriculum.co.uk",
        elementId: "andrewwyattEmail"
      },
      {
        email: "mailto:supriyaharikumar@offthecurriculum.co.uk",
        elementId: "supriyaharikumarEmail"
      },
      {
        email: "mailto:timsiu@offthecurriculum.co.uk",
        elementId: "timsiuEmail"
      },
      {
        email: "mailto:adedoyinokeowo@offthecurriculum.co.uk ",
        elementId: "adedoyinokeowoEmail"
      },
      {
        email: "mailto:gracephang@offthecurriculum.co.uk ",
        elementId: "gracephangEmail"
      }, 
      {
        email: "mailto:mingho@offthecurriculum.co.uk ",
        elementId: "minghoEmail"
      }, 
      {
        email: "mailto:abdulsufi@offthecurriculum.co.uk ",
        elementId: "abdulsufiEmail"
      }
    ];
  
    emailMappings.forEach(({ email, elementId }) => {
      const protectedEmail = p.replace(
        "mailto:josxexphxlxxevxxermore@offthecurrxiculumx.cox.uxk",
        email
      );
      document.getElementById(elementId).href = protectedEmail;
    });
  }
  
  return (
    /*Text for the website.*/
    <React.Fragment>
      <Title title='The team at Off the curriculum' />
      <Description  description='Here you will find  a list of our volunteers and little background knowledge about themselves' />
      <div className="centre-frame">
        <h1 className="meettheteam">{theTeam.title}</h1>
        <p className="theTeam">{theTeam.desc}</p>
        <ul className="grid cs-style-1">
          <h2 className="meettheteam">Senior Officers</h2>
          <li>
            <figure>
              <img 
                className="team_image"
                src={josephLevermore}
                width={width}
                alt={alt}
                effect="opacity"
              />
              <h4 className="card-title">Dr. Joseph Michael Levermore</h4>
              <h4 className="card-position">Chief Executive and Founder</h4>
              <h4 className="card-qoute">
                “The unexamined life is not worth living.” ― Socrates
              </h4>
              <figcaption>
                <h3>About Joseph</h3>
                <div class="MeetTeamicons">
                  <a
                    class="icons"
                    onClick={mailProtector}
                    target="_blank"
                    rel="noreferrer"
                    id="josephEmail"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="Email Joseph" />{" "}
                  </a>
                  <a
                    class="icons"
                    href="https://twitter.com/josephlevermore"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    class="icons"
                    href="https://www.linkedin.com/in/josephlevermore/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p class="card-text">
                  I am the founder of Off the Curriculum and a researcher at
                  Imperial College London, with a keen interest in educational
                  empowerment as a vehicle for social mobility. Upon realising
                  the limited opportunities for educational activities provided
                  to young people in Lambeth and Southwark London, we decided to
                  take action. I am passionate about sports, science (physics
                  and chemistry), and philosophy.
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={nicoleCurtis} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Nicole Curtis</h4>
              <h4 className="card-position">Chief Communications Officer</h4>
              <h4 className="card-qoute">
                “Life shrinks or expands in proportion to ones courage” ― A. Nin
              </h4>
              <figcaption>
                <h3>About Nicole</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    target="_blank"
                    rel="noreferrer"
                    id="nicoleEmail"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="Email Nicole" />{" "}
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/ncurtisss"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/nicole-curtis-1585b3a3/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I take care of all Marketing and Communications at Off the
                  Curriculum. I was born in London but moved to Spain as a
                  child, growing up there until university. Currently, I am an
                  MSc student reading Psychology and Neuroscience of Mental
                  Health at King’s College London. I work in Child and
                  Adolescent Mental Health and Addiction Rehabilitation. With a
                  passion for education, I jumped at the opportunity to be a
                  part of OTC. Curious by nature, I am passionate about mental
                  health and wellbeing, the creative and performing arts, food,
                  travel, and culture.
                </p>
              </figcaption>
            </figure>
          </li>
          <h2 className="meettheteam">Education Officers</h2>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={jawarieSuleman} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Jawaria Suleman</h4>
              <h4 className="card-position">Education Officer</h4>
              <h4 className="card-qoute">
               "The more I read, the more I acquire, the more certain I am that I know nothing." — Voltaire
              </h4>
              <figcaption>
                <h3>About Jawaria</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="jawariaEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/nicole-l-aa60301b5/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I am involved with Off the Curriculum, as an Educational Content Officer. Before this, I worked at several local and international institutes as a chemistry teacher. I was raised in Pakistan and moved to the UK to pursue my passion for education. I studied M.Sc. in Educational Studies at the University of Strathclyde and earned my degree with distinction. As someone who works in the education sector, I'm passionate about addressing educational disparities and the lack of advancement opportunities for students from disadvantaged backgrounds. I was introduced to the off-the-curriculum through my desire to contribute, and I was deeply moved by their outstanding work.
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={saimelaChotza} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Saimela Chotza</h4>
              <h4 className="card-position">Education Officer</h4>
              <h4 className="card-qoute">
                "The only dreams impossible to reach are the ones you never pursue." – Michael Deckman
              </h4>
              <figcaption>
                <h3>About Saimela</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="saimelaChotzaEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/nicole-l-aa60301b5/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I am an Education Evaluation Officer at Off the Curriculum. I have completed my studies both Bachelor & Master of Science in the field of Public Health. I have worked as a Public Health practitioner mainly during my university years and then as I had a great passion for education I founded a Language Centre. I aspire to take my next step in my career by completing a PHD program in the UK. I am passionate about education, mental health & wellbeing. And a great lover when it comes to books, arts and traveling. I am delighted to undertake this position at OTC because it will allow me to contribute to students' lives, which is our future!
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={emilyFisher} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Emily Fisher</h4>
              <h4 className="card-position">Education Officer</h4>
              <h4 className="card-qoute">
                "Never, ever underestimate the importance of having fun." - Randy Pausch.
              </h4>
              <figcaption>
                <h3>About Emily</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="emilyFisherEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/nicole-l-aa60301b5/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I am proud to be an education officer at Off the Curriculum. I have just finished my degree in Sociology and Anthropology where I focused on the experiences of young people in education in the UK. I am now working in research surrounding international law and human rights frameworks, being appointed as the anthropological lens. I have a small coffee shop and therefore drink lots of caffeine, and I love spending time outdoors, whether that be hiking, or swimming at Bournemouth beach. 
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={debraMurphy} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Debra Murphy</h4>
              <h4 className="card-position">Education Officer</h4>
              <h4 className="card-qoute">
                "Never, ever underestimate the importance of having fun." - Randy Pausch.
              </h4>
              <figcaption>
                <h3>About Debra</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="debraMurphyEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/nicole-l-aa60301b5/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                My background is in learning and development, and management. I also ran my own traditional tearoom in Scotland. I’m eager to help people; guiding candidates, or supporting trainers, but can recognise when a simple cup of tea, cake or a shoulder is needed. My passion is writing, with two books published and plenty more tales yet to be told. Of course, coming from Halifax, the home of Nestle, I have a sweet tooth. Life always seems to involve chocolate of some sort, a Yorkie Bar, Toffee Crisp, or a few Quality Street sweets. But I’m also quite partial to Cadbury’s Dairy Milk.
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={rashidaBadiwe} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Rashida Abdulai Badiwe</h4>
              <h4 className="card-position">Education Officer</h4>
              <h4 className="card-qoute">
              “There are decades where nothing happens; and there are weeks where decades happen”- Vladimir llyich Lenin.
              </h4>
              <figcaption>
                <h3>About Rashida</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="rashidaBadiweEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/nicole-l-aa60301b5/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                I work for Off the Curriculum as a volunteer in social media. I am from Ghana and recently relocated to the UK to attend Robert Gordon University in Scotland to get my Master's in Corporate Communication. It takes more work to study than it does for most pupils. Finding enjoyable and soothing study methods makes the effort worthwhile. I can help accomplish my ambition of using social media to improve simple learning with OTC.
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={nicoleLevermore} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Nicole T Levermore, MSc</h4>
              <h4 className="card-position">Education Officer</h4>
              <h4 className="card-qoute">
                “You don't have to be the same person you were 15 minutes ago.”
                ― N. Levermore
              </h4>
              <figcaption>
                <h3>About Nicole</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="nicoleLevermoreEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/nicole-l-aa60301b5/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I am an Education Officer at Off the Curriculum. I am a Ph.D.
                  Auditory Neuroscience candidate at the University of Brighton.
                  Before this, I read an MSc in Translational Neuroscience at
                  the University of Sheffield and a BSc in Neuroscience with
                  Psychology at Keele University. I am originally from
                  Birmingham. A love of mine is classical music, and I play the
                  cello in my spare time. Fun fact: During my time at Keele
                  University, I helped run the ‘Wholock’ Society- a society for
                  fans of the BBC TV shows Doctor Who and Sherlock.
                </p>
              </figcaption>
            </figure>
          </li>
          {/*<h2 className="meettheteam">EDUCO Content Officers</h2>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={andrewWyatt} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Andrew Wyatt</h4>
              <h4 className="card-position">EDUCO Content Officer</h4>
              <h4 className="card-qoute">
                "Life is like riding a bicycle. To keep your balance, you must keep moving." - Albert Einstein
              </h4>
              <figcaption>
                <h3>About Andrew</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="andrewwyattEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/nicole-l-aa60301b5/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  As a qualified teacher, having worked in primary schools and secondary schools in England, Portugal and Vietnam, as well as tutoring online, my experience in education covers quite a range of ages and subjects. I have seen the power of education and am delighted to be able to contribute to the work Off the Curriculum does. Apart from education, I'm passionate about sports and travel - I'm currently planning to visit my 40th country.
                </p>
              </figcaption>
            </figure>
          </li>
                */}
    
          <h2 className="meettheteam">Astrophysics Expert Panel and Content Creators</h2>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={dhavalShah} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Dhaval Shah, MSc</h4>
              <h4 className="card-position">Astrophysics Expert Panelist</h4>
              <h4 className="card-qoute">
                "It always seems impossible until it's done" ― Nelson Mandela
              </h4>
              <figcaption>
                <h3>About Dhaval</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="dhavalShahEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="Email rikki" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/dhavaluu19"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/dhaval-shah-730459149/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                I am an Astrophysics Expert Panel member at Off the Curriculum. I have completed my MSc in Data Intensive Astrophysics from Cardiff University. I am currently carrying out my research work on multi-messenger gravitational waves. During my spare time, I look at the sky and get lost for hours.
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={teresaSu} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Teresa Su</h4>
              <h4 className="card-position">Video Production Officer</h4>
              <h4 className="card-qoute">
                "We are all in the gutter, but some of us are looking at the
                stars" ― Oscar Wilde
              </h4>
              <figcaption>
                <h3>About Teresa</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="teresaEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/teresa-su-b21187ab/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I am a student volunteer at Off the Curriculum. I am Chinese
                  but grew up in Sydney, Australia before moving to the UK to
                  study a BSc in Theoretical Physics at University College
                  London. I am fascinated by all things physics from elementary
                  particles to galaxy clusters. I also love the reactions people
                  give when they find out how cool science truly is.
                </p>
              </figcaption>
            </figure>
          </li>
          <h2 className="meettheteam">Recruitment Officers</h2>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={timSiu} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Tim Siu</h4>
              <h4 className="card-position">Recruitment Team Lead</h4>
              <h4 className="card-qoute">
              “My mission in life is not merely to survive, but to thrive.. with.. passion, compassion and  style”  Maya Angelou
              </h4>
              <figcaption>
                <h3>About Tim</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="timsiuEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="Email rikki" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/timsiu/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                I am the Recruitment Officer at Off the Curriculum. I currently work for EY as the Executive Talent Acquisition Lead for Tax across EMEIA. My career at the core has been about champion individuals or teams to achieve success and helping to enhance people’s career by connecting them with the right opportunities and relationships, so I jumped at the opportunity to join the community at OTC. My background includes search and selection within Management Consultancy, industry and most recently I helped build out the Senior Hire teams at KPMG and EY, that focus on managing Partner hiring. Outside of work I’m a Dad of one. Parents from HK and my first career was in journalism.  Favourite place would have to be Japan where I lived for 2 years early in my career. 
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={supriyaHarikumar} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Supriya Harikumar</h4>
              <h4 className="card-position">Extended Project Qualification Recruitment and Programme Development Officer</h4>
              <h4 className="card-qoute">
              "There is nothing impossible to they who will try" - Alexander the Great
              </h4>
              <figcaption>
                <h3>About Supriya</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="supriyaharikumarEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="Email rikki" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/company/offthecurriculum-otc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                Originally from India, I relocated to London in 2018. I am a BTech graduate and a Scrum Master cum Quality Assurance lead by profession. Within my study/work forums, I have been spreading my wings across recruitment and employee relations, with a strong passion for educating communities, that has brought me to this space. I get engrossed by People and their diversity and thrive to understand the rhythm better! Am keen in growing OTC with the right talent force impacting community learning. 
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={gracePhang} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Grace Phang Yen Peng</h4>
              <h4 className="card-position">Recruitment Officer</h4>
              <h4 className="card-qoute">
              "Nothing is impossible. The word itself says: 'I'm possible'"
              </h4>
              <figcaption>
                <h3>About Grace</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="gracephangEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="Email Grace" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/gracephang/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                I am a passionate Strategic Talent Acquisition Manager who relishes fast paced environments and hitting my hiring targets! Also an end to end Recruitment Specialist where I enjoy meeting professionals of all levels to inspire them in shaping their future career path, and to also be inspired by their career aspirations as well. I have been in IT recruitment for over 10 years and very well versed in the IT lingo.
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={adedoyinOkeowo} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Adedoyin Okeowo</h4>
              <h4 className="card-position">Recruitment Officer</h4>
              <h4 className="card-qoute">
              "If you believe it’ll work out, you’ll see opportunities.” ―Wayne Dyer.
              </h4>
              <figcaption>
                <h3>About Adedoyin</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="adedoyinokeowoEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="Email Adedoyin" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/okeowo-adedoyin-a19a25a0/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                I'm thrilled to be a part of the "Off curriculum recruitment team." Being a part of the recruitment team brings me joy, as human resources are a significant aspect of my life. I had the opportunity to work as a human resource assistant at HT Business Consulting Limited, and as my interest in the field grew, I decided to pursue a master's degree in International Business with Human Resources in order to further advance my career.
                I'm really committed to assisting the next generation and overall encouraging literacy, which is one of the reasons I considered joining a team that focuses on education and having an influence on the younger generation.
                </p>
              </figcaption>
            </figure>
          </li>

          <h2 className="meettheteam">Extended Project Qualification Mentors</h2>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={rikkiGordon} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Rikki Gordon, MSc</h4>
              <h4 className="card-position">Education Officer</h4>
              <h4 className="card-qoute">
                “Education is learning what you didn’t know you didn’t know.” ―
                D. Boorstin
              </h4>
              <figcaption>
                <h3>About Rikki</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="rikkiEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="Email rikki" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/company/offthecurriculum-otc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I am delighted to be an Education Officer at Off the
                  Curriculum. I’m an MSc graduate in Biochemical & Environmental
                  toxicology from Lancaster University. After education, I have
                  worked in various industries, starting in e-cigarette safety
                  and occupational health to public health toxicology, where I
                  currently specialise in product safety. I have always aspired
                  to inspire the next generation of young scientists, and I aim
                  to bring this passion to OTC. I’m originally from Ireland,
                  which is still apparent from my love of rugby, potatoes & the
                  occasional pint of Guinness. Particular passions of mine
                  include all things science, education and public engagement,
                  reading, watching sport, socializing, and seeing new places.
                </p>
              </figcaption>
            </figure>
          </li>
          <h2 className="meettheteam">Website Development Officers</h2>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={traceyEdwards} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Tracey Edwards</h4>
              <h4 className="card-position">Website Development Team Lead</h4>
              <h4 className="card-qoute">
                "After every dark storm there is always brighter days ahead."
              </h4>
              <figcaption>
                <h3>About Tracey</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="traceyedwardsEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="Email rikki" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/company/offthecurriculum-otc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  When I'm not busy with my regular job, I dive into the exciting world of web development as a volunteer for Off the Curriculum. It's my little way of giving back to the community while having a blast with coding! But that's not all – during my weekdays, I get to share my passion by teaching beginner web development boot-camps at a university. Watching my ideas and creations come to life through coding is like pure magic, and it never fails to put a smile on my face!
                </p>
              </figcaption>
            </figure>
            </li>
            <li>
            <figure>
              <div className="team_image">
                <img
                  src={kieranGill} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Kieran Gill</h4>
              <h4 className="card-position">Web-development Officer</h4>
              <h4 className="card-qoute">
              “An investment in knowledge pays the best interest.” – Benjamin Franklin
              </h4>
              <figcaption>
                <h3>About Kieran</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="kierangillEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="Email Kieran" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/company/offthecurriculum-otc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                I am honoured to be given the opportunity to collaborate with the OTC Team. Before beginning my journey into Tech I was an English Teacher for 9 years and thus OTC’s mission really resonated with me. I fell in love with coding quickly after I centred my first div. Since then I have been on an exciting learning journey full of exhilarating peaks and character-building troughs. My hobbies include running, resistance training, reading, gaming and playing fetch with my favourite canine, Dori.
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={abdulSufi} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Abdul Sufi</h4>
              <h4 className="card-position">Web development Officer</h4>
              <h4 className="card-qoute">
                "When changing your thoughts, remember to also change your world." ― Peale
              </h4>
              <figcaption>
                <h3>About Abdul</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="abdulsufiEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="Email rikki" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/company/offthecurriculum-otc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I am a Web Development Officer at Off the Curriculum. My career background is anchored in education; I've been a licensed teacher, guiding students in both primary schools and colleges across London and Dubai. Though I currently call London home, I've had the fortune of experiencing life in five incredible countries. Outside of my professional commitments, I'm passionate about baking, travelling, sports and crafting digital content. I am excited to bring my web design skills to the team and create something amazing together!
                </p>
              </figcaption>
            </figure>
            </li>
            <li>
            <figure>
              <div className="team_image">
                <img
                  src={mingHo} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Ming Ho</h4>
              <h4 className="card-position">UX/UI Officer</h4>
              <h4 className="card-qoute">
                "A journey of a thousand miles begins with a single step" by Laozi
              </h4>
              <figcaption>
                <h3>About Ming</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="minghoEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="Email rikki" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                    alt="Twitter"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/chimingho/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I’m a UX designer passionate about building delightful digital products people love. I have12 years of experience crafting useful responsive websites and native/hybrid mobile apps for both startups and multinational companies. Originally from Hong Kong, residing in London since 2023.
                </p>
              </figcaption>
            </figure>
            </li>
          <h2 className="meettheteam">Finance Officers</h2>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={sineadMurphy} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Sinéad Murphy MSci, BFP, ACA</h4>
              <h4 className="card-position">Finance Officer</h4>
              <h4 className="card-qoute">
                “Do not train a child to learn by force, but direct them to what
                they enjoy” ― Plato
              </h4>
              <figcaption>
                <h3>About Sinéad</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="sineadEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/shinnykins"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/sineadsorohan/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I am the Finance Officer at Off the Curriculum. Education has
                  always been part of my life and explains why I have always
                  been eager to learn across a broad spectrum of topics. This
                  curiosity spurred me on to qualify as an ACA Chartered
                  Accountant after completing an MSci in Biological Sciences. I
                  know how lucky I have been to have had access to these
                  learning opportunities, so when I heard about Off the
                  Curriculum, I knew I wanted to be involved in helping bring
                  these opportunities to young people in my home city.
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={jingZhao} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Jing Zhao, BFP, ACA</h4>
              <h4 className="card-position">Finance Officer</h4>
              <h4 className="card-qoute">
                “Education is the passport to the future; tomorrow belongs to
                those prepared” ― X
              </h4>
              <figcaption>
                <h3>About Jing</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="jingEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/jing-zhao-acca-b45b57197/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I was absolutely delighted to be selected as the Finance
                  Officer at Off the Curriculum. As a Charted accountant who has
                  been working with various small/medium charities for many
                  years, I hope my expertise will help OTC to achieve its goals
                  in helping young people to reach their full potential.
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={samuelAnimashaun} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Samuel Animashaun</h4>
              <h4 className="card-position">Finance Officer</h4>
              <h4 className="card-qoute">
                "Develop a passion for learning and you will never cease to
                grow." – AJ. D’Angelo
              </h4>
              <figcaption>
                <h3>About Samuel</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="samuelEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/animashaun-o-samuel-4b365b36/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I am the Finance Officer at Off the Curriculum. I am currently
                  pursuing an MSc in Financial Technology and also preparing to
                  write my final module (Strategic Business Leader) to qualify
                  as an ACCA Chartered Accountant. Learning, improving my
                  skill-set and using this knowledge to make a positive impact
                  on my environment has always been what I enjoy doing. Reading
                  about the core values, vision and mission of Off the
                  Curriculum charity organization convinced me that I can make a
                  positive impact in the lives of younger people around me
                  through the organization.
                </p>
              </figcaption>
            </figure>
          </li>

          <h2 className="meettheteam">Policy Officers</h2>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={chrisSmith} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>
              <h4 className="card-title">Chris Smith</h4>
              <h4 className="card-position">Policy Officer</h4>
              <h4 className="card-qoute">
                "Excellence is not a gift, but a skill... achieved by acting
                rightly." ― Plato
              </h4>
              <figcaption>
                <h3>About Chris</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="chrisEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/chris-s-403b34155/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="linedin" />
                  </a>
                </div>
                <p className="card-text">
                  I am the Policy Officer at Off the Curriculum. Originally from
                  North Essex, I am currently a Highway Design Manager at Two
                  London Boroughs, after working at Transport for London and two
                  private consultancies. I went to University in Plymouth,
                  completing a degree in Geography. Ever since starting my
                  career in the Transport Planning and Highways sector I have
                  learned to maximise learning opportunities which has developed
                  me from a Geographer to a Highways Engineer. Throughout my
                  career in management and when leading teams I have always
                  mentored junior staff, either apprentices, graduates or
                  engineers to ensure that they have the same learning
                  opportunities as I have been lucky enough to benefit from
                  which has brought me to the Off the Curriculum team. Outside
                  of work I enjoy football, possibly a little too much for a man
                  of my age, and spending time with my young family.
                </p>
              </figcaption>
            </figure>
          </li>
          <h2 className="meettheteam">Multimedia Officers</h2>
          <li>
            <figure>
              <div className="team_image">
                <img
                  src={joeLo} // use normal <img> attributes as props
                  width={width}
                  alt={alt}
                  effect="opacity"
                />
              </div>

              <h4 className="card-title">Joe Lo</h4>
              <h4 className="card-position">Video Production Officer</h4>
              <h4 className="card-qoute">
                “ A man without dreams is no different to a salted fish.” ―
                Stephen Chow
              </h4>
              <figcaption>
                <h3>About Joe</h3>
                <div className="MeetTeamicons">
                  <a
                    className="icons"
                    onClick={mailProtector}
                    id="joeEmail"
                    target="_blank"
                    rel="noreferrer"
                    href="mailProtector:;"
                  >
                    <img src={Email} alt="email" />
                  </a>
                  <a
                    className="icons"
                    href="https://twitter.com/otc_london"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="twitter" />
                  </a>
                  <a
                    className="icons"
                    href="https://www.linkedin.com/in/offthecurriculum-otc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="linkedin" />
                  </a>
                </div>
                <p className="card-text">
                  I am from Hong Kong and I relocated to London in 2021. My last
                  job in Hong Kong was making videos for educational purposes at
                  the Open University of Hong Kong. What I enjoy most about
                  making educational videos is that not only I can help students
                  in their learning, but I can also gain a lot of knowledge of
                  different topics from what I am doing.
                </p>
              </figcaption>
            </figure>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}
export default meet_the_team;
