/**dependencies */
import {
  onValue,
  ref,
  update,
  equalTo,
  orderByChild,
  query,
} from "firebase/database";
import { useRef, useEffect, useState, useContext } from "react";
import { auth } from "./firebase";
/** components */
import { db } from "./firebase";
import { UserEmail } from "../App";
import { LoginContext } from "../App";
/**functionality */
function emails(s) {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

// Function to write webpage name to Firebase database
const WriteToFirebase = (webpageName, timestamp, videoTag) => {
  let emailStart = UserEmail.Provider;
  let data = useRef();
  const [currentUser, setCurrentUser] = useState();
  const isUserSignedIn = useContext(LoginContext); 
  
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      //console.log('1', currentUser.email)
    });
  });

  useEffect(() => {
    if (typeof UserEmail.Provider === "string") {
      let email = emails(currentUser.email);

      const dbRef = query(
        ref(db, "person/"),
        orderByChild("Email"),
        equalTo(email)
      );
      onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.val() == null) {
            window.location.replace("/CreateAProfile");
          } else {
            snapshot.forEach((childSnapshot) => {
              const childKey = childSnapshot.key;
              const childData = childSnapshot.val();

              data.current = childKey;
            });
          }
        },

        {
          onlyOnce: true,
        }
      );
     
    }
  }, [emailStart]);

  const formatDate = (timestamp) => {
    const dateObj = new Date(timestamp);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      dateObj
    );
    const year = dateObj.getFullYear();
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    return formattedDate;
  };
  const formattedTimestamp = formatDate(timestamp);

  // Push the webpage name to a new child node in the "webpages" collection

  const updates = {};

  useEffect(() => {
    if (data !== undefined) {
      setTimeout(() => {
        if (data.current !== undefined) {
          let id = data.current;
          updates["person/" + id + "/seminar"] = {
            title: webpageName,
            seminar: videoTag,
            timestamp: formattedTimestamp,
          };

          update(ref(db), updates)
            .then(() => {
              //console.log('Webpage name successfully written to Firebase!');
            })
            .catch((error) => {
              //console.error('Error writing webpage name to Firebase:', error);
            });
        }
      }, 1000);
    }
  });
};

export default WriteToFirebase;