import React from "react";

/*Careers-photo for officers*/
import Britishsignlanguage from "../../../images/functionality/main/careers-page/britishsignlanguage.webp";
import recruitmentOfficer from "../../../images/functionality/main/careers-page/recruitment.webp";
import socialmediaOfficer from "../../../images/functionality/main/careers-page/socialmedia.webp";
import policyOfficer from "../../../images/functionality/main/careers-page/policy.webp";
import fundraisingOfficer from "../../../images/functionality/main/careers-page/fundraising.webp";
import outreachOfficer from "../../../images/functionality/main/careers-page/outreach.webp";
import webdesignOfficer from "../../../images/functionality/main/careers-page/webdesign.webp";
import animationOfficer from "../../../images/functionality/main/careers-page/animation.webp";
import videographyOfficer from "../../../images/functionality/main/careers-page/videography.webp";

/*Careers-photo for volunteers*/
/*Term One*/
import immunologyVolunteer from "../../../images/functionality/main/careers-page/immunology.webp";
import psychologyVolunteer from "../../../images/functionality/main/careers-page/psy-neuro.webp";
import astrophysicsVolunteer from "../../../images/functionality/main/careers-page/astrophysics.webp";
import musicVolunteer from "../../../images/functionality/main/careers-page/music.webp";
import historyVolunteer from "../../../images/functionality/main/careers-page/history.webp";
/*Term Two*/
import horticultureandbotanyVolunteer from "../../../images/functionality/main/careers-page/horticulture_botany.webp";
import lawandthejudicialsystemVolunteer from "../../../images/functionality/main/careers-page/lawjudicialsystem.webp";
import historyoflangVolunteer from "../../../images/functionality/main/careers-page/historyoflanguage.webp";
import climateVolunteer from "../../../images/functionality/main/careers-page/climateChange.webp";
import businessVolunteer from "../../../images/functionality/main/careers-page/business.webp";
import MetaTags from "react-meta-tags";
import { careersPage } from "./Media/Careers";
import "./Mainstyling/Volunteering.css";

function Careers() {
  return (
    /*include text inside the React.Fragment*/
    <React.Fragment>
      {/*<MetaTags>
        <title>Volunteer with Off the Curriculum</title>
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <meta name="robots" content="index, follow" />
        <meta name="title" content="Volunteer with Off the Curriculum" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Off the Curriculum believes educational empowerment opens up previously unnoticeable doors... Why not inspire today?"
        />
        <meta
          name="keywords"
          content="Education, Charity, News, Educational News, Educational empowerment, Social Mobility, curriculum educational, London, South London, Lambeth, Southwark, United Kingdom"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/Careers"
        />
        <meta property="og:title" content="Volunteer with Off the Curriculum" />
        <meta
          property="og:description"
          content="Off the Curriculum believes educational empowerment opens up previously unnoticeable doors... Why not inspire today?"
        />
        <meta
          property="og:image"
          content="https://www.offthecurriculum.co.uk/images/logos/OTC_logo_small_colour_with_background.webp"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.offthecurriculum.co.uk/images/logos/OTC_logo_small_colour_with_background.webp"
        />
        <meta property="og:image:alt" content="The Off the Curriculum logo." />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.offthecurriculum.co.uk/Careers"
        />
        <meta
          property="twitter:title"
          content="Volunteer with Off the Curriculum"
        />
        <meta
          property="twitter:description"
          content="Off the Curriculum believes educational empowerment opens up previously unnoticeable doors... Why not inspire today?"
        />
        <meta property="twitter:site" content="@otc_london" />
        <meta
          property="twitter:image"
          content="https://www.offthecurriculum.co.uk/images/logos/OTC_logo_small_colour_with_background.webp"
        />
        <meta
          property="twitter:image:alt"
          content="The Off the Curriculum logo."
        />
  </MetaTags>*/}
      <div className="centre-frame">
        <h1 className="volunteerHeader">
          Be a part of history and help inspire young people by becoming an
          Officer at Off the Curriculum today!
        </h1>
        <p className="volunteerTemplate">{careersPage.officerPositions}</p>

        <ul className="grid cs-style-1">
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={recruitmentOfficer}
              />
              <h5 className="card-title">Recruitment Officer</h5>
              <h4 className="card-position">London or Remote</h4>
              <h4 className="card-qoute-careers">
                We are is looking for a Volunteer Recruitment Officer, to help recruit...
              </h4>
              <a
                className="volunteeringAlink"
                href="/recruitmentOfficer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={socialmediaOfficer}
              />
              <h5 className="card-title">Social Media Officer</h5>
              <h4 className="card-position">London or Remote</h4>
              <h4 className="card-qoute-careers">
                We are looking for a Social Media Officer to help create content for our various...
              </h4>
              <a
                className="volunteeringAlink"
                href="/socialmediaOfficer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img alt="Card cap" className="team_image" src={policyOfficer} />
              <h5 className="card-title">Policy Officer</h5>
              <h4 className="card-position">London or Remote</h4>
              <h4 className="card-qoute-careers">
                We are looking for two Policy Officers to support OTC with ensuring....
              </h4>
              <a className="volunteeringAlink" href="/policyOfficer" data-link>
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={Britishsignlanguage}
              />
              <h5 className="card-title">British Sign Language Officer</h5>
              <h4 className="card-position">London or Remote</h4>
              <h4 className="card-qoute-careers">
                To create an inclusive online environment we are looking for British Sign Language Interpreting Volunteers...
              </h4>
              <a
                className="volunteeringAlink"
                href="/britishSignLangOfficer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card cap"
                className="team_image"
                src={fundraisingOfficer}
              />
              <h5 className="card-title">Fundraising Officer</h5>
              <h4 className="card-position">London or Remote</h4>
              <h4 className="card-qoute-careers">
                We’re really excited to welcome creative, and
                resourceful Fundraising Officer to assist...
              </h4>
              <a
                className="volunteeringAlink"
                href="/fundraisingOfficer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={outreachOfficer}
              />
              <h5 className="card-title">Outreach Officer</h5>
              <h4 className="card-position">London or Remote</h4>
              <h4 className="card-qoute-careers">
                Join OTC and support our organisation foster relationships with local Businesses...
              </h4>
              <a
                className="volunteeringAlink"
                href="/outreachOfficer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={webdesignOfficer}
              />
              <h5 className="card-title">Web Design Officer</h5>
              <h4 className="card-position">London or Remote</h4>
              <h4 className="card-qoute-careers">
                We are looking for Web Development Officers to work support EDUCO development...
              </h4>
              <a
                className="volunteeringAlink"
                href="/webdesignOfficer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={animationOfficer}
              />
              <h5 className="card-title">Animation Officer</h5>
              <h4 className="card-position">London or Remote</h4>
              <h4 className="card-qoute-careers">
                We are currently recruiting an Animation Officer, to
                help develop and produce the visual effects, drawing and...
              </h4>
              <a
                className="volunteeringAlink"
                href="/aminationOfficer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={videographyOfficer}
              />
              <h5 className="card-title">Videography Officer</h5>
              <h4 className="card-position">London or Remote</h4>
              <h4 className="card-qoute-careers">
                We are looking for Videography Officers to help develop and produce the video...
              </h4>
              <a
                className="volunteeringAlink"
                href="/videographyOfficer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
        </ul>
        <h1 className="volunteerHeader">
          Be an EUDCO Content Creator with Off the Curriculum today!
        </h1>
        <p className="volunteerTemplate">{careersPage.volunteerPosition}</p>
        <ul className="grid cs-style-1">
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={immunologyVolunteer}
              />
              <h5 className="card-title">Immunology EDUCO Volunteer</h5>
              <a
                className="volunteeringAlink"
                href="/ImmunologyVolunteer"
                data-link
              >
                <br />
                <br />
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={psychologyVolunteer}
              />
              <h5 className="card-title">
                Psychology and Neuroscience EDUCO Volunteer
              </h5>
              <br />
              <a
                className="volunteeringAlink"
                href="/PsychologyVolunteer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card cap"
                className="team_image"
                src={astrophysicsVolunteer}
              />
              <h5 className="card-title">Astrophysics EDUCO volunteer</h5>
              <br />
              <br />
              <a
                className="volunteeringAlink"
                href="/astrophysicsVolunteer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={musicVolunteer}
              />
              <h5 className="card-title">Music EDUCO Volunteer</h5>
              <br />
              <br />
              <a className="volunteeringAlink" href="/MusicVolunteer" data-link>
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card cap"
                className="team_image"
                src={historyVolunteer}
              />
              <h5 className="card-title">History EDUCO Volunteer</h5>
              <br />
              <br />
              <a
                className="volunteeringAlink"
                href="/HistoryVolunteer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={climateVolunteer}
              />
              <h5 className="card-title">
                Climate Change and Sustainability EDUCO Volunteer
              </h5>
              <br />
              <br />
              <a
                className="volunteeringAlink"
                href="/ClimateVolunteer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={businessVolunteer}
              />
              <h5 className="card-title">Business EDUCO Volunteer</h5>
              <br />
              <br />

              <a
                className="volunteeringAlink"
                href="/BusinessVolunteer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={historyoflangVolunteer}
              />
              <h5 className="card-title">
                History of Language EDUCO volunteer
              </h5>
              <br />
              <br />
              <a
                className="volunteeringAlink"
                href="/HistoryoflangVolunteer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card  cap"
                className="team_image"
                src={lawandthejudicialsystemVolunteer}
              />
              <h5 className="card-title">
                Law and the Judicial System EDUCO Volunteer
              </h5>
              <br />
              <br />
              <a
                className="volunteeringAlink"
                href="/LawandthejudicialsystemVolunteer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
          <li>
            <figure>
              <img
                alt="Card cap"
                className="team_image"
                src={horticultureandbotanyVolunteer}
              />
              <h5 className="card-title">
                Horticulture and Botany EDUCO Volunteer
              </h5>
              <br />
              <br />
              <a
                className="volunteeringAlink"
                href="/HorticultureandbotanyVolunteer"
                data-link
              >
                <btn className="topicbtncareers">Find out more here...</btn>
              </a>
            </figure>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}
export default Careers;
