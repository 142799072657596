import { db } from "./firebase";
import { getAuth } from "firebase/auth";
import { ref, onValue, query } from "firebase/database";

import { VideoUpdater } from "../components/common/checkVideoPlays/videoPlays";
import { StoreVideo } from "./firebaseStoreVideo";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

let dummyElement = false;

const views = 1000;

//Attempting to load video numbers using react hook
export const ReactHookDump = (views) => {
  //console.log()
  const [currentViews, setCurrentViews] = useState(false);

  return (
    <>
      <div>{currentViews}</div>
      {currentViews && <h1>currentViews</h1>}
    </>
  );
};

function VideoCounts(name, likes, views) {
 //console.log('page name', name)
/*  const location = useLocation();

  //output is null 
    useEffect(() => {
     //console.log('Hello' + name);
    }, [location, dummyElement]);*/
}

export function FindVideo(name, likes, views) {
 // VideoCounts(name, likes, views)
  let videoName = name;

  const dbRef = query(ref(db, "video/name/" + videoName));

  onValue(dbRef, (snapshot) => {
    if (snapshot.exists()) {
      let data = snapshot.val();

      if (data) {
        let vName = data.key;
        let vLike = data.likes;
        let vViews = data.count;

        VideoUpdater(vName, vLike, vViews);
      }
    } else {
      StoreVideo(name, likes, views);
    }
  });
}
