import React, { useState, useEffect } from "react";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { introEduco } from "./educo/educoMedia";
import "./educo/educoMedia/Educo.css";
import { Title, Description } from "react-head-meta";
import EducoFinal from "../components/common/EducoHome/EducoHome";
import ShortsDiv from "./educo/Components/shortsDiv/shortsDiv";
import './styleSheet.css'
import CourseDiv from "./educo/Components/courseDiv";
import { ref, query, onValue} from "firebase/database";
import { db } from "../firebase/firebase";
import RecommendationEngine from "./educo/Components/RecommendationEngine/RecommendationEngine";

import { ModProfileCreate } from "./educo/Components/modalProfileCreate/modalProfileCreate";
import PageLoader from "../components/common/pageLoader/PageLoader";
import { CreateAProfile } from "./educo/profile/createAProfile";

export default function Home() {const auth = getAuth();
  const [currentUser, setCurrentUser] = useState();
  const [dbUser, setDBUser] = useState();
  const [showVerificationBanner, setShowVerificationBanner] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function emails(s) {
      return s.split("").reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
    }

    auth.onAuthStateChanged((user) => {
      const fetchData = async () => {
        setCurrentUser(auth.currentUser);
        
        if (!currentUser) return;

        const emailHash = emails(currentUser.email);
        const dbRef = query(ref(db, `person/${emailHash}`));

        onValue(
          dbRef,
          (snapshot) => {
            if (snapshot.val() == null) {
              setLoading(false);
            } else {
              //console.log('VALUE', snapshot.val());
              setDBUser(snapshot.val());
              setLoading(false);
            }
          },
          {
            onlyOnce: true,
          }
        );
      };

      if (user && !user.emailVerified) {
        setShowVerificationBanner(true);
      }

      fetchData();
    });
  }, [currentUser]);

  const handleVerificationClose = () => {
    setShowVerificationBanner(false);
  };

  const handleResendVerification = () => {
    if (currentUser) {
      sendEmailVerification(auth.currentUser);
      currentUser
        .sendEmailVerification()
        .then(() => {
          // Email verification resent!
        })
        .catch((error) => {
          // Handle error
          // console.error('Error resending verification email:', error);
        });
    }
    return setShowVerificationBanner(false);
  };

  //console.log('one', currentUser);

  return (
    <>
      <Title title='Educo at Off the Curriculum' />
      <Description description='Bespoke, empowering, and engaging… Off the Curriculum’s educational portal is like no other, learn with us today…' />
      {currentUser && dbUser ? (
        <div className="centre-frame">
          <h1>Welcome {currentUser.Name}!</h1>
          <p className="educo">{introEduco.desc}</p>
          <div className="dividerReels">
            <h2>Recommended for you!</h2>
            <ModProfileCreate />
            <RecommendationEngine />
            <h2>Shorts by Off the Curriculum!</h2>
            <ShortsDiv />
            <br />
            <h2>Courses by Off the Curriculum</h2>
            <CourseDiv />
          </div>
          <h2>Long-form content by Off the Curriculum</h2>
          <EducoFinal />
        </div>
      ) : (
        loading ? <PageLoader /> : <CreateAProfile />
      )}
    </>
  );
}