import React from "react";
import events from "./Events-index.js";
import { Link } from "react-router-dom";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "../Events.css";
import "./EventTemplate.css";
import { Title, Description } from "react-head-meta";
import ReactHtmlParser from 'react-html-parser';


function SectionText({ text }) {
  return (
    <div className="EventIntroDescription">
      {ReactHtmlParser(text)}
    </div>
  );
}
function EventTemplate(props) {
  const name = props.name;
  let event = {};
  let eventTime = {};
  let eventWhere = {};
  if (events[name]) event = events[name];
  if (event.where) eventWhere = event.where;
  if (event.time) eventTime = event.time;

  return (
    <React.Fragment>
      {/**new way of doing meta */}
      <Title title={event.title} />
      <Description description="up coming events at Off The Curriculum" />
      {/*<div className= "float-parent-element">
        <div className= "heading">
          <div className="event-header">
            <p>{event.title}</p>
          </div>
        </div>
    </div>*/
      /*Event summary*/}
      <div className="centre-frame">
          <div className="EventsColumnOne">
            <div className="registerBox">
            <center>
            <h6>
              {event.registerHere}
            </h6>
            <Link to={event.registrationPage}>
              <center>
                {" "}
                <button className="EventButton">
                    Register here.
                </button>
              </center>
            </Link>
            </center>
            </div>
          </div>
        <div className="EventsColumnTwo">
        <center>
        <div className="box">
              <img
                src={event.imgSrc}
                alt="event placeholder"
                className="EventImage"
              ></img>
            </div>
            <h2 className="EventPageHeader">
                {event.title}
            </h2>
            <h4 className="EventPageHeader">
                {event.subtitle}
              </h4>
              <p className="EventIntroDescription">
                {event.guestSpeaker}
              </p>
              <p className="EventIntroDescription">
                {event.shortDesc} 
              </p>
        </center>
        <div className="eventTitle">
            </div>
            <div className="EventWhereWhen">
              <div className="EventRow">
              <div className="WhereWhenColumn">
                <h3 className="EventHeaderDateTimeLocation">
                  Date and Time:
                </h3>
                <p>
                  {event.time}
                </p>
              </div>
              <div className="WhereWhenColumn">
                <h3 className="EventHeaderDateTimeLocation">
                  Location:
                </h3>
                <p>
                  {event.location}
                </p>
              </div>
              </div>

              <div className="EventsRefund">
                <h2 className="EventAbout">
                  {event.eventSummary}
                </h2>
                <p className="EventIntroDescription">
                  {event.fulldesc}
                </p>
              </div>
              <div className="EventsLongFormDescription">
                <h1>
                  {}
                </h1>
                <iframe src={event.embeddedMap}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                className='eventMap'
                ></iframe>
                <h2 className="EventAbout"> 
                  {event.aboutEvent}
                </h2>
                {event.eventDescription.map((items) => (
                  <div key={items.key}>
                    <h4 className="eventMainHeader">
                      {items.sectionTitle}
                    </h4>
                    <div className="eventMainText">
                    <SectionText text={items.sectionText} />
                    </div>
                    <br/>
                  </div>
                ))}
              </div>
            </div>
        </div>
        </div>
    </React.Fragment>
  );
}

export default EventTemplate;
