export default function GuestSpeakerForm() {
    return (
      <>
        <form method="dialog">
          <div className="form-example">
            <label htmlFor="guestSpeakerImage">
              Enter the guest speaker image link:{" "}
            </label>
            <input
              type="text"
              name="guestSpeakerImage"
              id="guestSpeakerImage"
              required
            />
          </div>
          <div className="form-example">
            <label htmlFor="guestSpeakerAlt">
              Enter the guest speaker image alt:{" "}
            </label>
            <input
              type="text"
              name="guestSpeakerAlt"
              id="guestSpeakerAlt"
              required
            />
          </div>
          <div className="form-example">
            <label htmlFor="guestSpeakerBio">Enter the guest speaker bio: </label>
            <input
              type="text"
              name="guestSpeakerBio"
              id="guestSpeakerBio"
              required
            />
          </div>
        </form>
      </>
    );
  }