const objWorkShopOneRegistration = {
    eventDate: `February, 4th 2023`,
    title: 'Novus Deus',
    subtitle: `
    Humanity and Artificial Intelligence
    `, 
    shortDescription: `
    Have you ever considered what the future of humanity will look like with the integration of AI? Have you thought about how this will influence your world? Your self-image? Have you been inspired by the rapid rate of innovation to one-day lead the tech industry to new heights? Why not join us at Imperial College London for a full day of excitement exploring Artificial and Humanity on Saturday, February 25th, 2023, from 10:00-15:30. To confirm your place, register by clicking the pink button on-screen and filling out the google form!
    `,
    formLink: 'https://docs.google.com/forms/d/e/1FAIpQLSdWIVfb4NftKAc8FadlUcDleEjYRD-BHynGHpuddYSg2Dm8-A/viewform?embedded=true',
};
  
export default objWorkShopOneRegistration; //;
