import { useState, useEffect, useRef } from 'react';
import {
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  onSnapshot,
  setDoc,
  doc,
  serverTimestamp,
  updateDoc
} from "firebase/firestore";
import { Helmet } from 'react-helmet'; 
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { auth, firestore } from '../../../../firebase/firebase';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './TrialProfile.css';
import { ref, get,child, getDatabase } from 'firebase/database';
import { getStorage, getDownloadURL } from "firebase/storage";
import { db } from '../../../../firebase/firebase';
import { useProfileStore } from '../../../../components/common/statePath/profileStore.tsx';
import { getAuth } from 'firebase/auth';
import { FirebaseDataProvider, useFirebaseData } from './firebaseData';
import MenteeChat from '../ProfileComponents/EPQChat/menteeChat';
//EPQ Mentor Components
import EPQMenteeDocumentDownload from '../ProfileComponents/EPQMenteeDocumentDownload/epqMentorDocumentDownload';
import Fileupload from '../../../../components/fileUpload/Fileupload';
import { Link } from 'react-router-dom';
import EPQMentorDocumentUpload from '../ProfileComponents/EPQMentorDocumentUpload/EPQMentorDocumentUpload';
import EPQTrainingVideo from '../ProfileComponents/EPQTrainingVideo/EPQTrainingVideo';
import { CreateAProfile } from '../createAProfile';
import BackIcon from '../ProfileComponents/EPQChat/BackIcon';
import RadarChart from '../../Components/RadarPlot/RadarPlot';

const LastViewedVideo = () => {
  const { video } = useFirebaseData();

  useEffect(() => {
    // Access the video data
  }, [video]);
  
    return (
      <div>
        <h4>
          Last viewed
        </h4>
        {video ? (
          <>
            <img
              src={video["videoImage"]}
              className="lastViewed"
              alt={video["videoImageAlt"]}
            />
            <br />
            <span id="views">Views: {video["count"]} | </span>
            <span>Likes: {video["likes"]}</span>
            <p className="watchHistory">{video["videoDesc"]}</p>
            <a href={"/" + video["videohref"]} className="watchHistory">
              <button className="fileDownloader">Watch here...</button>
            </a>
          </>
        ) : 
        <>
        <p>Explore EDUCO content today! <Link to='/educo'>Click here!</Link></p>
        </>
        } 
      </div>
    );
  };
  
const ProfileSection = (props) => {

  const profileData = props; 
    return (
      <div className="profilePhotoContainer">
        <div className="profilePhotoSection">
          <img src={profileData.profileData.picture} alt="" />
          <h3>Welcome back {profileData.profileData.Name} !</h3>
          <p>Age: {profileData.profileData.age}</p>
          <p>Last online: {profileData.profileData.lastViewTime}</p>
          <p style={{display:'none'}} className="mentorDataSet" id={profileData.profileData.mentorId}></p>
        </div>
      </div>
    );
  };
  function emails(s) {
    return s.split("").reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  const UserInfoComponent = () => {
    const { profileData } = useFirebaseData();
    const [loading, setLoading] = useState(true);
    const [sEmail, setSEmail] =useState('')
    let email

    useEffect(() => {
      if (profileData) {
        setLoading(false);
        if(profileData.Email !== null){
          let emailStart = profileData.Email;
       
          if (emailStart !== null) {
            // e.preventDefault();
          
        
            email = emails(emailStart);
            setSEmail(email)
          }
        }
      }
   
    }, [profileData]);
  
    if (loading) {
      return (
        <div className="card-container">
          <div className="profileCard">
            Loading
          </div>
          <div className="profileCard">
            <LastViewedVideo />
          </div>
          <div className="profileCard">
            <Calendar />
          </div>
        </div>
      );
    }
  
    if (!profileData || !profileData.token) {
      return <CreateAProfile />;
    }
  
    return (
      <div className="card-container">
        <div className="profileCard">
          <ProfileSection profileData={profileData} />
        </div>
        <div className="profileCard">
          <LastViewedVideo />
        </div>
        <div className="profileCard">
          <Calendar />
        </div>
      </div>
    );
  };
  
  

  const Calendar = () => {
  const currentDate = new Date();
  const currentMonth = 7; // August (0-based index)
  const currentYear = 2023;

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const startDay = new Date(currentYear, currentMonth, 1).getDay();

  const monthDays = Array.from({ length: daysInMonth }, (_, index) => index + 1);

  const eventDates = [19, 20];
  const upcomingEvent = 'Example Event';

  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });

  const handleCellClick = (event, day) => {
    if (eventDates.includes(day)) {
      const tooltipText = `Tooltip for ${day}`;
      const tooltipImageSrc = `image-for-${day}.jpg`;

      // Calculate tooltip position
      const cellRect = event.target.getBoundingClientRect();
      const tooltipLeft = cellRect.left + cellRect.width / 2;
      const tooltipTop = cellRect.top - cellRect.height;

      setTooltipContent({ text: tooltipText, imageSrc: tooltipImageSrc });
      setTooltipPosition({ left: tooltipLeft, top: tooltipTop });
    }
  };

  const handleTooltipClose = () => {
    window.location = '/AstrophysicsMasterClass'; 
  };

  const rows = Math.ceil((daysInMonth + startDay) / 7); // Calculate the number of rows needed

  return (
    <div className="calendar">
      <div className="upcoming-event">
        <h5>Upcoming Event: <b>Cosmic Horizons: Our Ever-Expanding Universe.</b></h5>
      </div>
      <p>Date: August 2023 19th and 20th</p>
      <p>Time: 10:00-15:30</p>
      <p>Location: Imperial College London</p>
      <p>Click the the below dates to register!</p>
      <table>
        <thead>
          <tr>
            <th>Sun</th>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
            <th>Sat</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: rows }, (_, rowIndex) => (
            <tr key={rowIndex}>
              {Array.from({ length: 7 }, (_, colIndex) => {
                const dayIndex = rowIndex * 7 + colIndex - startDay + 1;
                const day = dayIndex > 0 && dayIndex <= daysInMonth ? dayIndex : '';
                const isEventDay = eventDates.includes(dayIndex);

                const cellClassName = isEventDay ? 'event-day' : '';

                return (
                  <td
                    key={colIndex}
                    className={cellClassName}
                    onClick={(event) => handleCellClick(event, day)}
                  >
                    {day}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {tooltipContent && (
        <div
          className="calendarHover"
          style={{ left: tooltipPosition.left, top: tooltipPosition.top }}
        >
          <button className='fileDownloader'onClick={handleTooltipClose}>Register here!</button>
        </div>
      )}
    </div>
  );
};
   
const EducoAnalytics = () => {
    return (
        <>
      <div className="analyticsContainer">
        <div className="AnalyticsCardRadar">
          <h3>Radar Plot</h3>
          <p>Name</p>
          <p>My goal: </p>
          <RadarChart />
        </div>
        <div className="AnalyticsCardProgress">
          <h3>Progression</h3>
          <p>Name</p>
          <p>My goal: </p>
        </div>
      </div>
      <div className="profilePhotoContainer">
        <div className="profilePhotoSection">
          <h3>Next milestones to complete</h3>
          <p>Name</p>
          <p>My goal: </p>
        </div>
      </div>
      </>
    );
  };

  const WorkShopSeries = () => {
    return (
        <div className="profilePhotoContainer">
        <div className="profilePhotoSection">
          <h3>Upcoming events and declare your interests</h3>
          <p>Name</p>
          <p>My goal: </p>
        </div>
      </div>
    );
    }

    const DocumentDownload = ({ documentUrl, fileName }) => {

      const handleDownload = () => {
        const link = document.createElement('a');
        link.href = documentUrl;
        link.download = fileName;
        link.click();
      };
    
      return (
        <button className='fileDownloader' onClick={handleDownload}>Download here</button>
      );
    };    

const EPQComponent = () => {
  const { profileData } = useFirebaseData();

    return (
      <div>
        {profileData && profileData.Mentee ? (
          <div className='EPQComponent'>
            <h4>Extended Project Qualification - Mentee</h4>
              <h5>
                <b>Milestone:</b> Literature review
              </h5>
              <h6>
                <b>Deadline:</b> July 19<sup>th</sup>, 2023
                <br />
                <b>Feedback received by:</b> August 1<sup>st</sup>, 2023
              </h6>
              <div className="EPQ">
                <p className="profileDesc">
                  The upcoming milestone in your EPQ journey is to circulate
                  your literature review draft to your mentor for their reviewal. To do
                  so, please upload a <b>word document </b>version of your final draft
                  using the below button. The file name should be as follows: 'yourname+EPQMilestoneOne', for example: 'josephlevermoreEPQMilestoneOne'
                </p>              
                <Fileupload />
                <h5>
                  Unsure how to start?
                </h5>
                <p>
                  Please download our step-by-step guide to support you in completing <b>milestone one</b> of your EPQ journey with Off the Curriculum.  
                </p>
                <DocumentDownload
                documentUrl="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EPQ%2FMilestone%20One%2FMilestone%20One_%20Literature%20Review.docx?alt=media&token=c0f75565-0212-4a92-8327-f7e45e6194ba"
                fileName="Milestone One_Literature Review.docx"
              />
              </div>

          </div>
        ) :       
        <div className='EPQComponent' style={{display: 'none'}}>
          </div>
          } 
      </div>
    );
  };
  const EPQMentorComponent = () => {
    const { profileData } = useFirebaseData();  
      return (
        <div>
          {profileData && profileData.Mentor ? (
            <div className='EPQComponent'>
              <h4>Extended Project Qualification - Mentor</h4>
              <p>
                Thank you very much for volunteering to be a Extended Project Qualification (EPQ) Mentor with Off the Curriculum. Your support is invaluable! Your mentee <b>{profileData.EPQ.MenteeName}</b> has chosen to study <b>{profileData.EPQ.topicChosen}</b>.
              </p>
              <h5>
                  <b>Programme Logistics</b>
              </h5>
              <p>
                Please download the <b>Extended Project Qualification Welcome Pack</b>, which will be a step-by-step guide to support you in your EPQ mentoring journey with Off the Curriculum. To do so click the below button. 
              </p>
                  <DocumentDownload
                  documentUrl="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/EPQ%2FWelcomePack%2FExtended%20Project%20Qualification%20-%20Welcome%20Pack%20(2023-24).docx?alt=media&token=118c24d8-8f62-4b44-909e-f7e19ed22883"
                  fileName="Milestone One_Literature Review.docx"
                />
                <h5>
                  <b>Programme Training</b>
                </h5>
                <p>
                  Please see below an induction video explaining in detail the EPQ programme. In this video, Dr. Joseph Levermore - will explain the programme journey for you and your mentee and offer training and advice on interacting with your student, {profileData.EPQ.MenteeName}. Upon watching this, if you have any questions, please do <a className='EPQALink' href='mailto:josephlevermore@offthecurriculum.co.uk'> email Joseph directly.</a> 
                  </p>  
                  <EPQTrainingVideo />
                <h5>
                  <b>Milestone:</b> Literature review
                </h5>
                <h6>
                  <b>Deadline:</b> July 19<sup>th</sup>, 2023
                  <br />
                </h6>
                <div className="EPQ">
                  <h6>
                  {profileData.EPQ.MenteeName} draft EPQ Literature review
                  </h6>
                  <p>

                    The first milestone of your mentee's EPQ journey is to complete a Literature Review on {profileData.EPQ.topicChosen}. They have received a step-by-step guide from Off the Curriculum, which outlines how to write a Literature Review, perform a keyword search, and utilize scholarly search engines like Google Scholar. Once {profileData.EPQ.MenteeName} has completed the Literature Review, they will upload a copy in <b>Word document format to our database</b>. The button below will change from grey to salmon once the student has uploaded their file. If your button is still grey, please don't worry; your student will upload their EPQ essay shortly.
                  </p>    
                  <EPQMenteeDocumentDownload />
                  <h6>
                    Upload your comments and suggest amendments!
                  </h6>   
                  <p>
                    Ahead of your first EPQ meet the mentor session - if you have had time, please upload your comments and suggested improvements here.  
                  </p>          
                  <EPQMentorDocumentUpload />
                </div>
            </div>
          ) :       
          <div className='EPQComponent' style={{display: 'none'}}>
            </div>
            } 
        </div>
      );
    };
const EPQChatBox = () => {
  const { profileData } = useFirebaseData(); 
  const [isOpen, setIsOpen] = useState(false);  
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [chatUsers, setChatUsers] = useState([]);
  const [newMessages, setNewMessages] = useState(0);
  const mentorId = profileData?.EPQ?.MentorInfo; 
  const menteeId = profileData?.EPQ?.MenteeInfo;
  const userNameId = profileData?.Name;
  useEffect(() => {
    // Call the menteeInfo function and store the returned data in chatUsers state
    if (isOpen) {
      // Call the menteeInfo function and store the returned data in chatUsers state
      const chatUserList = menteeInfo();
      setChatUsers(chatUserList);
    }
  }, [isOpen]);
    useEffect(() => {
      const recentMessagesQuery = query(collection(firestore, 'mentors', `${mentorId}`, `${menteeId}`), orderBy("timestamp"));
      const unsubscribe = onSnapshot(recentMessagesQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const message = change.doc.data();
          if (change.type === "added" && message.isNew === true) {
            // Process the new message
            if (userNameId !== message.a) {
            //console.log(message)
            //console.log('New message:', message);
            setNewMessages(newMessages + 1);
            } else {

            }

            // Update the message's isNew flag to mark it as processed
            const docRef = doc(collection(firestore, 'mentors', `${mentorId}`, `${menteeId}`), change.doc.id);
            updateDoc(docRef, { isNew: false });
          }
        })
      });
    
      return () => {
        unsubscribe(); // Cleanup when component unmounts
      };
    }, [isOpen, newMessages, mentorId, menteeId]);

  const handleUserClick = (user) => {
    setSelectedUser(user);
  }
  function menteeInfo() {
    let epqMentees;
    let chatUsers;
    let epqMentor;
    let menteePic; 
    let mentorPic;
    if (profileData.Mentor === true) {
      epqMentees = profileData.EPQ.MenteeName;
      menteePic = profileData.picture;
      chatUsers = [
        { 
          id: 1, 
          name: `${epqMentees}`, 
          pic: menteePic
         },
      ];
    } else {
      epqMentor = profileData.EPQ.MentorName;
      console.log(epqMentor)
      mentorPic = profileData.picture;
      chatUsers = [
        { 
          id: 1, 
          name: `${epqMentor}`, 
          pic: mentorPic,
        },
      ];
    }
    return chatUsers;
  }
  // Conditionally render the entire component based on profileData
  if (profileData?.Mentor || profileData?.Mentee){
    //console.log(profileData.Mentor)
    return (
      <div style={{ position: 'fixed', bottom: 20, left: 20 }}>
        {/* Chat trigger button */}
        <button
        onClick={() => {
          setIsOpen(!isOpen);
          if (!isOpen) {
            // Reset new message count when opening the chat
            setNewMessages(0);
          }
        }}
        className={newMessages > 0 ? 'new-messages-button' : 'chat-button'}
      >
        {newMessages > 0 ? `New Messages (${newMessages})` : 'Chat'}
      </button>

        {/* Expandable chat section */}
        {isOpen && (
          <div
            style={{
              width: 300,
              height: 300,
              border: '1px solid black',
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid black',
              backgroundColor: 'white',
            }}
          >
            {isChatOpen ? (
              <>
                <button onClick={() => setIsChatOpen(false)}>
                  <BackIcon />
                </button>

                <ChatPage user={selectedUser} />
              </>
            ) : (
              <div>
                {chatUsers.map((user) => (
                  <div
                    key={user.id}
                    onClick={() => {
                      setSelectedUser(user);
                      setIsChatOpen(true);
                    }}
                    style={{
                      cursor: 'pointer', // Change the cursor to a pointer on hover
                    }}
                  >
                    <h6>Chat with your {profileData.Mentor ? 'mentee' : 'mentor'}:</h6>
                    <div className="user-info">
                      <img src={user.pic} alt="User Profile" />
                      {user.name}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  } else {
    // Return null and therefore no component
    return null;
  }
}    
export { EPQChatBox };

function ChatPage({ user }) {
  const [messages, setMessages] = useState([]);
  const { profileData } = useFirebaseData(); 
  const messageInputElement = useRef(null)
  const chatContainerRef = useRef(null); 
  const uniqueMessageIds = new Set();
  
  //this is what I need to change now - every user should have their mentor info
  const mentorId = profileData.EPQ.MentorInfo; 
  //console.log(mentorId)
  const menteeId = profileData.EPQ.MenteeInfo; 
  const profilePic = profileData.picture;
  const mentorTrue = profileData.Mentor; 
  const menteeTrue = profileData.Mentee;
  let getUserName = auth.currentUser.displayName;
  //console.log(menteeId)
  useEffect(() => {
    loadMessages();

    // Listen for new messages in real-time
    const recentMessagesQuery = query(collection(firestore, 'mentors', `${mentorId}`, `${menteeId}`), orderBy("timestamp"));
    const unsubscribe = onSnapshot(recentMessagesQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const message = change.doc.data();
          displayMessage(
            change.doc.id,
            message.timestamp,
            message.name,
            message.text,
            message.profilePicUrl
          );
        }
      });
    });

    return () => {
      // Unsubscribe from real-time updates when the component unmounts
      unsubscribe();
    };
  }, []);

  const scrollToBottom = () => {
    setTimeout(() => {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }, 0);
  };
  //const chat = getChatData(user.id); // Fetch chat data 
  function loadMessages() {

    if (profileData?.Mentor || profileData?.Mentee) {
      //console.log('hello')
      const recentMessagesQuery = query(collection(firestore, 'mentors', `${mentorId}`,`${menteeId}` ), 
      orderBy("timestamp")
      ); 
      //console.log('here', recentMessagesQuery)
      onSnapshot(recentMessagesQuery, function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "removed") {
            //console.log('removed')
          } else {
            let message = change.doc.data();
            //console.log(message);
            displayMessage(
              change.doc.id, // Pass the document ID as an identifier
              message.timestamp, // Pass the timestamp
              message.name, // Pass the name
              message.text, // Pass the text
              message.profilePicUrl // Pass the pic
            );
          }
        });
      });

    } else {
      // Handle the case when mentorInfo is falsy or not available
    }
  }
  async function saveMessage(messageText) {
    // Add a new message entry to the Firebase database.
    try {
      //console.log(getUserName)
      await addDoc(collection(firestore, "mentors", `${mentorId}`, `${menteeId}`), {
        studentId: `${menteeId}`,
        name: getUserName,
        text: messageText,
        profilePicUrl: profilePic, 
        timestamp: serverTimestamp(),
        isNew: true,
      });
    } catch (error) {
      //console.error("Error writing new message to Firebase Database", error);
    }
  }
  async function saveMessagingDeviceToken() {
    try {
      const currentToken = await getToken(getMessaging());
      if (currentToken) {
        // Saving the Device Token to Cloud Firestore.
        const tokenRef = doc(firestore, "fcmTokens", currentToken);
        await setDoc(tokenRef, { uid: auth.currentUser.uid });

        // This will fire when a message is received while the app is in the foreground.
        // When the app is in the background, firebase-messaging-sw.js will receive the message instead.
        onMessage(getMessaging(), (message) => {});
      } else {
        // Need to request permissions to show notifications.
        requestNotificationsPermissions();
      }
    } catch (error) {
      //console.error("Unable to get messaging token.", error);
    }
  }
      // Requests permissions to show notifications.
      async function requestNotificationsPermissions() {
        const permission = await Notification.requestPermission();
    
        if (permission === "granted") {
          // Notification permission granted.
          await saveMessagingDeviceToken();
        } else {
        }
      }
      async function onMessageFormSubmit(e) {
        e.preventDefault(); // Prevent the default form submission behavior

    const messageText = messageInputElement.current.value;

    if (messageText.trim() === "") {
      alert("Please enter a message before sending.");
      return;
    }

    if (!auth.currentUser) {
      alert("Please sign in to send a message.");
      return;
    }

    // Disable the send button while the message is being sent to prevent double submission
    document.getElementById("submit").disabled = true;

    try {
      await saveMessage(messageText);
      // Clear message input field
      messageInputElement.current.value = "";
    } catch (error) {
      //console.error("Error sending message:", error);
    } finally {
      // Re-enable the send button after the message is sent (whether successful or not)
      document.getElementById("submit").disabled = false;
    }
  }


  function displayMessage(id, timestamp, name, text, pic) {
    // Generate a unique message identifier
    const messageId = `${id}_${timestamp}_${name}`;

    // Check if the message with the same identifier already exists
    if (!uniqueMessageIds.has(messageId)) {
      const newMessage = {
        id: messageId,
        timestamp,
        name,
        text,
        pic,
      };

      // Update the Set with the new message ID
      uniqueMessageIds.add(messageId);

      // Update the messages state with the new message
      setMessages((prev) => [...prev, newMessage]);

    }
  }
  
  function deleteMessage(id) {
    // Remove message from UI
    setMessages(prev => prev.filter(m => m.id !== id))
    return(
      <>
      </>
    )
  }
  scrollToBottom();
  return (
    <div 
    ref={chatContainerRef}
    style={{
      flex: 1,
      overflowY: 'scroll',
      maxHeight: '300px', // Set a fixed or maximum height
      /* Additional CSS styles */
    }}
    >
      <h6>{user.name}</h6>
      {/*{chat.messages.map(message => (
        <div>{message.text}</div>
      ))}*/}
        <>
        {messages.map((message) => { 
          return (
            <Message
              key={message.id}
              timestamp={message.timestamp}
              name={message.name}
              text={message.text}
              pic={message.pic}
            />
          );
        })}
        </>
        <form onSubmit={onMessageFormSubmit}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <textarea
          ref={messageInputElement}
          className="messageField"
          id="messageInput"
          placeholder="Type your message..."
          spellCheck
          autoComplete="off"
          style={{ flex: 1, resize: 'vertical' }} // Allow vertical resizing
        ></textarea>
        <button
            id="submit"
            type="submit"
            className="messageSubmit"
        >Send</button>
      </div>
      </form>
    </div>
  )
} 
function Message({id, timestamp, name, text, pic}) {
  if (!timestamp || !timestamp.seconds || !timestamp.nanoseconds) {
    return null; // or handle the case when timestamp is not defined
  }
  const seconds = timestamp.seconds;
  const milliseconds = timestamp.nanoseconds / 1e6;
  const dateTime = new Date(seconds * 1000 + milliseconds);
  function convert(timer) {
    //console.log(timer);
    return timer.toDate();
  }
  let time = convert(timestamp);
  let timer = time.toString().split("GMT")
  
  return (
    <div className="chatMessage">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={pic} 
          alt='Avatar'
          style={{ 
            marginRight: '10px', 
            width: '40px', 
            height: '40px' }}
          />
          <div>
            <div style={{ 
              color: 'grey', 
              fontSize: '14px' 
            }}>
              {name}
            </div>
            <div style={{ 
              color: 'grey', 
              fontSize: '9px' 
            }}>
              {timer[0]}
            </div>
            <div style={{ 
              color: 'black', 
              fontSize: '16px' }}>
                {text}
              </div>
          </div>
        </div>
      <div></div>
    </div>
  );
}

const OtherOpportunities = () => {
    return (
        <div className="profilePhotoContainer">
        <div className="profilePhotoSection">
          <h3>Affordable tutoring</h3>
          <p>Name</p>
          <p>My goal: </p>
        </div>
      </div>
    );
};

const TrialProfile = () => {

  return (
    <div className='profile-frame'>
      <Helmet>
        <title>Profile</title>
        <meta name="description" content='Off the Curriculum Profile' />
        <meta property="og:title" content="Profile" />
        <meta property="og:description" content="https://www.splashlearn.com/math-vocabulary/wp-content/uploads/2023/01/pp1-2.png" />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        {/* Add more meta tags as needed */}
      </Helmet>
        <Row className="show-grid">
            {/*Left sided navigation pane*/}
            
            <Col xs={12} sm={12} md={12} ig={12}>
              <FirebaseDataProvider>

                  <UserInfoComponent />
                  <EPQComponent />
                  <EPQMentorComponent />
                  <EPQChatBox />
    

              </FirebaseDataProvider>
            </Col>
        </Row>
    </div>
  ) 
}

export default TrialProfile
