import Stars from '../../../../../images/functionality/educo/icons/term-1/astronomy/stars.webp'
import Planets from '../../../../../images//functionality/educo/icons/term-1/astronomy/planets.webp'
import Protoplanets from '../../../../../images/functionality/educo/icons/term-1/astronomy/protoplanets.webp'
import Solarsystem from '../../../../../images/functionality/educo/icons/term-1/astronomy/solarsystem.webp'
import Telescope from '../../../../../images/functionality/educo/icons/term-1/astronomy/telescope.webp'
import Moon from '../../../../../images/functionality/educo/icons/term-1/astronomy/moon.webp'

const objAstronomy = {
    webpagedesc: ``, 
    webpagekeywords: ``, 
    webpagepageurl: ``, 
    webpagethumbnail: ``, 
    webpagethumbnailAlt:``, 
    organisationtwitter: ``, 
    title: 'Astronomy', 
    courseDesc: 
        `
        During the astronomy/astrophysics online seminar series, you will explore the solar system, learn about the beauty of the cosmos, and comprehend the fragility of life.
        `
    ,
    quickLinkOneDesc:
    `
    Induction seminar
    `
    ,
    quickLinkOne: `
    /AstronomyInductionSeminar
    `, 
    quickLinkTwoDesc: 
    `
    The Stars
    `, 
    quickLinkTwo:
    `
    /AstronomyTheStars
    `, 
    quickLinkThreeDesc:
    `
    Dwarf Planets
    `, 
    quickLinkThree: 
    `
    /AstronomyDwarfPlanets
    `, 
    quickLinkFourDesc:`
    TheMoons
    `, 
    quickLinkFour: 
    `
    /AstronomyTheMoons
    `, 
    quickLinkFiveDesc: 
    `
    Space Policy
    `, 
    quickLinkFive: 
    `
    /AstronomySpacePolicy
    `, 
    courses:[
    {
        key: 1, 
        sectionimage: Telescope, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        Induction Seminar
        `, 
        sectiondesc: `
        Our Astrophysics Iduction Seminar featured guest lectures from two Ph.D. candidates Sara Motaghian and Liina Jukko. Sara discussed the possibilities of life on Mars and Liina delved into all things Axions and Dark Matter. 
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
                [
                    {
                        key: 1, 
                        videoTitle: 'A Journey Through The Solar System', 
                        videoDesc:`In the seminar, you will explore the Cosmos hearing about the different types of Space Missions, the ExoMars 2022 programme, the analytical instruments equipped on the Rosalind Franklin Rover, the difference between Chondritic and Iron meteorites (Sara Montaghain), the particle-wave duality, Dark and Normal Matter, Dark Matter candidates, Axion stars, Inflation and all things Black holes. Enjoy!`, 
                        videoInfo: `Views: 15 | Likes: 11 | Sara Motaghian and Liina Jukko`, 
                        videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomyInductionSeminar%2FastronomyInductionSeminarThumbnail.webp?alt=media&token=080d378d-51a3-4ab9-810d-ad7e405d3b1b',
                        videoImageAlt:`A Journey Through The Solar System Thumbnail`, 
                        videohref:`/AstronomyInductionSeminar`, 
                    },
                ]
    }, 
    {
        key: 2, 
        sectionimage: Solarsystem, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        The Solar System
        `, 
        sectiondesc: `
        In these educo videos, you will explore the formation of the solar system, study the evolution of the Solar System and the physical changes of asteroids. Additionally, you will gain insights into different types of space exploration missions, their scientific aims, instrumentation, and the study of planetary atmospheres and surfaces.
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'Space Policy', 
                videoDesc:`Led by Research Fellows at the European Space Policy Institute (ESPI), find out how we engage with space to maximise benefit to society..`, 
                videoInfo: `Views: 3 | Likes: 1 | Jules Varma`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FSpacePolicy%2FspacePolicyThumbnail.webp?alt=media&token=d8fa576a-008b-41e6-9214-611c0cbe786e',
                videoImageAlt:`A Journey Through The Solar System Thumbnail`, 
                videohref:`/AstronomySpacePolicy`, 
            },
            {
                key: 2, 
                videoTitle: 'The Solar System', 
                videoDesc:`Why not uncover extraterrestrial missions to Mars, Jupiter and beyond!`, 
                videoInfo: `Views: 10 | Likes: 1 | Teresa Su`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FtheSolarSystem%2FtheSolarSystemThumbnail.webp?alt=media&token=c665d55c-f9d8-49a5-b548-94b310a86e6c',
                videoImageAlt:`A Journey Through The Solar System Thumbnail`, 
                videohref:`/AstronomyTheSolarSystem`, 
            },
            {
                key: 3, 
                videoTitle: 'The Mystery of Dark Matter', 
                videoDesc:`Explore's the term “Dark matter”, and how it composes around 27% of the universe’s energy.`, 
                videoInfo: `Views: 6 | Likes: 4 | Liina Jukko`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FtheMysteryofDarkMark%2FmysterydarkMatterThumbnail.webp?alt=media&token=c2980edb-84cd-4d46-972c-2479210a39c9',
                videoImageAlt:`The Mystery of Dark Matter Thumbnail`, 
                videohref:`/AstronomyDarkMatter`, 
            },
        ]
    }, 
    {
        key: 3, 
        sectionimage: Stars, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        The Stars
        `, 
        sectiondesc: `
        Here you will review the physics of Stars. This seminar will introduce you to hydrostatic and thermal equilibrium equations, elemental abundances and the Solar neutrino problem, conditions for convective instability, and stellar structure equations.
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'The Stars', 
                videoDesc:`We will explore how Stars evolve from a pre-main sequence star to a Supernova (depending on the Stars size). The rise and death of a Star is an incredibly dramatic episode, one we are sure you are going to enjoy exploring!`, 
                videoInfo: `Views: 17 | Likes: 2 | Teresa Su`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FtheStars%2FtheStarsThumbnail.webp?alt=media&token=7e1cd686-e8bd-4a8f-aa7b-4b106dd5c77d',
                videoImageAlt:`
                videoTitle: 'The Stars Thumbnail`, 
                videohref:`/AstronomyTheStars`, 
            },
            {
                key: 2, 
                videoTitle: 'An Astronomical Object: Black Hole', 
                videoDesc:`According to the theory of general relativity, the fabric of the universe is a 4-dimensional spacetime.`, 
                videoInfo: `Views: 15 | Likes: 11 | Liina Jukko`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FanAstrophysicalObjectBlackHole%2FanAstronomicalObjectThumbnail.webp?alt=media&token=1ca77c99-7279-4310-bfe4-afd0869c2873',
                videoImageAlt:`An Astronomical Object: Black Hole Thumbnail`, 
                videohref:`/AstronomyBlackHoles`, 
            },
        ]
    }, 
    {
        key: 4, 
        sectionimage: Protoplanets, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        The Protoplanets
        `, 
        sectiondesc: `
        In these EDUCO videos, you will delve into the concept of "Protoplanets," which are celestial bodies that possess enough mass to be influenced by gravity and orbit a star (Sun) but are neither moons nor planets. This seminar will explore the role of Protoplanets in uncovering Neptune's orbital origins, providing insights into the early solar system, assisting in the search for planet 9, and enhancing our understanding of icy moons like Ceres.
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
            key: 1, 
            videoTitle: 'Dwarf Planets', 
            videoDesc:`Explore the controversial history of classifying Pluto - is it a Planet or just another celestial body?`, 
            videoInfo: `Views: 4 | Likes: 2 | Teresa Su`, 
            videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FdwarfPlanets%2FdwarfPlanetsThumbnail.webp?alt=media&token=d3f81ec5-d99f-4d41-87de-c6b8ceac8162',
            videoImageAlt:`A Journey Through The Solar System Thumbnail`, 
            videohref:`/AstronomyDwarfPlanets`, 
            },
        ]
    }, 
    {
        key: 5, 
        sectionimage: Planets, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        The Planets
        `, 
        sectiondesc: `
        In these EDUCO videos, you will explore the physics of planetary and satellite interiors, atmospheres, and orbital dynamics. Topics covered include planetary differentiation, internal density models, heat generation and flow, magnetic field generation, and the evolution of atmospheres in gas giant and terrestrial planets. Emphasis will be placed on contrasting the histories of Earth, Mars, and Venus.
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
            key: 1, 
            videoTitle: 'The Planets', 
            videoDesc:`How do scientist examine planetary interiors using Seismology, Geodesy, Planetary heatflow, and Electromagnetic sounding?`, 
            videoInfo: `Views: 3 | Likes: 1 | Teresa Su`, 
            videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FthePlanets%2FthePlanetsThumbnail.webp?alt=media&token=86a39d43-4da6-4171-813a-77fe6cd6c5bf',
            videoImageAlt:`The Planets Thumbnail`, 
            videohref:`/AstronomyThePlanets`, 
            },
        ]
    }, 
    {
        key: 6, 
        sectionimage: Moon, 
        sectionimageAlt: `
        Astronomy section icon
        `, 
        sectiontitle: `
        The Moons
        `, 
        sectiondesc: `
        In these Educo videos, you will explore tidal phenomena, including ocean tides and the Moon's involvement. The seminar will also highlight the moon's potential for scientific research and its unique role in providing fundamental astrophysical measurements for our understanding of gravity in the Universe.
        `, 
        sectionlinkdesc: `
        Click here to watch their Lecture
        `, 
        sectionlink: `
        /AstronomyInductionSeminar
        `,
        educovideo: 
        [
            {
                key: 1, 
                videoTitle: 'The Moons', 
                videoDesc:`Let's discover how the Moon plays an important part in our Cosmological research, and explore the plans for NASA's Lunar Crater Radio Telescope programmme!`, 
                videoInfo: `Views: 31 | Likes: 3 | Teresa Su`, 
                videoImage: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FtheMoons%2FtheMoonThumbnail.webp?alt=media&token=c20af988-8c86-40b8-b6de-fc0f9edfd482',
                videoImageAlt:`The Moons Thumbnail`, 
                videohref:`/AstronomyTheMoons`, 
            },
        ]
    }
    ],
}

export default objAstronomy; 
