/**dependencies */
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  setDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { ref, onValue, query as dbQuery } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useRef, useState, useEffect, useContext } from "react";
/**components */

import { firestore, auth, db } from "./firebase";
import { UserEmail } from "../App";
import { LoginContext } from "../App";

function emails(s) {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

export function GetProfilePicUrl() {
  const [currentUser, setCurrentUser] = useState();
  const isUserSignedIn = useContext(LoginContext);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  });

  GetProfilePicUrl.picture = useRef();
  useEffect(() => {
    let emailStart = UserEmail.Provider;

    if (typeof emailStart.email !== "undefined") {
      let email = emails(emailStart.email);
      const dbRef = dbQuery(ref(db, "person/" + email));
      onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.val() === null) {
            // window.location.replace("/CreateAProfile");
          } else {
            snapshot.forEach((childSnapshot) => {
              const childData = childSnapshot.val();
              GetProfilePicUrl.picture.current = childData.picture;
            });
          }
        },

        {
          onlyOnce: true,
        }
      );
    }
  }, [currentUser]);
  return GetProfilePicUrl.picture.current;
}
// Loads chat messages history and listens htmlFor upcoming ones.
let getUserName;
export function messageBuilder() {
  if (window.onload) {
    getUserName = auth.currentUser.displayName;
  }
  function loadMessages() {
    // Create the query to load the last 12 messages and listen htmlFor new ones.
    let dataComments = collection(firestore, "comments");

    let comment = document.title;
    let commentStore = comment.replaceAll(" ", "");

    const recentMessagesQuery = query(
      collection(firestore, "comments", "videos", commentStore),
      orderBy("timestamp")
    );

    // Start listening to the query.
    onSnapshot(recentMessagesQuery, function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        if (change.type === "removed") {
          console.log(change.doc.id);
        } else {
          let message = change.doc.data();
          console.log(message);
        }
      });
    });
  }
  async function saveMessage(messageText) {
    // Add a new message entry to the Firebase database.
    let comment = document.title;
    let dataComments = collection(firestore, "comments");

    let commentStore = comment.replaceAll(" ", "");

    try {
      //console.log(GetProfilePicUrl.prototype);
      await addDoc(collection(dataComments, "videos", commentStore), {
        name: getUserName,
        text: messageText,
        profilePicUrl: GetProfilePicUrl.picture.current,
        timestamp: serverTimestamp(),
      });
    } catch (error) {
      //console.error("Error writing new message to Firebase Database", error);
    }
  }

  
  // Template htmlFor messages.
  let MESSAGE_TEMPLATE =
    '<div className="messageContainer">' +
    '<div id="pic"></div>' +
    '<div id="name">:</div>' +
    "|" +
    '<div id="time">:</div>' +
    '<div id="message">:</div>' +
    "</div>";


  // Enables or disables the submit button depending on the values of the input
  // fields.


  loadMessages();
}

window.onload = (e) => {
  let refresh = document.getElementById("refresh");

  refresh.addEventListener("click", () => {
    messageBuilder();
  });
};