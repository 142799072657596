import objMightyMinds from "./supplementaryProg/MightyMinds/coursePage/MightyMinds";
import objAstronomy from "./term-1/Astronomy/CoursePage/Astronomy";
import objBusiness from "./term-1/Business/coursePage/Business";
import objHistoryAfricanDiaspora from "./term-1/history/coursePage/HistoryAfricanDiaspora";
import objImmunology from "./term-1/Immunology/CoursePage/Immunology";
import objMusic from "./term-1/Music/coursePage/Music";
import objPsychologyNeuroscience from "./term-1/psychologyNeuroscience/coursePage/PsychologyNeuroscience";
import objClimateChange from "./term-2/climateChangeAndSustainability/coursePage/ClimateChange";
import objArtificialIntelligenceRobotics from "./term-3/artificialIntelligenceRobotics/coursePage/ArtificialIntelligenceRobotics";
import objPolitics from "./term-3/politics/coursePage/Politics";


export default {
    objAstronomy, 
    objArtificialIntelligenceRobotics,
    objBusiness,
    objClimateChange,
    objImmunology, 
    objMightyMinds, 
    objMusic,
    objPolitics,
    objPsychologyNeuroscience, 
    objHistoryAfricanDiaspora
}; 
