import React from "react";

function Student() {
    return ( 
        <>
        <h1>hello</h1>
        </>

        );
    }
    export default Student;