import React, { useState, useEffect } from 'react';
import { getDatabase, child, get } from 'firebase/database';

import { getStorage, ref, getDownloadURL, listAll, downloadURL, getMetadata } from "firebase/storage";
import { useFirebaseData } from '../../MainPage/firebaseData'; 

const EPQMenteeDocumentDownload = ({ folderPath }) => {
    const { profileData } = useFirebaseData();
    const [isFolderPresent, setIsFolderPresent] = useState(false);
    const [filePath, setFilePath] = useState();
    const storage = getStorage();
  useEffect(() => {
    const fetchData = async () => {
      //Next step of this is to pull down the data based on a users input in the left column! 
      //You have to take the average of each spectrum present in the folder
      try {
            const menteeEmail = profileData.EPQ.MenteeInfo; 
            const fileRef = ref(storage, `EPQ/MenteeFolder/litReview/${menteeEmail}`);  
            const folderSnapshot = await listAll(fileRef);
            //console.log(folderSnapshot.items.length ===1)
            if (folderSnapshot.items.length === 1) {
                const fileMetadata = folderSnapshot.items[0];
                getMetadata(fileMetadata).then((metadata) => {
                    setFilePath(metadata.fullPath)
                })
                .catch((error) => {
                    // Uh-oh, an error occurred!
                });
                setIsFolderPresent(true)

            } else {
                //console.log("There are no files in the folder.");
                setIsFolderPresent(false)
            }
            } catch(error) {
                setIsFolderPresent(false);
                alert('Error:', error, 'Please inform Dr. Joseph Levermore!')
                //console.log('error')
            }
            };


            fetchData();
        }, [folderPath]);   

            const handleDownload = async () => {
                //Edit file uploader button, if file is present the download url will be collected, and file will be downloaded. If there is no file present in the folder - the button will be greyed out, and an alert message will appear (on click). 
            if (isFolderPresent === true) {
                getDownloadURL(ref(storage, `${filePath}`))
            .then((url) => {
                const link = document.createElement('a');
                link.href = url;
                link.click();
            })
            .catch((error) => {
                // Handle any errors
            });

            } else {
                alert("Your mentee is yet to upload their Literature Review!");
            };
            }

  // Edits the colour of the button - grey if false, salmon if true
  const buttonColor = isFolderPresent ? '#ffbaab' : 'grey';

  return (
    <button className={`fileDownloader ${buttonColor}`} style={{backgroundColor:buttonColor}} onClick={handleDownload}>
      Download me
    </button>
  );
};


export default EPQMenteeDocumentDownload;
