const objRecruitmentOfficer = {
  title: "Recruitment Officer",
  subtitle: "London or Remote",
  desc: `help recruit different types of volunteers from London Universities and 
        various profiles of Officers for the Charity.`,
  descFull: ``,
  skills: [
    "HR",
    "Office and Administration Management",
    "Volunteer Management",
  ],
  where: {
    location: "London, SE12DU",
    desc: "Mainly at home, or remote opportunity.",
  },
  time: {
    workTime: "Either in or out of office hours",
    timeNeeded: "3-7 hours / week",
  },
  whatWillYouBeDoing: {
    p: `The main duties of the role include:`,
    ul: [
      `Working closely with the Volunteer Lead and Officer to recruit our 
        targeted number of Student Volunteers for each term (6-week periods)`,
      `Using different Social Media platforms to identify suitable candidates 
        and recruit them`,
      `Collaborating with the Communications and Marketing Lead and Social Media
        Officers to improve the Charity’s social media profile to attract more
        candidates`,
      `Taking part in short interviews to assess the recruited candidates’ 
        motivationsand profiles`,
    ],
  },
  whatAreWeLookingFor: {
    p: ``,
    ul: [
      `Excellent multi-channel communication and network building`,
      `Independent and team working skills`,
      `Good time management`,
      `Experience using Social Media`,
      `Experience in Recruitment (interviewing, onboarding, etc.)`,
    ],
  },
  whatDifferenceWillYouMake: {
    p: `The Recruitment Officer will have direct impact on the growth of the 
        Charity and the quality of the participants' and volunteers' experience. 
        Helping with recruitment, they will have the opportunity to build 
        prosperous relationships with the local community and all those involved 
        with the Charity.`,
    ul: [],
  },
  whatsInItForTheVolunteer: {
    p: ``,
    ul: [],
  },
};

export default objRecruitmentOfficer;
