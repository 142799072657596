const programme = {
    page_title:"The Seminar Series Programme",
    programme_desc: `
        Off the Curriculum's educational programme is a term-time only programme, consisting of in-person seminars (term-time) and educational excursions. 
        Off the Curriculum organise weekly seminar series in six-week blocks, during the first three terms of each academic year. 
        Our student-centred project based programme gives participants the deciding say on what they would like to learn. Decided by a majority vote, with each student voting from a predefined list of potential projects. 
        To view the predefined list of projects for our upcoming series, and to investigate our 2021/22 seminar series, please see below. 
    `, 
    yearOnetitle: "Year One: 2021/22", 
    yearOneprogramme: "The schedule of last years' educational  (September 2021 - April 2022) can be seen below:    ", 
    courseTitleFour: "The African Diaspora", 
    yearTwotitle: `Year Two: 2022/23`,
    yearTwoprogramme: `The schedule for our educational programme this academic year (September 2022 - June 2023) can be seen below:`, 
    summerTermOneTitle: `Summer Term Five: September to October`, 
    summerTermOnedesc: `
    During the term five, participants will have the opportunity to choose from "COVID-19 and it's impacts on human society", "Inequality in the AI age", and "Twenty one solutions for the Twenty First Century"!
    The schedule for our induction term is described below:
    `,
    summerT1ChoiceOneTitle: `COVID 19 and it's impact on human society`, 
    summerT1C1S1:`Lung Anatomy and Respiratory Medicine`, 
    summerT1C1S2: `Transmission modeling`, 
    summerT1C1S3: `Evaluating the human impact of lockdowns`, 
    summerT1C1S4: `Govermental Policy in response to outbreaks`, 
    summerT1C1S5: 'A new outbreak has sweeped the Nation: Calling our future leaders, we need your support', 
    summerT1ChoiceTwoTitle: `Inequality in the AI age`, 
    summerT1C2S1: `What it means to have less?`, 
    summerT1C2S2: `Should comparative living be a central thesis of society`,
    summerT1C2S3: `Modelling inequality`, 
    summerT1C2S4: `Is inequity a thing to be solved? `, 
    summerT1C2S5: `To build you state: How would you bring about a more equal society?`, 
    summerT1ChoiceThreeTitle:`Twenty one solutions for the Twenty First Century`,
    summerT1C3S1: `Art in the AI Age`, 
    summerT1C3S2: `Brain Computer Interface and the Future of Human Intelligence`, 
    summerT1C3S3: `Potentialities of the future`, 
    summerT1C3S4: `Travel of the future: Extraterrestrial`, 
    summerT1C3S5: `What will you solve?`, 

    summerTermTwoTitle: 'Winter Term Six: Saturday February 4th, 2022 | 10:00-16:00', 
    summerTermTwodesc: `
    During the term five, participants will have the opportunity to choose from "Mitigating environmental catastrophe", "A peoples history", and "Is history cyclical or linear"!
    The schedule for our induction term is described below:
    `,
    summerT2ChoiceOneTitle: `Mitigating environmental catastrophe`, 
    summerT2C1S1: `Chemical pollution and environmental degradation`, 
    summerT2C1S2: `Environmental individualism and policy`, 
    summerT2C1S3: `Botany for the 21st Century`, 
    summerT2C1S4: `Green washing`, 
    summerT2C1S5: 'Atmospheric CO2 is now 500ppm, where do we go from here?', 
    summerT2ChoiceTwoTitle: `A peoples history`, 
    summerT2C2S1: `A videographic family tree!`, 
    summerT2C2S2: `Confessions of human migration`,
    summerT2C2S3: `The great explorers`, 
    summerT2C2S4: `Historically influential peoples and how they shaped my world`, 
    summerT2C2S5: `What do you think the story of your descendants will be!`, 
    summerT2ChoiceThreeTitle:`Is history cyclical or linear?`,
    summerT2C3S1: `Mistake after mistake: A story of human history`, 
    summerT2C3S2: `Theodosius, Tang dynasty, Mana mausi, and Ardashir I: Akin or unlike?`, 
    summerT2C3S3: `COVID-19, Spanish Influenza, Ebola, Bubonic plague: Of biologics `, 
    summerT2C3S4: `Dickinson, Goethe, Hemingway: Of written word`, 
    summerT2C3S5: `Your predictions for the future`, 

    autumnTermThreeTitle: 'Spring Term Seven: Saturday March 25th, 2022 | 10:00 - 16:00', 
    autumnTermThreedesc: `
    During the term five, participants will have the opportunity to choose from "Navigating a Post-Truth World", "War is a necessary evil", and "Religion and the future".
    The schedule for our induction term is described below:
    `,
    autumnT3ChoiceOneTitle: `Navigating a Post-Truth World`, 
    autumnT3C1S1: `What is a lie?`, 
    autumnT3C1S2: `Can you tell real from fake: Deep fakes and AI`, 
    autumnT3C1S3: `The psychology of manipulation`, 
    autumnT3C1S4: `Will community bonds hold?`, 
    autumnT3C1S5: 'Who should be responsible for regulating truth?', 
    autumnT3ChoiceTwoTitle: `War is a necessary evil`,
    autumnT3C2S1: `Can a universial definition of 'evil' be adopted?`, 
    autumnT3C2S2: `Imperial wars`,
    autumnT3C2S3: `The psychology of the individual and society`, 
    autumnT3C2S4: `War and the environment`, 
    autumnT3C2S5: `Geopolitics: Mitigating wars of your future`, 
    autumnT3ChoiceThreeTitle:`Religion and the future`,
    autumnT3C3S1: `Religion and human development`, 
    autumnT3C3S2: `The rise of the secularism and its shadow`, 
    autumnT3C3S3: `The creation of a national identityy`, 
    autumnT3C3S4: `Are we all God's chosen nation`, 
    autumnT3C3S5: `The role of Religion in our lives`, 
}

export {
    programme,
};