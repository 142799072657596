const extendedProject = {
    pageTitle: `
    Off the Curriculum and the Extended Project Qualification
    `, 
    pageIntroduction: `
    The Extended Project Qualification (EPQ) is an independent research project worth half an A-level. Many schools offer this for students to complete alongside their A-levels, and is a programme moderated by school representatives and evaluated by exam boards like Edexcel.
    To receive an EPQ students have the choice of three routes: Dissertation - an extended essay of 5000 words, Investigation - collection of primary data, creation of a 4000-5000 word report and delivery of a 10-minute presentation, or Artefact - a shorter essay of 1000 words to accompany an artefact they create - these can range from an app to a creative writing piece.
    OTC's recent partnership with Lambeth Academy states that seminar volunteers (Ph.D. candidates or Post-Docs) engaged with our charity are able to act as experts for students completing an EPQ, which has been designed to be beneficial to both the mentor and mentee.
    The EPQ qualification will be awarded by Lambeth Academy and not OTC. During this pilot programme, both institutions will assess the effectiveness of this mentorship programme by comparing the quality of OTC participants’ EPQs to the EPQs of students from previous cohorts who weren’t enrolled in the programme. 
    This EPQ programme will run as part of Off the Curriculum's Academic Mentoring scheme. 
    `, 
    headerTwo: `
    EPQ Programme Itinerary
    `, 
    headerThree:`
    Milestones
    `, 
    milestonesDesc: `
    The EPQ qualification will be awarded by Lambeth Academy and not OTC. During this pilot programme, both institutions will assess the effectiveness of this mentorship programme by comparing the quality of OTC participants’ EPQs to the EPQs of students from previous cohorts who weren’t enrolled in the programme. 
    `, 
    headerFour: `
    OTC-led Literature Review
    `, 
    litreviewDesc: `
    During the first month of the EPQ (March to April 18th), students will have the opportunity to conduct their own literature review and will be mentored on how to do so throughout its completion via two Off the Curriculum Welcome sessions.
    This created review document will be circulated to the students mentors prior to their first mentor-mentee session, for both parties to discuss in further detail.
    `, 
    mentorproTitle: `
    Mentor Programme
    `, 
    mentorproDesc: `
    Over the course of the remaining EPQ programme, students and their mentors will meet either during their compulsory quarterly milestone meetings or for additional meetings agreed upon by involved parties, to discuss updates on their project. Compulsory quarterly meetings will consist of the following milestones: Commission, Progression, Evaluation, and Reflection (CPER - a working title). 
    `, 
    recruitmentTitle:`
    Recruitment and Training for Mentors
    `, 
    recruitmentsubHeading:`
    Recruitment
    `, 
    recruitmentP:`
    Off the Curriculum will lead the recruitment of mentors for the students EPQ projects, which will be based on the mentors area of expertise and their alignment to our Safeguarding policies. 
    `, 
    recruitmentsubHeading2: 
    `
    Training
    `, 
    trainingDesc:`
    Off the Curriculum will run two key training sessions: an Induction and Academic Training Programme. During the Induction training session, all mentors will receive an in-depth induction detailing: the EPQ; the milestone programme; the marking criteria and how to bring about a successful project; and safeguarding and child protection policies.
    During the bespoke Mentorship Training session, the content will cover communication, facilitating a research project, working to the EPQ's marking criteria, and how to lead the creation of a successful mentor-mentee relationship.  
    `, 
    evaluationTitle:`
    Review of Programme
    `, 
    evaulationDesc:`
    Off the Curriculum will evaluate the performance of this programme in collaboration with Lambeth Academy personnel. Initial discussions have indicated that the following may be of interest: Qualitative - student perception of the EPQ and their engagement; Quantitative - comparative results between our cohort and the year prior; and National statistics comparisons. 
    `, 
    






}

export {
    extendedProject, 
}