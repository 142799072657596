import { db } from "./firebase";

import { ref as refs, onValue, query } from "firebase/database";
import { auth } from "./firebase";

import { useState, useEffect } from "react";

let quizzes = [];

export default function GetQuizResult() {
  let number = 0;

  const [quiz, setQuiz] = useState(["no quiz results"]);
  const [counts, setCounts] = useState(number);

  function emails(s) {
    return s.split("").reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }
  let email = emails(auth.currentUser.email);

  //console.log(email.prototype, email);
  const dbRef = query(refs(db, "Quiz/" + email));

  //console.log(dbRef);
  onValue(
    dbRef,
    (snapshot) => {
      //console.log(snapshot.val());
      if (snapshot.val() == null) {

      } else {
      
            quizzes.push([snapshot.val()]);
            //console.log(quizzes);

            number++;
            setCounts(number);
         // }
        //});
      }
    },
    {
      onlyOnce: true,
    }
  );
let response = []
  useEffect(() => {
    if (counts === 1) {
      quizzes[0].map((res) => {
         let solos =Object.entries( res);
       //console.log(solos)

solos.forEach((solo) =>{
  let wrongs = solo[1].wrongA
  let percentage = (solo[1].score / solo[1].maxScore) *100
  //console.log(percentage)
  let wrong = Object.entries( wrongs).toString()
  //console.log()
         response.push(
          <div>
            <h4>
              {" "}
              For the quiz {solo[1].title}, you scored {percentage}%. The answer(s) you got wrong are as follows:
              {wrong}
            </h4>
          </div>
        );
        setQuiz(response)
      })
     
      });
    }
  }, [counts]);

  return (
    <>
      <div>
        {counts === 1 ? <div>{quiz}</div> : <div>No quiz results </div>}
      </div>
    </>
  );
}