import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "off-the-curriculum-8ef2a.firebaseapp.com",
  databaseURL:
    "https://off-the-curriculum-8ef2a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
//process.env.REACT_APP_FIREBASE_TOKEN;
let sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
let debugToken = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
const app = initializeApp(firebaseConfig);

const auth = getAuth();

if (
  window.location.host === "localhost:3000" ||
  window.location.host === "localhost:3001"
) {
  // key for debug and prod
  if (process.env.NODE_ENV !== "production") {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(sitekey, debugToken),
    isTokenAutoRefreshEnabled: true,
  });
} else {
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(sitekey),
    isTokenAutoRefreshEnabled: true,
  });
}

export { auth };
export { app };
export const fbStorage = getStorage();
export const firestore = getFirestore()

export const db = getDatabase();

/** initialize app-check
/*const appCheck =
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} = require("firebase/app-check");
//  initialize app
const app = initializeApp(firebaseConfig);
// check if in debug mode for app-check token to work
{/
}*/